import React, { Dispatch, SetStateAction } from 'react';
import * as C from './styles';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { IUser } from '../../interfaces/user.interface';

interface ICard {
  user: IUser;
  handleEdit: (user: IUser) => void;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
}

const CardUser = ({ handleEdit, setShowDialog, user }: ICard) => {
  return (
    <C.Card>
      <C.TitleCard>{user.name}</C.TitleCard>
      <C.containerInfos>
        <div>
          <span>E-mail</span>
          <p>{user.email}</p>
        </div>
        <div>
          <span>Tipo</span>
          <p>{user.role}</p>
        </div>
      </C.containerInfos>
      <C.containerButtonsActions>
        <IconButton className="btn_edit" onClick={() => handleEdit(user)}>
          <EditIcon />
        </IconButton>
        <IconButton className="btn_delete" onClick={() => setShowDialog(true)}>
          <DeleteIcon />
        </IconButton>
      </C.containerButtonsActions>
    </C.Card>
  );
};

export default CardUser;
