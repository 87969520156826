import Api from '../api';
import { IInstitutions } from '../interfaces/institutions.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(data: IInstitutions): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.post(
    '/institutions',
    {
      name: data.name,
      title: data.title,
      type: data.type,
      description: data.description,
      contact: data.contact,
      socialMedia: data.socialMedia,
      address: {
        street: data.address.street,
        number: data.address.number,
        zipCode: data.address.zipCode,
        district: data.address.district,
        city: data.address.city,
        state: data.address.state,
        street2: data.address.street2,
      },
      logoId: data.logoId,
      bannerId: data.bannerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}
export async function Update(data: IInstitutions): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.put(
    `/institutions/${data.id}`,
    {
      name: data.name,
      title: data.title,
      type: data.type,
      description: data.description,
      contact: data.contact,
      socialMedia: data.socialMedia,
      address: data.address,
      logoId: data.logoId,
      bannerId: data.bannerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Get(): Promise<IInstitutions[] | undefined> {
  let response: IInstitutions[] | undefined;

  await Api.get(`/institutions`, {
    params: { size: 1000 },
  })
    .then(res => {
      return (response = res.data.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}

export async function GetById(id: string): Promise<IInstitutions | undefined> {
  let response: IInstitutions | undefined;

  await Api.get(`/institutions/${id}`)
    .then(res => {
      return (response = res.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}
