export function getAccessToken() {
  const TOKEN_KEY = '' + process.env.REACT_APP_ENV_TOKEN;
  return sessionStorage.getItem(TOKEN_KEY);
}

export function setAccessToken(token: string) {
  const TOKEN_KEY = '' + process.env.REACT_APP_ENV_TOKEN;
  return sessionStorage.setItem(TOKEN_KEY, token);
}

export function isLogged() {
  return !!getAccessToken();
}
