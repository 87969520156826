import Api from '../api';
import { unmaskValue } from '../helpers/mask';
import { IEvent, IListEvent } from '../interfaces/event.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(data: IEvent): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.post(
    '/events',
    {
      name: data.name,
      date: data.date,
      location: data.location,
      admissionCharged: data.admissionCharged,
      description: data.description,
      city: data.city,
      fee: unmaskValue(data.fee),
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Update(data: IEvent): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.put(
    `/events/${data.id}`,
    {
      name: data.name,
      date: data.date,
      location: data.location,
      admissionCharged: data.admissionCharged,
      description: data.description,
      city: data.city,
      fee: unmaskValue(data.fee),
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Get(): Promise<IListEvent | undefined> {
  let response: IListEvent | undefined;

  await Api.get(`/events`, {
    params: { size: 1000 },
  })
    .then(res => {
      return (response = res.data);
    })
    .catch(err => console.log(err));

  return response;
}

export async function GetById(id: string): Promise<IEvent | undefined> {
  let response: IEvent | undefined;

  await Api.get(`/events/${id}`)
    .then(res => {
      return (response = res.data);
    })
    .catch(err => console.log(err));

  return response;
}
