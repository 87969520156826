import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../context';
import { useNavigate } from 'react-router-dom';
import RoomIcon from '@mui/icons-material/Room';
import IconButton from '@mui/material/IconButton';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';
import LinearProgress from '@mui/material/LinearProgress';
import ApartmentIcon from '@mui/icons-material/Apartment';
import NavbarAdmin from '../../../components/NavbarAdmin';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IDashboardMetrics } from '../../../interfaces/metrics.interface';
import * as DashboardMetrics from '../../../services/metrics.service';

const HomeAdmin = () => {
  const infoUser = useSelector((state: RootStore) => state.user);

  const navigation = useNavigate();
  const [showScrollTop, setShowScrollTop] = useState(false);

  const [showCards, setShowCards] = useState(false);
  const [listInfos, setListInfos] = useState<IDashboardMetrics>();

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const setInfos = async () => {
    const response = await DashboardMetrics.DashboardList();
    setListInfos(response);
  };

  useEffect(() => {
    setInfos();
    setTimeout(() => {
      setShowCards(true);
    }, 2000);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);
  return (
    <C.container>
      <NavbarAdmin secondary={true} />
      <C.header>
        <C.containerTitles>
          <C.title>Painel de Administradores</C.title>
          <C.subTitle>Bem-vindo, {infoUser.name}!</C.subTitle>
        </C.containerTitles>
      </C.header>
      {!showCards && <LinearProgress className="linear_progress" />}
      {showCards && (
        <C.wraper>
          <C.containerCards data-aos="zoom-in">
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/usuarios')}>
                <C.headerCard>
                  <PersonOutlineIcon />
                  <C.titleCard>Usuários</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.user.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>{listInfos?.user.pending}</C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/startups')}>
                <C.headerCard>
                  <RocketLaunchIcon />
                  <C.titleCard>Startups</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.startup.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>
                      {listInfos?.startup.pending}
                    </C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/parques')}>
                <C.headerCard>
                  <SchoolIcon />
                  <C.titleCard>Parques Técnologicos</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.park.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>{listInfos?.park.pending}</C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/ambientes')}>
                <C.headerCard>
                  <SchoolIcon />
                  <C.titleCard>Ambientes de Inovação</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>
                      {listInfos?.environment.total}
                    </C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>
                      {listInfos?.environment.pending}
                    </C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/empresas')}>
                <C.headerCard>
                  <BusinessIcon />
                  <C.titleCard>Empresas</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.company.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>
                      {listInfos?.company.pending}
                    </C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/instituicoes')}>
                <C.headerCard>
                  <ApartmentIcon />
                  <C.titleCard>Instituições</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>
                      {listInfos?.institution.total}
                    </C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>
                      {listInfos?.institution.pending}
                    </C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/mentores')}>
                <C.headerCard>
                  <AddReactionIcon />
                  <C.titleCard>Mentores</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.mentor.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>{listInfos?.mentor.pending}</C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            {/* <C.Card>
              <MenuItem>
                <C.headerCard>
                  <MenuBookIcon />
                  <C.titleCard>Projetos</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>51</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>3</C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card> */}
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/cidades')}>
                <C.headerCard>
                  <RoomIcon />
                  <C.titleCard>Cidades</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.city.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
            <C.Card>
              <MenuItem onClick={() => navigation('/admin/eventos')}>
                <C.headerCard>
                  <CalendarMonthIcon />
                  <C.titleCard>Eventos</C.titleCard>
                </C.headerCard>
                <C.bottom>
                  <div>
                    <C.countActive>{listInfos?.event.total}</C.countActive>
                    <C.infoActive>Ativos</C.infoActive>
                  </div>
                  <div>
                    <C.countPending>{listInfos?.event.pending}</C.countPending>
                    <C.infoPending>Pendentes</C.infoPending>
                  </div>
                </C.bottom>
              </MenuItem>
            </C.Card>
          </C.containerCards>
        </C.wraper>
      )}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default HomeAdmin;
