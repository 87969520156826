import React, { useState, useEffect } from 'react';
import * as C from './styles';
import SlideMenu from '../Slide';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableActive from './tableActive';
import TablePendeng from './tablePendeng';
import { Button, IconButton } from '@mui/material';
import NavbarAdmin from '../../../components/NavbarAdmin';
import FilterModal from '../../../components/Events/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Details from '../../User/list/Details';

const AdminEvents = () => {
  const [value, setValue] = React.useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [fee, setFee] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [show, setShow] = useState(false);

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    }
  }, [refresh]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };
  const handleVisualize = (id: string) => {
    setSelectedId(id);
    setShow(true);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <C.container>
      <C.wraper>
        <NavbarAdmin secondary={true} />
        <C.header>
          <C.headerWraper>
            <C.containerFiltersHeader>
              <C.filters>
                <C.title>Eventos</C.title>
                <C.containerTextField>
                  <C.textFieldWraper>
                    <SearchRoundedIcon />
                    <C.textField
                      placeholder="Nome do evento"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </C.textFieldWraper>
                  <Button variant="contained" onClick={handleFilters}>
                    Filtros
                  </Button>
                </C.containerTextField>
              </C.filters>
            </C.containerFiltersHeader>
          </C.headerWraper>
        </C.header>
        <SlideMenu currentStep={4} />
        <C.containerTables>
          <TablePendeng
            setSelectedId={handleVisualize}
            setRefresh={setRefresh}
            name={name}
            city={city}
            fee={fee}
          />
          {!refresh && (
            <TableActive
              setSelectedId={handleVisualize}
              refresh={refresh}
              name={name}
              city={city}
              fee={fee}
            />
          )}
        </C.containerTables>
      </C.wraper>
      <FilterModal
        show={showFilter}
        setShow={setShowFilter}
        name={name}
        city={city}
        fee={fee}
        setName={setName}
        setCity={setCity}
        setFee={setFee}
      />
      {show && (
        <Details
          visualization={true}
          category="event"
          showModal={show}
          setShowModal={setShow}
          id={selectedId}
        />
      )}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default AdminEvents;
