import React from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logobranco.png';
import LogoSebrae from '../../assets/sebrae.png';
import LogoAGS from '../../assets/logo-AGS-branco.png';
import LogoSOHA from '../../assets/horizontal-branco.png';
import Instagram from '../../assets/instagram.svg';

const Footer = () => {
  const navigation = useNavigate();
  return (
    <C.container>
      <C.wraper>
        <C.boxOne>
          <img src={Logo} alt="Logo StartupMap" />
          <C.contact>
            <C.instagram>
              <a
                href="https://www.instagram.com/somosags/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
            </C.instagram>
            <Button variant="text" onClick={() => navigation('/contato')}>
              Fale Conosco
            </Button>
          </C.contact>
        </C.boxOne>
        <C.boxTwo>
          <C.containerInfos>
            <C.itemInfo>
              <C.title>Sobre</C.title>
              <C.infos>
                A Startup Map é uma iniciativa da AGS (Associação Gaúcha de
                Startups) em parceria com o Sebrae/RS que visa fornecer
                informações sobre Startups para todos.
              </C.infos>
            </C.itemInfo>
            {/* <C.itemInfo>
              <C.title>Parcerias</C.title>
              <C.infos>
                Fale com a comunicação Sebrae para utilização das logos Sebrae e
                Startup Map em suas ações e/ou eventos com foco no digital.
                Vamos te apoiar na divulgação.
              </C.infos>
            </C.itemInfo> */}
          </C.containerInfos>

          <C.containerLogo>
            <C.logos>
              <img className="logo_sebrae" src={LogoSebrae} alt="Logo SEBRAE" />
              <img className="logo_ags" src={LogoAGS} alt="Logo AGS" />
            </C.logos>
            <C.containerPoweredBy>
              <p>Powered by</p>
              <a href="http://www.soha.com.br" target="_blank" rel="noreferrer">
                <img
                  style={{ width: 48, height: 26 }}
                  className="logo_soha"
                  src={LogoSOHA}
                  alt="Logo SOHA"
                />
              </a>
            </C.containerPoweredBy>
          </C.containerLogo>
        </C.boxTwo>

        <C.containerLinks>
          <C.link href="/termos-e-condicoes" target="_blank" rel="noreferrer">
            Políticas de Privacidade
          </C.link>

          <C.link>contato@startupmap.com.br</C.link>
        </C.containerLinks>
      </C.wraper>
    </C.container>
  );
};

export default Footer;
