import styled from 'styled-components';
export const containerSlide = styled.div`
  width: 100%;
  height: 60px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

export const slide = styled.div`
  width: 100%;
  height: 60px;
  max-width: 1500px;

  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 25px;
    color: ${({ theme }) => theme.COLORS.BLACK};
  }
`;

export const containerActions = styled.div`
  width: 100%;
  max-width: 92%;
  height: 100%;
  overflow: hidden;
  display: flex;
  gap: 20;
  padding-left: 20px;
  padding-right: 50px;
  justify-content: space-between;
`;

export const action = styled.div<{ active: boolean }>`
  border-bottom: 2px solid
    ${props => (props.active ? '#26D2A9' : 'transparent')};
  height: 100%;
  transition: all 0.3s ease-in-out;

  .ref_test {
    width: 100%;
    height: 300px;
    background-color: azure;
  }

  .MuiButtonBase-root {
    transition: all 0.3s ease-in-out;
    max-width: 200px;
    height: 100%;

    font-size: 14px !important;
    text-transform: uppercase;
    justify-content: center;
    font-weight: 500;
    color: ${props => (props.active ? '#26D2A9' : '#606470')};
    font-family: 'Montserrat', sans-serif !important;
    &:hover {
      background-color: none !important ;
    }
    &:focus {
      background-color: none !important;
    }
  }
`;
