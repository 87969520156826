export const regions = [
  { id: 1, name: 'Todas' },
  { id: 2, name: 'Sudeste' },
  { id: 3, name: 'Nordeste' },
  { id: 4, name: 'Sudoeste' },
  { id: 5, name: 'Centro Ocidental' },
  { id: 6, name: 'Centro Oriental' },
  { id: 7, name: 'Região Metropolitana' },
  { id: 8, name: 'Sul' },
];
