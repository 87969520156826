import React from 'react';
import * as C from './styles';
import Form1 from '../forms/form1';
import Form2 from '../forms/form2';
import Form3 from '../forms/form3';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/DoneAllRounded';
import * as EventService from '../../../services/event.service';

import { themeDialog } from '../../../styles/theme/themeMui';
import { IAddStartup } from '../../../interfaces/startup.interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import EventIcon from '@mui/icons-material/EventAvailableRounded';
import dayjs, { Dayjs } from 'dayjs';
import { checkEventFields } from '../../../helpers/account.check.fields';
import { maskValue } from '../../../helpers/mask';

export const AddModal = ({
  setShow,
  show,
  id,
  refresh,
  visualization = false,
}: IAddStartup) => {
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [index, setIndex] = useState(1);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [notReturn, setNotReturn] = useState(false);

  const [name, setName] = useState('');
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));

  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [fee, setFee] = useState('');
  const [city, setCity] = useState<string>('');
  const [admissionCharged, setAdmissionCharged] = useState(0);

  const [site, setSite] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  const handleNext = () => {
    const dateFormatted = date ? date?.format() : null;

    const data = {
      name: name,
      date: dateFormatted,
      location: location,
      admissionCharged: admissionCharged,
      fee: fee,
      city: city,
      description: description,
      contact: {
        site: site,
        email: email,
        phone: phone,
      },
    };
    if (!checkEventFields(data, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);

        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      if (index === 1) {
        setIndex(2);
        setForm1(false);
        setForm2(true);
      }
      if (index === 2) {
        setIndex(3);
        setForm2(false);
        setForm3(true);
      }
    }
  };

  const handlePrev = () => {
    if (index === 2) {
      setIndex(1);
      setForm1(true);
      setForm2(false);
    }
    if (index === 3) {
      setIndex(2);
      setForm2(true);
      setForm3(false);
    }
  };

  const handleConcluded = async () => {
    const dateFormatted = date ? date?.format() : null;

    const data = {
      id: id,
      name: name,
      date: dateFormatted,
      location: location,
      admissionCharged: admissionCharged,
      fee: fee,
      city: city,
      description: description,
      contact: {
        site: site,
        email: email,
        phone: phone,
      },
    };

    if (!checkEventFields(data, index).success) {
      setTimeout(() => {
        setShowAlertErr(true);
        setErrMessage(checkEventFields(data, index).message);
        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      setForm3(false);
      setShowConcluded(true);
      setNotReturn(true);
      let success = false;

      if (isEditing) {
        success = await EventService.Update(data);
      } else {
        success = await EventService.Create(data);
      }
      if (success) {
        if (isEditing && refresh) {
          refresh();
        }
        setSuccess(true);
        setTitle('Tudo\nPronto!');
        setSubOne(
          'Obrigada pela sua colaboração!  Assim que seu conteúdo for aprovado, ele ficará disponível para consulta no site.',
        );
        setSubTwo('');
        setTimeout(() => {
          handleClose();
        }, 4000);
      } else {
        setSuccess(false);
        setTitle('Erro');
        setSubOne(
          'Sentimos muito, porém nosso servidor não conseguiu gravar as informações. A situação deverá ser resolvida em breve.',
        );
        setSubTwo(
          'Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte.',
        );
      }
      setTimeout(() => {
        setNotReturn(false);
        setShowConcluded(false);
        setTitle('');
        setSubOne('');
        setSubTwo('');
      }, 5000);
    }
  };

  const handleClose = () => {
    setShow(false);
    setForm1(true);
    setForm2(false);
    setForm3(false);

    setShowConcluded(false);
    setIndex(1);
    setName('');
    setDate(dayjs(new Date()));
    setLocation('');
    setDescription('');
    setFee('');
    setCity('');
    setAdmissionCharged(0);
    setSite('');
    setEmail('');
    setPhone('');
  };

  const setInfos = async (id: string) => {
    const response = await EventService.GetById(id);
    if (response) {
      setName(response.name);
      if (response.date) {
        setDate(dayjs(Date.parse(response.date)));
      }
      setLocation(response.location);
      setDescription(response.description);

      if (response.fee) {
        setFee(maskValue(response.fee));
      }
      setCity(response.city);
      setAdmissionCharged(response.admissionCharged);
      setSite(response.contact.site);
      setEmail(response.contact.email);
      setPhone(response.contact.phone);
    }
  };

  useEffect(() => {
    if (id) {
      setInfos(id);
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>
            <EventIcon />
            {isEditing ? 'Edite seu Evento' : 'Cadastre seu Evento'}
          </C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {form1 && (
            <Form1
              name={name}
              date={date}
              setName={setName}
              setDate={setDate}
              showMessageErr={showAlertErr}
              visualization={visualization}
            />
          )}

          {form2 && (
            <Form2
              location={location}
              description={description}
              admissionCharged={admissionCharged}
              fee={fee}
              city={city}
              setCity={setCity}
              setLocation={setLocation}
              setDescription={setDescription}
              setAdmissionCharged={setAdmissionCharged}
              setFee={setFee}
              showMessageErr={showAlertErr}
              visualization={visualization}
            />
          )}

          {form3 && (
            <Form3
              site={site}
              email={email}
              phone={phone}
              setSite={setSite}
              setEmail={setEmail}
              setPhone={setPhone}
              showMessageErr={showAlertErr}
              visualization={visualization}
              message={errMessage}
            />
          )}

          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>
        <C.containerDotsMobile>
          <C.dot disabled={form1} />
          <C.dot disabled={form2} />
          <C.dot disabled={form3} />
        </C.containerDotsMobile>
        <C.containerButtons disabled={form1}>
          <Button
            variant="outlined"
            className="btn_previus"
            disabled={form1 || notReturn}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
            Anterior
          </Button>
          <C.containerDots>
            <C.dot disabled={form1} />
            <C.dot disabled={form2} />
            <C.dot disabled={form3} />
          </C.containerDots>

          <>
            {form3 || showConcluded ? (
              <Button
                variant="outlined"
                className="btn_send"
                onClick={handleConcluded}
                disabled={showConcluded || visualization}
              >
                Enviar
                <SendIcon />
              </Button>
            ) : (
              <Button
                variant="outlined"
                className="btn_next"
                onClick={handleNext}
              >
                Próximo
                <ArrowNextIcon />
              </Button>
            )}
          </>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default AddModal;
