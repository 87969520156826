import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarMobile from '../../../components/NavbarMobile';
import PersondIcon from '@mui/icons-material/MoodRounded';
import LocationIcon from '@mui/icons-material/FmdGoodRounded';
import * as CitiesService from '../../../services/cities.service';
import { isLogged } from '../../../services/session.service';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { ICity } from '../../../services/cities.service';
import { type } from '../../../interfaces/card.interface';
import CardPolicy from '../../../components/CardPolicy';

const DetailsCity = () => {
  const [city, setCity] = useState<ICity>();
  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getCity = async () => {
      const response = await CitiesService.Get(id);
      setCity(response);
    };

    getCity();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />

        <C.headerDetails>
          <C.containerInfos>
            <C.containerTitles>
              <C.headerTitle>{city?.name}</C.headerTitle>
              <C.containerSubTitles>
                <C.headerSubTitle>{city?.contact.email}</C.headerSubTitle>
                <C.headerSubTitle>{city?.contact.phone}</C.headerSubTitle>
                {/* {logged && (
                  <C.containerButtonEdit>
                    <IconButton aria-label="social">
                      <EditRoundedIcon />
                    </IconButton>
                  </C.containerButtonEdit>
                )} */}
              </C.containerSubTitles>
            </C.containerTitles>
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>Descrição</C.detailTitle>
            <pre>{city?.description}</pre>

            <C.space />
            <C.detailTitle>Políticas Públicas</C.detailTitle>
            <C.containerCards>
              {city?.publicPolicy.map(item => (
                <CardPolicy
                  key={item.name}
                  title={item.name}
                  region={item.description}
                  type={type.First}
                  id={item.name}
                />
              ))}
            </C.containerCards>
          </C.details>

          <C.containerInfosCity>
            <C.infoItem>
              <C.infoIcon>
                <LocationIcon />
              </C.infoIcon>
              <C.infoTitle>Região</C.infoTitle>
              <C.infoSubTitle>{city?.region}</C.infoSubTitle>
            </C.infoItem>
            <C.infoItem>
              <C.infoIcon>
                <PersondIcon />
              </C.infoIcon>
              <C.infoTitle>Prefeito</C.infoTitle>
              <C.infoSubTitle>{city?.mayor}</C.infoSubTitle>
            </C.infoItem>
          </C.containerInfosCity>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/cidades')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsCity;
