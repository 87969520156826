import { validateEmail } from './validate.email';
import { IFormdata } from '../interfaces/login.interface';

export const checkLoginFields = (data: IFormdata) => {
  let message = '';
  let success = true;

  if (!data.email && !data.password) {
    message = 'Preencha todos os campos';
    success = false;
    return { message, success };
  }

  if (!data.email) {
    message = 'Preencha o campo E-mail';
    success = false;
    return { message, success };
  }
  if (data.email) {
    if (!validateEmail(data.email)) {
      message = 'O E-mail informado é inválido';
      success = false;
      return { message, success };
    }
  }

  if (!data.password) {
    message = 'Preencha o campo Senha';
    success = false;
    return { message, success };
  }

  return { message, success };
};
