import styled from 'styled-components';
import { shade } from 'polished';

export const containerFilters = styled.div`
  width: 80% !important;

  .MuiButton-text {
    min-width: 0 !important;
    padding: 5px !important;
    border-radius: 50px !important;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  svg {
    font-size: 30px !important;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }

  @media screen and (max-width: 600px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
  }

  @media screen and (max-width: 600px) {
    svg {
      font-size: 20px !important;
    }
  }
`;

export const containerTitle = styled.div`
  display: flex;
  padding: 20px 40px;
  align-items: center;
  justify-content: space-between;

  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px !important;
    color: #26d2a9 !important;
  }
  svg {
    font-size: 30px !important;
    color: #26d2a9 !important;
  }
  @media screen and (max-width: 600px) {
    padding: 5%;
  }
`;
export const title = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;

  svg {
    margin-right: 15px;
    color: #606470 !important;
    font-size: 40px !important;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    svg {
      font-size: 32px !important;
    }
  }
`;

export const containerButtons = styled.div<{ disabled: boolean }>`
  display: flex;
  padding: 20px 40px;
  justify-content: space-between;

  .btn_add {
    border: none;
    font-weight: 400;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    outline: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      border: none;
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_cancel {
    border: none;
    font-weight: 400;
    text-transform: none;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    outline: 1px solid ${({ theme }) => theme.COLORS.RED};
    color: ${({ theme }) => theme.COLORS.RED}!important;
    &:hover {
      border: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }
`;

export const containerTextFields = styled.div`
  width: 100%;
  padding: 10px 20px;
`;
export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
export const textFieldWraper100 = styled.div`
  margin: 10px 0;
`;
export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;

export const boxTextField = styled.div`
  width: 100%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const space = styled.div`
  margin: 50px 0;
`;

export const textAreaField = styled.textarea`
  border: 0;
  width: 100%;
  min-height: 160px;
  padding: 20px;
  resize: none;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &.textaera_master {
    min-height: 500px;
  }

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const boxTextField50 = styled.div`
  width: 50%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }

  @media screen and (max-width: 730px) {
    margin: 30px 0;
    width: 100%;
  }
`;
