import React, { useState, useEffect } from 'react';
import * as C from './styles';
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CardUser from '../../../components/CardUser';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/EditRounded';
import NavbarAdmin from '../../../components/NavbarAdmin';
import AddModal from '../../../components/AdminUsers/Add';
import LinearProgress from '@mui/material/LinearProgress';
import { IUser } from '../../../interfaces/user.interface';
import EditModal from '../../../components/AdminUsers/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import FilterModal from '../../../components/AdminUsers/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import * as UserService from '../../../services/user.service';
import { themeDialogAlert, themeTable } from '../../../styles/theme/themeMui';

interface Column {
  id: 'name' | 'email' | 'role' | 'termsAcceptedAt' | 'actions';
  label: string;
  maxWidth?: number;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome Completo', minWidth: 250 },
  { id: 'email', label: 'Email', minWidth: 250 },
  { id: 'role', label: 'Tipo', minWidth: 250 },
  { id: 'termsAcceptedAt', label: 'Aceite dos termos', minWidth: 250 },
  { id: 'actions', label: 'Ações', minWidth: 10 },
];

const AdminUsers = () => {
  const count = 5;
  const [page, setPage] = useState(1);
  const [rowsCount, setRowsCount] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [message, setMessage] = useState('');
  const [showLoad, setShowLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [search, setSearch] = useState('');
  const [info, setInfo] = useState('');

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');

  const [user, setUser] = useState<IUser>();

  const [listUsers, setListUsers] = useState<IUser[]>([]);

  const [showList, setShowList] = useState(false);

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };
  const handleAdd = () => {
    setShowAdd(!showAdd);
  };

  const handleEdit = (user: IUser) => {
    setUser(user);
    setShowEdit(!showEdit);
  };

  const handleDelete = async () => {
    setShowButton(false);
    setShowLoad(true);

    const success = await UserService.Remove(user?.id);
    setTimeout(() => {
      setShowLoad(false);
      if (success) {
        setMessage('Deletado com sucesso');
        setShowAlertSuc(true);
        setTimeout(() => {
          setShowDialog(false);
          window.location.reload();
        }, 3000);
      } else {
        setShowAlertSuc(false);
        setMessage('Falha ao tentar deletar usuário');
        setShowAlertErr(true);
        setTimeout(() => {
          setShowDialog(false);
        }, 3000);
      }
    }, 2000);
  };

  const handleDialog = (user: IUser) => {
    setUser(user);
    setShowDialog(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);

    const response = await UserService.List(
      email,
      role,
      name,
      newPage + 1,
      count,
    );
    if (response?.data && response?.paging) {
      setListUsers(response.data);
      setRowsCount(response.paging.count);
    }
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);

    const response = await UserService.List(
      email,
      role,
      name,
      1,
      +event.target.value,
    );
    if (response?.data && response?.paging) {
      setListUsers(response.data);
      setRowsCount(response.paging.count);
    }
  };

  const getListUsers = async (search?: string) => {
    const response = await UserService.List(
      email,
      role,
      name,
      1,
      rowsPerPage,
      search,
    );
    if (response?.data && response?.paging) {
      setListUsers(response.data);
      setPage(0);
      setRowsCount(response.paging.count);
      setRowsPerPage(response.paging.size);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearch(info);
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    getListUsers(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getListUsers();
    setTimeout(() => {
      setShowList(true);
    }, 2000);
  }, []);
  return (
    <C.container>
      <NavbarAdmin secondary={true} />
      <C.header>
        <C.headerWraper>
          <C.containerFiltersHeader>
            <C.filters>
              <C.title>Usuários</C.title>
              <C.containerTextField>
                <C.textFieldWraper>
                  <SearchRoundedIcon />
                  <C.textField
                    placeholder="Nome do Usuário"
                    value={info}
                    onChange={e => setInfo(e.target.value)}
                  />
                </C.textFieldWraper>
                <Button variant="contained" onClick={handleFilters}>
                  Filtros
                </Button>
              </C.containerTextField>
            </C.filters>
          </C.containerFiltersHeader>
          <C.containerButtonAdd>
            <Button variant="contained" onClick={handleAdd}>
              Criar Usuário
            </Button>
          </C.containerButtonAdd>
        </C.headerWraper>
      </C.header>
      {/* <SlideMenu /> */}

      {!showList && <LinearProgress className="linear_progress" />}

      {showList ? (
        <C.containerTable data-aos="zoom-in">
          <C.titleTable>Usuários</C.titleTable>
          {listUsers?.map((item, index) => {
            return (
              <CardUser
                key={index}
                user={item}
                handleEdit={handleEdit}
                setShowDialog={setShowDialog}
              />
            );
          })}
          <Paper sx={themeTable}>
            <TableContainer className="table_desk">
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="table_head_desk">
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, width: 20 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listUsers ? (
                    listUsers.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {columns.map(column => {
                            let value = row[column.id];

                            value =
                              column.format && typeof value === 'number'
                                ? column.format(value)
                                : value && column.id === 'termsAcceptedAt'
                                ? new Date(value).toLocaleDateString()
                                : value;

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                className={
                                  column.id === 'name' ? 'table_cell_click' : ''
                                }
                              >
                                {column.id === 'actions' && (
                                  <C.containerButtonsActions>
                                    <IconButton
                                      className="btn_edit"
                                      onClick={() => handleEdit(row)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      className="btn_delete"
                                      onClick={() => handleDialog(row)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </C.containerButtonsActions>
                                )}

                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="pagination"
              labelRowsPerPage="Itens"
              labelDisplayedRows={({ from, to, count }) => {
                return from + ' à ' + to + ' de ' + count;
              }}
              rowsPerPageOptions={[5, 10, 25, 30]}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </C.containerTable>
      ) : null}

      <FilterModal
        name={name}
        role={role}
        email={email}
        setRole={setRole}
        setName={setName}
        state={showFilter}
        setEmail={setEmail}
        handle={getListUsers}
        setState={setShowFilter}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <EditModal show={showEdit} setShow={setShowEdit} user={user} />

      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
      >
        <C.containerTitle>
          <C.titleDialog>Excluir usuário</C.titleDialog>
          <Button variant="text" onClick={() => setShowDialog(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialog>
            Tem certeza que deseja excluir este usuário? Essa ação não poderá
            ser desfeita.
          </C.textDialog>

          <C.containerButtonsDialog>
            {showButton ? (
              <>
                <Button
                  variant="outlined"
                  className="btn_cancel"
                  onClick={() => setShowDialog(false)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  className="btn_confirm"
                  onClick={handleDelete}
                >
                  Excluir
                </Button>
              </>
            ) : null}
            {showLoad ? <CircularProgress size={34} color={'primary'} /> : null}
            {showAlertErr ? (
              <Alert variant="outlined" severity="error" className="alert">
                {message}
              </Alert>
            ) : null}

            {showAlertSuc ? (
              <Alert variant="outlined" severity="success" className="alert">
                {message}
              </Alert>
            ) : null}
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
    </C.container>
  );
};

export default AdminUsers;
