import React from 'react';
import * as C from './styles';
import LogoDefault from '../../assets/logo-default.png';

import { ICard } from '../../interfaces/card.interface';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo';

const Card = ({ name, title, city, path, description, id, logoId }: ICard) => {
  const navigation = useNavigate();
  return (
    <C.container onClick={() => navigation(`/${path}/${id}`, { state: id })}>
      <C.headerCard className="header_card" />
      <C.containerInfos>
        <C.containerImage className="image_card">
          {logoId ? (
            <Logo size={130} url={logoId} />
          ) : (
            <img src={LogoDefault} alt="Logo Startup" />
          )}
        </C.containerImage>
        <C.title className="text_card">{name}</C.title>
        <C.office className="text_card">{title}</C.office>
        <C.office className="text_card">{description}</C.office>
        <C.descriptions className="text_card truncate">{city}</C.descriptions>
      </C.containerInfos>
    </C.container>
  );
};

export default Card;
