export const validateCNPJ = (cnpj: string): boolean => {
  const cnpjDigits = cnpj.replace(/[^\d]+/g, '');

  if (cnpjDigits.length !== 14) {
    return false;
  }

  const firstVerifier = calculateCNPJVerifier(cnpjDigits.slice(0, 12), 5);
  const secondVerifier = calculateCNPJVerifier(cnpjDigits.slice(0, 13), 6);

  return cnpjDigits.slice(12, 14) === `${firstVerifier}${secondVerifier}`;
};

const calculateCNPJVerifier = (digits: string, base: number): number => {
  let sum = 0;

  for (let i = 0; i < digits.length; i++) {
    sum += parseInt(digits[i]) * base;
    base = base === 2 ? 9 : base - 1;
  }

  const mod = sum % 11;

  return mod < 2 ? 0 : 11 - mod;
};
