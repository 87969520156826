import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { regions } from './regions';
import { Button } from '@mui/material';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Cidades from '../../../assets/cidades.png';
import { isLogged } from '../../../services/session.service';
import AddModal from '../../../components/Cities/Add';
import CardCities from '../../../components/CardCities';
import { type } from '../../../interfaces/card.interface';
import NavbarMobile from '../../../components/NavbarMobile';
import FilterModal from '../../../components/Cities/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { ICity } from '../../../services/cities.service';
import * as CitiesService from '../../../services/cities.service';

const Cities = () => {
  const [index, setIndex] = useState(1);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [filteredCities, setFilteredCities] = useState<ICity[]>();

  const [filterRegion, setFilterRegion] = useState('');
  const [filterCity, setFilterCity] = useState('');

  const handleAdd = () => {
    setShowAdd(!showAdd);
  };
  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  const getCities = async () => {
    await CitiesService.List({ setList: setCityList });
  };

  const handleFilterName = (i: number, n: string) => {
    setIndex(i + 1);
    n === 'Todas' ? setFilterRegion('') : setFilterRegion(n);
  };

  useEffect(() => {
    setFilteredCities(
      cityList?.filter(city => {
        const regionMatch = city.region
          .toLowerCase()
          .includes(filterRegion.toLowerCase());

        const cityMatch = city.name
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return regionMatch && cityMatch;
      }),
    );
  }, [filterRegion, filterCity, cityList]);

  useEffect(() => {
    getCities();
  }, []);

  return (
    <C.container>
      <Navbar secondary={false} logged={isLogged()} />
      <NavbarMobile />
      <C.wraper>
        <C.containerFilters>
          <C.filters>
            <C.title>Cidades</C.title>
            <C.containerTextField>
              <C.textFieldWraper>
                <SearchRoundedIcon />
                <C.textField placeholder="Nome da cidade" />
              </C.textFieldWraper>
              <Button variant="contained" onClick={handleFilters}>
                Filtros
              </Button>
            </C.containerTextField>
            {/* <Button
              variant="contained"
              className="btn_add_city"
              onClick={handleAdd}
            >
              Cadastrar Cidade
            </Button> */}
            <Button
              variant="contained"
              className="btn_mobile_pro"
              onClick={handleFilters}
            >
              Filtros
            </Button>

            <C.containerButtonAdd>
              {/* <Button
                variant="contained"
                className="btn_mobile_pro"
                onClick={handleAdd}
              >
                Cadastrar Cidade
              </Button> */}
            </C.containerButtonAdd>
            <C.containerButtonRegions>
              {regions.map((e, i) => (
                <C.buttomWraper
                  key={e.id}
                  active={index === e.id ? true : false}
                >
                  <Button
                    variant="contained"
                    className="btn_region"
                    onClick={() => handleFilterName(i, e.name)}
                  >
                    {e.name}
                  </Button>
                </C.buttomWraper>
              ))}
            </C.containerButtonRegions>
          </C.filters>
        </C.containerFilters>

        <C.containerImage>
          <C.image>
            <img src={Cidades} alt="Cidades" />
          </C.image>
        </C.containerImage>
      </C.wraper>

      <C.containerButtonFilters>
        <C.containerButtonRegions>
          {regions.map((e, i) => (
            <C.buttomWraper key={e.id} active={index === e.id ? true : false}>
              <Button
                variant="contained"
                className="btn_region_mobile"
                onClick={() => handleFilterName(i, e.name)}
              >
                {e.name}
              </Button>
            </C.buttomWraper>
          ))}
        </C.containerButtonRegions>
      </C.containerButtonFilters>

      <C.fillEfect />
      <C.wraperTwo>
        <C.containerCards>
          {filteredCities?.map((item, index) => {
            return (
              <CardCities
                key={index}
                title={item.name}
                type={type.First}
                id={item.id ? item.id : ''}
                region={item.region}
                path="detalhes-cidade"
              />
            );
          })}
        </C.containerCards>
      </C.wraperTwo>
      <FilterModal
        state={showFilter}
        setState={setShowFilter}
        cities={cityList}
        setFilterCity={setFilterCity}
        setFilterRegion={setFilterRegion}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <Footer />
    </C.container>
  );
};

export default Cities;
