import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 200px;
  background-color: ${({ theme }) => theme.COLORS.GREY_V3};

  .MuiLinearProgress-root {
    background-color: #fff !important;
  }
  .linear_progress .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const header = styled.div`
  width: 100%;
  padding: 0 2%;
  display: flex;
  min-height: 290px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
`;

export const containerTitles = styled.div`
  width: 400px;
  text-align: center;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;
export const title = styled.h2`
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 980px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  }
`;
export const subTitle = styled.h4`
  color: #ffff;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;

export const wraper = styled.div`
  padding: 0 5%;
`;
export const containerCards = styled.div`
  max-width: 1600px;

  margin: 0 auto;
  display: grid;
  padding-top: 80px;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 1130px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-height: 280px;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);

  svg {
    font-size: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }

  .MuiButtonBase-root {
    padding: 20px !important;
    display: block !important;
    max-width: 100% !important;
    border-radius: 8px !important;
    &:hover {
      background-color: #fff !important;
    }
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
  }
`;

export const headerCard = styled.div`
  width: 100%;
  text-align: center;
`;
export const titleCard = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  @media screen and (max-width: 980px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  }
`;

export const bottom = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 20px 0;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
  }
`;
export const infoActive = styled.p`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XS}em;
`;
export const countActive = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
`;
export const infoPending = styled.p`
  color: ${({ theme }) => theme.COLORS.RED};
  font-size: ${({ theme }) => theme.FONT_SIZES.XS}em;
`;
export const countPending = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.RED};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
`;

export const containerButtonScrollTop = styled.div<{ show: boolean }>`
  width: 100%;
  height: 60px;
  z-index: 13000;
  margin-top: 40px;

  position: fixed;
  bottom: 10px;
  right: 20px;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: end;
  .MuiIconButton-root {
    width: 50px !important;
    height: 50px !important;
    min-width: 0;
    padding: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
    &:hover {
      transform: translateY(-7px);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 20px -7px;
      background-color: ${({ theme }) => theme.COLORS.YELLOW_V2} !important;
    }

    svg {
      color: ${({ theme }) => theme.COLORS.BLACK};
      font-size: 40px;
    }
  }

  @media screen and (max-width: 980px) {
    justify-content: center;
    bottom: 0;
    right: 0;
  }
`;
