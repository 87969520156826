import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { Alert, Button, IconButton } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/AddRounded';
import { theme } from '../../../styles/theme/themeMui';
import { FormikProps } from 'formik';
import { IMentor, IMentorship } from '../../../interfaces/mentor.interface';
import { cities } from '../../../helpers/options';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { MaskOnlyNumbers } from '../../../helpers/mask';
import { IFormProps } from '../../../interfaces/form.interface';

const Form5 = (props: FormikProps<IMentor> & IFormProps) => {
  const { visualization } = props;
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [mentorship, setMentorship] = useState<IMentorship[]>([]);

  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [city, setCity] = useState('');

  const clearStates = () => {
    setName('');
    setYear('');
    setCity('');
  };

  const addMentorship = () => {
    setMentorship([
      ...mentorship,
      { name: name, year: parseInt(year), city: city },
    ]);
  };

  const handleAddPartiner = () => {
    if (!name || !year || !city) {
      setTimeout(async () => {
        setShowAlertErr(true);

        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      setShowForm(!showForm);
      addMentorship();
      clearStates();
      props.setFieldValue('mentorship', mentorship);
    }
  };

  const removeMentorship = (index: number) => {
    const mentorships = [...mentorship];
    mentorships.splice(index, 1);
    setMentorship(mentorships);
  };

  const editMentorship = (index: number) => {
    setName(mentorship[index].name);
    setYear(mentorship[index].year.toString());
    setCity(mentorship[index].city);
    setShowEditForm(true);
    setIndexEdit(index);
    props.setFieldValue('mentorship', mentorship);
  };

  const saveEditMentorship = (index: number) => {
    if (!name || !year || !city) {
      setTimeout(async () => {
        setShowAlertErr(true);

        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      const mentorships = [...mentorship];
      mentorship[index].name = name;
      mentorship[index].year = parseInt(year);
      mentorship[index].city = city;
      setMentorship(mentorships);
      setShowEditForm(false);
      clearStates();
      props.setFieldValue('mentorship', mentorship);
    }
  };

  useEffect(() => {
    props.setFieldValue('mentorship', mentorship);
  }, [mentorship]);

  useEffect(() => {
    if (props.values.mentorship.length > 0) {
      setMentorship(props.values.mentorship);
    }
  }, [props.values.mentorship]);
  return (
    <C.container>
      <C.titleMaster>Histórico de Mentorias</C.titleMaster>
      {mentorship.map((e, index) => (
        <>
          <C.textFieldWraper100 key={e.name}>
            <C.titleTextField>{e.name}</C.titleTextField>
            <C.textFieldList>
              <C.yearText>{e.year}</C.yearText>
              <C.containerButtonsActions>
                <IconButton
                  className="btn_edit"
                  onClick={() => editMentorship(index)}
                  disabled={visualization}
                >
                  <EditIcon />
                  <C.textFieldEdit>Editar</C.textFieldEdit>
                </IconButton>
              </C.containerButtonsActions>
              <C.containerButtonsActions>
                <IconButton
                  className="btn_delete"
                  onClick={() => removeMentorship(index)}
                  disabled={visualization}
                >
                  <DeleteIcon />
                  <C.textFieldDelete>Excluir</C.textFieldDelete>
                </IconButton>
              </C.containerButtonsActions>
            </C.textFieldList>
          </C.textFieldWraper100>
          {showEditForm && indexEdit === index && (
            <>
              <C.textFieldWraper100>
                <C.boxTextField>
                  <C.titleTextField>Nome do Evento</C.titleTextField>
                  <C.textField
                    placeholder="Digite nome do evento"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    disabled={visualization}
                  />
                </C.boxTextField>
              </C.textFieldWraper100>

              <C.textFieldWraper50>
                <C.boxTextField>
                  <C.titleTextField>Ano</C.titleTextField>
                  <C.textField
                    placeholder="Ano"
                    value={year}
                    maxLength={4}
                    onChange={e => setYear(MaskOnlyNumbers(e.target.value))}
                    disabled={visualization}
                  />
                </C.boxTextField>

                <C.boxTextField>
                  <C.titleTextField>Cidade</C.titleTextField>
                  <Select
                    sx={theme}
                    className="select"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    displayEmpty
                    disabled={visualization}
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {cities.map(city => {
                      return (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </C.boxTextField>
              </C.textFieldWraper50>

              <C.buttonsSupport>
                <Button
                  variant="contained"
                  className="btn_save"
                  onClick={() => saveEditMentorship(index)}
                  disabled={visualization}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  className="btn_cancel"
                  disabled={visualization}
                  onClick={() => {
                    clearStates();
                    setShowEditForm(false);
                  }}
                >
                  Cancelar
                </Button>
              </C.buttonsSupport>
            </>
          )}
        </>
      ))}

      <C.textFieldWraper100>
        <C.boxTextField>
          <Button
            variant="contained"
            className="btn_add_partner"
            onClick={() => setShowForm(!showForm)}
            disabled={visualization}
          >
            <AddIcon />
            Adicionar Mentoria
          </Button>
        </C.boxTextField>
      </C.textFieldWraper100>

      {showForm && (
        <>
          <C.textFieldWraper100>
            <C.boxTextField>
              <C.titleTextField>Nome do Evento</C.titleTextField>
              <C.textField
                placeholder="Digite nome do evento"
                value={name}
                disabled={visualization}
                onChange={e => setName(e.target.value)}
              />
            </C.boxTextField>
          </C.textFieldWraper100>

          <C.textFieldWraper50>
            <C.boxTextField>
              <C.titleTextField>Ano</C.titleTextField>
              <C.textField
                placeholder="Ano"
                value={year}
                maxLength={4}
                onChange={e => setYear(MaskOnlyNumbers(e.target.value))}
                disabled={visualization}
              />
            </C.boxTextField>

            <C.boxTextField>
              <C.titleTextField>Cidade</C.titleTextField>
              <Select
                sx={theme}
                className="select"
                value={city}
                onChange={e => setCity(e.target.value)}
                disabled={visualization}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {cities.map(city => {
                  return (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  );
                })}
              </Select>
            </C.boxTextField>
          </C.textFieldWraper50>

          <C.buttonsSupport>
            <Button
              variant="contained"
              className="btn_save"
              onClick={handleAddPartiner}
              disabled={visualization}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              className="btn_cancel"
              disabled={visualization}
              onClick={() => {
                clearStates();
                setShowEditForm(false);
              }}
            >
              Cancelar
            </Button>
          </C.buttonsSupport>
        </>
      )}
      {showAlertErr ? (
        <Alert
          variant="outlined"
          severity="error"
          className="alert"
          sx={{ mt: 1 }}
        >
          Preencha todos os campos
        </Alert>
      ) : null}
    </C.container>
  );
};
export default Form5;
