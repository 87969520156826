import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as C from './styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CardActive from './CardActive';
import TableActive from './tableActive';
import CardAnalyze from './CardAnalyze';
import CardPendeng from './CardPendeng';
import TablePendeng from './tablePendeng';
import TableAnalyze from './tableAnalyze';
import { IMines } from '../../../interfaces/mine.interface';
import * as UserService from '../../../services/user.service';

interface IUser {
  setButtonScroll: Dispatch<SetStateAction<boolean>>;
}

const UserRecords = ({ setButtonScroll }: IUser) => {
  const [value, setValue] = React.useState(0);
  const [listPendeng, setListPendeng] = useState<IMines>();
  const [listAnalysis, setListAnalysis] = useState<IMines>();
  const [listApproved, setListApproved] = useState<IMines>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const listData = async () => {
    const approveds = await UserService.ListMine('A');
    const pendengs = await UserService.ListMine('P');
    const analysis = await UserService.ListMine('R');

    setListPendeng(pendengs);
    setListAnalysis(analysis);
    setListApproved(approveds);
  };

  useEffect(() => {
    listData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <C.container>
      <C.wraper>
        <C.containerTabs>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="Aprovados" />
            <Tab label="Pendentes" />
            <Tab label="Em Análise" />
          </Tabs>
        </C.containerTabs>
        <C.containerTables>
          <TableActive
            listApproved={{
              data: listApproved?.data,
              paging: listApproved?.paging,
            }}
            refresh={listData}
            setButtonScroll={setButtonScroll}
          />
          <TablePendeng
            listPendeng={{
              data: listPendeng?.data,
              paging: listPendeng?.paging,
            }}
            refresh={listData}
            setButtonScroll={setButtonScroll}
          />
          <TableAnalyze
            listAnalysis={{
              data: listAnalysis?.data,
              paging: listAnalysis?.paging,
            }}
            refresh={listData}
            setButtonScroll={setButtonScroll}
          />
        </C.containerTables>
        <C.containerCards>
          {value === 0 && (
            <C.cardItem>
              <CardActive
                listPendeng={{
                  data: listApproved?.data,
                  paging: listApproved?.paging,
                }}
                setButtonScroll={setButtonScroll}
              />
            </C.cardItem>
          )}
          {value === 1 && (
            <C.cardItem>
              <CardPendeng
                listPendeng={{
                  data: listApproved?.data,
                  paging: listApproved?.paging,
                }}
                setButtonScroll={setButtonScroll}
              />
            </C.cardItem>
          )}

          {value === 2 && (
            <>
              <C.subTitleTable>
                Cadastros em análise devem ser corrigidos e mandados novamente
                para aprovação.
              </C.subTitleTable>
              <C.cardItem>
                <CardAnalyze
                  listPendeng={{
                    data: listAnalysis?.data,
                    paging: listAnalysis?.paging,
                  }}
                  setButtonScroll={setButtonScroll}
                />
              </C.cardItem>
            </>
          )}
        </C.containerCards>
      </C.wraper>
    </C.container>
  );
};

export default UserRecords;
