import React from 'react';
import * as C from './styles';
import { Dispatch, SetStateAction } from 'react';
import { MaskCEP, MaskPhone } from '../../../helpers/mask';
import { Alert } from '@mui/material';

interface IForm {
  site: string;
  phone: string;
  instagram: string;
  email: string;
  facebook: string;
  linkedin: string;
  youtube: string;
  setSite: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  setInstagram: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setFacebook: Dispatch<SetStateAction<string>>;
  setLinkedin: Dispatch<SetStateAction<string>>;
  setYoutube: Dispatch<SetStateAction<string>>;
  showAlertErr: boolean;
  message: string;
  visualization: boolean;
}

const Form3 = ({
  site,
  email,
  phone,
  setSite,
  youtube,
  facebook,
  linkedin,
  setPhone,
  setEmail,
  instagram,
  setYoutube,
  setFacebook,
  setLinkedin,
  setInstagram,
  showAlertErr,
  message,
  visualization = false,
}: IForm) => {
  return (
    <C.container>
      <C.titleMaster>Contato</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Site</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Site"
            value={site}
            onChange={e => setSite(e.target.value)}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Telefone*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Telefone"
            value={phone}
            maxLength={15}
            onChange={e => setPhone(MaskPhone(e.target.value))}
          />
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Instagram</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Instagram"
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>E-mail Corporativo*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />
      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>YouTube</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="YouTube"
            value={youtube}
            onChange={e => setYoutube(e.target.value)}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Facebook</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Facebook"
            value={facebook}
            onChange={e => setFacebook(e.target.value)}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Linkedin</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Linkedin"
            value={linkedin}
            onChange={e => setLinkedin(e.target.value)}
          />
        </C.boxTextField>
      </C.textFieldWraper33>
      {showAlertErr ? (
        <Alert
          variant="outlined"
          severity="error"
          className="alert"
          sx={{ mt: 1 }}
        >
          {message}
        </Alert>
      ) : null}
    </C.container>
  );
};
export default Form3;
