import Api from '../api';
import { IResponse } from '../interfaces/login.interface';

export async function Create({ data }: any): Promise<IResponse> {
  const email = data.email ? data.email.trim() : data.email;
  const password = data.password;
  const termsAccepted = data.termsAccepted;
  const response: IResponse = {
    success: false,
    token: '',
    role: '',
    termsAccepted: false,
  };

  await Api.post('/login', { email, password, termsAccepted })
    .then(res => {
      response.success = true;
      response.role = res.data.role;
      response.token = res.data.token;
      response.termsAccepted = res.data.termsAccepted;
    })
    .catch(error => {
      console.log(error);
      response.success = false;
    });

  return response;
}
