import React, { useState } from 'react';
import * as C from './styles';
import Navbar from '../../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../../styles/theme/themeMui';
import NavbarMobile from '../../../components/NavbarMobile';
import * as UserService from '../../../services/user.service';

import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { checkUserFields } from '../../../helpers/account.check.fields';
import { validateEmail } from '../../../helpers/validate.email';

interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
  showPasswordConfirm: boolean;
}

const Account = () => {
  const navigation = useNavigate();

  const [termsAcepted, setTermsAcepted] = useState(false);
  const [shereAcepted, setShereAcepted] = useState(false);

  const [message, setMessage] = useState('');
  const [showLoad, setShowLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const validateForm = () => {
    let valid = true;
    const errors: any = {};

    if (formValues.name.trim() === '') {
      errors.name = 'Campo obrigatório';
      valid = false;
    }

    if (formValues.email.trim() === '') {
      errors.email = 'Campo obrigatório';
      valid = false;
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'E-mail inválido';
      valid = false;
    }

    if (formValues.password.trim() === '') {
      errors.password = 'Campo obrigatório';
      valid = false;
    } else if (!/^(?=\D*\d)[a-zA-Z0-9\W]{5,}$/.test(formValues.password)) {
      errors.password =
        'Senha inválida. Deve conter pelo menos 6 caracteres e pelo menos 1 número.';
      valid = false;
    }

    if (formValues.passwordConfirm.trim() === '') {
      errors.passwordConfirm = 'Campo obrigatório';
      valid = false;
    } else if (formValues.password !== formValues.passwordConfirm) {
      errors.passwordConfirm = 'Senhas não conferem';
      valid = false;
    } else if (
      !/^(?=\D*\d)[a-zA-Z0-9\W]{5,}$/.test(formValues.passwordConfirm)
    ) {
      errors.passwordConfirm =
        'Confirmação de senha inválida. Deve conter pelo menos 6 caracteres e pelo menos 1 número.';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const [values, setValues] = React.useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    showPasswordConfirm: false,
  });

  const handleTermsAccepted = (id: string) => {
    if (id == '1') {
      if (termsAcepted) {
        setTermsAcepted(false);
      } else {
        setTermsAcepted(true);
      }
    }

    if (id == '2') {
      if (shereAcepted) {
        setShereAcepted(false);
      } else {
        setShereAcepted(true);
      }
    }
  };

  const handleRegister = async () => {
    if (!validateForm()) return;
    setShowLoad(true);

    const data = {
      name: formValues.name,
      email: formValues.email,
      password: formValues.password,
      confirm: formValues.passwordConfirm,
    };
    if (!checkUserFields(data).success) {
      const msgError = checkUserFields(data).message;
      setTimeout(async () => {
        setShowLoad(false);
        setShowButton(false);
        setShowAlertErr(true);
        setMessage(msgError);

        setTimeout(() => {
          setShowAlertErr(false);
          setShowButton(true);
          setMessage('');
        }, 2000);
      }, 2000);
    } else {
      const { success, message } = await UserService.Create({
        data: data,
      });

      setTimeout(async () => {
        if (success) {
          setShowLoad(false);
          setShowButton(false);
          setShowAlertSuc(true);
          setMessage('Cadastrado com sucesso');
          setTimeout(() => {
            setShowAlertSuc(false);
            setShowButton(true);
            setMessage('');
            navigation('/login');
          }, 2000);
        } else {
          setShowLoad(false);
          setShowButton(false);
          setShowAlertErr(true);
          setMessage(message);

          setTimeout(() => {
            setShowAlertErr(false);
            setShowButton(true);
            setMessage('');
          }, 2000);
        }
      }, 2000);
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowPasswordConfirm = () => {
    setValues({
      ...values,
      showPasswordConfirm: !values.showPasswordConfirm,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm>
        <C.form>
          <C.title>Criar Conta</C.title>
          <C.space />
          <C.textField
            placeholder="Nome*"
            value={formValues.name}
            onChange={e =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            style={{ marginBottom: formErrors.name ? 0 : 30 }}
          />
          {formErrors.name && (
            <C.errorMessage>{formErrors.name}</C.errorMessage>
          )}
          <C.textField
            placeholder="E-mail*"
            value={formValues.email}
            onChange={e =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            style={{ marginBottom: formErrors.email ? 0 : 30 }}
          />
          {formErrors.email && (
            <C.errorMessage>{formErrors.email}</C.errorMessage>
          )}

          <OutlinedInput
            sx={theme}
            placeholder="Senha*"
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={formValues.password}
            onChange={e =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <C.infoTextField>
            Deve conter no mínimo 6 caracteres incluindo um número
          </C.infoTextField>
          {formErrors.password && (
            <C.errorMessage>{formErrors.password}</C.errorMessage>
          )}
          <C.spaceII />
          <OutlinedInput
            sx={theme}
            className="mui_textfield"
            placeholder="Repetir Senha*"
            id="outlined-adornment-password"
            type={values.showPasswordConfirm ? 'text' : 'password'}
            value={formValues.passwordConfirm}
            onChange={e =>
              setFormValues({ ...formValues, passwordConfirm: e.target.value })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPasswordConfirm}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formErrors.passwordConfirm && (
            <C.errorMessage>{formErrors.passwordConfirm}</C.errorMessage>
          )}
          <C.checkBoxes>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    onChange={e => handleTermsAccepted(e.target.value)}
                  />
                }
                label="Aceito os"
              />
              <C.linkAcepteds href="/termos-e-condicoes" target={'_blank'}>
                Termos e condições
              </C.linkAcepteds>
            </div>
            {/* <div>
              <FormControlLabel
                control={
                  <Checkbox
                    value={2}
                    onChange={e => handleTermsAccepted(e.target.value)}
                  />
                }
                label="Aceito compartilhar meus dados com a AGS"
              />
            </div> */}
          </C.checkBoxes>

          {showButton ? (
            <Button
              variant="contained"
              className="btn_login"
              onClick={handleRegister}
              disabled={showLoad || !termsAcepted}
            >
              {showLoad ? (
                <CircularProgress size={34} color={'primary'} />
              ) : (
                'Criar Conta'
              )}
            </Button>
          ) : null}
          {showAlertErr ? (
            <Alert variant="outlined" severity="error" className="alert">
              {message}
            </Alert>
          ) : null}

          {showAlertSuc ? (
            <Alert variant="outlined" severity="success" className="alert">
              {message}
            </Alert>
          ) : null}
        </C.form>
        <C.containerButtonSupport>
          <Button
            variant="contained"
            className="btn_support"
            onClick={() => navigation('/login')}
          >
            Cancelar
          </Button>
        </C.containerButtonSupport>
      </C.containerForm>
    </C.container>
  );
};

export default Account;
