import styled from 'styled-components';

export const container = styled.div`
  flex: 0 20%;
  max-width: 100%;
  height: 460px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  margin: 20px;
  box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
  &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
  }
  &:hover .card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
  }
  &:hover .text_card,
  .text_header_card {
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
  }

  &:hover .header_card {
    background-color: ${({ theme }) => theme.COLORS.YELLOW_V2};
  }

  &:hover .image_card {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 355px;
    max-height: 100px;
  }

  @media only screen and (max-width: 1090px) {
    height: 540px;
  }
`;
export const wraper = styled.div``;

export const headerCard = styled.div`
  width: 100%;
  height: 30%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
`;
export const containerInfos = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 14px;
`;

export const containerImage = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(142, 141, 208, 0.16);
  padding: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
export const title = styled.h3`
  width: 100%;
  margin-top: 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.COLORS.GREEN_V4};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;
export const office = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.COLORS.GREY_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const descriptions = styled.p`
  width: 100%;
  margin-top: 10px;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.GREY_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
