import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 180px;
  display: none;
  padding: 20px;
  max-height: 280px;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  margin: 30px 0;
  box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
  svg {
    font-size: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }

  /* &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
  } */

  @media only screen and (max-width: 920px) {
    display: block;
  }
`;

export const TitleCard = styled.h2`
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const containerInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  p {
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.BLACK};
  }
`;
export const containerButtonsActions = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;

  margin-top: 10px;

  .btn_edit {
    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
`;
