import React, { useState } from 'react';
import * as C from './styles';
import { Alert, Button, CircularProgress } from '@mui/material';
import Navbar from '../../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import NavbarMobile from '../../../components/NavbarMobile';
import Api from '../../../api';

const RecoverPass = () => {
  const navigation = useNavigate();
  const [email, setEmail] = useState('');
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const sendRecoveryPassword = async () => {
    setLoading(true);

    await Api.post('/request-password-reset', {
      email: email.trim(),
    })
      .then(() => {
        setLoading(false);
        setShowAlertSuc(true);
        setTimeout(() => {
          setShowAlertSuc(false);
          navigation('/login');
        }, 2000);
      })
      .catch(e => {
        setErrMessage(e.response.data.message);
        setLoading(false);
        setShowAlertErr(true);
        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      });
  };

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm>
        <C.form>
          <C.title>Esqueci a senha</C.title>
          <C.space />
          <C.textField
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-mail"
          />
          <C.infoTextField>
            Digite o e-mail cadastrado na plataforma
          </C.infoTextField>
          {showAlertSuc ? (
            <Alert
              style={{ marginTop: 10 }}
              variant="outlined"
              severity="success"
              className="alert"
            >
              {
                'Foi enviado um email com as intruções para redefinição de senha'
              }
            </Alert>
          ) : null}

          {showAlertErr ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              {errMessage}
            </Alert>
          ) : null}

          <Button
            variant="contained"
            className="btn_login"
            onClick={() => {
              sendRecoveryPassword();
            }}
            disabled={loading}
          >
            Enviar
          </Button>
          <Button
            variant="text"
            className="btn_recover"
            onClick={() => navigation('/login')}
          >
            Voltar para login
          </Button>
        </C.form>
      </C.containerForm>
      <C.containerButtonSupport>
        <Button
          variant="contained"
          className="btn_support"
          onClick={() => navigation('/criar-conta')}
        >
          Cadastre-se
        </Button>
      </C.containerButtonSupport>
    </C.container>
  );
};

export default RecoverPass;
