import React, { useEffect } from 'react';
import * as C from './styles';
import Logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/MenuRounded';
import LoginIcon from '@mui/icons-material/LoginRounded';

const NavbarMobile = () => {
  const navigation = useNavigate();

  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null,
  );

  const open = Boolean(anchorElTwo);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElTwo) {
      setAnchorElTwo(event.currentTarget);
      return;
    }
    setAnchorElTwo(null);
  };
  const handleClose = () => {
    setAnchorElTwo(null);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1090) setAnchorElTwo(null);
    });
  }, []);

  return (
    <C.container>
      <C.wraper>
        <C.containerBurgerMenu>
          <Button variant="text" onClick={handleClick}>
            <MenuIcon />
          </Button>
        </C.containerBurgerMenu>
        <C.containerLogo>
          <C.logo src={Logo} />
        </C.containerLogo>
        <C.containerBurgerMenu>
          <Button variant="text" onClick={() => navigation('/login')}>
            <LoginIcon />
          </Button>
        </C.containerBurgerMenu>
      </C.wraper>
      <Menu
        style={{ top: '26px', position: 'absolute', zIndex: '99999999' }}
        className="popper_menu"
        anchorEl={anchorElTwo}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        aria-haspopup="true"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigation('/')}>Página Inicial</MenuItem>
        <MenuItem onClick={() => navigation('/startups')}>Startups</MenuItem>
        <MenuItem onClick={() => navigation('/empresas')}>Empresas</MenuItem>
        <MenuItem onClick={() => navigation('/instituicoes')}>
          Instituições
        </MenuItem>
        <MenuItem onClick={() => navigation('/parques-tecnologicos')}>
          Parques Tecnológicos
        </MenuItem>
        <MenuItem onClick={() => navigation('/ambiente-inovacao')}>
          Ambientes de Inovação
        </MenuItem>
        <MenuItem onClick={() => navigation('/mentores')}>
          Banco de Mentores
        </MenuItem>
        <MenuItem onClick={() => navigation('/cidades')}>Cidades</MenuItem>
        <MenuItem onClick={() => navigation('/agenda-eventos')}>
          Eventos
        </MenuItem>
        <MenuItem onClick={() => navigation('/contato')}>Contato</MenuItem>
      </Menu>
    </C.container>
  );
};

export default NavbarMobile;
