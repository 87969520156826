import React, { useEffect } from 'react';
import AOS from 'aos';
import Rotas from './routes';
import THEME from './styles/theme';
import { useDispatch } from 'react-redux';
import { GlobalStyle } from './styles/global';
import { BrowserRouter } from 'react-router-dom';
import { UpdateRedux } from './context/utils/UpdateRedux';
import { ThemeProvider as Themesystem } from 'styled-components';
import {
  createTheme,
  ThemeProvider as ThemeProviderMaterial,
} from '@mui/material';
import { getAccessToken } from './services/session.service';

function App() {
  const dispatch = useDispatch();
  const token = getAccessToken();

  const innerTheme = createTheme({
    palette: {
      primary: {
        main: '#26D2A9',
      },
    },
    typography: { allVariants: { color: '#606470', fontFamily: 'Montserrat' } },
  });
  useEffect(() => {
    if (token) {
      UpdateRedux(dispatch);
    }
    AOS.init({ duration: 900 });
  }, []);
  return (
    <Themesystem theme={THEME}>
      <BrowserRouter>
        <ThemeProviderMaterial theme={innerTheme}>
          <Rotas />
        </ThemeProviderMaterial>
      </BrowserRouter>
      <GlobalStyle />
    </Themesystem>
  );
}

export default App;
