import React from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import PhotoIcon from '@mui/icons-material/PhotoSizeSelectActualRounded';
import { ITechnologyPark } from '../../../interfaces/technologyParks.interface';
import { FormikProps } from 'formik';
import { IFormProps } from '../../../interfaces/form.interface';

const Form5 = (props: FormikProps<ITechnologyPark> & IFormProps) => {
  const { visualization } = props;
  const formattingFile = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        if (image[0].size < 512000) {
          reader.addEventListener('load', () => {
            props.setFieldValue('imagePath', reader.result);
          });
          reader.readAsDataURL(image[0]);

          props.setFieldValue('logoId', image[0]);
          props.setFieldValue('imageSelected', true);
        }
      }
    }
  };

  const formattingBanner = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        if (image[0].size < 512000) {
          reader.addEventListener('load', () => {
            props.setFieldValue('bannerPath', reader.result);
          });
          reader.readAsDataURL(image[0]);

          props.setFieldValue('bannerId', image[0]);
          props.setFieldValue('bannerSelected', true);
        }
      }
    }
  };
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleMaster>Mais Informações</C.titleMaster>
        <C.titleTextField>Logo</C.titleTextField>
        <C.boxLogo>
          <C.containerLogo>
            {props.values.imageSelected ? (
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundImage: `url(${props.values.imagePath})`,
                }}
                className="logo"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerLogo>
          <div className="image_wraper">
            <C.infoImage>largura 500px x altura 500px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
              disabled={visualization}
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingFile(t.target.files)}
                disabled={visualization}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
        {/* END IMAGE */}
      </C.textFieldWraper100>

      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Banner</C.titleTextField>
        <C.boxLogo>
          <C.containerBanner>
            {props.values.bannerSelected ? (
              <div
                style={{
                  width: 235,
                  height: '100%',
                  backgroundImage: `url(${props.values.bannerPath})`,
                }}
                className="banner"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerBanner>
          <div>
            <C.infoImage>largura 1500px X Altura 400px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
              disabled={visualization}
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingBanner(t.target.files)}
                disabled={visualization}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form5;
