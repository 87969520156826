import React from 'react';
import * as C from './styles';
import Navbar from '../../../components/Navbar';
import NavbarMobile from '../../../components/NavbarMobile';

const Terms = () => {
  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm data-aos="zoom-in">
        <C.form>
          <div style={{ padding: 50 }}>
            <p>TERMOS DE USO E SERVI&Ccedil;OS DO STARTUPMAP</p>
            <p>
              <br></br>
            </p>
            <p>
              Ol&aacute;! Que bom contar com seu interesse! Antes de usar nossos
              servi&ccedil;os, tire um tempo para ler nossos Termos de Uso e
              Servi&ccedil;o e conhecer as regras que regem nossa
              rela&ccedil;&atilde;o com voc&ecirc;.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Abaixo esclareceremos alguns pontos que julgamos importantes. Caso
              persista alguma d&uacute;vida acerca de quaisquer pontos
              discutidos ou n&atilde;o neste documento, por favor, n&atilde;o
              hesite em contatar-nos pelo
              endere&ccedil;o&nbsp;[contato@startupmap.com.br].&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>1. DEFINI&Ccedil;&Otilde;ES:</p>
            <p>
              <br></br>
            </p>
            <p>
              No presente instrumento, entendemos as express&otilde;es abaixo de
              acordo com as seguintes defini&ccedil;&otilde;es:
            </p>
            <p>
              <br></br>
            </p>
            <p>
              &nbsp;AGS:&nbsp;ASSOCIA&Ccedil;&Atilde;O GA&Uacute;CHA DE
              STARTUPS, associa&ccedil;&atilde;o civil sem fins econ&ocirc;micos
              e lucrativos, inscrita sob o CNPJ n.&ordm; 23.427.125/0001-06, em
              Porto Alegre, Rio Grande do Sul, em sua sede na Av. Ipiranga,
              n&ordm; 6681, bairro Partenon, TECNOPUC pr&eacute;dio 97,
              Coworking, sob o CEP: 90160-092 0
            </p>
            <p>
              <br></br>
            </p>
            <p>
              PLATAFORMA:&nbsp;Sistema Web, oferecido pela&nbsp;AGS, em parceria
              com o SEBRAERS, de propriedade da&nbsp;AGS, opera&ccedil;&atilde;o
              e responsabilidade da&nbsp;AGS, onde todos os USU&Aacute;RIOS
              podem visualizar e utilizar as funcionalidades disponibilizadas
              pela&nbsp;AGS. A PLATAFORMA tem o prop&oacute;sito exclusivo de
              mapeamento e divulga&ccedil;&atilde;o de informa&ccedil;&otilde;es
              sobre o ecossistema de startups no Estado do Rio Grande do Sul,
              sem finalidade econ&ocirc;mica, e sim puramente
              estat&iacute;stica, visando promover um ambiente inovador e
              multidisciplinar, auxiliando na mensura&ccedil;&atilde;o e
              impulsionamento de novas iniciativas voltadas para o ecossistema
              de inova&ccedil;&atilde;o no Estado do Rio Grande do Sul.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              USU&Aacute;RIO(S): pessoa f&iacute;sica e/ou jur&iacute;dica
              cadastrada na&nbsp;PLATAFORMA, que acesse e/ou utilize as
              funcionalidades ofertadas pela&nbsp;AGS.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              TRATAMENTO DE DADOS:&nbsp;Nos termos do artigo 5, inciso X, da Lei
              13.709 de 2018, toda opera&ccedil;&atilde;o realizada
              pela&nbsp;AGS&nbsp;com dados pessoais do&nbsp;USU&Aacute;RIO, como
              as que se referem a coleta, produ&ccedil;&atilde;o,
              recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o,
              utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o,
              transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento,
              arquivamento, armazenamento, elimina&ccedil;&atilde;o,
              avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o,
              modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o,
              transfer&ecirc;ncia, difus&atilde;o ou
              extra&ccedil;&atilde;o.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>2. ADES&Atilde;O:</p>
            <p>
              <br></br>
            </p>
            <p>
              Este instrumento regula as condi&ccedil;&otilde;es de uso
              da&nbsp;PLATAFORMA&nbsp;sendo um contrato entre
              os&nbsp;USU&Aacute;RIOS&nbsp;e a&nbsp;AGS. A
              utiliza&ccedil;&atilde;o das funcionalidades oferecidas
              atrav&eacute;s da&nbsp;PLATAFORMA&nbsp;indica expressamente que
              voc&ecirc; concorda com todos os termos e condi&ccedil;&otilde;es
              contidos neste instrumento e com as disposi&ccedil;&otilde;es
              legais aplic&aacute;veis &agrave; esp&eacute;cie.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <br></br>
                    </td>
                    <td>
                      <br></br>
                      <p>
                        Se voc&ecirc;&nbsp;N&Atilde;O CONCORDA&nbsp;com as
                        disposi&ccedil;&otilde;es aqui
                        contidas,&nbsp;N&Atilde;O&nbsp;acesse, visualize, baixe
                        ou use de qualquer forma qualquer p&aacute;gina,
                        conte&uacute;do, informa&ccedil;&atilde;o ou
                        funcionalidades da&nbsp;PLATAFORMA.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <br></br>
            </p>
            <p>
              <br></br>
            </p>
            <p>
              VOC&Ecirc; ENTENDE E CONCORDA QUE A AGS CONSIDERAR&Aacute; O USO
              DAS FUNCIONALIDES DISPOSTOS COMO ACEITA&Ccedil;&Atilde;O DESTES
              TERMOS E TODAS AS DEMAIS DISPOSI&Ccedil;&Otilde;ES LEGAIS
              PERTINENTES &Agrave; ESP&Eacute;CIE.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USU&Aacute;RIO
              AUTORIZA EXPRESSAMENTE O TRATAMENTO DE SEUS DADOS, A FIM DE
              GARANTIR A MANUTEN&Ccedil;&Atilde;O E O BOM DESEMPENHO DAS
              FUNCIONALIDADES DA PLATAFORMA.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O USU&Aacute;RIO, NESTE ATO, MANIFESTA O SEU COMPLETO
              CONSENTIMENTO PARA O COMPARTILHAMENTO DOS DADOS COLETADOS E
              TRATADOS PELA AGS, NOS TERMOS DESTE INSTRUMENTO.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Caso voc&ecirc;&nbsp;N&Atilde;O CONCORDE&nbsp;com as
              disposi&ccedil;&otilde;es previstas neste
              instrumento,&nbsp;N&Atilde;O&nbsp;acesse, visualize, baixe ou
              utilize de qualquer forma nenhuma p&aacute;gina, conte&uacute;do,
              informa&ccedil;&atilde;o ou funcionalidade da&nbsp;PLATAFORMA.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Os presentes termos est&atilde;o dispon&iacute;veis para leitura,
              a qualquer momento, na&nbsp;PLATAFORMA,&nbsp;em&nbsp;
              <a href="http://www.startupmap.com.br/termos_de_uso.pdf">
                http://www.startupmap.com.br/termos_de_uso.pdf
              </a>
              .&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>3. QUEM SOMOS E O QUE FAZEMOS:</p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;&eacute; uma associa&ccedil;&atilde;o sem fins
              lucrativos que trabalha para desenvolver o ecossistema de startups
              do Estado do Rio Grande do Sul, baseando-se em quatro pilares:
              compartilhamento de conhecimentos, conex&atilde;o de talentos,
              acesso a investidores e fomento de neg&oacute;cios. &nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>4. CONDI&Ccedil;&Otilde;ES GERAIS DE USO:</p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS,&nbsp;em parceria com o SEBRAERS, apenas disponibiliza
              a&nbsp;PLATAFORMA, que oferece as funcionalidades de mapeamento e
              divulga&ccedil;&atilde;o de informa&ccedil;&otilde;es sobre o
              ecossistema de startups no Estado do Rio Grande do Sul, sem
              finalidade econ&ocirc;mica, e sim puramente estat&iacute;stica,
              visando promover um ambiente inovador e multidisciplinar,
              auxiliando na mensura&ccedil;&atilde;o e impulsionamento de novas
              iniciativas voltadas para o ecossistema de inova&ccedil;&atilde;o
              no Estado do Rio Grande do Sul.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;apenas disponibiliza
              a&nbsp;PLATAFORMA&nbsp;aos&nbsp;USU&Aacute;RIOS&nbsp;regularmente
              cadastrados, n&atilde;o havendo, entre estas partes, qualquer
              outra rela&ccedil;&atilde;o, de forma que, n&atilde;o &eacute;
              poss&iacute;vel imputar &agrave;&nbsp;AGS&nbsp;a responsabilidade
              por quaisquer danos causado a outros&nbsp;USU&Aacute;RIOS,&nbsp;ou
              a terceiros, por atos oriundos
              de&nbsp;USU&Aacute;RIOS&nbsp;durante o uso das funcionalidades
              dispon&iacute;veis da&nbsp;PLATAFORMA, sendo dever da AGS a
              disponibiliza&ccedil;&atilde;o tecnol&oacute;gica adequada e
              segura, nos padr&otilde;es de mercado existentes.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              No momento do cadastro,
              os&nbsp;USU&Aacute;RIOS&nbsp;poder&atilde;o utilizar todas as
              funcionalidades disponibilizadas na&nbsp;PLATAFORMA, declarando,
              para tanto, terem lido, compreendido e aceitado todos os
              dispositivos contidos neste Termos de Uso.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;se compromete a utilizar das
              funcionalidades da&nbsp;PLATAFORMA&nbsp;com boa-f&eacute;, de
              acordo com a legisla&ccedil;&atilde;o em vigor, a moral e os bons
              costumes.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;&eacute; o &uacute;nico
              respons&aacute;vel pela seguran&ccedil;a de sua senha e pelo uso
              de seu cadastro na&nbsp;PLATAFORMA, por isto, recomendamos que
              n&atilde;o compartilhem com terceiros tais
              informa&ccedil;&otilde;es e, caso estas informa&ccedil;&otilde;es
              sejam, por qualquer motivo, extraviadas ou&nbsp;hackeadas,
              o&nbsp;USU&Aacute;RIO&nbsp;dever&aacute; informar imediatamente
              &agrave;&nbsp;AGS, atrav&eacute;s do e-mail
              contato@startupmap.com.br, a fim de que se possa solucionar a
              quest&atilde;o.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Cabe somente aos&nbsp;USU&Aacute;RIOS&nbsp;responderem por
              quaisquer danos causados a terceiros, a
              outros&nbsp;USU&Aacute;RIOS, &agrave;&nbsp;PLATAFORMA&nbsp;ou
              &agrave; pr&oacute;pria&nbsp;AGS, decorrentes do mal uso das
              funcionalidades da&nbsp;PLATAFORMA.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Os&nbsp;USU&Aacute;RIOS&nbsp;n&atilde;o devem utilizar as
              funcionalidades dispon&iacute;veis na&nbsp;PLATAFORMA&nbsp;para
              quaisquer fins ou meios ilegais, difamat&oacute;rios,
              discriminat&oacute;rios, abusivos, ofensivos,
              pornogr&aacute;ficos, obscenos, agressivos, injuriosos,
              inver&iacute;dicos, vexat&oacute;rios, enganosos, caluniosos,
              violentos, vulgares, ou de ass&eacute;dio, amea&ccedil;a ou uso de
              falsa identidade, ou seja, qualquer uso escuso que possa
              prejudicar a&nbsp;AGS, outros&nbsp;USU&Aacute;RIOS&nbsp;ou
              terceiros.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Em nenhuma hip&oacute;tese a&nbsp;AGS&nbsp;ser&aacute;
              responsabilizada por quaisquer danos suportados
              pelos&nbsp;USU&Aacute;RIOS&nbsp;por eventual indisponibilidade
              tempor&aacute;ria da&nbsp;PLATAFORMA.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;deve possuir todos os softwares e
              hardwares necess&aacute;rios para acessar
              &agrave;&nbsp;PLATAFORMA, incluindo, mas n&atilde;o se limitando,
              a computador/dispositivo m&oacute;vel com acesso &agrave;
              Internet, cabendo &agrave;&nbsp;AGS, t&atilde;o somente,
              disponibilizar
              a&nbsp;PLATAFORMA&nbsp;ao&nbsp;USU&Aacute;RIO,&nbsp;nos termos
              deste instrumento.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O uso da&nbsp;PLATAFORMA&nbsp;pelos&nbsp;USU&Aacute;RIOS&nbsp;fica
              condicionado ao seu cadastro pr&eacute;vio, bem como ao respeito
              &agrave;s disposi&ccedil;&otilde;es constantes neste
              instrumento.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>5. DO CADASTRO</p>
            <p>
              <br></br>
            </p>
            <p>
              Apenas podem se cadastrar na&nbsp;PLATAFORMA&nbsp;pessoas
              f&iacute;sicas absolutamente capazes e pessoas jur&iacute;dicas.
            </p>
            <p>
              <br></br>Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Startup&nbsp;realizem o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rios&nbsp;(i)&nbsp;Nome
              completo,&nbsp;(ii)&nbsp;T&iacute;tulo,&nbsp;(iii)&nbsp;Qual
              problema sua startup
              resolve,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o detalhada do seu
              pitch,&nbsp;(v)&nbsp;Endere&ccedil;o completo,&nbsp;(vi), E-mail
              corporativo,&nbsp;(vii)&nbsp;Telefone
              corporativo,&nbsp;(viii)&nbsp;CNPJ,&nbsp;(ix), Se j&aacute; foi
              acelerado, a descri&ccedil;&atilde;o,&nbsp;(x),
              Segmento,&nbsp;(xi)&nbsp;Est&aacute;gio da
              startup,&nbsp;(xii)&nbsp;Modelo de
              neg&oacute;cio,&nbsp;(xiii)&nbsp;Tipo de
              neg&oacute;cio,&nbsp;(xiv), N&uacute;mero de
              colaboradores,&nbsp;(xv)&nbsp;Data da
              funda&ccedil;&atilde;o,&nbsp;(xvi)&nbsp;nome dos S&oacute;cios e
              seus respectivos e-mails corporativos, Cargo, CPF, G&ecirc;nero e
              Perfil.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Empresas,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rio&nbsp;(i)&nbsp;Nome
              completo,&nbsp;(ii)&nbsp;T&iacute;tulo,&nbsp;(iii)&nbsp;Qual
              problema sua empresa
              resolve,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o detalhada do seu
              pitch,&nbsp;(v)&nbsp;Endere&ccedil;o completo,&nbsp;(vi), E-mail
              corporativo,&nbsp;(vii)&nbsp;Telefone
              corporativo,&nbsp;(viii)&nbsp;CNPJ,&nbsp;(ix)&nbsp;Segmento,&nbsp;(x)&nbsp;Est&aacute;gio
              da startup,&nbsp;(xi)&nbsp;Modelo de
              neg&oacute;cio,&nbsp;(xii)&nbsp;Tipo de
              neg&oacute;cio,&nbsp;(xiii), N&uacute;mero de
              colaboradores,&nbsp;(xiv)&nbsp;Data da
              funda&ccedil;&atilde;o,&nbsp;(xv)&nbsp;nome dos S&oacute;cios e
              seus respectivos e-mails corporativos, Cargo, CPF, G&ecirc;nero e
              Perfil.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Institui&ccedil;&atilde;o,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados obrigat&oacute;rio&nbsp;
              (i)&nbsp;Nome
              completo,&nbsp;(ii)&nbsp;T&iacute;tulo,&nbsp;(iii)&nbsp;Tipo,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o
              detalhada do seu pitch,&nbsp;(v)&nbsp;Endere&ccedil;o
              completo,&nbsp;(vi), E-mail corporativo,
              e,&nbsp;&nbsp;(vii)&nbsp;Telefone corporativo.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do tipo&nbsp;Parque
              Tecnol&oacute;gico,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rio&nbsp;(i)&nbsp;Nome
              completo,&nbsp;(ii)&nbsp;T&iacute;tulo,&nbsp;(iii)&nbsp;Tipo,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o
              detalhada do seu pitch,&nbsp;(v)&nbsp;Endere&ccedil;o
              completo,&nbsp;(vi), E-mail corporativo,
              e,&nbsp;&nbsp;(vii)&nbsp;Telefone corporativo.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Mentores,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rio&nbsp;(i)&nbsp;Nome
              completo,&nbsp;(ii)&nbsp;T&iacute;tulo,&nbsp;(iii)&nbsp;Tipo,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o
              detalhada do seu pitch,&nbsp;(v)&nbsp;Endere&ccedil;o
              completo,&nbsp;(vi), E-mail
              corporativo,&nbsp;&nbsp;(vii)&nbsp;Telefone corporativo,
              e&nbsp;(viii)&nbsp;habilidade + score.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Cidade,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rio&nbsp;(i)&nbsp;Regi&atilde;o,&nbsp;(ii)&nbsp;Nome,&nbsp;(iii)&nbsp;Nome
              do Prefeito em
              exerc&iacute;cio,&nbsp;(iv)&nbsp;Descri&ccedil;&atilde;o
              detalhada,&nbsp;(v)&nbsp;Site,&nbsp;(vi), E-mail
              corporativo,&nbsp;&nbsp;(vii)&nbsp;Telefone corporativo,&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para que o&nbsp;USU&Aacute;RIO&nbsp;do
              tipo&nbsp;Eventos,&nbsp;realize o seu cadastro
              na&nbsp;PLATAFORMA,&nbsp;dever&atilde;o fornecer
              &agrave;&nbsp;AGS&nbsp;os seguintes dados
              obrigat&oacute;rio&nbsp;(i)&nbsp;Nome,&nbsp;(ii)&nbsp;Data,&nbsp;(iii)&nbsp;Hor&aacute;rio
              e,&nbsp;(iv)&nbsp;local,&nbsp;(v)&nbsp;Custo do ingresso, (vi),
              Descri&ccedil;&atilde;o,&nbsp;&nbsp;(vii)&nbsp;site,&nbsp;(viii),
              E-mail, e,&nbsp;(ix)&nbsp;Telefone
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;compreende que s&oacute; poder&aacute;
              compartilhar na plataforma informa&ccedil;&otilde;es de pessoas
              f&iacute;sicas, especialmente dados pessoais, como nome completo
              dos s&oacute;cios, com a devida autoriza&ccedil;&atilde;o dos
              mesmos, n&atilde;o sendo permitido tal cadastro sem o devido
              consentimento legal dos s&oacute;cios.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Em caso de cadastro sem a devida autoriza&ccedil;&atilde;o,
              o&nbsp;USU&Aacute;RIO&nbsp;dever&aacute; comunicar imediatamente
              a&nbsp;AGS&nbsp;para a devida exclus&atilde;o dos dados.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Para a utiliza&ccedil;&atilde;o regular da&nbsp;PLATAFORMA,
              o&nbsp;USU&Aacute;RIO&nbsp;dever&aacute; efetuar cadastro,
              preenchendo todos os dados solicitados
              pela&nbsp;PLATAFORMA&nbsp;no momento do cadastramento.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;compreende que ao cadastrar, de forma
              livre e consentida, as informa&ccedil;&otilde;es
              na&nbsp;PLATAFORMA, tais informa&ccedil;&otilde;es tornam-se
              p&uacute;blicas, n&atilde;o sendo de responsabilidade
              da&nbsp;AGS&nbsp;qualquer tipo de contato comercial ou negocial
              nos pontos de contato livremente disponibilizados e publicizados
              pelo&nbsp;USU&Aacute;RIO, podendo o mesmo, a qualquer tempo,
              solicitar a exclus&atilde;o de tais dados.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              &Eacute; de exclusiva responsabilidade
              dos&nbsp;USU&Aacute;RIOS&nbsp;fornecer, atualizar e garantir a
              veracidade dos dados cadastrais, n&atilde;o recaindo
              &agrave;&nbsp;AGS&nbsp;qualquer tipo de responsabilidade civil e
              criminal resultante de dados inver&iacute;dicos, incorretos ou
              incompletos fornecidos pelos&nbsp;USU&Aacute;RIOS.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;se reserva o direito de utilizar todos os meios
              v&aacute;lidos e poss&iacute;veis para identificar
              seus&nbsp;USU&Aacute;RIOS, bem como de solicitar dados adicionais
              e documentos que estime serem pertinentes a fim de conferir os
              dados informados. Neste caso, o uso
              da&nbsp;PLATAFORMA&nbsp;pelo&nbsp;USU&Aacute;RIO&nbsp;fica
              condicionado ao envio dos documentos eventualmente
              solicitados.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Caso um cadastro seja considerado suspeito de conter dados
              err&ocirc;neos ou inver&iacute;dicos, a&nbsp;AGS&nbsp;se reserva
              ao direito de suspender, tempor&aacute;ria ou definitivamente, sem
              necessidade de aviso pr&eacute;vio,
              o&nbsp;USU&Aacute;RIO&nbsp;respons&aacute;vel pelo cadastro. No
              caso de suspens&atilde;o n&atilde;o assistir&aacute;
              ao&nbsp;USU&Aacute;RIO&nbsp;direito a qualquer tipo de
              indeniza&ccedil;&atilde;o ou ressarcimento por perdas e danos,
              lucros cessantes ou ainda danos morais.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;poder&aacute; ter acesso &agrave;s suas
              informa&ccedil;&otilde;es coletadas e sobre o&nbsp;TRATAMENTO DE
              DADOS&nbsp;realizado pela&nbsp;AGS, de forma gratuita,
              atrav&eacute;s de solicita&ccedil;&atilde;o para
              contato@startupmap.com.br,&nbsp;ou atrav&eacute;s de seu cadastro
              na&nbsp;PLATAFORMA, podendo edit&aacute;-las ou exclu&iacute;-las
              a qualquer tempo. &nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A coleta dos dados do&nbsp;USU&Aacute;RIO&nbsp;visa
              identific&aacute;-lo, bem como habilit&aacute;-lo ao correto uso
              da&nbsp;PLATAFORMA, e, com isto, a&nbsp;AGS&nbsp;poder&aacute;
              assegurar a boa qualidade das funcionalidades de mapeamento.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Ao consentir com os termos do presente instrumento,
              o&nbsp;USU&Aacute;RIO&nbsp;declara expressamente estar ciente que
              a coleta dos seus dados &eacute; primordial para o bom
              funcionamento da&nbsp;PLATAFORMA, autorizando, desde j&aacute;,
              o&nbsp;TRATAMENTO DE
              DADOS&nbsp;pela&nbsp;AGS&nbsp;e&nbsp;SEBRAERS.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              &Eacute; expressamente vedada a cria&ccedil;&atilde;o de mais de
              um cadastro de&nbsp;USU&Aacute;RIO&nbsp;na&nbsp;PLATAFORMA. Em
              caso de multiplicidade de cadastros elaborados por um
              s&oacute;&nbsp;USU&Aacute;RIO, a&nbsp;AGS&nbsp;se reserva o
              direito de, a seu exclusivo crit&eacute;rio, sem contrapartida
              indenizat&oacute;ria e sem necessidade de pr&eacute;via
              anu&ecirc;ncia ou comunica&ccedil;&atilde;o, inabilitar todos os
              cadastros existentes em nome deste&nbsp;USU&Aacute;RIO, podendo
              n&atilde;o aceitar novo cadastro do
              referido&nbsp;USU&Aacute;RIO&nbsp;na&nbsp;PLATAFORMA.<br></br>
              <br></br>
            </p>
            <p>
              A&nbsp;PLATAFORMA&nbsp;oferece a possibilidade de
              o&nbsp;USU&Aacute;RIO&nbsp;utilizar de algumas de suas
              funcionalidades sem a necessidade de cadastro, entretanto, a
              aus&ecirc;ncia de cadastro n&atilde;o descaracteriza
              o&nbsp;USU&Aacute;RIO, nem permite o desrespeito a este
              instrumento.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;acessar&aacute; o seu cadastro
              na&nbsp;PLATAFORMA&nbsp;por meio de&nbsp;login&nbsp;e senha,
              comprometendo-se a n&atilde;o informar a terceiros estes dados,
              responsabilizando-se integralmente pelo uso que deles seja feito.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;compromete-se a notificar
              a&nbsp;AGS&nbsp;imediatamente, por meio dos canais de contato
              mantidos pela&nbsp;AGS&nbsp;na&nbsp;PLATAFORMA, a respeito de
              qualquer uso n&atilde;o autorizado de sua conta.
              O&nbsp;USU&Aacute;RIO&nbsp;ser&aacute; o &uacute;nico
              respons&aacute;vel pelas opera&ccedil;&otilde;es efetuadas em sua
              conta, uma vez que o acesso s&oacute; ser&aacute; poss&iacute;vel
              mediante a utiliza&ccedil;&atilde;o de senha de seu exclusivo
              conhecimento.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;compromete-se a notificar
              a&nbsp;AGS&nbsp;imediatamente, por meio dos canais de contato
              mantidos pela&nbsp;AGS&nbsp;na&nbsp;PLATAFORMA, a respeito de
              qualquer conhecimento de irregularidades de
              outros&nbsp;USU&Aacute;RIOS&nbsp;que possam ocasionar danos aos
              pr&oacute;prios&nbsp;USU&Aacute;RIOS&nbsp;da&nbsp;PLATAFORMA, a
              esta, a&nbsp;AGS&nbsp;ou a terceiros.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Em nenhuma hip&oacute;tese ser&aacute; permitida a cess&atilde;o,
              a venda, o aluguel ou outra forma de transfer&ecirc;ncia do
              cadastro do&nbsp;USU&Aacute;RIO.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Ao seu exclusivo crit&eacute;rio a&nbsp;AGS&nbsp;poder&aacute;
              excluir, inabilitar, criar limites nas funcionalidades, suspender,
              bloquear, por tempo indeterminado, sem aviso pr&eacute;vio ou
              contrapartida indenizat&oacute;ria, cadastros
              de&nbsp;USU&Aacute;RIOS&nbsp;que sejam considerados ofensivos,
              manifestamente inver&iacute;dicos, que infrinjam os termos deste
              instrumento, a Constitui&ccedil;&atilde;o Federal ou a
              legisla&ccedil;&atilde;o ordin&aacute;ria em vigor.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;se reserva ao direito de n&atilde;o permitir novo
              cadastro de&nbsp;USU&Aacute;RIOS&nbsp;que j&aacute; tenham sido
              cancelados, inabilitados, bloqueados, exclu&iacute;dos ou
              suspensos da&nbsp;PLATAFORMA. N&atilde;o se permitir&aacute;,
              ainda, a cria&ccedil;&atilde;o de novos cadastros por pessoas
              cujos cadastros originais tenham sido cancelados, bloqueados,
              inabilitados, exclu&iacute;dos ou suspensos por
              infra&ccedil;&otilde;es &agrave;s pol&iacute;ticas
              da&nbsp;PLATAFORMA&nbsp;ou &agrave; legisla&ccedil;&atilde;o
              vigente.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Ao concordar com o presente instrumento,
              o&nbsp;USU&Aacute;RIO&nbsp;declara estar ciente de que &eacute; o
              &uacute;nico respons&aacute;vel pelo seu cadastro, sendo certo que
              qualquer preju&iacute;zo causado pela inser&ccedil;&atilde;o de
              informa&ccedil;&otilde;es desatualizadas, inexatas ou
              inver&iacute;dicas, n&atilde;o poder&atilde;o ser imputados
              &agrave;&nbsp;AGS&nbsp;ou &agrave;&nbsp;PLATAFORMA.
            </p>
            <p>
              <br></br>
            </p>
            <p>6. DAS FUNCIONALIDADES:</p>
            <p>
              <br></br>
            </p>
            <p>
              Aos&nbsp;USU&Aacute;RIOS&nbsp;a&nbsp;PLATAFORMA&nbsp;oferece as
              seguintes funcionalidades:&nbsp;<br></br>
              <br></br>
            </p>
            <p>
              (i)&nbsp;Buscador inteligente das Startups, que livremente
              realizaram o cadastro e foram mapeadas no Estado do Rio Grande do
              Sul;
            </p>
            <p>
              (ii)&nbsp;Buscador inteligente do cadastro de Empresas cadastradas
              e mapeados no Estado do Rio Grande do Sul;
            </p>
            <p>
              (iii)&nbsp;Buscador inteligente do cadastro de Parques
              Tecnol&oacute;gicos e hubs cadastrados e mapeados no Estado do Rio
              Grande do Sul;
            </p>
            <p>
              (iv)&nbsp;Buscador inteligente do cadastro de Ambientes de
              Inova&ccedil;&atilde;o cadastrados e mapeados no Estado do Rio
              Grande do Sul;
            </p>
            <p>
              (v)&nbsp;Buscador inteligente de banco de mentores, que livremente
              realizaram o cadastro e foram mapeados no Estado do Rio Grande do
              Sul;&nbsp;
            </p>
            <p>
              (vi)&nbsp;Buscador inteligente acerca da densidade
              demogr&aacute;fica das Startups mapeadas no Estado do Rio Grande
              do Sul;&nbsp;
            </p>
            <p>
              (vii)&nbsp;Buscador inteligente de Cidades incentivadoras da
              inova&ccedil;&atilde;o no Estado do Rio Grande do Sul; e,&nbsp;
            </p>
            <p>
              (viii)&nbsp;Buscador inteligente de eventos de
              inova&ccedil;&atilde;o do Estado do Rio Grande do Sul;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;poder&aacute; a qualquer tempo, sem necessidade de
              aviso pr&eacute;vio, ou contrapartida indenizat&oacute;ria, editar
              e/ou excluir as funcionalidades existentes, bem como incluir novas
              funcionalidades &agrave;&nbsp;PLATAFORMA.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>7. SUPORTE T&Eacute;CNICO:&nbsp;</p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;prestar&aacute; suporte
              aos&nbsp;USU&Aacute;RIOS&nbsp;da&nbsp;PLATAFORMA&nbsp;atrav&eacute;s
              do e-mail contato@startupmap.com.br. Nas
              solicita&ccedil;&otilde;es de suporte via e-mail,
              a&nbsp;AGS&nbsp;ter&aacute; at&eacute; 30 (trinta) dias para
              proceder &agrave; resposta.
            </p>
            <p>
              <br></br>
            </p>
            <p>8. PAGAMENTO:</p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;PLATAFORMA&nbsp;N&Atilde;O POSSUI&nbsp;qualquer meio de
              cobran&ccedil;a ou valor cobrado pela&nbsp;AGS&nbsp;em face
              do&nbsp;USU&Aacute;RIO. Sendo, para todos os
              fins,&nbsp;GRATUITA&nbsp;a todos os&nbsp;USU&Aacute;RIOS.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>9. AVISO DE PRIVACIDADE E DO TRATAMENTO DOS DADOS:</p>
            <p>
              <br></br>
            </p>
            <p>
              Durante a utiliza&ccedil;&atilde;o da&nbsp;PLATAFORMA,
              pelo&nbsp;USU&Aacute;RIO, a&nbsp;AGS&nbsp;coletar&aacute; e
              armazenar&aacute; as informa&ccedil;&otilde;es fornecidas
              pelos&nbsp;USU&Aacute;RIOS, nos termos deste instrumento,
              conjuntamente com informa&ccedil;&otilde;es geradas
              automaticamente, tais como, as caracter&iacute;sticas do
              dispositivo de acesso, do navegador, registros de acesso &agrave;
              aplica&ccedil;&atilde;o (IP, com data e hora),
              informa&ccedil;&otilde;es acessadas, telas acessadas, dados de
              geolocaliza&ccedil;&atilde;o, hist&oacute;rico de
              aplica&ccedil;&otilde;es, dentre outras, dos&nbsp;USU&Aacute;RIOS,
              que ser&atilde;o armazenadas no banco de dados e tamb&eacute;m no
              cookie do browser.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Os dados coletados dos&nbsp;USU&Aacute;RIOS, pela&nbsp;AGS, por
              meio da utiliza&ccedil;&atilde;o da&nbsp;PLATAFORMA, ser&atilde;o
              utilizados para fins puramente estat&iacute;sticos do mapeamento
              do ecossistema de inova&ccedil;&atilde;o do Estado do Rio Grande
              do Sul, sendo totalmente sem fins lucrativos, visando o
              aprimoramento de iniciativas de inova&ccedil;&atilde;o do Estado,
              sendo vedado o uso dos dados para fins publicit&aacute;rios ou
              comerciais.
            </p>
            <p>&nbsp;</p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;declara compreender, aceitar e
              consentir que todos os dados coletados por meio
              da&nbsp;PLATAFORMA&nbsp;sejam tratados, nos termos do artigo 5,
              inciso X, da Lei 13.709 de 2018, pela&nbsp;AGS&nbsp;ou por
              terceiros, sendo estes:
            </p>
            <p>
              <br></br>
              <br></br>
            </p>
            <p>
              (i)&nbsp;a AWS que pode ser contatada por meio do:&nbsp;
              <a href="https://aws.amazon.com/pt/compliance/data-privacy/">
                https://www.google.com/url?q=https://aws.amazon.com/pt/compliance/data-privacy/&amp;sa=D&amp;source=docs&amp;ust=1675629705728710&amp;usg=AOvVaw208w12Gq_UaRMuQnI09F5c
              </a>
              ;
            </p>
            <p>
              (ii)&nbsp;a Google Analytics que pode ser contatada por meio
              do:&nbsp;
              <a href="https://support.google.com/campaignmanager/answer/9932167?hl=en">
                https://www.google.com/url?q=https://support.google.com/campaignmanager/answer/9932167?hl%3Den&amp;sa=D&amp;source=docs&amp;ust=1675629705728926&amp;usg=AOvVaw1-K1HDq6RlJFK3rKi1Rpvc
              </a>
              &nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              Todos os dados fornecidos livremente
              pelo&nbsp;USU&Aacute;RIO&nbsp;&agrave;&nbsp;AGS, por meio da
              utiliza&ccedil;&atilde;o da&nbsp;PLATAFORMA, ser&atilde;o
              considerados de dom&iacute;nio p&uacute;blico pela&nbsp;AGS, com
              exce&ccedil;&atilde;o de senhas de acesso e login
              do&nbsp;US&Aacute;RIO.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;se compromete a:&nbsp;<br></br>
              <br></br>
            </p>
            <p>
              (i)&nbsp;Utiliza&ccedil;&atilde;o de m&eacute;todos padr&otilde;es
              de mercado para criptografar os dados coletados, al&eacute;m de
              demais formas padr&atilde;o de encripta&ccedil;&atilde;o, para
              garantir sua inviolabilidade.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;garante ao&nbsp;USU&Aacute;RIO, no que diz
              respeito, exclusivamente ao processamento de dados pessoais, os
              seguintes direitos:
            </p>
            <p>
              <br></br>
            </p>
            <p>
              (i) A confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento
              de seus dados pessoais;
            </p>
            <p>
              (ii) O acesso aos seus dados coletados pela&nbsp;PLATAFORMA,
              atrav&eacute;s do seu pr&oacute;prio login ou mediante
              requisi&ccedil;&atilde;o ao contato@startupmap.com.br;
            </p>
            <p>
              (iii) A corre&ccedil;&atilde;o de seus dados, caso estes estejam
              incompletos, inexatos ou desatualizados;
            </p>
            <p>
              (iv) O bloqueio ou elimina&ccedil;&atilde;o de dados
              desnecess&aacute;rios, excessivos ou tratados em desconformidade
              com a legisla&ccedil;&atilde;o brasileira aplic&aacute;vel;&nbsp;
            </p>
            <p>
              (v) A portabilidade dos dados pessoais, para si ou para terceiro,
              mediante requisi&ccedil;&atilde;o expressa feita
              pelo&nbsp;USU&Aacute;RIO&nbsp;&agrave;&nbsp;AGS, atrav&eacute;s de
              contato@startupmap.com.br;&nbsp;
            </p>
            <p>
              (vi) A elimina&ccedil;&atilde;o dos dados pessoais tratados com o
              seu consentimento, desde que n&atilde;o haja
              determina&ccedil;&atilde;o legal para mant&ecirc;-los registrados
              junto &agrave;&nbsp;AGS;
            </p>
            <p>
              (vii) Informa&ccedil;&otilde;es sobre a possibilidade e
              consequ&ecirc;ncia do n&atilde;o fornecimento do consentimento
              do&nbsp;USU&Aacute;RIO.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O&nbsp;USU&Aacute;RIO&nbsp;poder&aacute; enviar e-mail para
              contato@startupmap.com.br, apontando d&uacute;vidas e/ou
              requerimentos relacionados a seus dados pessoais.&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;poder&aacute; excluir os dados pessoais coletados
              dos&nbsp;USU&Aacute;RIOS:&nbsp;
            </p>
            <p>
              <br></br>
            </p>
            <p>
              (i) Quando a finalidade para a qual foram coletados seja
              alcan&ccedil;ada; ou, quando deixarem de ser necess&aacute;rios ou
              pertinentes para o alcance da finalidade, conforme finalidades
              descritas nos presentes Termos de Uso e Pol&iacute;tica de
              Privacidade;
            </p>
            <p>
              (ii) Quando o&nbsp;USU&Aacute;RIO&nbsp;revogar seu consentimento,
              nos casos em que este se fizer necess&aacute;rio, requerendo a
              exclus&atilde;o &agrave;&nbsp;AGS&nbsp;atrav&eacute;s do
              contato@startupmap.com.br; ou,&nbsp;
            </p>
            <p>(iii) Caso seja determinado por autoridade competente.</p>
            <p>
              <br></br>
            </p>
            <p>10. DISPOSI&Ccedil;&Otilde;ES GERAIS:</p>
            <p>
              <br></br>
            </p>
            <p>
              Todos os materiais, patentes, marcas, registros, dom&iacute;nios,
              nomes, privil&eacute;gios, cria&ccedil;&otilde;es, imagens e todos
              os direitos conexos e relacionados com a&nbsp;PLATAFORMA&nbsp;e
              desenvolvidos pela&nbsp;AGS, s&atilde;o e permanecer&atilde;o de
              &uacute;nica e exclusiva propriedade da&nbsp;AGS, concordando
              os&nbsp;USU&Aacute;RIOS&nbsp;em n&atilde;o praticar ato ou fato
              que, por qualquer modo, prejudique os direitos previstos aqui e
              tampouco reivindicar qualquer direito ou privil&eacute;gio sobre
              os mesmos.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              A&nbsp;AGS&nbsp;poder&aacute; alterar este instrumento a qualquer
              momento, bastando, para tanto, publicarmos uma vers&atilde;o
              revisada em nosso site. Por este motivo, recomendamos
              veementemente que sempre visite esta se&ccedil;&atilde;o de nosso
              Site, lendo-a, periodicamente. Mas, para contribuir com o bom
              relacionamento, tamb&eacute;m enviaremos um e-mail informando
              acerca dessas mudan&ccedil;as.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              O presente instrumento constitui o entendimento integral entre o
              USU&Aacute;RIO e a AGS e &eacute; regido pelas Leis Brasileiras,
              ficando eleito o foro da cidade de Porto Alegre/RS como
              &uacute;nico competente para dirimir quest&otilde;es decorrentes
              do presente instrumento, com ren&uacute;ncia expressa a qualquer
              outro foro, por mais privilegiado que seja.&nbsp;
            </p>
          </div>
        </C.form>
      </C.containerForm>
    </C.container>
  );
};

export default Terms;
