import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { theme } from '../../../styles/theme/themeMui';
import { FormikProps } from 'formik';
import { ICompany } from '../../../interfaces/company.interface';
import {
  businessModelOPT,
  businessSegmentsOPT,
  businessStageOPT,
  businessTypeOPT,
} from '../../../helpers/options';
import { Alert } from '@mui/material';
import { MaskOnlyNumbers } from '../../../helpers/mask';
import { IFormProps } from '../../../interfaces/form.interface';

const Form3 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization } = props;
  return (
    <C.container>
      <C.titleMaster>Classificação</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Segmento</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="businessSegment"
            value={props.values.businessSegment}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessSegmentsOPT.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && props.values.businessSegment === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Estágio</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="businessStage"
            value={props.values.businessStage}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessStageOPT.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && props.values.businessStage === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Modelo de Negócio</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="businessModel"
            value={props.values.businessModel}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessModelOPT.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && props.values.businessModel === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Tipo de Negócio</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="businessType"
            value={props.values.businessType}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessTypeOPT.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && !props.values.businessType ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Número de colaboradores</C.titleTextField>
          <C.textField
            placeholder="Digite o número de colaboradores"
            name="employees"
            value={props.values.employees}
            onChange={e => {
              props.setFieldValue('employees', MaskOnlyNumbers(e.target.value));
            }}
            disabled={visualization}
          />
          {showAlertErr && !props.values.employees ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
    </C.container>
  );
};
export default Form3;
