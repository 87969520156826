import React, { Dispatch, SetStateAction, useState } from 'react';
import * as C from './styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import { IFilterStartup } from '../../../interfaces/filter.interface';
import { regions } from '../forms/regions';
import { ICity } from '../../../services/cities.service';

interface ICityFilter {
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  cities: ICity[];
  setFilterRegion: Dispatch<SetStateAction<string>>;
  setFilterCity: Dispatch<SetStateAction<string>>;
}

const FilterModal = ({
  setState,
  state,
  cities,
  setFilterRegion,
  setFilterCity,
}: ICityFilter) => {
  const [currentRegion, setCurrentRegion] = useState('');
  const [city, setCity] = useState('');

  const handleClose = () => {
    setState(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentRegion(event.target.value);
    setCity('');
  };
  const handleChangeCity = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  const clearStates = () => {
    setCurrentRegion('');
    setCity('');
  };

  const clearFilters = () => {
    setFilterCity('');
    setFilterRegion('');
    clearStates();
    setState(false);
  };

  const applyFilters = () => {
    setFilterCity(city);
    setFilterRegion(currentRegion);

    setState(false);
  };

  const filteredCities =
    currentRegion.length > 0
      ? cities.filter(region => region.region.includes(currentRegion))
      : cities;

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state}
      >
        {/* <TitleModal id="customized-dialog-title" onClose={handleClose}>
          Filtrar
        </TitleModal> */}
        <C.containerTitle>
          <C.title>Filtrar</C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Região</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={currentRegion}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas as Regiões</em>
                  </MenuItem>
                  {regions.map(item => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>Cidade</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={city}
                  onChange={handleChangeCity}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas as Cidades</em>
                  </MenuItem>
                  {currentRegion.length > 0
                    ? filteredCities.map(item => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))
                    : cities.map(item => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>
        </DialogContent>

        <C.containerButtons>
          <Button
            variant="outlined"
            className="btn_clear"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            className="btn_apply"
            onClick={applyFilters}
          >
            Aplicar Filtros
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default FilterModal;
