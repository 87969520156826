import { shade } from 'polished';
import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
`;

export const wraper = styled.div`
  display: flex;
  max-width: 1600px;
  margin: 0 auto;
  justify-content: space-between;

  @media only screen and (max-width: 1090px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
`;

export const wraperTwo = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.GREY_V3};
`;
export const containerFilters = styled.div`
  width: 100%;
  height: 500px;
  display: flex;

  .btn_mobile {
    display: none;
  }

  .btn_add_startup {
    font-weight: 700;
    margin-top: 30px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FF8787')} !important;
    }

    @media only screen and (max-width: 1090px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1090px) {
    flex-wrap: wrap;
    height: 0;
    position: absolute;
    z-index: 1000;
    width: 100%;

    .btn_mobile {
      margin: 0 auto;
      display: block;
      font-weight: 700;
      padding: 13px 35px;
      text-transform: none;
      color: #fff !important;
      font-family: 'Montserrat';
      font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
      background-color: ${({ theme }) => theme.COLORS.RED};
      &:hover {
        background-color: ${shade(0.2, '#FF8787')} !important;
      }
    }
  }
`;

export const filters = styled.div`
  width: 100%;
  margin-top: 150px;
  padding-left: 8%;
  @media only screen and (max-width: 1090px) {
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    justify-content: center;
  }

  @media only screen and (max-width: 580px) {
    margin-top: 50px;
    text-align: center;
  }
`;

export const title = styled.h1`
  font-weight: 900;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.GREEN_V4};
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
`;
export const containerTextField = styled.div`
  width: 100%;
  display: -webkit-box;
  svg {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
  button {
    font-weight: 700;
    margin-left: -10px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FF8787')} !important;
    }

    @media only screen and (max-width: 1090px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1090px) {
    width: 100%;
    justify-content: center;
  }
`;

export const containerButtonAdd = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  justify-content: center;
`;

export const textFieldWraper = styled.div``;

export const textField = styled.input`
  width: 400px;
  border: 0;
  height: 3.6375em;
  border-radius: 3px;
  padding-left: 60px;
  margin-right: 45px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.RED};

  &:focus {
    outline: 2px solid ${({ theme }) => theme.COLORS.RED};
  }
  @media only screen and (max-width: 1090px) {
    margin: 0 auto 20px auto;
  }

  @media only screen and (max-width: 590px) {
    width: 300px;
    margin: 0 auto 20px auto;
  }
`;

export const containerImage = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: start;
  @media only screen and (max-width: 1090px) {
    justify-content: center;
    align-items: center;
  }
`;
export const image = styled.div`
  width: 80%;
  height: 300px;

  & img {
    top: 3px;
    z-index: 1002;
    margin-left: 50px;
    position: absolute;
    height: 700px !important;
  }

  @media only screen and (max-width: 1099px) {
    & img {
      top: 3px;
      z-index: 1002;
      position: absolute;
      height: 580px !important;
    }
  }

  @media only screen and (max-width: 1180px) {
    width: 100%;
    display: flex;
    justify-content: center;
    & img {
      top: 3px;
      z-index: 1002;
      margin-left: 90px;
    }
  }

  @media only screen and (max-width: 580px) {
    & img {
      margin-top: 25px;
      margin-left: 60px;
      position: relative;
      height: 90% !important;
    }
  }
`;

export const fillEfect = styled.div`
  top: 441px;
  width: 100%;
  height: 200px;
  z-index: 1001;
  position: absolute;
  clip-path: polygon(0 97%, 100% 0, 100% 100%, 0% 100%);
  background-color: ${({ theme }) => theme.COLORS.GREY_V3};
  -webkit-clip-path: polygon(0 97%, 100% 0, 100% 100%, 0% 100%);

  @media only screen and (max-width: 1090px) {
    height: 254px;
    top: 240px;
  }

  @media only screen and (max-width: 580px) {
    height: 254px;
    top: 150px;
  }
`;

export const containerCards = styled.div`
  width: 100%;
  max-width: 1600px;
  display: grid;
  padding-top: 40px;
  margin: 0 auto;
  padding-bottom: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (max-width: 1090px) {
    margin-top: 110px;
    padding-top: 370px;
    padding-bottom: 160px;
  }
  @media only screen and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 19px;
    padding-top: 470px;
    padding-bottom: 160px;
    grid-template-columns: 1fr;
  }
`;
