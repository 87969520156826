export interface ICard {
  id: string;
  type: type;
  title: string;
  path?: string;
  region?: string;
  city?: string;
  name?: string;
  logoId?: string;
  description?: string;
}

export interface ILogo {
  size: number;
  url: string;
}

export interface ICardEvent {
  id: string;
  type: type;
  title: string;
  date: string;
  location: string;
  admissionCharged: number;
  fee: string;
  path?: string;
}

export interface ICardMentor {
  id: string;
  name: string;
  title: string;
  city: string;
  type: type;
  path?: string;
}

export interface ICardProjects {
  id: number;
  type: type;
  typeProject: string;
  title: string;
  path?: string;
}

export enum type {
  First,
  Secondary,
}
