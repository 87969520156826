import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import NavbarMobile from '../../../components/NavbarMobile';
import ConcludedContact from '../../../components/ConcludedContact';
import Api from '../../../api';
import { isLogged } from '../../../services/session.service';
import { validateEmail } from '../../../helpers/validate.email';

const Contact = () => {
  const [showConcluded, setShowConcluded] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const validateForm = () => {
    let valid = true;
    const errors: any = {};

    if (formValues.name.trim() === '') {
      errors.name = 'Campo obrigatório';
      valid = false;
    }

    if (formValues.email.trim() === '') {
      errors.email = 'Campo obrigatório';
      valid = false;
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'E-mail inválido';
      valid = false;
    }

    if (formValues.phone.trim() === '') {
      errors.phone = 'Campo obrigatório';
      valid = false;
    } else if (!/^\d{10,11}$/.test(formValues.phone)) {
      errors.phone = 'Telefone inválido';
      valid = false;
    }

    if (formValues.message.trim() === '') {
      errors.message = 'Campo obrigatório';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const handleSubmit = async () => {
    const payload = {
      name: formValues.name,
      email: formValues.email.trim(),
      phone: formValues.phone,
      message: formValues.message,
    };

    await Api.post('/contact-form', payload);

    setFormValues({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const handleSend = async () => {
    if (validateForm()) {
      setShowConcluded(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />
        <C.headerDetails>
          <C.containerInfos>
            <C.containerTitles>
              <C.headerTitle>Contato</C.headerTitle>
              <C.headerSubTitle>
                Fale conosco através do formulário abaixo!<br></br>
                Confira também nosso&nbsp;
                <a
                  className="support_material"
                  rel="noreferrer"
                  target="_blank"
                  href="https://drive.google.com/drive/folders/1iHZRyvJ0cmF34kKQ5ZMexNEwQq29OUyx?usp=share_link"
                >
                  material de apoio
                </a>
                .
              </C.headerSubTitle>
            </C.containerTitles>
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.contactForm>
            {showConcluded ? (
              <ConcludedContact
                handleSubmit={handleSubmit}
                setState={setShowConcluded}
              />
            ) : (
              <>
                <C.titleForm>Envie uma mensagem</C.titleForm>
                <C.textFieldWraper100>
                  <C.titleTextField>Nome</C.titleTextField>
                  <C.textField
                    placeholder="Seu nome"
                    value={formValues.name}
                    onChange={e =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                  />
                  {formErrors.name && (
                    <C.errorMessage>{formErrors.name}</C.errorMessage>
                  )}
                </C.textFieldWraper100>
                <C.textFieldWraper50>
                  <C.boxTextField>
                    <C.titleTextField>E-mail</C.titleTextField>
                    <C.textField
                      placeholder="Seu e-mail"
                      value={formValues.email}
                      onChange={e =>
                        setFormValues({ ...formValues, email: e.target.value })
                      }
                    />
                    {formErrors.email && (
                      <C.errorMessage>{formErrors.email}</C.errorMessage>
                    )}
                  </C.boxTextField>
                  <C.boxTextField>
                    <C.titleTextField>Telefone</C.titleTextField>
                    <C.textField
                      placeholder="Seu telefone"
                      value={formValues.phone}
                      onChange={e =>
                        setFormValues({ ...formValues, phone: e.target.value })
                      }
                    />
                    {formErrors.phone && (
                      <C.errorMessage>{formErrors.phone}</C.errorMessage>
                    )}
                  </C.boxTextField>
                </C.textFieldWraper50>
                <C.textFieldWraper100>
                  <C.titleTextField>Mensagem</C.titleTextField>
                  <C.textAreaField
                    placeholder="Sua mensagem"
                    className="textaera_master"
                    value={formValues.message}
                    onChange={e =>
                      setFormValues({ ...formValues, message: e.target.value })
                    }
                  />
                  {formErrors.message && (
                    <C.errorMessage>{formErrors.message}</C.errorMessage>
                  )}
                </C.textFieldWraper100>
                <C.containerButton>
                  <Button
                    variant="contained"
                    className="btn_contact"
                    onClick={handleSend}
                  >
                    Enviar
                  </Button>
                </C.containerButton>
              </>
            )}
          </C.contactForm>
        </C.containerBody>
      </C.container>
      <Footer />
    </>
  );
};

export default Contact;
