import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/DoneAllRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import * as C from './styles';
import Form1 from '../forms/form1';
import Form2 from '../forms/form2';
import Form3 from '../forms/form3';
import Form7 from '../forms/form7';
import Concluded from '../../Concluded';
import { themeDialog } from '../../../styles/theme/themeMui';
import { IAddStartup } from '../../../interfaces/startup.interface';
import { errors, payload } from '../forms/Form.Types';
import * as CitiesService from '../../../services/cities.service';
import { validateEmail } from '../../../helpers/validate.email';

export const validURL = (url: string): boolean => {
  const re = new RegExp(
    '^(https?://)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return re.test(url);
};

function validPhone(phone: string): boolean {
  const cleanPhone = phone.replace(/\D/g, '');

  const isMobile =
    /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );

  if (isMobile) {
    return /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );
  } else {
    return /^([1-9]{2})\s?[2-5|7-9][0-9]{7}$/.test(cleanPhone);
  }
}

const initialValues: CitiesService.ICity = {
  region: '',
  name: '',
  mayor: '',
  description: '',
  contact: {
    site: '',
    email: '',
    phone: '',
  },
  publicPolicy: [],
};

const initialRequiredFields: errors = {
  regionError: false,
  nameError: false,
  mayorError: false,
  descriptionError: false,
  siteError: false,
  emailError: false,
  phoneError: false,
  emailValid: false,
  phoneValid: false,
  siteValid: false,
};

export const AddModal = ({
  setShow,
  show,
  id,
  visualization = false,
}: IAddStartup) => {
  const [data, setData] = useState<CitiesService.ICity>(initialValues);
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('0');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);

  const [requiredFields, setRequiredFields] = useState<errors>(
    initialRequiredFields,
  );

  const [index, setIndex] = useState(1);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  // const [form4, setForm4] = useState(false);
  // const [form5, setForm5] = useState(false);
  // const [form6, setForm6] = useState(false);
  const [form7, setForm7] = useState(false);
  const [notReturn, setNotReturn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const validator1 = () => {
    const { region, name, mayor } = data;

    setRequiredFields({
      ...requiredFields,
      regionError: !region,
      nameError: !name,
      mayorError: !mayor,
    });

    if (Boolean(!region) || Boolean(!name) || Boolean(!mayor)) return false;
    return true;
  };
  const validator2 = () => {
    const { description } = data;

    setRequiredFields({
      ...requiredFields,
      descriptionError: Boolean(!description),
    });

    if (!description) return false;
    return true;
  };
  const validator3 = () => {
    const { site, phone, email } = data.contact;

    setRequiredFields({
      ...requiredFields,
      siteError: Boolean(!site),
      siteValid: !validURL(site),
      phoneError: Boolean(!phone),
      phoneValid: !validPhone(phone),
      emailError: Boolean(!email),
      emailValid: !validateEmail(email),
    });

    if (!validURL(site) || !validPhone(phone) || !validateEmail(email))
      return false;
    return true;
  };

  const handleNext = () => {
    if (index === 1) {
      if (validator1()) {
        setIndex(2);
        setForm1(false);
        setForm2(true);
      }
    }
    if (index === 2) {
      if (validator2()) {
        setIndex(3);
        setForm2(false);
        setForm3(true);
      }
    }
    if (index === 3) {
      if (validator3()) {
        setIndex(7);
        setForm3(false);
        setForm7(true);
      }
    }
  };

  const handlePrev = () => {
    if (index === 2) {
      setIndex(1);
      setForm1(true);
      setForm2(false);
    }
    if (index === 3) {
      setIndex(2);
      setForm2(true);
      setForm3(false);
    }

    if (index === 7) {
      setIndex(3);
      setForm7(false);
      setForm3(true);
    }
  };

  const handleConcluded = async () => {
    let success = '';

    setForm7(false);
    setShowConcluded(true);
    setNotReturn(true);

    if (isEditing) {
      //serviço de edição
      success = await CitiesService.Update(data);
    } else {
      success = await CitiesService.Create(data);
    }

    if (!success) {
      setSuccess(true);
      setTitle('Tudo\nPronto!');

      setSubOne('Obrigada pela sua colaboração!');
      setSubTwo('');
      setTimeout(() => {
        handleClose();
      }, 4000);
    } else {
      setSuccess(false);
      setTitle('Erro');
      setSubOne(
        'Sentimos muito, porém nosso servidor não conseguiu gravar as informações. A situação deverá ser resolvida em breve.',
      );
      setSubTwo(success);
      setTimeout(() => {
        setForm7(true);
        setNotReturn(false);
        setShowConcluded(false);
        setTitle('');
        setSubOne('');
        setSubTwo('');
      }, 5000);
    }
  };

  const handleClose = () => {
    setShow(false);
    setForm1(true);
    setForm2(false);
    setForm3(false);
    setForm7(false);

    setData({
      id: id,
      region: '',
      name: '',
      mayor: '',
      description: '',
      contact: {
        site: '',
        email: '',
        phone: '',
      },
      publicPolicy: [],
    });
    setShowConcluded(false);
    setIndex(1);
  };

  const handleDataChange = (
    field: keyof payload,
    value:
      | string
      | { site: string; email: string; phone: string }
      | { name: string; description: string }
      | { name: string; description: string }[],
  ) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const setInfos = async (id: string) => {
    const response = await CitiesService.Get(id);
    if (response) {
      setData({
        id: id,
        region: response.region,
        name: response.name,
        mayor: response.mayor,
        description: response.description,
        contact: {
          site: response.contact.site,
          email: response.contact.email,
          phone: response.contact.phone,
        },
        publicPolicy: response.publicPolicy,
      });
    }
  };

  useEffect(() => {
    if (id) {
      setInfos(id);
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>
            <LocationCityRoundedIcon />
            {isEditing ? 'Edição de Cidade' : 'Cadastro de Cidade'}
          </C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {form1 && (
            <Form1
              key={JSON.stringify(requiredFields)}
              data={data}
              onDataChange={handleDataChange}
              listErrors={requiredFields}
              visualization={visualization}
            />
          )}

          {form2 && (
            <Form2
              key={JSON.stringify(requiredFields)}
              data={data}
              onDataChange={handleDataChange}
              listErrors={requiredFields}
              visualization={visualization}
            />
          )}
          {form3 && (
            <Form3
              key={JSON.stringify(requiredFields)}
              data={data}
              onDataChange={handleDataChange}
              visualization={visualization}
              listErrors={requiredFields}
            />
          )}

          {form7 && (
            <Form7
              data={data}
              onDataChange={handleDataChange}
              visualization={visualization}
            />
          )}
          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>
        <C.containerDotsMobile>
          <C.dot disabled={form1} />
          <C.dot disabled={form2} />
          <C.dot disabled={form3} />
          <C.dot disabled={form7} />
        </C.containerDotsMobile>
        <C.containerButtons disabled={form1}>
          <Button
            variant="outlined"
            className="btn_previus"
            disabled={form1 || notReturn}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
            Anterior
          </Button>
          <C.containerDots>
            <C.dot disabled={form1} />
            <C.dot disabled={form2} />
            <C.dot disabled={form3} />
            <C.dot disabled={form7} />
          </C.containerDots>
          {form7 || showConcluded ? (
            <Button
              variant="outlined"
              className="btn_send"
              onClick={handleConcluded}
              disabled={!form7 || visualization}
            >
              Enviar
              <SendIcon />
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="btn_next"
              onClick={handleNext}
            >
              Próximo
              <ArrowNextIcon />
            </Button>
          )}
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default AddModal;
