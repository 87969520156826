import { shade } from 'polished';
import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  padding: 0px 20px;

  .btn_add_image {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }

    @media only screen and (max-width: 1090px) {
      display: none;
    }
  }

  .btn_add_partner {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }

  .btn_save {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }

  .btn_cancel {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FD5D5D')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }
`;

export const titleMaster = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
`;

export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;

export const errMessageText = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
  color: ${({ theme }) => theme.COLORS.RED} !important;
`;

export const textFieldWraper100 = styled.div`
  .datepicker_event {
    width: 100%;
  }
`;
export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const textFieldWraper33 = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;
export const boxTextField = styled.div`
  width: 100%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 30px 0;
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const textAreaField = styled.textarea`
  border: 0;
  width: 100%;
  min-height: 160px;
  padding: 20px;
  resize: none;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &.textaera_master {
    min-height: 500px;
  }

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const space = styled.div`
  margin: 50px 0;
`;

export const containerLogo = styled.div`
  width: 130px;
  height: 130px;
  display: flex;
  margin-top: 20px;
  border-radius: 65px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V1};

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
`;

export const buttonsSupport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
`;
