import { shade } from 'polished';
import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height: 715px;
  text-align: center;
  .load {
    margin-top: 240px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  .check_icon {
    margin-top: 200px;
    font-size: 40px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }

  .btn_new_contact {
    font-weight: 700;
    margin-top: 30px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FD5D5D')} !important;
    }

    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
  }
`;

export const title = styled.h2`
  font-weight: 700;
  max-width: 350px;
  margin: 10px auto 20px auto;
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
`;

export const subTitle = styled.p`
  max-width: 600px;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
