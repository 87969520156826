import React, { useEffect } from 'react';
import * as C from './styles';
import { useState } from 'react';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Dispatch, SetStateAction } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { IUser } from '../../../interfaces/user.interface';
import * as UserService from '../../../services/user.service';
import { themeDialog } from '../../../styles/theme/themeMui';
import { checkEditUserFields } from '../../../helpers/account.check.fields';
import CloseIcon from '@mui/icons-material/Close';

interface IEdit {
  show: boolean;
  user: IUser | undefined;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const EditModal = ({ setShow, show, user }: IEdit) => {
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setShow(false);
    setShowConcluded(false);
  };

  const handleSave = async () => {
    const data = {
      id: id,
      name: name,
      email: email,
    };
    if (!checkEditUserFields(data).success) {
      const msgError = checkEditUserFields(data).message;
      setTimeout(async () => {
        setSuccess(false);
        setTitle('Ooops!');
        setSubOne(msgError);

        setTimeout(() => {
          setShowConcluded(false);
          setTitle('');
          setSubOne('');
        }, 2000);
      }, 2000);
    } else {
      const success = await UserService.UpdateByAdmin({ email, id, name });

      setTimeout(() => {
        if (success) {
          setSuccess(true);
          setTitle('Tudo certo!');
          setSubOne('Usuário editado com sucesso');
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          setSuccess(false);
          setTitle('Ooops!');
          setSubOne('Erro ao tentar editar usuário');

          setTimeout(() => {
            setShowConcluded(false);
            setTitle('');
            setSubOne('');
          }, 4000);
        }
      }, 2000);
    }
    setShowConcluded(true);
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setId(user.id);
    }
  }, [user]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>Editar Usuário</C.title>
          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {!showConcluded ? (
            <C.containerTextFields>
              <C.textFieldWraper50>
                <C.boxTextField>
                  <C.boxTextField>
                    <C.titleTextField>Nome</C.titleTextField>
                    <C.textField
                      placeholder="Digite o nome"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </C.boxTextField>
                </C.boxTextField>
                <C.boxTextField>
                  <C.titleTextField>E-mail</C.titleTextField>
                  <C.textField
                    placeholder="Digite o e-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </C.boxTextField>
              </C.textFieldWraper50>
            </C.containerTextFields>
          ) : null}
          {showConcluded ? (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          ) : null}
        </DialogContent>

        <C.containerButtons disabled={false}>
          <Button
            variant="outlined"
            className="btn_cancel"
            onClick={handleClose}
          >
            Cancelar
          </Button>

          <Button variant="outlined" className="btn_add" onClick={handleSave}>
            Salvar
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default EditModal;
