import Api from '../api';
import { IFormdata } from '../interfaces/startup.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(data: IFormdata): Promise<any> {
  const response: any = { success: false, message: '' };
  const token = getAccessToken();

  await Api.post(
    '/startups',
    {
      name: data.name,
      shortDescription: data.shortDescription,
      description: data.description,
      solution: data.solution,
      companyCode: data.thereIsFormalization ? data.companyCode : null,
      companyType: data.thereIsFormalization ? data.companyType : null,
      accelerationProgram: data.accelerationProgram,
      foundingDate: data.foundingDate,
      partners: data.partners,
      employees: data.employees,
      businessSegment: data.businessSegment,
      businessStage: data.businessStage,
      businessModel: data.businessModel,
      businessType: data.businessType,
      contact: data.contact,
      address: {
        street: data.address.street,
        number: data.address.number,
        zipCode: data.address.zipCode,
        district: data.address.district,
        city: data.address.city,
        state: data.address.state,
        street2: data.address.street2,
      },
      socialMedia: data.socialMedia,
      bannerId: data.bannerId,
      logoId: data.logoId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(res => {
      console.log(res);
      response.success = true;
    })
    .catch(error => {
      console.log(error);
      response.success = false;
      response.message = error.response.data.message;
    });

  return response;
}

export async function GetAll(): Promise<IFormdata[] | undefined> {
  let response: IFormdata[] | undefined;

  await Api.get(`/startups`, {
    params: { size: 1000 },
  })
    .then(res => {
      return (response = res.data.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}

export async function Get(id: string): Promise<IFormdata | undefined> {
  let response: IFormdata | undefined = undefined;
  const token = getAccessToken();

  await Api.get(`/startups/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(res => {
      return (response = res.data);
    })
    .catch(err => console.log(err));

  return response;
}

export async function Update(data: IFormdata): Promise<any> {
  const response: any = { success: false, message: '' };
  const token = getAccessToken();

  await Api.put(
    `/startups/${data.id}`,
    {
      name: data.name,
      shortDescription: data.shortDescription,
      description: data.description,
      solution: data.solution,
      companyCode: data.thereIsFormalization ? data.companyCode : null,
      companyType: data.thereIsFormalization ? data.companyType : null,
      accelerationProgram: data.accelerationProgram,
      foundingDate: data.foundingDate,
      partners: data.partners,
      employees: data.employees,
      businessSegment: data.businessSegment,
      businessStage: data.businessStage,
      businessModel: data.businessModel,
      businessType: data.businessType,
      contact: {
        site: data.contact.site,
        phone: data.contact.phone,
        email: data.contact.email.trim(),
      },
      address: {
        street: data.address.street,
        number: data.address.number,
        zipCode: data.address.zipCode,
        district: data.address.district,
        city: data.address.city,
        state: data.address.state,
        street2: data.address.street2,
      },
      socialMedia: data.socialMedia,
      bannerId: data.bannerId,
      logoId: data.logoId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response.success = true;
    })
    .catch(error => {
      console.log(error);
      response.success = false;
      if (error.response.data.response.message) {
        response.message = error.response.data.response.message.join(',');
      } else {
        response.message = error.response.data.message;
      }
    });

  return response;
}
