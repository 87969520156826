import React, { useEffect, useState } from 'react';
import * as C from './styles';
import LogoDefault from '../../../assets/logo-default.png';
import { Button } from '@mui/material';
import Slider from '@mui/material/Slider';
import Navbar from '../../../components/Navbar';
import BannerLogo from '../../../assets/banner.png';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import NavbarMobile from '../../../components/NavbarMobile';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import { useLocation } from 'react-router-dom';
import * as MentorService from '../../../services/mentor.service';
import CardCities from '../../../components/CardCities';
import { type } from '../../../interfaces/card.interface';
import Banner from '../../../components/Banner';
import Logo from '../../../components/Logo';
import { IMentor } from '../../../interfaces/mentor.interface';
import { isLogged } from '../../../services/session.service';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: '100%',
  },
];

function valuetext(value: number) {
  return `${value}%`;
}

const DetailsMentor = () => {
  const [mentor, setMentor] = useState<IMentor>();

  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getMentor = async () => {
      await MentorService.GetById(id).then(res => {
        setMentor(res);
      });
    };

    getMentor();
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />
        {mentor?.bannerId ? (
          <Banner size={400} url={mentor?.bannerId} />
        ) : (
          <C.bannerDetails src={BannerLogo} alt="Banner Detalhes" />
        )}
        <C.headerDetails>
          <C.containerInfos>
            <C.containerImage className="image_card">
              {mentor?.logoId ? (
                <Logo size={180} url={mentor?.logoId} />
              ) : (
                <img src={LogoDefault} alt="Logo Startup" />
              )}
            </C.containerImage>
            <C.containerTitles>
              <C.headerTitle>{mentor?.name}</C.headerTitle>
              <C.headerSubTitle>{mentor?.contact.email}</C.headerSubTitle>
              <C.headerSubTitle>{mentor?.contact.phone}</C.headerSubTitle>
            </C.containerTitles>
            <C.containerButtonsSocial>
              {mentor?.socialMedia?.instagram && (
                <IconButton
                  onClick={() =>
                    window.open(
                      mentor?.socialMedia?.instagram?.includes('https://')
                        ? mentor?.socialMedia?.instagram
                        : `https://${mentor?.socialMedia?.instagram}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {mentor?.socialMedia?.facebook && (
                <IconButton
                  onClick={() =>
                    window.open(
                      mentor?.socialMedia?.facebook?.includes('https://')
                        ? mentor?.socialMedia?.facebook
                        : `https://${mentor?.socialMedia?.facebook}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <FacebookIcon />
                </IconButton>
              )}
              {mentor?.socialMedia?.linkedin && (
                <IconButton
                  onClick={() =>
                    window.open(
                      mentor?.socialMedia?.linkedin?.includes('https://')
                        ? mentor?.socialMedia?.linkedin
                        : `https://${mentor?.socialMedia?.linkedin}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {mentor?.socialMedia?.youtube && (
                <IconButton
                  onClick={() =>
                    window.open(
                      mentor?.socialMedia?.youtube?.includes('https://')
                        ? mentor?.socialMedia?.youtube
                        : `https://${mentor?.socialMedia?.youtube}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <YouTubeIcon />
                </IconButton>
              )}
              {mentor?.contact?.site && (
                <IconButton
                  onClick={() =>
                    window.open(
                      mentor?.contact?.site?.includes('https://')
                        ? mentor?.contact?.site
                        : `https://${mentor?.contact?.site}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <PublicIcon />
                </IconButton>
              )}
            </C.containerButtonsSocial>
            {/* {logged && (
              <C.containerButtonEdit>
                <IconButton aria-label="social">
                  <EditRoundedIcon />
                </IconButton>
              </C.containerButtonEdit>
            )} */}
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>{mentor?.title}</C.detailTitle>
            <pre>{mentor?.description}</pre>

            <C.TitleMaster>Histórico de mentorias</C.TitleMaster>
            <C.containerCards>
              {mentor?.mentorship.map(item => (
                <CardCities
                  key={item.name}
                  title={item.name}
                  region={item.year.toString()}
                  type={type.First}
                  id={item.name}
                />
              ))}
            </C.containerCards>
          </C.details>
          <C.containerSkils>
            <C.title>Competências</C.title>

            {mentor?.skills.map(skill => (
              <C.skilItem key={skill.name}>
                <C.skilName>{skill.name}</C.skilName>

                <Slider
                  disabled
                  sx={{ color: '#26d2a9', marginTop: '30px' }}
                  aria-label="Always visible"
                  defaultValue={skill.score}
                  getAriaValueText={valuetext}
                  step={10}
                  marks={marks}
                  valueLabelDisplay="on"
                />
              </C.skilItem>
            ))}
          </C.containerSkils>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/mentores')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsMentor;
