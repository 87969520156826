import React, { useState, useEffect } from 'react';
import * as C from './styles';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import parse from 'html-react-parser';
import { Alert } from '@mui/material';

interface IConcluded {
  title: string;
  subOne: string;
  success: boolean;
  subTwo?: string;
}

const Concluded = ({ subOne, success, subTwo, title }: IConcluded) => {
  const [showLoad, setShowLoad] = useState(false);

  useEffect(() => {
    setShowLoad(true);
    setTimeout(() => {
      setShowLoad(false);
    }, 3000);
  }, []);

  const displayError = (error: any) => {
    if (error) {
      return (
        <Alert variant="outlined" severity="error" className="alert">
          <C.subTitle>{parse(subTwo || '')}</C.subTitle>
        </Alert>
      );
    }
  };

  return (
    <C.container>
      {showLoad ? (
        <CircularProgress size={60} className="load" />
      ) : (
        <>
          {success ? (
            <CheckCircleRoundedIcon className="check_icon" />
          ) : (
            <HighlightOffRoundedIcon className="error_icon" />
          )}
          <C.title success={success}>{title}</C.title>
          <C.subTitle>{subOne}</C.subTitle>
          {displayError(subTwo)}
        </>
      )}
    </C.container>
  );
};
export default Concluded;
