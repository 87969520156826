import styled from 'styled-components';

export const containerTable = styled.div`
  width: 100%;
  padding: 0 2%;
  max-width: 1550px;
  margin: 50px auto 0 auto;

  .MuiMenuItem-root {
    width: 100px !important;
    justify-content: center;
    font-family: 'Montserrat';
    color: ${({ theme }) => theme.COLORS.BLACK}!important;
  }
`;

export const titleTable = styled.h2`
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.RED};
  font-size: ${({ theme }) => theme.FONT_SIZES.XS}em;
`;

export const subTitleTable = styled.p`
  margin: 10px 0 20px 0;
  font-weight: 400;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;

export const containerButtonsActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .btn_edit {
    svg {
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
`;
