import styled from 'styled-components';

export const containerLogo = styled.div`
  display: flex;
  margin-top: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(142, 141, 208, 0.16);
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
  padding: 10px;

  .logo {
    background-size: contain;
    background-position: center;
    margin: 15px;
  }

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
`;
