import styled from 'styled-components';
import Macbook from '../../assets/macbook.png';

export const container = styled.div`
  width: 1000px;
  height: 800px;
  margin: 60px auto;
  padding: 2%;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 10px auto;
    height: 100%;
  }
`;

export const image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${Macbook});

  //TODO: AJUSTAR HEIGHT
  .map {
    width: 72%;
    top: -16px;
    left: 7px;
    height: 56.3%;
    margin: 0 auto;
    position: relative;
    background-color: black;
  }

  @media screen and (max-width: 2400px) {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    .map {
      width: 72%;
      top: -13px;
      left: 5px;
    }
  }

  @media screen and (max-width: 1500px) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .map {
      width: 71%;
      height: 50%;
      top: -13px;
      left: 5px;
    }
  }

  @media screen and (max-width: 1090px) {
    width: 80%;
    height: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
    background-image: none;
    .map {
      width: 100%;
      height: 100%;
      top: -1px;
      left: 5px;
    }
  }
`;
