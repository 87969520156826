import React from 'react';
import * as C from './styles';
import { FormikProps } from 'formik';
import { ICompany } from '../../../interfaces/company.interface';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form2 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization } = props;
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Detalhes do negócio</C.titleTextField>
        <C.textAreaField
          placeholder="Descreva o seu negócio..."
          className="textaera_master"
          name="description"
          value={props.values.description}
          onChange={props.handleChange}
          disabled={visualization}
          maxLength={2000}
        />
        <p>{`${props.values.description.length}/2000`}</p>

        {showAlertErr && props.values.description === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form2;
