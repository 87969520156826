import React from 'react';
import Home from '../screens/Site/home';
import Login from '../screens/Site/login';
import Cities from '../screens/Site/cities';
import Events from '../screens/Site/events';
import Contact from '../screens/Site/contact';
import Account from '../screens/Site/account';
import Mentores from '../screens/Site/mentors';
import Startups from '../screens/Site/startups';
import { Routes, Route } from 'react-router-dom';
import Companies from '../screens/Site/companies';
import ResetPass from '../screens/Site/resetPass';
import DetailsPark from '../screens/Site/datailPark';
import DetailsCity from '../screens/Site/datailCity';
import { PrivateAdmin, PrivateUser } from './private';
import RecoverPass from '../screens/Site/recoverPass';
import ResetPassword from '../screens/Site/resetPassword';
import Innovations from '../screens/Site/innovations';
import DetailsEvent from '../screens/Site/datailEvent';
import Instituitons from '../screens/Site/institutions';
import DetailsMentor from '../screens/Site/detailMentors';
import DetailsStartup from '../screens/Site/detailStatup';
import DetailsCompany from '../screens/Site/datailCompany';
import TechnologyParks from '../screens/Site/technologyParks';
import DetailsInnovations from '../screens/Site/detailInnovations';
import DetailsInstituitions from '../screens/Site/detailInstituitions';

import HomeUser from '../screens/User';
import HomeAdmin from '../screens/Admin/home';
import Adminparks from '../screens/Admin/parks';
import AdminUsers from '../screens/Admin/users';
import Settings from '../screens/Admin/settings';
import AdminCities from '../screens/Admin/cities';
import AdminEvents from '../screens/Admin/events';
import AdminMentors from '../screens/Admin/mentors';
import AdminStartups from '../screens/Admin/startups';
import AdminInnovations from '../screens/Admin/innovations';
import AdminCompanies from '../screens/Admin/companies';
import AdminInstitutions from '../screens/Admin/institutions';
import Terms from '../screens/Site/terms';
import TermsAccept from '../screens/Site/termsAccept';

const Rotas: React.FC = () => {
  return (
    <Routes>
      {/** Public Routes **/}
      <Route index element={<Home />} />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cidades" element={<Cities />} />
      <Route path="/contato" element={<Contact />} />
      <Route path="/startups" element={<Startups />} />
      <Route path="/mentores" element={<Mentores />} />
      <Route path="/Empresas" element={<Companies />} />
      <Route path="/criar-conta" element={<Account />} />
      <Route path="/agenda-eventos" element={<Events />} />
      <Route path="/redefinir-senha" element={<ResetPass />} />
      <Route path="/instituicoes" element={<Instituitons />} />
      <Route path="/detalhes-evento/:id" element={<DetailsEvent />} />
      <Route path="/detalhes-cidade/:id" element={<DetailsCity />} />
      <Route path="/detalhes-parque/:id" element={<DetailsPark />} />
      <Route path="/recuperar-senha" element={<RecoverPass />} />
      <Route path="/ambiente-inovacao" element={<Innovations />} />
      <Route path="/detalhes-mentor/:id" element={<DetailsMentor />} />
      <Route path="/detalhes-startup/:id" element={<DetailsStartup />} />
      <Route path="/detalhes-empresa/:id" element={<DetailsCompany />} />
      <Route path="/detalhes-inovacao/:id" element={<DetailsInnovations />} />
      <Route path="/parques-tecnologicos" element={<TechnologyParks />} />
      <Route
        path="/detalhes-instituicoes/:id"
        element={<DetailsInstituitions />}
      />
      <Route path="/termos-e-condicoes" element={<Terms />} />
      <Route path="/aceite-termos" element={<TermsAccept />} />
      <Route path="/reset-senha" element={<ResetPassword />} />

      {/** Private Routes Admin **/}
      <Route path="/admin/*" element={<PrivateAdmin />}>
        <Route index element={<HomeAdmin />} />
        <Route path="perfil" element={<Settings />} />
        <Route path="eventos" element={<AdminEvents />} />
        <Route path="parques" element={<Adminparks />} />
        <Route path="cidades" element={<AdminCities />} />
        <Route path="usuarios" element={<AdminUsers />} />
        <Route path="startups" element={<AdminStartups />} />
        <Route path="ambientes" element={<AdminInnovations />} />
        <Route path="empresas" element={<AdminCompanies />} />
        <Route path="mentores" element={<AdminMentors />} />
        <Route path="instituicoes" element={<AdminInstitutions />} />
      </Route>

      {/** Private Routes User **/}
      <Route path="/usuario/*" element={<PrivateUser />}>
        <Route index element={<HomeUser />} />
      </Route>
    </Routes>
  );
};

export default Rotas;
