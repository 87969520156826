/* eslint-disable no-console */
import { Dispatch, SetStateAction } from 'react';
import Api from '../api';
import { getAccessToken } from '../services/session.service';

interface IOwner {
  id: string;
  name: string;
  role: string;
}

interface IPublicPolicy {
  name: string;
  description: string;
}

interface IContact {
  id?: string;
  site: string;
  email: string;
  phone: string;
}

export interface ICity {
  id?: string;
  name: string;
  region: string;
  mayor: string;
  description: string;
  status?: string;
  owner?: IOwner;
  contact: IContact;
  publicPolicy: IPublicPolicy[];
}

interface IList {
  setList: Dispatch<SetStateAction<ICity[]>>;
}

interface IDelete {
  id: string;
  setMessage: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setCode: Dispatch<SetStateAction<number>>;
}

export async function List({ setList }: IList) {
  await Api.get('/cities', {
    params: { size: 1000 },
  })
    .then(res => {
      setList(res.data.data);
    })
    .catch(error => {
      if (error.response.status === 419) {
        console.log(error);
      }
      if (error.response.status === 401) {
        console.log(error);
      }
    });
}

export async function Get(id: string): Promise<ICity | undefined> {
  let response: ICity | undefined;

  await Api.get(`/cities/${id}`)
    .then(res => {
      console.log(res.data);
      return (response = res.data);
    })
    .catch(error => {
      if (error.response.status === 419) {
        console.log(error);
      }
      if (error.response.status === 401) {
        console.log(error);
      }
    });
  return response;
}

export async function Create(data: ICity) {
  const token = getAccessToken();
  let response = '';

  await Api.post(
    '/cities',
    {
      name: data.name,
      mayor: data.mayor,
      region: data.region,
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
      description: data.description,
      publicPolicy: data.publicPolicy,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      // response = true;
    })
    .catch(error => {
      response = error.response.data.message;
      // eslint-disable-next-line no-console
      console.log(error.response.data.message);
    });

  return response;
}

export async function Update(data: ICity) {
  const token = getAccessToken();
  let response = '';

  await Api.put(
    `/cities/${data.id}`,
    {
      name: data.name,
      mayor: data.mayor,
      region: data.region,
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
      description: data.description,
      publicPolicy: data.publicPolicy,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      // console.log(res.data);
      // response = true;
    })
    .catch(error => {
      response = error.response.data.message;
      // eslint-disable-next-line no-console
      console.log(error);
    });
  return response;
}

export async function Delete({ id, setCode, setMessage, setSuccess }: IDelete) {
  const token = getAccessToken();

  await Api.delete(`/cities/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(res => {
      setCode(res.data.code);
      setMessage(res.data.message);
      setSuccess(res.data.success);
    })
    .catch(error => {
      console.log(error);
    });
}
