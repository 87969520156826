import React from 'react';
import * as C from './styles';
import { useState, useEffect } from 'react';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import { payload } from './Form.Types';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ICity } from '../../../services/cities.service';

export interface IPartiner {
  name: string;
  email: string;
  office: string;
  cpf: string;
  genre: string;
  profile: string;
}

type Policy = {
  name: string;
  description: string;
};

const Form7 = ({
  data,
  onDataChange,
  visualization,
}: {
  data: ICity;
  onDataChange: (
    field: keyof payload,
    value:
      | string
      | { site: string; email: string; phone: string }
      | { name: string; description: string }
      | { name: string; description: string }[],
  ) => void;
  visualization: boolean;
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [newPolicy, setNewPolicy] = useState<Policy>({
    name: '',
    description: '',
  });
  const [policies, setPolicies] = useState<Policy[]>([]);

  const handleAddPartiner = () => {
    setNewPolicy({
      name: '',
      description: '',
    });
    setShowForm(!showForm);
  };

  // useEffect(() => {
  //   onDataChange('publicPolicy', policies);
  // }, [policies]);

  const addNewPublicPolicy = () => {
    setPolicies(data => [...data, newPolicy]);
    onDataChange('publicPolicy', [...policies, newPolicy]);
    handleAddPartiner();
  };

  const removePolicy = (index: number) => {
    const newPolicies = [...policies];
    newPolicies.splice(index, 1);
    setPolicies(newPolicies);
    onDataChange('publicPolicy', newPolicies);
  };

  const editPolicy = (index: number) => {
    setNewPolicy({
      name: policies[index].name,
      description: policies[index].description,
    });
    setShowEditForm(true);
    setIndexEdit(index);
    onDataChange('publicPolicy', policies);
  };

  const clearStates = () => {
    setNewPolicy({
      name: '',
      description: '',
    });
  };

  useEffect(() => {
    setPolicies(data.publicPolicy);
  }, [data.publicPolicy]);

  const saveEditPolicy = (index: number) => {
    const newPolicies = [...policies];
    newPolicies[index].name = newPolicy.name;
    newPolicies[index].description = newPolicy.description;
    setPolicies(newPolicies);
    setShowEditForm(false);
    clearStates();
  };

  return (
    <C.container>
      <C.titleMaster>Políticas Públicas</C.titleMaster>
      {data.publicPolicy.map((e, index) => (
        <>
          <C.textFieldWraper100 key={e.name}>
            <C.textFieldList>
              <C.yearText>{e.name}</C.yearText>
              <C.containerButtonsActions>
                <IconButton
                  className="btn_edit"
                  onClick={() => editPolicy(index)}
                  disabled={visualization}
                >
                  <EditIcon />
                  <C.textFieldEdit>Editar</C.textFieldEdit>
                </IconButton>
              </C.containerButtonsActions>
              <C.containerButtonsActions>
                <IconButton
                  className="btn_delete"
                  onClick={() => removePolicy(index)}
                  disabled={visualization}
                >
                  <DeleteIcon />
                  <C.textFieldDelete>Excluir</C.textFieldDelete>
                </IconButton>
              </C.containerButtonsActions>
            </C.textFieldList>
          </C.textFieldWraper100>
          {showEditForm && indexEdit === index && (
            <>
              <C.textFieldWraper100>
                <C.boxTextField>
                  <C.titleTextField>Nome</C.titleTextField>
                  <C.textField
                    placeholder="Nome da política"
                    value={newPolicy?.name}
                    onChange={e =>
                      setNewPolicy({ ...newPolicy, name: e.target.value })
                    }
                    disabled={visualization}
                  />
                </C.boxTextField>
              </C.textFieldWraper100>
              <C.space />
              <C.textFieldWraper100>
                <C.titleTextField>Descrição resumida</C.titleTextField>
                <C.textAreaField
                  placeholder="Digite uma breve descrição..."
                  value={newPolicy?.description}
                  onChange={e =>
                    setNewPolicy({ ...newPolicy, description: e.target.value })
                  }
                  disabled={visualization}
                />
              </C.textFieldWraper100>
              <C.buttonsSupport>
                <Button
                  variant="contained"
                  className="btn_save"
                  disabled={
                    !newPolicy?.name || !newPolicy?.description || visualization
                  }
                  onClick={() => saveEditPolicy(index)}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  className="btn_cancel"
                  onClick={() => {
                    clearStates();
                    setShowEditForm(false);
                  }}
                  disabled={visualization}
                >
                  Cancelar
                </Button>
              </C.buttonsSupport>
            </>
          )}
        </>
      ))}

      {!showForm && (
        <C.textFieldWraper100>
          <C.boxTextField>
            <Button
              variant="contained"
              className="btn_add_partner"
              onClick={handleAddPartiner}
            >
              <AddIcon />
              Adicionar Política
            </Button>
          </C.boxTextField>
        </C.textFieldWraper100>
      )}
      {showForm && (
        <>
          <C.textFieldWraper100>
            <C.boxTextField>
              <C.titleTextField>Nome</C.titleTextField>
              <C.textField
                placeholder="Nome da política"
                value={newPolicy?.name}
                onChange={e =>
                  setNewPolicy({ ...newPolicy, name: e.target.value })
                }
              />
            </C.boxTextField>
          </C.textFieldWraper100>
          <C.space />
          <C.textFieldWraper100>
            <C.titleTextField>Descrição resumida</C.titleTextField>
            <C.textAreaField
              placeholder="Digite uma breve descrição..."
              value={newPolicy?.description}
              onChange={e =>
                setNewPolicy({ ...newPolicy, description: e.target.value })
              }
            />
          </C.textFieldWraper100>
          <C.buttonsSupport>
            <Button
              variant="contained"
              className="btn_save"
              disabled={!newPolicy?.name || !newPolicy?.description}
              onClick={addNewPublicPolicy}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              className="btn_cancel"
              onClick={handleAddPartiner}
            >
              Cancelar
            </Button>
          </C.buttonsSupport>
        </>
      )}
    </C.container>
  );
};
export default Form7;
