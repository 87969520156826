import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getAccessToken } from '../../services/session.service';

export const PrivateAdmin = () => {
  const local = useLocation().pathname;
  const sessionToken = getAccessToken();

  if (local == '/admin') {
    if (!sessionToken) {
      return <Navigate to={'/login'} />;
    }
  }

  return <Outlet />;
};

export const PrivateUser = () => {
  const local = useLocation().pathname;
  const sessionToken = sessionStorage.getItem(
    '' + process.env.REACT_APP_ENV_TOKEN,
  );

  if (local == '/usuario') {
    if (!sessionToken) {
      return <Navigate to={'/login'} />;
    }
  }

  return <Outlet />;
};
