export const ItensSlide = [
  {
    id: 0,
    path: '/admin/startups',
    name: 'Startups',
  },
  {
    id: 1,
    path: '/admin/instituicoes',
    name: 'Instituições',
  },
  {
    id: 2,
    path: '/admin/parques',
    name: 'Parques Tecnológicos',
  },
  {
    id: 3,
    path: '/admin/empresas',
    name: 'Empresas',
  },
  {
    id: 4,

    path: '/admin/eventos',
    name: 'Eventos',
  },
  {
    id: 5,
    path: '/admin/mentores',
    name: 'Banco de Mentores',
  },
  {
    id: 6,

    path: '/admin/cidades',
    name: 'Cidades',
  },
  {
    id: 7,
    path: '/admin/ambientes',
    name: 'Ambientes de Inovação',
  },
];
