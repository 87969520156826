import { Alert } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { IFormProps } from '../../../interfaces/form.interface';
import { IInnovationEnvironment } from '../../../interfaces/innovationEnvironment.interface';
import * as C from './styles';

const Form2 = (props: FormikProps<IInnovationEnvironment> & IFormProps) => {
  const { showAlertErr, visualization } = props;

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Detalhes do Ambiente</C.titleTextField>
        <C.textAreaField
          maxLength={2000}
          placeholder="Detalhes do ambiente..."
          className="textaera_master"
          name="description"
          value={props.values.description}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.description.length}/2000`}</p>
        {showAlertErr && props.values.description === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form2;
