import React, { useEffect, useRef, useState } from 'react';
import * as C from './styles';
import Slider from 'react-slick';
import { Button } from '@mui/material';
import Map from '../../../components/Map';
import { Doughnut } from 'react-chartjs-2';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import NavbarHome from '../../../components/NavbarHome';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import Instituicoes from '../../../assets/instituicoes.png';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import NavbarMobileHome from '../../../components/NavbarMobileHome';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import { IChartData, ILocation } from '../../../interfaces/home.interface';
import * as DashboardMetrics from '../../../services/metrics.service';
import { calculatePercentage } from '../../../helpers/percent.helper';
import IconNational from '../../../assets/iconNational.svg';
import IconRegional from '../../../assets/iconRegional.svg';
import { isLogged } from '../../../services/session.service';

ChartJS.register(ArcElement);

const Home = () => {
  const navigation = useNavigate();

  const myRef = useRef<HTMLDivElement | null>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [metrics, setMetrics] = useState<IChartData>();

  const settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots custom-dots',
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const dots = [
    <C.chartDot1 key={0} />,
    <C.chartDot2 key={1} />,
    <C.chartDot3 key={2} />,
    <C.chartDot4 key={2} />,
  ];

  const institutions = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.institution.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const companies = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.company.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const startups = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.startup.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const mentors = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.mentor.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const parks = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.park.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const inovations = {
    datasets: [
      {
        label: 'Total',
        data: metrics?.environment.segments.map(e => e.total),
        borderWidth: 1,
        backgroundColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
        borderColor: ['#FFCA43', '#12C096', '#FF8787', '#D4FBF1'],
      },
    ],
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (myRef) {
      myRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const getMetrics = async () => {
    const response = await DashboardMetrics.GetHomeMetrics();
    setMetrics(response);
  };

  useEffect(() => {
    getMetrics();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);

  return (
    <C.container>
      <C.headerHome>
        <NavbarHome secondary={true} logged={isLogged()} />
        <NavbarMobileHome />
        <C.infosHeader data-aos="zoom-in">
          <C.titleHeader>Startup Map:</C.titleHeader>
          <C.titleHeader>Conexão, Convergência e Informação</C.titleHeader>
          <C.subTitleHeader>
            Juntos por um estado Inovador, que empreende e cria oportunidades
            para o desenvolvimento dos negócios de base tecnológica
          </C.subTitleHeader>
          <Button
            variant="contained"
            className="btn_header"
            onClick={handleScroll}
          >
            Conheça
          </Button>
        </C.infosHeader>
      </C.headerHome>

      <C.mapsSection ref={myRef}>
        <C.containerInfosMap>
          <C.titleMap>Ecossistemas de Inovação</C.titleMap>
          <C.subTitleMap>
            Os ecossistemas promovem um ambiente multidisciplinar, contando com
            programas e ações para incentivar a inovação em diversas áreas..
          </C.subTitleMap>

          <Button
            variant="contained"
            className="btn_home"
            onClick={() => navigation('startups')}
          >
            Conheça as Startups cadastradas
          </Button>
        </C.containerInfosMap>
        <C.containerMap>
          <Map />
        </C.containerMap>
      </C.mapsSection>
      <C.containerCardsInfo>
        <C.cardInfo>
          <PublicRoundedIcon />
          <C.cardTitle>300</C.cardTitle>
          <C.cardSubTitle>Municípios</C.cardSubTitle>
          <C.cardText>
            Eles contam com startups de diversas áreas, além de políticas
            públicas para incentivar a inovação.
          </C.cardText>
        </C.cardInfo>
        <C.cardInfo>
          <SchoolRoundedIcon />
          <C.cardTitle>Apoio</C.cardTitle>
          <C.cardSubTitle>Aos Municípios</C.cardSubTitle>
          <C.cardText>
            Os municípios podem contar com políticas públicas específicas que
            ajudam a alavancar as startups.
          </C.cardText>
        </C.cardInfo>
        <C.cardInfo>
          <HandshakeOutlinedIcon />
          <C.cardTitle>Inovação</C.cardTitle>
          <C.cardSubTitle>Colaborativa</C.cardSubTitle>
          <C.cardText>
            Ao fazer parte do ecossistema, além de apoio, as startups podem
            coompartilhar experiências e conhecimento.
          </C.cardText>
        </C.cardInfo>
      </C.containerCardsInfo>
      <C.technologySection>
        <C.image>
          <img src={Instituicoes} alt="Park" />
        </C.image>
        <C.parkTitle>Atores de Inovação</C.parkTitle>
        <C.parkSubTitle>
          O ecossistema é composto por diversos atores relevantes, através de
          uma rápida visão é possível conhecer um pouco sobre eles.
        </C.parkSubTitle>
        <C.containerCharts>
          <C.chartCard>
            <C.chart>
              <Doughnut data={institutions} />
              <CorporateFareRoundedIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.institution.total}</C.chartTitle>
              <C.chartTitle>Instituições</C.chartTitle>
              {metrics?.institution.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>

                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.institution.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('instituicoes')}
              >
                Conheça as Instituições
              </Button>
            </C.containerInfosChart>
          </C.chartCard>

          <C.chartCard>
            <C.chart>
              <Doughnut data={companies} />
              <CorporateFareRoundedIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.company.total}</C.chartTitle>
              <C.chartTitle>Empresas</C.chartTitle>
              {metrics?.company.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>

                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.company.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('empresas')}
              >
                Conheça as Empresas
              </Button>
            </C.containerInfosChart>
          </C.chartCard>
        </C.containerCharts>

        <C.containerCharts>
          <C.chartCard>
            <C.chart>
              <Doughnut data={startups} />
              <RocketLaunchRoundedIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.startup.total}</C.chartTitle>
              <C.chartTitle>Startups</C.chartTitle>
              {metrics?.startup.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>

                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.startup.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('startups')}
              >
                Conheça as Startups
              </Button>
            </C.containerInfosChart>
          </C.chartCard>

          <C.chartCard>
            <C.chart>
              <Doughnut data={inovations} />
              <LanguageRoundedIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.environment.total}</C.chartTitle>
              <C.chartTitle>Ambientes de Inovação</C.chartTitle>
              {metrics?.environment.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>

                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.environment.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('ambiente-inovacao')}
              >
                Conheça os Ambientes
              </Button>
            </C.containerInfosChart>
          </C.chartCard>
        </C.containerCharts>

        <C.containerCharts>
          <C.chartCard>
            <C.chart>
              <Doughnut data={mentors} />
              <InsertEmoticonIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.mentor.total}</C.chartTitle>
              <C.chartTitle>Mentores</C.chartTitle>
              {metrics?.mentor.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>
                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.mentor.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('mentores')}
              >
                Conheça os Mentores
              </Button>
            </C.containerInfosChart>
          </C.chartCard>

          <C.chartCard>
            <C.chart>
              <Doughnut data={parks} />
              <SchoolRoundedIcon />
            </C.chart>
            <C.containerInfosChart>
              <C.chartTitle>{metrics?.park.total}</C.chartTitle>
              <C.chartTitle>Parques Tecnológicos</C.chartTitle>
              {metrics?.park.segments.map((e, index) => (
                <C.chartInfosWraper key={index}>
                  <C.chartInfos>{dots[index]}</C.chartInfos>

                  <C.chartInfos>
                    <C.chartText>{e.name}</C.chartText>
                    <C.chartNumber>{e.total}</C.chartNumber>
                    <C.chartNumber>
                      {calculatePercentage(e.total, metrics?.park.total)}
                    </C.chartNumber>
                  </C.chartInfos>
                </C.chartInfosWraper>
              ))}
              <Button
                variant="contained"
                className="btn_chart"
                onClick={() => navigation('parques-tecnologicos')}
              >
                Conheça os Parques
              </Button>
            </C.containerInfosChart>
          </C.chartCard>
        </C.containerCharts>
      </C.technologySection>
      <C.opportunitiesSection>
        <div className="opp_wraper">
          <C.opportunitiesInfos>
            <C.opportunitiesTitle>
              Oportunidades para Startups
            </C.opportunitiesTitle>
            <C.opportunitiesSubtitle>
              Os ecossistemas promovem um ambiente multidisciplinar, contando
              com programas e ações para incentivar a inovação em diversas
              áreas.
            </C.opportunitiesSubtitle>
            <Button
              variant="contained"
              className="btn_home"
              onClick={() => navigation('agenda-eventos')}
            >
              Conheça
            </Button>
          </C.opportunitiesInfos>
          <C.opportunitiesCalcs>
            <C.opportunitiesItem>
              <HandshakeOutlinedIcon />
              <C.opportunitiesText>
                <span>139</span> Municípios
              </C.opportunitiesText>
            </C.opportunitiesItem>
            <C.opportunitiesItem>
              <HandshakeOutlinedIcon />
              <C.opportunitiesText>
                <span>50.000</span> Empresas
              </C.opportunitiesText>
            </C.opportunitiesItem>
            <C.opportunitiesItem>
              <HandshakeOutlinedIcon />
              <C.opportunitiesText>
                <span>1.6 Mi.</span> de Pessoas
              </C.opportunitiesText>
            </C.opportunitiesItem>
            <C.opportunitiesItem>
              <HandshakeOutlinedIcon />
              <C.opportunitiesText>
                <span>15.512</span> PIB per capita
              </C.opportunitiesText>
            </C.opportunitiesItem>
          </C.opportunitiesCalcs>
        </div>
      </C.opportunitiesSection>
      <C.tendenciesSection>
        <C.tendenciesTitle>Tendências</C.tendenciesTitle>
        <C.containerSlide>
          <Slider {...settings}>
            <C.tendenciesItem>
              <C.tendenciesContainerCard>
                <C.tendenciesCard>
                  <PublicRoundedIcon />
                  <C.tendenciesCardTitle>Globais</C.tendenciesCardTitle>
                </C.tendenciesCard>
              </C.tendenciesContainerCard>
              <C.tendenciesContainerList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Inteligencia Artificial
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Metaverso
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  WEB 3.0
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Computação Quântica
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Sistemas Autônomos
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Aplicativos Compostos
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Malha de Segurança
                </C.tendenciesTextList>
              </C.tendenciesContainerList>
            </C.tendenciesItem>
            <C.tendenciesItem>
              <C.tendenciesContainerCard>
                <C.tendenciesCard>
                  <C.imgTendecia
                    src={IconNational}
                    alt="logo bandeira nacional"
                  />
                  <C.tendenciesCardTitle>Nacionais</C.tendenciesCardTitle>
                </C.tendenciesCard>
              </C.tendenciesContainerCard>
              <C.tendenciesContainerList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Tecnologias Sustentáveis
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Big Data
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Cloud Computing
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Realidade Aumentada
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Tecnologias Assistidas
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Empresas Distribuídas
                </C.tendenciesTextList>
              </C.tendenciesContainerList>
            </C.tendenciesItem>
            <C.tendenciesItem>
              <C.tendenciesContainerCard>
                <C.tendenciesCard>
                  <C.imgTendecia
                    src={IconRegional}
                    alt="logo bandeira nacional"
                  />
                  <C.tendenciesCardTitle>Regionais</C.tendenciesCardTitle>
                </C.tendenciesCard>
              </C.tendenciesContainerCard>
              <C.tendenciesContainerList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Grafeno e Nano Materiais
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Biotechnology
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  AgriTechs
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Health
                </C.tendenciesTextList>
                <C.tendenciesTextList>
                  <ArrowForwardOutlinedIcon />
                  Hiper Automação
                </C.tendenciesTextList>
              </C.tendenciesContainerList>
            </C.tendenciesItem>
          </Slider>
        </C.containerSlide>
      </C.tendenciesSection>
      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
      <Footer />
    </C.container>
  );
};

export default Home;
