import styled from 'styled-components';
import { shade } from 'polished';

export const container = styled.div`
  width: 100%;
`;

export const title = styled.h1`
  font-weight: 700;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;

export const headerDetails = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};

  @media screen and (max-width: 1090px) {
    height: auto;
  }
`;

export const containerInfos = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8%;
  max-width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 1390px) {
    padding: 0 8%;
    max-width: 100%;
  }
  @media screen and (max-width: 1090px) {
    display: block;
    padding-bottom: 50px;
  }
`;

export const containerTitles = styled.div`
  width: 100%;
  margin-left: 30px;
  margin-top: 10px;
  text-align: center;

  @media screen and (max-width: 1090px) {
    margin-left: 0px;
    margin-top: 30px;
  }
`;
export const headerTitle = styled.h1`
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
  @media screen and (max-width: 1090px) {
    width: 100% !important;
    margin: 0 auto;
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;

export const containerSubTitles = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const headerSubTitle = styled.h4`
  max-width: 80%;
  font-weight: 400;
  margin-right: 40px;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: #fff;
  @media screen and (max-width: 1390px) {
    padding: 0 1%;
  }
  @media screen and (max-width: 1090px) {
    width: 100%;
    margin: 10px auto;
  }
`;

export const bodyText = styled.h4`
  max-width: 80%;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK};
  @media screen and (max-width: 1390px) {
    padding: 0 1%;
  }
  @media screen and (max-width: 1090px) {
    width: 100%;
    margin: 10px auto;
  }
`;

export const containerBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10%;
  max-width: 80%;
  margin: 0 auto;

  .btn_inscrevase {
    font-weight: 700;
    margin-top: 30px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FF8787')} !important;
    }

    @media only screen and (max-width: 1090px) {
      // display: none;
    }
  }

  @media screen and (max-width: 1390px) {
    padding: 0 1%;
  }
  @media screen and (max-width: 1090px) {
    width: 100%;
    max-width: 100%;
    display: block;
    padding: 0;
  }
`;
export const details = styled.div`
  width: 85%;
  height: 100%;
  padding-top: 40px;
  margin-bottom: 100px;

  @media screen and (max-width: 1090px) {
    width: 100%;
    text-align: center;
  }
`;

export const detailTitle = styled.h3`
  max-width: 60%;
  font-weight: 700;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;

  @media screen and (max-width: 1090px) {
    max-width: 100%;
    text-align: center;
  }
`;
export const detailText = styled.p`
  max-width: 80%;
  font-weight: 300;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
`;

export const containerInfosCity = styled.div`
  width: 25%;
  height: 100%;
  padding-top: 40px;
  text-align: center;
  margin-bottom: 100px;
  @media screen and (max-width: 1090px) {
    width: 100%;
  }
`;

export const infoItem = styled.div`
  margin-top: 30px;
`;

export const infoIcon = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0 auto;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(142, 141, 208, 0.16);

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 20px -12px;
  }

  svg {
    font-size: 80px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;
export const infoTitle = styled.h3`
  font-weight: 700;
  margin: 20px 0 5px 0;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XS}em;
`;
export const infoSubTitle = styled.p`
  font-weight: 300;
  color: ${({ theme }) => theme.COLORS.GREY_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const containerButtonReturn = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2%;
  max-width: 70%;
  margin: 50px auto;

  .button_return {
    padding: 10px 35px;
    color: ${({ theme }) => theme.COLORS.BLACK};

    &:hover {
      background-color: rgba(96, 100, 112, 0.1) !important;
    }
    svg {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1590px) {
    padding: 0 1%;
    max-width: 100%;
  }
`;

export const containerButtonEdit = styled.div`
  top: -5px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    top: 0;
    margin-top: 20px;
    justify-content: center;
  }

  .MuiIconButton-root {
    min-width: 0;
    padding: 5px;
    margin: 0 10px;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;

    &:hover {
      transform: translateY(-7px);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 20px -7px;
      color: ${({ theme }) => theme.COLORS.GREEN_V4} !important;
    }
  }

  svg {
    font-size: 30px;
    color: #fff;
  }
`;

export const space = styled.div`
  margin: 70px 0;
`;
