import styled from 'styled-components';

export const card = styled.div`
  width: 100%;
  height: auto;
  margin: 20px 0;
  padding: 20px;
  max-height: 280px;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
  svg {
    font-size: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }

  .MuiMenuItem-root {
    padding: 0px 2px !important;
    font-weight: 500 !important;
    justify-content: center;
    font-family: 'Montserrat';
    color: ${({ theme }) => theme.COLORS.BLACK}!important;
  }
`;

export const title = styled.h3`
  font-size: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.COLORS.BLACK};
`;
export const subTitle = styled.h2`
  font-size: 25px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
`;

export const containerInfos = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 500;
    top: -12px;
    position: relative;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }

  label {
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  p {
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.BLACK};
  }
`;
export const containerButtonsActions = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;

  margin-top: 10px;

  .btn_edit {
    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
`;
