import React, { useState } from 'react';
import * as C from './styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import {
  businessModelOPT,
  businessSegmentsOPT,
  businessStageOPT,
  businessTypeOPT,
  cities,
} from '../../../helpers/options';
import { IFilterStartups } from '../../../interfaces/filter.interface';

const FilterModal = ({
  setState,
  state,
  setFilterCity,
  setFilterType,
  setFilterName,
  setFilterModel,
  setFilterSegment,
  setFilterStage,
}: IFilterStartups) => {
  const handleClose = () => {
    setState(false);
  };

  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [type, setType] = useState('');
  const [model, setModel] = useState('');
  const [segment, setSegment] = useState('');
  const [stage, setStage] = useState('');

  const clearStates = () => {
    setName('');
    setType('');
    setCity('');
    setModel('');
    setSegment('');
    setStage('');
  };

  const clearFilters = () => {
    setFilterCity('');
    setFilterType('');
    setFilterName('');
    setFilterModel('');
    setFilterSegment('');
    setFilterStage('');
    clearStates();
    setState(false);
  };

  const applyFilters = () => {
    setFilterCity(city);
    setFilterType(type);
    setFilterName(name);
    setFilterSegment(segment);
    setFilterModel(model);
    setFilterStage(stage);

    setState(false);
  };

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state}
      >
        <C.containerTitle>
          <C.title>Filtrar</C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper100>
              <C.titleTextField>Nome</C.titleTextField>
              <C.textField
                placeholder="Digite o nome da Startup"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </C.textFieldWraper100>

            <C.textFieldWraper50>
              {/* <C.boxTextField>
                <C.titleTextField>Região</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas as Regiões</em>
                  </MenuItem>
                  <MenuItem value={10}>Teste</MenuItem>
                </Select>
              </C.boxTextField> */}
              <C.boxTextField>
                <C.titleTextField>Cidade</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas as Cidades</em>
                  </MenuItem>
                  {cities.map(city => {
                    return (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Segmento</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={segment}
                  onChange={e => setSegment(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Segmentos</em>
                  </MenuItem>
                  {businessSegmentsOPT.map(segment => {
                    return (
                      <MenuItem key={segment} value={segment}>
                        {segment}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>Estágio</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={stage}
                  onChange={e => setStage(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Estágios</em>
                  </MenuItem>
                  {businessStageOPT.map(stage => {
                    return (
                      <MenuItem key={stage} value={stage}>
                        {stage}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Modelo de negócio</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={model}
                  onChange={e => setModel(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Modelos de Negócio</em>
                  </MenuItem>
                  {businessModelOPT.map(model => {
                    return (
                      <MenuItem key={model} value={model}>
                        {model}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>Tipo de negócio</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={type}
                  onChange={e => setType(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Tipos de Negócio</em>
                  </MenuItem>
                  {businessTypeOPT.map(type => {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>
        </DialogContent>

        <C.containerButtons>
          <Button
            variant="outlined"
            className="btn_clear"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            className="btn_apply"
            onClick={applyFilters}
          >
            Aplicar Filtros
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default FilterModal;
