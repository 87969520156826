import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { AddModal as StartupDetails } from '../../../../components/Startups/Add';
import { AddModal as CompanyDetails } from '../../../../components/Company/Add';
import { AddModal as ParkDetails } from '../../../../components/TechnologyParks/Add';
import { AddModal as InstitutionDetails } from '../../../../components/Institutions/Add';
import { AddModal as InnovationDetails } from '../../../../components/Innovations/Add';
import { AddModal as EventDetails } from '../../../../components/Events/Add';
import { AddModal as MentorDetails } from '../../../../components/Mentors/Add';
import { AddModal as CityDetails } from '../../../../components/Cities/Add';

const Details = ({
  id,
  category,
  showModal,
  setShowModal,
  visualization = false,
  refresh,
}: {
  id: string;
  category: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  visualization?: boolean;
  refresh?: () => Promise<void>;
}) => {
  switch (category) {
    case 'startup': {
      return (
        <StartupDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'city': {
      return (
        <CityDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'company': {
      return (
        <CompanyDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'park': {
      return (
        <ParkDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'institution': {
      return (
        <InstitutionDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'environment': {
      return (
        <InnovationDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'event': {
      return (
        <EventDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    case 'mentor': {
      return (
        <MentorDetails
          visualization={visualization}
          show={showModal}
          setShow={setShowModal}
          id={id}
          refresh={refresh}
        />
      );
    }

    default:
      return null;
  }
};

export default Details;
