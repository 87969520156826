import Api from '../api';
import { IChartData, ILocation } from '../interfaces/home.interface';
import { IDashboardMetrics } from '../interfaces/metrics.interface';
import { getAccessToken } from '../services/session.service';

export async function DashboardList(): Promise<IDashboardMetrics> {
  let response: any;
  const token = getAccessToken();

  await Api.get('/metrics/dashboard', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => {
      response = res.data;
    })
    .catch(error => {
      console.log(error);
    });

  return response;
}

export async function GetHomeMetrics(): Promise<IChartData | undefined> {
  let response: IChartData | undefined;

  await Api.get(`/metrics/home`)
    .then(res => {
      return (response = res.data);
    })
    .catch(err => console.log(err));

  return response;
}

export async function GetLocations(): Promise<ILocation[] | undefined> {
  let response: ILocation[] | undefined;

  await Api.get(`/metrics/locations`)
    .then(res => {
      return (response = res.data);
    })
    .catch(err => console.log(err));

  return response;
}
