import Api from '../api';
import { IGetMentor, IMentor } from '../interfaces/mentor.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(data: IMentor): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.post(
    '/mentors',
    {
      city: data.city,
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
      description: data.description,
      genre: data.genre,
      mentorship: data.mentorship,
      businessSegment: data.businessSegment,
      name: data.name,
      skills: data.skills,
      socialMedia: {
        instagram: data.socialMedia.instagram,
        facebook: data.socialMedia.facebook,
        linkedin: data.socialMedia.linkedin,
        youtube: data.socialMedia.youtube,
      },
      title: data.title,
      logoId: data.logoId,
      bannerId: data.bannerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Update(data: IMentor): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.put(
    `/mentors/${data.id}`,
    {
      city: data.city,
      contact: {
        site: data.contact.site,
        email: data.contact.email.trim(),
        phone: data.contact.phone,
      },
      description: data.description,
      genre: data.genre,
      mentorship: data.mentorship,
      businessSegment: data.businessSegment,
      name: data.name,
      skills: data.skills,
      socialMedia: {
        instagram: data.socialMedia.instagram,
        facebook: data.socialMedia.facebook,
        linkedin: data.socialMedia.linkedin,
        youtube: data.socialMedia.youtube,
      },
      title: data.title,
      logoId: data.logoId,
      bannerId: data.bannerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Get(): Promise<IGetMentor | undefined> {
  let response: IGetMentor | undefined;

  await Api.get(`/mentors`, {
    params: { size: 1000 },
  })
    .then(res => {
      return (response = res.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}

export async function GetById(id: string): Promise<IMentor | undefined> {
  let response: IMentor | undefined;

  await Api.get(`/mentors/${id}`)
    .then(res => {
      return (response = res.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}
