import { FormHelperText } from '@mui/material';
import React from 'react';
import { MaskPhone } from '../../../helpers/mask';
import { payload, errors } from './Form.Types';
import * as C from './styles';
import { ICity } from '../../../services/cities.service';

const Form3 = ({
  data,
  onDataChange,
  listErrors,
  visualization,
}: {
  data: ICity;
  onDataChange: (
    field: keyof payload,
    value:
      | string
      | { site: string; email: string; phone: string }
      | { name: string; description: string }
      | { name: string; description: string }[],
  ) => void;
  listErrors: errors;
  visualization: boolean;
}) => {
  return (
    <C.container>
      <C.titleMaster>Contato</C.titleMaster>
      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>Site</C.titleTextField>
          <C.textField
            placeholder="Site"
            value={data.contact.site}
            onChange={e =>
              onDataChange('contact', { ...data.contact, site: e.target.value })
            }
            disabled={visualization}
          />
          {listErrors.siteError && (
            <FormHelperText error>Preencha o campo</FormHelperText>
          )}
          {listErrors.siteValid && (
            <FormHelperText error>URL inválida</FormHelperText>
          )}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>E-mail</C.titleTextField>
          <C.textField
            placeholder="E-mail"
            value={data.contact.email}
            onChange={e =>
              onDataChange('contact', {
                ...data.contact,
                email: e.target.value,
              })
            }
            disabled={visualization}
          />
          {listErrors.emailError && (
            <FormHelperText error>Preencha o campo</FormHelperText>
          )}
          {listErrors.emailValid && data.contact.email && (
            <FormHelperText error>Email inválido</FormHelperText>
          )}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Telefone</C.titleTextField>
          <C.textField
            placeholder="Telefone"
            value={data.contact.phone}
            maxLength={15}
            onChange={e =>
              onDataChange('contact', {
                ...data.contact,
                phone: MaskPhone(e.target.value),
              })
            }
            disabled={visualization}
          />
          {listErrors.phoneError && (
            <FormHelperText error>Preencha o campo</FormHelperText>
          )}
          {listErrors.phoneValid && data.contact.phone && (
            <FormHelperText error>Telefone inválido</FormHelperText>
          )}
        </C.boxTextField>
      </C.textFieldWraper33>
    </C.container>
  );
};
export default Form3;
