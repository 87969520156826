import React from 'react';
import * as C from './styles';
import { Dispatch, SetStateAction } from 'react';
import { Alert } from '@mui/material';

interface IForm {
  name: string;
  resolution: string;
  description: string;
  setName: Dispatch<SetStateAction<string>>;
  setResolution: Dispatch<SetStateAction<string>>;
  setDescription: Dispatch<SetStateAction<string>>;
  showAlertErr: boolean;
  visualization: boolean;
}

const Form1 = ({
  name,
  resolution,
  description,
  setDescription,
  setName,
  setResolution,
  showAlertErr,
  visualization = false,
}: IForm) => {
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Nome*</C.titleTextField>
        <C.textField
          placeholder="Digite o nome da Startup"
          value={name}
          maxLength={60}
          onChange={e => setName(e.target.value)}
          disabled={visualization}
        />
        <p>{`${name.length}/60`}</p>
        {showAlertErr && name == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Descrição resumida*</C.titleTextField>
        <C.textAreaField
          disabled={visualization}
          placeholder="Descreva uma breve descrição..."
          value={description}
          maxLength={140}
          onChange={e => setDescription(e.target.value)}
        />
        <p>{`${description.length}/140`}</p>
        {showAlertErr && description == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Qual problema sua startup resolve?*</C.titleTextField>
        <C.textAreaField
          disabled={visualization}
          placeholder="Descreva aqui..."
          value={resolution}
          maxLength={700}
          onChange={e => setResolution(e.target.value)}
        />
        <p>{`${resolution.length}/700`}</p>
        {showAlertErr && resolution == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form1;
