import React, { useState, useEffect } from 'react';
import * as C from './styles';
import UserRecords from './list';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import SettingsUser from './settings';
import { useSelector } from 'react-redux';
import { RootStore } from '../../context';
import Navbar from '../../components/Navbar';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavbarMobileUser from '../../components/NavbarMobileUser';

const HomeUser = () => {
  const infoUser = useSelector((state: RootStore) => state.user);

  const [showList, setShowList] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowList(true);
    }, 2000);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);

  return (
    <C.container>
      <Navbar secondary={true} logged={true} />
      <NavbarMobileUser />
      <C.header>
        <C.containerTitles>
          <C.title>Configurações</C.title>
          <C.subTitle>Bem-vindo, {infoUser.name}!</C.subTitle>
        </C.containerTitles>
      </C.header>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Informações Pessoais" />
          <Tab label="Cadastros" />
        </Tabs>
      </Box>
      {!showList && <LinearProgress className="linear_progress" />}

      {value == 0 && showList ? <SettingsUser /> : null}
      {value == 1 && showList ? (
        <UserRecords setButtonScroll={setShowScrollTop} />
      ) : null}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default HomeUser;
