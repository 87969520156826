import { shade } from 'polished';
import styled from 'styled-components';

export const containerTable = styled.div`
  width: 100%;
  padding: 0 2%;
  max-width: 1550px;
  margin: 50px auto 0 auto;

  .MuiMenuItem-root {
    width: 100px !important;
    justify-content: center;
    font-family: 'Montserrat';
    color: ${({ theme }) => theme.COLORS.BLACK}!important;
  }

  .Mui-checked {
    color: ${({ theme }) => theme.COLORS.GREEN_V2}!important;
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
`;

export const titleTable = styled.h2`
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XS}em;
`;

export const containerButtonsActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .btn_edit {
    svg {
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
  .btn_transfer {
    svg {
      color: ${({ theme }) => theme.COLORS.GREY_V5};
    }
  }
`;

// Dialog's
export const titleDialog = styled.h2`
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const containerTitle = styled.div`
  display: flex;
  padding: 20px 40px;
  align-items: center;
  justify-content: space-between;

  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px !important;
    color: #26d2a9 !important;
  }

  @media screen and (max-width: 600px) {
    padding: 5%;
  }
`;

export const textDialog = styled.p`
  text-align: center;
  font-weight: 700;
  padding: 0 10%;
  margin-top: 30px;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
`;

export const containerButtonsDialog = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .btn_confirm {
    font-weight: 500;
    color: #fff !important;
    font-family: 'Montserrat';
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2}!important;
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_cancel {
    border: none;
    font-weight: 500;
    font-family: 'Montserrat';
    outline: 1px solid ${({ theme }) => theme.COLORS.RED};
    color: ${({ theme }) => theme.COLORS.RED}!important;

    &:hover {
      border: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }
`;
export const textDialogTransfer = styled.p`
  text-align: center;
  font-weight: 400;
  padding: 0 5%;
  margin-top: 5px;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
`;

export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  gap: 80px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;

export const boxTextField = styled.div`
  width: 100%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;
export const containerTextFields = styled.div`
  width: 100%;
  padding: 0 40px;
`;
export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
