import React from 'react';
import * as C from './styles';
import { Dispatch, SetStateAction } from 'react';
import { MaskCEP, MaskOnlyNumbers } from '../../../helpers/mask';
import { Alert, MenuItem, Select } from '@mui/material';
import { theme } from '../../../styles/theme/themeMui';
import { statesOPT } from '../../../helpers/options';

interface IForm {
  city: string;
  state: string;
  number: string;
  address: string;
  zipCode: string;
  district: string;
  complement: string;
  setCity: Dispatch<SetStateAction<string>>;
  setState: Dispatch<SetStateAction<string>>;
  setNumber: Dispatch<SetStateAction<string>>;
  setDistric: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<string>>;
  setZipcode: Dispatch<SetStateAction<string>>;
  setComplement: Dispatch<SetStateAction<string>>;
  showAlertErr: boolean;
  visualization: boolean;
}

const Form8 = ({
  city,
  state,
  number,
  zipCode,
  address,
  setCity,
  district,
  setState,
  setNumber,
  setDistric,
  setZipcode,
  complement,
  setAddress,
  setComplement,
  showAlertErr,
  visualization = false,
}: IForm) => {
  return (
    <C.container>
      <C.titleMaster>Localização</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>CEP*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="CEP"
            value={zipCode}
            maxLength={8}
            onChange={e => setZipcode(MaskCEP(e.target.value))}
          />
          {showAlertErr && zipCode === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Endereço*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Endereço"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          {showAlertErr && address === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>

      <C.space />
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Número*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Número"
            value={number}
            onChange={e => setNumber(MaskOnlyNumbers(e.target.value))}
          />
          {showAlertErr && number === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Complemento</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Complemento"
            value={complement}
            onChange={e => setComplement(e.target.value)}
          />
        </C.boxTextField>
      </C.textFieldWraper50>

      <C.space />
      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>Estado*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Estado"
            value={state}
            onChange={e => setState(e.target.value)}
          />
          {showAlertErr && state === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>

        <C.boxTextField>
          <C.titleTextField>Cidade*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Cidade"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
          {showAlertErr && city === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Bairro*</C.titleTextField>
          <C.textField
            disabled={visualization}
            placeholder="Bairro"
            value={district}
            onChange={e => setDistric(e.target.value)}
          />
          {showAlertErr && district === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper33>
    </C.container>
  );
};
export default Form8;
