export const theme = {
  '.MuiSlider-root .MuiSlider-marked': {
    color: '#26D2A9 !important',
  },
  '&.focused .MuiInputBase-formControl': {
    borderColor: '#26D2A9 !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#26D2A9 !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#26D2A9',
    borderWidth: '2px',

    color: '#606470',
    '&::placeholder': {
      color: '#606470 !important',
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    color: '#606470 !important',
    padding: '20px !important',
    '&.focused': {
      borderColor: '#26D2A9',
    },
  },
  '.Mui-checked': {
    color: '#26D2A9 !important',
  },
  '.Mui-focused': {
    boderColor: '#26D2A9 !important',
  },
  // '.MuiSvgIcon-root ': {
  //   fill: 'white !important',
  // },

  // '& .label.Mui-focused': {
  //   color: 'red',
  // },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#26D2A9',
    },
  },
};

export const themeDialog = {
  '& .MuiDialogContent-root': {
    padding: '20px',
    minHeight: '640px !important',
    '@media screen and (max-widht: 780px)': {
      margin: '0px !important',
      width: '100% !important',
      height: '100% !important',
    },
  },

  '& .MuiDialogActions-root': {
    padding: '20px',
  },
  '& .MuiPaper-root': {
    '@media screen and (max-width: 780px)': {
      maxHeight: '100% !important',
      margin: '0px !important',
      width: '100% !important',
      minHeight: '100% !important',
    },
  },
};

export const themeDialogAlert = {
  '& .MuiDialogContent-root': {
    padding: '20px',
    minHeight: '240px !important',
    '@media screen and (max-widht: 780px)': {
      margin: '0px !important',
      width: '100% !important',
      height: '100% !important',
    },
  },

  '& .MuiDialogActions-root': {
    padding: '20px',
  },
  '& .MuiPaper-root': {
    maxWidth: '550px !important',
    '@media screen and (max-width: 780px)': {
      maxHeight: '100% !important',
      margin: '0px !important',
      width: '100% !important',
      minHeight: '100% !important',
    },
  },
};

export const themeTable = {
  '.MuiPaper-root': {
    maxWidth: '100% !important',
    overflow: 'hidden',
  },
};
