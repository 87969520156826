import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  .load {
    margin-top: 240px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  .check_icon {
    margin-top: 200px;
    font-size: 40px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  .error_icon {
    margin-top: 200px;
    font-size: 40px;
    color: ${({ theme }) => theme.COLORS.RED};
  }
`;

export const title = styled.h2<{ success: boolean }>`
  font-weight: 700;
  max-width: 250px;
  margin: 10px auto 20px auto;
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  color: ${props =>
    props.success
      ? ({ theme }) => theme.COLORS.GREEN_V2
      : ({ theme }) => theme.COLORS.RED} !important;
`;

export const subTitle = styled.p`
  max-width: 100%;
  margin: 10px auto;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
