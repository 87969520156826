import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarMobile from '../../../components/NavbarMobile';
import { isLogged } from '../../../services/session.service';
import LocationIcon from '@mui/icons-material/FmdGoodRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import EventCard from '../../../components/EventCard';
import { type } from '../../../interfaces/card.interface';
import { IEvent } from '../../../interfaces/event.interface';
import * as EventService from '../../../services/event.service';

const DetailsEvent = () => {
  const [event, setEvent] = useState<IEvent>();

  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getMentor = async () => {
      await EventService.GetById(id).then(res => {
        setEvent(res);
      });
    };

    getMentor();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />

        <C.headerDetails>
          <C.containerInfos>
            <C.containerTitles>
              <C.headerTitle>{event?.name}</C.headerTitle>
              <C.containerSubTitles>
                <C.headerSubTitle>{event?.contact.email}</C.headerSubTitle>
                <C.headerSubTitle>{event?.contact.phone}</C.headerSubTitle>
              </C.containerSubTitles>
            </C.containerTitles>
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <EventCard
              admissionCharged={
                event && event.admissionCharged ? event.admissionCharged : 2
              }
              title={event ? event.name : ''}
              date={event && event.date ? event.date : ''}
              fee={event && event.fee ? event.fee : ''}
              type={type.First}
              location={event ? event.location : ''}
              id={''}
            />
            <C.space />
            <C.bodyText>
              <span
                dangerouslySetInnerHTML={{
                  __html: (event?.description || '').replace(/\n/g, '<br />'),
                }}
              ></span>
            </C.bodyText>
            <C.space />
            <Button
              variant="contained"
              className="btn_inscrevase"
              onClick={() => {
                window.open(
                  event?.contact?.site?.includes('https://')
                    ? event?.contact?.site
                    : `https://${event?.contact?.site}`,
                  '_blank',
                );
              }}
              disabled={!event?.contact.site}
            >
              Inscreva-se
            </Button>
          </C.details>

          <C.containerInfosCity>
            <C.infoItem>
              <C.infoIcon>
                <LocationIcon />
              </C.infoIcon>
              <C.infoTitle>Cidade</C.infoTitle>
              <C.infoSubTitle>{event?.city}</C.infoSubTitle>
            </C.infoItem>
            {/* <C.infoItem>
              <C.infoIcon>
                <LocationIcon />
              </C.infoIcon>
              <C.infoTitle>Região</C.infoTitle>
              <C.infoSubTitle>XXXXXX</C.infoSubTitle>
            </C.infoItem> */}
          </C.containerInfosCity>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/agenda-eventos')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsEvent;
