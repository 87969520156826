import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../styles/theme/themeMui';
import Select from '@mui/material/Select';
import { FormikProps } from 'formik';
import { ICompany } from '../../../interfaces/company.interface';
import { companyTypes } from '../../../helpers/options';
import { MaskCNPJ } from '../../../helpers/mask';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form1 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization, message } = props;
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Nome</C.titleTextField>
        <C.textField
          placeholder="Digite o nome da empresa"
          name="name"
          maxLength={60}
          value={props.values.name}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.name.length}/60`}</p>
        {showAlertErr && props.values.name === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Descrição resumida</C.titleTextField>
        <C.textAreaField
          placeholder="Digite uma breve descrição..."
          name="shortDescription"
          value={props.values.shortDescription}
          onChange={props.handleChange}
          disabled={visualization}
          maxLength={140}
        />
        <p>{`${props.values.shortDescription.length}/140`}</p>

        {showAlertErr && props.values.shortDescription === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Qual problema seu negócio resolve?</C.titleTextField>
        <C.textAreaField
          placeholder="Descreva aqui..."
          name="solution"
          value={props.values.solution}
          onChange={props.handleChange}
          disabled={visualization}
          maxLength={700}
        />
        <p>{`${props.values.solution.length}/700`}</p>

        {showAlertErr && props.values.solution === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleTextField>Tipo</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="companyType"
            value={props.values.companyType}
            onChange={props.handleChange}
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {companyTypes.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && props.values.companyType === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper100>
      <C.textFieldWraper100>
        <C.titleTextField>CNPJ</C.titleTextField>
        <C.textField
          placeholder="Digite o CNPJ da empresa"
          name="companyCode"
          value={props.values.companyCode}
          onChange={e =>
            props.setFieldValue('companyCode', MaskCNPJ(e.target.value))
          }
          disabled={visualization}
        />
        {showAlertErr ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            {message}
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form1;
