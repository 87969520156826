import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { Alert, Button } from '@mui/material';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../styles/theme/themeMui';
import Select from '@mui/material/Select';
import { FormikProps } from 'formik';
import { IMentor, ISkill } from '../../../interfaces/mentor.interface';
import { skills } from '../../../helpers/options';
import { IFormProps } from '../../../interfaces/form.interface';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: '100',
  },
];

function valuetext(value: number) {
  return `${value}`;
}

const Form4 = (props: FormikProps<IMentor> & IFormProps) => {
  const [abilities, setAbilities] = useState<ISkill[]>([
    {
      name: '',
      score: 0,
    },
  ]);

  const { showAlertErr, visualization } = props;

  const addAbility = () => {
    setAbilities([...abilities, { name: '', score: 0 }]);
  };

  useEffect(() => {
    setAbilities(props.values.skills);
  }, []);

  return (
    <C.container>
      <C.titleMaster>Habilidades</C.titleMaster>
      {abilities.map((e, index) => (
        <C.textFieldWraper50 key={index}>
          <C.boxTextField>
            <C.titleTextField>Selecione suas Habilidades</C.titleTextField>
            <Select
              sx={theme}
              className="select"
              name="skills"
              value={e.name}
              onChange={(event: any) => {
                const newAbilities = [...abilities];
                newAbilities[index].name = event.target.value;
                setAbilities(newAbilities);
                props.setFieldValue('skills', newAbilities);
              }}
              displayEmpty
              disabled={visualization}
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>

              {skills.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
            {showAlertErr && abilities[index].name === '' ? (
              <Alert
                variant="outlined"
                severity="error"
                className="alert"
                sx={{ mt: 1 }}
              >
                Informação Obrigatória
              </Alert>
            ) : null}
          </C.boxTextField>
          <C.boxTextField>
            <Slider
              sx={{ color: '#26d2a9', marginTop: '30px' }}
              aria-label="Always visible"
              defaultValue={0}
              getAriaValueText={valuetext}
              step={10}
              marks={marks}
              value={e.score}
              valueLabelDisplay="on"
              disabled={visualization}
              onChange={(event: any) => {
                const newAbilities = [...abilities];
                newAbilities[index].score = event.target.value;
                setAbilities(newAbilities);
                props.setFieldValue('skills', newAbilities);
              }}
            />
          </C.boxTextField>
        </C.textFieldWraper50>
      ))}

      <C.space />
      <Button
        variant="contained"
        className="btn_add_ability"
        onClick={addAbility}
        disabled={visualization}
      >
        Adicionar Habilidade
      </Button>
    </C.container>
  );
};
export default Form4;
