import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as C from './styles';
import { Button } from '@mui/material';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import { type } from '../../../interfaces/card.interface';
import Company from '../../../assets/empresas.png';
import AddModal from '../../../components/Company/Add';
import NavbarMobile from '../../../components/NavbarMobile';
import FilterModal from '../../../components/Company/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { isLogged } from '../../../services/session.service';
import { ICompany } from '../../../interfaces/company.interface';
import * as CompanyService from '../../../services/company.service';
import Card from '../../../components/Card';

const Companies = () => {
  const navigation = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [companies, setCompanies] = useState<ICompany[]>();
  const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>();
  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterCity, setFilterCity] = useState('');

  const [filterModel, setFilterModel] = useState('');
  const [filterSegment, setFilterSegment] = useState('');
  const [filterStage, setFilterStage] = useState('');

  const handleAdd = () => {
    if (isLogged()) {
      setShowAdd(!showAdd);
    } else {
      navigation('/login');
    }
  };
  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    setFilteredCompanies(
      companies?.filter(startup => {
        const nameMatch = startup.name
          .toLowerCase()
          .includes(filterName.toLowerCase());
        const typeMatch = startup.businessType
          .toLowerCase()
          .includes(filterType.toLowerCase());
        const modelMatch = startup.businessModel
          .toLowerCase()
          .includes(filterModel.toLowerCase());
        const StageMatch = startup.businessStage
          ?.toLowerCase()
          .includes(filterStage.toLowerCase());
        const segmentMatch = startup.businessSegment
          ?.toLowerCase()
          .includes(filterSegment.toLowerCase());
        const cityMatch = startup.address.city
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return (
          nameMatch &&
          typeMatch &&
          modelMatch &&
          StageMatch &&
          segmentMatch &&
          cityMatch
        );
      }),
    );
  }, [
    filterName,
    filterType,
    filterCity,
    filterSegment,
    filterStage,
    filterModel,
    companies,
  ]);

  const getCompanies = async () => {
    const response: ICompany[] | undefined = await CompanyService.Get();
    setCompanies(response);
  };
  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 0);
  }, []);
  return (
    <C.container>
      <Navbar secondary={false} logged={isLogged()} />
      <NavbarMobile />
      <C.wraper>
        <C.containerFilters>
          <C.filters>
            <C.title>Empresas</C.title>
            <C.containerTextField>
              <C.textFieldWraper>
                <SearchRoundedIcon />
                <C.textField
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                />
              </C.textFieldWraper>
              <Button variant="contained" onClick={handleFilters}>
                Filtros
              </Button>
            </C.containerTextField>
            <Button
              variant="contained"
              className="btn_add_startup"
              onClick={handleAdd}
            >
              Cadastrar Empresa
            </Button>
          </C.filters>

          <Button
            variant="contained"
            className="btn_mobile"
            onClick={handleFilters}
          >
            Filtros
          </Button>

          <C.containerButtonAdd>
            <Button
              variant="contained"
              className="btn_mobile"
              onClick={handleAdd}
            >
              Cadastrar Empresas
            </Button>
          </C.containerButtonAdd>
        </C.containerFilters>

        <C.containerImage>
          <C.image>
            <img src={Company} alt="Company" />
          </C.image>
        </C.containerImage>
      </C.wraper>
      <C.fillEfect />
      <C.wraperTwo>
        <C.containerCards>
          {filteredCompanies?.map(item => (
            <Card
              key={item.id}
              title={item.shortDescription}
              city={item.address.city}
              name={item.name}
              type={type.First}
              id={item.id ? item.id : ''}
              logoId={item.logoId}
              path="detalhes-empresa"
            />
          ))}
        </C.containerCards>
      </C.wraperTwo>
      <FilterModal
        state={showFilter}
        setState={setShowFilter}
        setFilterCity={setFilterCity}
        setFilterModel={setFilterModel}
        setFilterName={setFilterName}
        setFilterSegment={setFilterSegment}
        setFilterStage={setFilterStage}
        setFilterType={setFilterType}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <Footer />
    </C.container>
  );
};

export default Companies;
