import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 1000;
  position: relative;
  display: none;

  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);

  .MuiPaper-root {
    font-family: 'Montserrat';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  }

  @media only screen and (max-width: 1090px) {
    display: block;
  }
`;

export const wraper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const containerBurgerMenu = styled.div`
  .dropDown {
    width: 250px;
    height: 400px;
    opacity: 0;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.6s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
`;
export const link = styled.a`
  font-weight: 400;
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const containerLogo = styled.div``;

export const logo = styled.img`
  max-height: 60px;
  @media only screen and (max-width: 570px) {
    max-height: 50px;
  }
`;
export const containerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButton-text {
    font-weight: 700;
    padding: 5px 15px;
    border-radius: 50px;
    text-transform: none !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    font-family: 'Montserrat' !important;
    border: 2px solid ${({ theme }) => theme.COLORS.GREEN_V1};
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
`;
