import { IFormdata } from '../interfaces/startup.interface';
import { validateCNPJ } from './validate.cnpj';
import { validateEmail } from './validate.email';
import { validateURL } from './validate.url';

export const checkCreateSturtupFields = (data: IFormdata) => {
  let message = '';
  let success = true;
  if (
    !data.name ||
    !data.employees ||
    data.partners.length < 1 ||
    !data.solution ||
    !data.description ||
    !data.foundingDate ||
    !data.businessStage ||
    !data.businessModel ||
    !data.businessSegment ||
    !data.shortDescription ||
    !data.address.city ||
    !data.address.state ||
    !data.address.street ||
    !data.address.number ||
    !data.address.zipCode ||
    !data.address.district ||
    !data.contact.email ||
    !data.contact.phone
  ) {
    message =
      'Para efetivar o cadastro da startup, você precisa preencher todos os campos obrigatórios';
    success = false;

    return { message, success };
  }

  if (data.thereIsAcelerarion) {
    if (!data.accelerationProgram) {
      success = false;
      message =
        'Você precisa informar quais programas de aceleração já participou';
      return { message, success };
    }
  }

  if (data.thereIsFormalization) {
    if (!data.companyType) {
      success = false;
      message = 'Você precisa informar o tipo de formalização';
      return { message, success };
    }

    if (!data.companyCode) {
      success = false;
      message = 'Você precisa informar o CNPJ';
      return { message, success };
    }
  }
  if (data.partners.length === 0) {
    success = false;
    message = 'Você precisa informar o CNPJ';
    return { message, success };
  }

  return { message, success };
};

function validPhone(phone: string): boolean {
  const cleanPhone = phone.replace(/\D/g, '');

  const isMobile =
    /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );

  if (isMobile) {
    return /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );
  } else {
    return /^([1-9]{2})\s?[2-5|7-9][0-9]{7}$/.test(cleanPhone);
  }
}

export const checkProgressSturtupFields = (data: IFormdata, index: number) => {
  let success = true;
  let message = '';

  switch (index) {
    case 1:
      if (!data.name || !data.shortDescription || !data.solution) {
        success = false;
        break;
      }
      break;
    case 2:
      if (!data.description) {
        success = false;
        break;
      }
      break;
    case 3:
      if (
        !data.address.city ||
        !data.address.number ||
        !data.address.state ||
        !data.address.district ||
        !data.address.street ||
        !data.address.zipCode
      ) {
        success = false;
        break;
      }
      break;
    case 4:
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }

      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }

      if (data.socialMedia?.instagram) {
        if (!validateURL(data.socialMedia?.instagram)) {
          success = false;
          message = `O formato do instagram é inválido`;
        }
      }

      if (data.socialMedia?.linkedin) {
        if (!validateURL(data.socialMedia?.linkedin)) {
          success = false;
          message = `O formato do linkedin é inválido`;
        }
      }

      if (data.socialMedia?.facebook) {
        if (!validateURL(data.socialMedia?.facebook)) {
          success = false;
          message = `O formato do facebook é inválido`;
        }
      }

      if (data.socialMedia?.youtube) {
        if (!validateURL(data.socialMedia?.youtube)) {
          success = false;
          message = `O formato do youtube é inválido`;
        }
      }

      break;
    case 5:
      if (data.thereIsAcelerarion && !data.accelerationProgram) {
        success = false;
        break;
      }

      if (
        data.thereIsFormalization &&
        (!data.companyType || !data.companyCode)
      ) {
        message = `Informação Obrigatória`;
        success = false;
        break;
      }
      if (data.thereIsFormalization && data.companyCode) {
        if (!validateCNPJ(data.companyCode)) {
          success = false;
          message = 'O formato do CNPJ é inválido';
        }
      }

      break;

    case 6:
      if (data.employees === 0) {
        success = false;
        message = 'Número de colaboradores deve ser no mínimo 1';
        break;
      }

      if (
        !data.businessModel ||
        !data.businessSegment ||
        !data.businessStage ||
        !data.businessType ||
        !data.employees
      ) {
        success = false;
        break;
      }

      break;
    case 7:
      if (!data.foundingDate) {
        success = false;
        break;
      }

      if (data.foundingDate.toDate() > new Date()) {
        success = false;
        break;
      }
      break;
    case 8:
      if (data.partners.length == 0) {
        message = 'Você deve salvar o cadastro de um sócio antes de enviar';
        success = false;
        break;
      }

      if (!data.isPartnersSaved) {
        message = 'Você deve salvar o cadastro do sócio antes de enviar';
        success = false;
        break;
      }
      break;
    default:
      success = true;
  }

  return { message, success };
};
