import { shade } from 'polished';
import styled from 'styled-components';
import Banner from '../../../assets/banner_home.png';
import BannerTendencias from '../../../assets/imghome2.png';

export const container = styled.div`
  width: 100%;

  .btn_header {
    font-weight: 700;
    margin-top: 30px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_home {
    font-weight: 700;
    margin-top: 70px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_chart {
    font-weight: 700;
    padding: 15px 15px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }
`;

export const headerHome = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 900px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${Banner});
  @media screen and (max-width: 900px) {
    height: 80vh;
    min-height: 400px;
  }
`;

export const infosHeader = styled.div`
  width: 100%;
  height: 300px;
  padding: 0 2%;
  text-align: center;
  margin-top: 300px;

  @media screen and (max-width: 900px) {
    margin-top: 100px;
    height: 200px;
  }
`;

export const titleHeader = styled.h2`
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;

export const subTitleHeader = styled.p`
  color: #fff;
  max-width: 700px;
  font-weight: 500;
  margin: 30px auto 0px auto;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;

export const mapsSection = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px 10%;
  align-items: center;
  justify-content: center;
  background-color: #ffff;

  @media screen and (max-width: 2400px) {
    padding: 20px 20%;
  }

  @media screen and (max-width: 1500px) {
    padding: 20px 2%;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 auto;
  }
`;

export const containerInfosMap = styled.div`
  width: 30%;
  height: 600px;
  padding: 20px;

  @media screen and (max-width: 2400px) {
    width: 40%;
  }
  @media screen and (max-width: 1500px) {
    width: 100%;
    height: 400px;
  }
`;

export const titleMap = styled.h2`
  max-width: 500px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 1500px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;

export const subTitleMap = styled.p`
  font-weight: 300;
  margin-top: 30px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  @media screen and (max-width: 1500px) {
    margin: 20px auto 0 auto;
  }
  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  }
`;

export const containerMap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    background-color: #ffff;
    margin-top: 60px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
`;

export const containerCardsInfo = styled.div`
  width: 100%;
  margin-top: -150px;
  padding: 10px 10% 90px 10%;
  display: flex;
  gap: 200px;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  @media screen and (max-width: 1250px) {
    gap: 20px;
  }

  /* @media screen and (max-width: 780px) {
    margin-top: -280px;
    flex-wrap: wrap;
    padding: 10px 10% 40px 10%;
  } */
  @media screen and (max-width: 980px) {
    margin-top: 90px;
    flex-wrap: wrap;
  }
`;

export const cardInfo = styled.div`
  width: 400px;
  height: 300px;
  text-align: center;

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const cardTitle = styled.h3`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
`;
export const cardSubTitle = styled.h4`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;
export const cardText = styled.p`
  font-weight: 300;
  margin-top: 10px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;

export const technologySection = styled.section`
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: ${({ theme }) => theme.COLORS.GREY_V1};

  @media screen and (max-width: 940px) {
    padding-bottom: 120px;
  }
`;

export const image = styled.div`
  width: 300px;
  margin: 0 auto;
  img {
    margin-left: -130px;
  }
  @media screen and (max-width: 940px) {
    width: 200px;
    img {
      margin-left: -190px;
    }
  }

  @media screen and (max-width: 650px) {
    width: 10px;
    img {
      width: 340px;
      display: block;
      margin-left: -130px;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 450px) {
    width: 10px;
    img {
      width: 280px;
      display: block;
      margin-left: -100px;
      margin-bottom: 50px;
    }
  }
`;

export const parkTitle = styled.h2`
  font-weight: 700;
  margin-top: -110px;
  color: ${({ theme }) => theme.COLORS.GREEN_V4};
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;

export const parkSubTitle = styled.p`
  max-width: 790px;
  font-weight: 300;

  margin: 10px auto 0 auto;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  }
`;

export const containerCharts = styled.div`
  width: 100%;
  gap: 160px;
  margin-top: 50px;
  display: flex;
  padding: 10px 20%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1390px) {
    gap: 30px;
  }

  @media screen and (max-width: 1240px) {
    flex-wrap: wrap;
    gap: 50px;
  }

  @media screen and (max-width: 940px) {
    gap: 150px;
    padding: 10px 2%;
  }
  @media screen and (max-width: 680px) {
    gap: 60px;
  }
`;

export const chartCard = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 940px) {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
`;

export const chart = styled.div`
  width: 300px;

  svg {
    font-size: 80px;
    top: -190px;
    position: relative;
    color: ${({ theme }) => theme.COLORS.BLACK};
  }
  @media screen and (max-width: 1040px) {
    width: 250px;

    svg {
      font-size: 70px;
      top: -165px;
    }
  }

  @media screen and (max-width: 780px) {
    width: 150px;
    svg {
      font-size: 55px;
      top: -105px;
    }
  }
`;
export const containerInfosChart = styled.div`
  width: 60%;
  margin-left: 30px;
  margin-bottom: 20px;
  text-align: left;
  top: -70px;
  position: relative;
  @media screen and (max-width: 940px) {
    width: 100%;
    text-align: center;
    top: -50px;
    margin-left: 0;
  }
`;

export const chartTitle = styled.h2`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;

export const chartInfosWraper = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  margin: 10px 0;
  justify-content: flex-start;

  @media screen and (max-width: 940px) {
    justify-content: center;
  }
`;

export const chartInfos = styled.div`
  gap: 8px;
  display: flex;
  @media screen and (max-width: 940px) {
    margin-bottom: 10px;
  }
`;

export const chartDot1 = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffca43;
`;
export const chartDot2 = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #12c096;
`;
export const chartDot3 = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff8787;
`;
export const chartDot4 = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #d4fbf1;
`;

export const chartText = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const chartNumber = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;

export const opportunitiesSection = styled.section`
  width: 100%;

  .opp_wraper {
    max-width: 1220px;
    margin: 0 auto;
    display: flex;
    gap: 300px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1120px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 30px 2%;
  }
`;

export const opportunitiesInfos = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 40px;
  @media screen and (max-width: 1120px) {
    text-align: center;
    margin: 40px auto 0 auto;
    height: 450px;
  }
`;

export const opportunitiesTitle = styled.h2`
  max-width: 500px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
  @media screen and (max-width: 1120px) {
    max-width: 100%;
  }
  @media screen and (max-width: 950px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;
export const opportunitiesSubtitle = styled.p`
  font-weight: 300;
  margin-top: 30px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  @media screen and (max-width: 1120px) {
    max-width: 100%;
  }

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  }
`;

export const opportunitiesCalcs = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 40px;
`;

export const opportunitiesItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: left;
  margin-bottom: 50px;

  svg {
    font-size: 60px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  @media screen and (max-width: 1120px) {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
`;

export const opportunitiesText = styled.p`
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;

export const tendenciesSection = styled.section`
  width: 100%;
  padding: 40px 0 200px 0;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  background-image: url(${BannerTendencias});
`;

export const tendenciesTitle = styled.h2`
  color: #fff;
  padding-top: 60px;
  font-weight: 700;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 780px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  }
`;

export const tendenciesItem = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 880px) {
    flex-wrap: wrap;
  }
`;

export const tendenciesContainerCard = styled.div`
  width: 50%;
  padding: 20px 100px;
  @media screen and (max-width: 880px) {
    width: auto;
  }
`;

export const tendenciesCard = styled.div`
  width: 200px;
  height: 270px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V2};

  svg {
    color: white;
    top: 30px;
    font-size: 100px;
    position: relative;
  }

  @media screen and (max-width: 1088px) {
    margin: 0 auto;
  }
`;

export const tendenciesCardTitle = styled.h2`
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
`;

export const tendenciesContainerList = styled.div`
  width: 50%;
  height: auto;
  text-align: left;

  @media screen and (max-width: 1088px) {
    width: auto;
  }
`;

export const tendenciesTextList = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;

  svg {
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
    margin-right: 20px;
  }
`;

export const containerButtonScrollTop = styled.div<{ show: boolean }>`
  width: 50%;
  height: 60px;
  z-index: 13000;
  margin-top: 40px;

  position: fixed;
  bottom: 10px;
  right: 20px;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: end;
  .MuiIconButton-root {
    width: 50px !important;
    height: 50px !important;
    min-width: 0;
    padding: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
    &:hover {
      transform: translateY(-7px);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 20px -7px;
      background-color: ${({ theme }) => theme.COLORS.YELLOW_V2} !important;
    }

    svg {
      color: ${({ theme }) => theme.COLORS.BLACK};
      font-size: 40px;
    }
  }

  @media screen and (max-width: 980px) {
    justify-content: center;
    bottom: 0;
    right: 0;
  }
`;

export const containerSlide = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  padding: 0 3% !important;
  justify-content: center;
`;

export const imgTendecia = styled.img`
  width: 110px;
  height: 110px;
  top: 30px;
  font-size: 80px;
  position: relative;
`;
