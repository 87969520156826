import React from 'react';
import * as C from './styles';
import { ILogo } from '../../interfaces/card.interface';

const Banner = ({ size, url }: ILogo) => {
  return (
    <C.containerLogo style={{ height: size }}>
      <div
        className="logo"
        style={{
          height: size,
          width: '100%',
          backgroundImage: `url(${
            process.env.REACT_APP_ENV_BASE_API + '/files/' + url
          })`,
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    </C.containerLogo>
  );
};

export default Banner;
