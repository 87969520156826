import React from 'react';
import * as C from './styles';
import { useState } from 'react';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import * as UserService from '../../../services/user.service';
import { IAddStartup } from '../../../interfaces/startup.interface';
import { checkUserFieldsTwo } from '../../../helpers/account.check.fields';

export const AddModal = ({ setShow, show, id }: IAddStartup) => {
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);

  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleCreate = async () => {
    setShowConcluded(true);
    const data = {
      name: name,
      email: email,
      role: role,
      password: password,
      confirm: passwordConfirm,
    };

    if (!checkUserFieldsTwo(data).success) {
      const msgError = checkUserFieldsTwo(data).message;
      setTimeout(async () => {
        setSuccess(false);
        setTitle('Ooops!');
        setSubOne(msgError);

        setTimeout(() => {
          setShowConcluded(false);
          setTitle('');
          setSubOne('');
        }, 4000);
      }, 2000);
    } else {
      const { success, message } = await UserService.CreateByAdmin({
        confirm: passwordConfirm,
        email,
        password,
        name,
        role,
      });

      setTimeout(() => {
        if (success) {
          setSuccess(true);
          setTitle('Tudo certo!');
          setSubOne('Usuário cadastrado com sucesso');
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          setSuccess(false);
          setTitle('Ooops!');
          setSubOne(message);

          setTimeout(() => {
            setShowConcluded(false);
            setTitle('');
            setSubOne('');
          }, 4000);
        }
      }, 2000);
    }
  };

  const handleClose = () => {
    setShow(false);

    setShowConcluded(false);
  };

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>Criar Usuário</C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {!showConcluded ? (
            <C.containerTextFields>
              <C.textFieldWraper50>
                <C.boxTextField>
                  <C.boxTextField>
                    <C.titleTextField>Nome</C.titleTextField>
                    <C.textField
                      placeholder="Digite o nome"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </C.boxTextField>
                </C.boxTextField>
                <C.boxTextField>
                  <C.titleTextField>E-mail</C.titleTextField>
                  <C.textField
                    placeholder="Digite o e-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </C.boxTextField>
              </C.textFieldWraper50>

              <C.textFieldWraper50>
                <C.boxTextField>
                  <C.boxTextField>
                    <C.titleTextField>Senha</C.titleTextField>
                    <C.textField
                      placeholder="Digite a senha"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </C.boxTextField>
                  <C.infoTextField>
                    Deve conter no mínimo 6 caracteres incluindo um número
                  </C.infoTextField>
                </C.boxTextField>
                <C.boxTextField>
                  <C.titleTextField>Confirme a senha</C.titleTextField>
                  <C.textField
                    placeholder="Digite a confirmação da senha"
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}
                  />
                </C.boxTextField>
              </C.textFieldWraper50>
              <C.textFieldWraper50>
                <C.boxTextField50>
                  <C.titleTextField>Tipo</C.titleTextField>
                  <Select
                    sx={theme}
                    className="select"
                    value={role}
                    onChange={e => setRole(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione o tipo</em>
                    </MenuItem>
                    <MenuItem value="user">Usuário</MenuItem>
                    <MenuItem value="admin">Administrador</MenuItem>
                  </Select>
                </C.boxTextField50>
              </C.textFieldWraper50>
            </C.containerTextFields>
          ) : null}

          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>

        <C.containerButtons disabled={false}>
          <Button
            variant="outlined"
            className="btn_cancel"
            onClick={handleClose}
          >
            Cancelar
          </Button>

          <Button variant="outlined" className="btn_add" onClick={handleCreate}>
            Cadastrar
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default AddModal;
