import React, { useState } from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { Dispatch, SetStateAction } from 'react';
import { theme } from '../../../styles/theme/themeMui';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formalizationTypeOPT } from '../../../helpers/options';
import { MaskCNPJ } from '../../../helpers/mask';
import { Alert } from '@mui/material';

interface IForm {
  register: string;
  acelerationText: string;
  formalizationType: string;
  thereIsAcelerarion: boolean;
  thereIsFormalization: boolean;
  setRegister: Dispatch<SetStateAction<string>>;
  setAcelerationText: Dispatch<SetStateAction<string>>;
  setFormalizationType: Dispatch<SetStateAction<string>>;
  setThereIsAceleration: Dispatch<SetStateAction<boolean>>;
  setThereIsFormalization: Dispatch<SetStateAction<boolean>>;
  showAlertErr: boolean;
  message: string;
  visualization: boolean;
}

const Form4 = ({
  register,
  setRegister,
  acelerationText,
  formalizationType,
  thereIsAcelerarion,
  setAcelerationText,
  thereIsFormalization,
  setFormalizationType,
  setThereIsAceleration,
  setThereIsFormalization,
  showAlertErr,
  message,
  visualization = false,
}: IForm) => {
  const handleFormalization = (value: boolean) => {
    setThereIsFormalization(value);
    if (!value) {
      setRegister('');
      setFormalizationType('');
    }
  };

  const handleAceleration = (value: boolean) => {
    setThereIsAceleration(value);
    if (!value) {
      setAcelerationText('');
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFormalizationType(event.target.value);
  };

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleMaster>Formalização</C.titleMaster>
        <C.titleTextField>Sua startup tem CNPJ?</C.titleTextField>
        <C.boxTextField>
          <RadioGroup
            row
            sx={theme}
            value={thereIsFormalization ? 'Sim' : 'Não'}
            onChange={e => {
              handleFormalization(e.target.value == 'Sim' ? true : false);
            }}
          >
            <FormControlLabel
              disabled={visualization}
              value="Sim"
              label="Sim"
              control={<Radio />}
            />
            <FormControlLabel
              disabled={visualization}
              value="Não"
              label="Não"
              control={<Radio />}
            />
          </RadioGroup>
        </C.boxTextField>
      </C.textFieldWraper100>
      {thereIsFormalization && (
        <C.textFieldWraper50>
          <C.boxTextField>
            <C.titleTextField>Tipo de Formalização*</C.titleTextField>
            <Select
              disabled={visualization}
              sx={theme}
              className="select"
              value={formalizationType}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {formalizationTypeOPT.map(item => (
                <MenuItem value={item} key={item}>
                  <em>{item}</em>
                </MenuItem>
              ))}
            </Select>
            {showAlertErr &&
            thereIsFormalization &&
            formalizationType === '' ? (
              <Alert
                variant="outlined"
                severity="error"
                className="alert"
                sx={{ mt: 1 }}
              >
                Informação Obrigatória
              </Alert>
            ) : null}
          </C.boxTextField>
          <C.boxTextField>
            <C.titleTextField>CNPJ*</C.titleTextField>
            <C.textField
              disabled={visualization}
              placeholder="CNPJ"
              value={register}
              maxLength={18}
              onChange={e => setRegister(MaskCNPJ(e.target.value))}
            />
            {showAlertErr ? (
              <Alert
                variant="outlined"
                severity="error"
                className="alert"
                sx={{ mt: 1 }}
              >
                {message}
              </Alert>
            ) : null}
          </C.boxTextField>
        </C.textFieldWraper50>
      )}

      <C.space />

      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleMaster>Aceleração</C.titleMaster>
          <C.titleTextField>
            Já participou de programas de incubação, aceleradora e /ou afins?
          </C.titleTextField>
          <RadioGroup
            row
            sx={theme}
            value={thereIsAcelerarion ? 'Sim' : 'Não'}
            onChange={e => {
              handleAceleration(e.target.value == 'Sim' ? true : false);
            }}
          >
            <FormControlLabel
              disabled={visualization}
              value="Sim"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              disabled={visualization}
              value="Não"
              control={<Radio />}
              label="Não"
            />
          </RadioGroup>
        </C.boxTextField>
        {thereIsAcelerarion && (
          <C.boxTextField>
            <C.titleTextField>
              Quais programas de incubação, aceleradora e/ou afins*?
            </C.titleTextField>
            <C.textAreaField
              disabled={visualization}
              placeholder="Digite aqui"
              value={acelerationText}
              maxLength={700}
              onChange={e => setAcelerationText(e.target.value)}
            />
            <p>{`${acelerationText.length}/700`}</p>
            {showAlertErr && thereIsAcelerarion && acelerationText === '' ? (
              <Alert
                variant="outlined"
                severity="error"
                className="alert"
                sx={{ mt: 1 }}
              >
                Informação Obrigatória
              </Alert>
            ) : null}
          </C.boxTextField>
        )}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form4;
