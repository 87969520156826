import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as C from './styles';
import { Button } from '@mui/material';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import { type } from '../../../interfaces/card.interface';
import Calendar from '../../../assets/calendario.png';
import EventCard from '../../../components/EventCard';
import NavbarMobile from '../../../components/NavbarMobile';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddModal from '../../../components/Events/Add';
import FilterModal from '../../../components/Events/Filter';
import { isLogged } from '../../../services/session.service';
import * as EventService from '../../../services/event.service';
import { IListEvent } from '../../../interfaces/event.interface';
import { maskValue } from '../../../helpers/mask';

const Events = () => {
  const navigation = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [events, setEvents] = useState<IListEvent>();
  const [showFilter, setShowFilter] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [fee, setFee] = useState(true);

  const getEvents = async () => {
    const response: IListEvent | undefined = await EventService.Get();
    setEvents(response);
  };

  const handleAdd = () => {
    if (isLogged()) {
      setShowAdd(!showAdd);
    } else {
      navigation('/login');
    }
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getEvents();
  }, []);
  return (
    <>
      <C.container>
        <Navbar secondary={false} logged={isLogged()} />
        <NavbarMobile />
        <C.wraper>
          <C.containerFilters>
            <C.filters>
              <C.title>Agenda de Eventos</C.title>
              <C.containerTextField>
                <C.textFieldWraper>
                  <SearchRoundedIcon />
                  <C.textField />
                </C.textFieldWraper>
                <Button variant="contained" onClick={handleFilters}>
                  Filtros
                </Button>
              </C.containerTextField>
              <Button
                variant="contained"
                className="btn_add_event"
                onClick={handleAdd}
              >
                Cadastrar Evento
              </Button>
            </C.filters>

            <Button
              variant="contained"
              className="btn_mobile"
              onClick={handleFilters}
            >
              Filtros
            </Button>

            <C.containerButtonAdd>
              <Button
                variant="contained"
                className="btn_mobile"
                onClick={handleAdd}
              >
                Cadastrar Evento
              </Button>
            </C.containerButtonAdd>
          </C.containerFilters>

          <C.containerImage>
            <C.image>
              <img src={Calendar} alt="Eventos" />
            </C.image>
          </C.containerImage>
        </C.wraper>
        <C.fillEfect />
        <C.wraperTwo>
          <C.containerCards>
            {events?.data
              ?.filter(e => {
                if (city == '') return e;
                else if (e.city && city != '') return e.city == city;
              })
              .filter(e => (fee ? e : e.fee == null))
              .map(item => (
                <EventCard
                  key={item.id}
                  admissionCharged={item.admissionCharged}
                  title={item.name}
                  date={item.date}
                  fee={maskValue(item.fee ? item.fee * 100 + '' : '') + ''}
                  location={item.location}
                  type={type.First}
                  id={item.id}
                  path="detalhes-evento"
                />
              ))}
          </C.containerCards>
          {/* <C.containerButton>
      <Button variant="contained" className="btn_add">
        Cadastrar um Evento
      </Button>
    </C.containerButton> */}
        </C.wraperTwo>
        <FilterModal
          show={showFilter}
          setShow={setShowFilter}
          name={name}
          city={city}
          fee={fee}
          setName={setName}
          setCity={setCity}
          setFee={setFee}
        />
        <AddModal show={showAdd} setShow={setShowAdd} />
        <Footer />
      </C.container>
    </>
  );
};

export default Events;
