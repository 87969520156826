import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../styles/theme/themeMui';
import Select from '@mui/material/Select';
import { FormikProps } from 'formik';
import { IInnovationEnvironment } from '../../../interfaces/innovationEnvironment.interface';
import { technologyParks } from '../../../helpers/options';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form1 = (props: FormikProps<IInnovationEnvironment> & IFormProps) => {
  const { showAlertErr, visualization } = props;

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Nome</C.titleTextField>
        <C.textField
          placeholder="Digite o nome do parque"
          name="name"
          maxLength={60}
          value={props.values.name}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.name.length}/60`}</p>
        {showAlertErr && props.values.name === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Título</C.titleTextField>
        <C.textField
          maxLength={100}
          placeholder="Digite o título do parque"
          name="title"
          value={props.values.title}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.title.length}/100`}</p>
        {showAlertErr && props.values.title === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleTextField>Tipo </C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="type"
            value={props.values.type}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {technologyParks.map(park => {
              return (
                <MenuItem key={park} value={park}>
                  {park}
                </MenuItem>
              );
            })}
          </Select>
          {showAlertErr && props.values.type === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper100>
    </C.container>
  );
};

export default Form1;
