import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IConcludedContact } from '../../interfaces/concluded.contatc.iterface';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const Concluded = ({ setState, handleSubmit }: any) => {
  const [showLoad, setShowLoad] = useState(false);

  const handleForm = () => {
    setState(false);
  };

  useEffect(() => {
    const handle = async () => {
      setShowLoad(true);
      await handleSubmit();
      setShowLoad(false);
    };
    handle();
  }, []);
  return (
    <C.container>
      {showLoad ? (
        <CircularProgress size={60} className="load" />
      ) : (
        <>
          <CheckCircleRoundedIcon className="check_icon" />
          <C.title>Pronto!</C.title>
          <C.subTitle>
            Agora é só esperar a resposta da nossa equipe!
          </C.subTitle>
          <C.subTitle>
            Ela chegará no seu e-mail registrado o mais rápido possível.
          </C.subTitle>

          <Button
            variant="contained"
            className="btn_new_contact"
            onClick={handleForm}
          >
            Enviar outra mensagem
          </Button>
        </>
      )}
    </C.container>
  );
};
export default Concluded;
