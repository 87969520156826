export default {
  COLORS: {
    RED: '#FF8787',
    // BLACK: '#333840',
    BLACK: '#606470',
    BLACK_V2: '#333840',
    YELLOW: '#FFCA43',
    YELLOW_V2: '#DCA821',
    GREEN_V1: '#D4FBF1',
    GREEN_V2: '#26D2A9',
    GREEN_V3: '#12C096',
    GREEN_V4: '#21987C',
    GREY_V1: '#F6F8FC',
    GREY_V2: '#B3B3B3',
    GREY_V3: '#F5F5F5',
    GREY_V4: '#D8D8D8',
    GREY_V5: '#949494',
  },
  FONT_SIZES: {
    SX: 0.7,
    SM: 0.9,
    MM: 1,
    MD: 1.2,
    XS: 1.3,
    XM: 1.5,
    XL: 2.0,
    XX: 3,
  },
};
