import React from 'react';
import * as C from './styles';
import { Dayjs } from 'dayjs';
import locale from 'dayjs/locale/pt-br';
import { Button, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { theme } from '../../../styles/theme/themeMui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PhotoIcon from '@mui/icons-material/PhotoSizeSelectActualRounded';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Alert } from '@mui/material';

interface IForm {
  foundation: Dayjs | null;
  imageSelected: boolean;
  bannerSelected: boolean;
  imagePath: string;
  bannerPatch: string;
  setImage: Dispatch<SetStateAction<File | undefined>>;
  setBanner: Dispatch<SetStateAction<File | undefined>>;
  setimagePath: Dispatch<SetStateAction<any>>;
  setBannerPatch: Dispatch<SetStateAction<any>>;
  setImageSelected: Dispatch<SetStateAction<boolean>>;
  setBannerSelected: Dispatch<SetStateAction<boolean>>;
  setFoundation: Dispatch<SetStateAction<Dayjs | null>>;
  showAlertErr: boolean;
  visualization: boolean;
}

const Form6 = ({
  setFoundation,
  foundation,
  setImage,
  setBanner,
  imagePath,
  bannerPatch,
  setBannerPatch,
  setimagePath,
  imageSelected,
  bannerSelected,
  setImageSelected,
  setBannerSelected,
  showAlertErr,
  visualization = false,
}: IForm) => {
  const handleChange = (newValue: Dayjs | null) => {
    setFoundation(newValue);
  };

  const formattingFile = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setimagePath(reader.result);
        });
        reader.readAsDataURL(image[0]);

        if (image[0].size < 512000) {
          setImage(image[0]);
          setImageSelected(true);
        }
      }
    }
  };

  const formattingBanner = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setBannerPatch(reader.result);
        });
        reader.readAsDataURL(image[0]);

        if (image[0].size < 512000) {
          setBanner(image[0]);
          setBannerSelected(true);
        }
      }
    }
  };

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleMaster>Mais Informações</C.titleMaster>
        <C.titleTextField>Logo</C.titleTextField>
        <C.boxLogo>
          <C.containerLogo>
            {imageSelected ? (
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundImage: `url(${imagePath})`,
                }}
                className="logo"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerLogo>
          <div className="image_wraper">
            <C.infoImage>largura 500px x altura 500px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingFile(t.target.files)}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
        {/* END IMAGE */}
      </C.textFieldWraper100>

      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Banner</C.titleTextField>
        <C.boxLogo>
          <C.containerBanner>
            {bannerSelected ? (
              <div
                style={{
                  width: 235,
                  height: '100%',
                  backgroundImage: `url(${bannerPatch})`,
                }}
                className="banner"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerBanner>
          <div>
            <C.infoImage>largura 1500px X Altura 400px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingBanner(t.target.files)}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
      </C.textFieldWraper100>

      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleTextField>Data da Fundação*</C.titleTextField>

          <LocalizationProvider
            adpterLocale={locale}
            dateAdapter={AdapterDayjs}
          >
            <DesktopDatePicker
              disabled={visualization}
              inputFormat="DD/MM/YYYY"
              value={foundation}
              onChange={handleChange}
              renderInput={params => <TextField sx={theme} {...params} />}
            />
          </LocalizationProvider>
        </C.boxTextField>
        {showAlertErr && !foundation ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Data de fundação é obrigatório
          </Alert>
        ) : null}
        {showAlertErr && foundation ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Data de fundação não pode ser no futuro
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form6;
