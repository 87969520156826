import React from 'react';
import * as C from './styles';
import { ICard } from '../../interfaces/card.interface';
import { useNavigate } from 'react-router-dom';

const Card = ({ title, path, region, id }: ICard) => {
  const navigation = useNavigate();
  return (
    <C.container
      onClick={() => {
        path ? navigation(`/${path}/${id}`, { state: id }) : null;
      }}
    >
      <C.headerCard className="header_card" />
      <C.containerInfos>
        <C.title className="text_card">{title}</C.title>

        <C.descriptions className="text_card">{region}</C.descriptions>
      </C.containerInfos>
    </C.container>
  );
};

export default Card;
