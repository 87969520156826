import React, { Dispatch, SetStateAction } from 'react';
import * as C from './styles';
import locale from 'dayjs/locale/pt-br';
import { Alert, TextField } from '@mui/material';
import { theme } from '../../../styles/theme/themeMui';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

interface IForm {
  name: string;
  date: Dayjs | null;
  setName: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<Dayjs | null>>;
  showMessageErr: boolean;
  visualization: boolean;
}

const Form1 = ({
  name,
  date,
  setName,
  setDate,
  showMessageErr,
  visualization,
}: IForm) => {
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Nome</C.titleTextField>
        <C.textField
          placeholder="Digite o nome do Evento"
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={visualization}
          maxLength={100}
        />
        <p>{`${name.length}/100`}</p>
        {showMessageErr && !name ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Data</C.titleTextField>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={date}
            onChange={e => setDate(e)}
            className="datepicker_event"
            renderInput={params => <TextField sx={theme} {...params} />}
            disabled={visualization}
          />
        </LocalizationProvider>
        {showMessageErr && name ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Data do evento não pode ser menor do que data atual
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Hora</C.titleTextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={date}
            ampm={false}
            onChange={e => setDate(e)}
            className="datepicker_event"
            renderInput={params => <TextField sx={theme} {...params} />}
            disabled={visualization}
          />
        </LocalizationProvider>
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form1;
