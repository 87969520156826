import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../styles/theme/themeMui';
import Select from '@mui/material/Select';
import { errors, payload } from './Form.Types';
import { cities } from './cities';
import { FormHelperText } from '@mui/material';
import { regions } from './regions';
import { ICity } from '../../../services/cities.service';

const Form1 = ({
  data,
  onDataChange,
  listErrors,
  visualization,
}: {
  data: ICity;
  onDataChange: (
    field: keyof payload,
    value:
      | string
      | { site: string; email: string; phone: string }
      | { name: string; description: string }
      | { name: string; description: string }[],
  ) => void;
  listErrors: errors;
  visualization: boolean;
}) => {
  //const { data } = props;
  return (
    <C.container>
      <C.space />
      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleTextField>Região</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            value={data.region}
            onChange={e => onDataChange('region', e.target.value)}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {regions.map(item => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </C.boxTextField>
        {listErrors.regionError && (
          <FormHelperText error>Preencha o campo</FormHelperText>
        )}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.boxTextField>
          <C.titleTextField>Cidade</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            value={data.name}
            onChange={e => onDataChange('name', e.target.value)}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {cities.map(item => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </C.boxTextField>
        {listErrors.nameError && (
          <FormHelperText error>Preencha o campo</FormHelperText>
        )}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Nome do Prefeito</C.titleTextField>
        <C.textField
          placeholder="Digite o nome do prefeito"
          value={data.mayor}
          onChange={e => onDataChange('mayor', e.target.value)}
          disabled={visualization}
        />
        {listErrors.mayorError && (
          <FormHelperText error>Preencha o campo</FormHelperText>
        )}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form1;
