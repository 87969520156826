import React, { useState, useEffect } from 'react';
import * as C from './styles';
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { getAccessToken } from '../../../../services/session.service';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/EditRounded';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import {
  themeDialogAlert,
  themeTable,
} from '../../../../styles/theme/themeMui';
import Api from '../../../../api';
import Tooltip from '@mui/material/Tooltip';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

interface Column {
  id: 'name' | 'region' | 'user' | 'actions';
  label: string;
  maxWidth?: number;
  minWidth?: number;
  align?: 'center' | 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome da Cidade' },
  { id: 'region', label: 'Região' },
  { id: 'user', label: 'Usuário' },
  { id: 'actions', label: 'Ações', align: 'center' },
];

const TableCities = ({
  setRefresh,
  setSelectedId,
  filterRegion,
  filterCity,
}: any) => {
  const count = 8;
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(count);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [entityId, setEntityId] = useState('');

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newpage: number,
  ) {
    setpg(newpage);
  }

  const handleDelete = async () => {
    await Api.delete(`/cities/${entityId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setRegisters([]);

    handleLoad();
    setShowDelete(false);
  };

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  const token = getAccessToken();

  const [registers, setRegisters] = useState<any[]>([]);
  const [filteredRegisters, setFilteredRegisters] = useState<any[]>([]);

  useEffect(() => {
    setFilteredRegisters(
      registers?.filter(city => {
        const regionMatch =
          !city.region ||
          !filterRegion ||
          city.region.toLowerCase().includes(filterRegion.toLowerCase());

        const cityMatch = city.name
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return regionMatch && cityMatch;
      }),
    );
  }, [filterRegion, filterCity, registers]);

  const handleLoad = async () => {
    const response = await Api.get('/cities?page=1&size=1000', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setRegisters(response.data.data);
    setPage(response.data.paging.page - 1);
    setRowsCount(response.data.paging.count);
    setRowsPerPage(response.data.paging.count);
  };

  useEffect(() => {
    handleLoad();
  }, []);
  return (
    <C.containerTable>
      <Paper sx={themeTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table_head_desk">
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredRegisters ? (
                filteredRegisters
                  .slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        {columns.map(column => {
                          const value =
                            column.id == 'user'
                              ? row['owner']['name']
                              : row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.id === 'name' ? 'table_cell_click' : ''
                              }
                            >
                              {/* {column.id === 'active' && (
                              <Switch {...label} defaultChecked />
                            )} */}
                              {column.id === 'actions' && (
                                <C.containerButtonsActions>
                                  <Tooltip title="Visualizar">
                                    <IconButton
                                      className="btn_edit"
                                      onClick={() => setSelectedId(row.id)}
                                    >
                                      <VisibilityRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <IconButton
                                    className="btn_edit"
                                    onClick={() => console.log('')}
                                  >
                                    <EditIcon />
                                  </IconButton> */}
                                  <Tooltip title="Excluir">
                                    <IconButton
                                      className="btn_delete"
                                      onClick={() => {
                                        setEntityId(row.id);
                                        setShowDelete(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </C.containerButtonsActions>
                              )}

                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Itens"
          rowsPerPageOptions={[5, 10, 25, 30]}
          component="div"
          labelDisplayedRows={({ from, to, count }) => {
            return from + ' à ' + to + ' de ' + count;
          }}
          count={filteredRegisters.length}
          rowsPerPage={rpg}
          page={pg}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDelete(false)}
        aria-labelledby="customized-dialog-title"
        open={showDelete}
      >
        <C.containerTitle>
          <C.titleDialog>Excluir cidade</C.titleDialog>
          <Button variant="text" onClick={() => setShowDelete(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialog>
            Tem certeza que deseja excluir esta cidade? Essa ação não poderá ser
            desfeita.
          </C.textDialog>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => handleDelete()}
            >
              Excluir
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
    </C.containerTable>
  );
};

export default TableCities;
