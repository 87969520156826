import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import React from 'react';
import { payload, errors } from './Form.Types';
import * as C from './styles';
import { ICity } from '../../../services/cities.service';

const Form2 = ({
  data,
  onDataChange,
  listErrors,
  visualization,
}: {
  data: ICity;
  onDataChange: (
    field: keyof payload,
    value:
      | string
      | { site: string; email: string; phone: string }
      | { name: string; description: string }
      | { name: string; description: string }[],
  ) => void;
  listErrors: errors;
  visualization: boolean;
}) => {
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Detalhes da cidade</C.titleTextField>
        <C.textAreaField
          placeholder="Descreva a cidade"
          className="textaera_master"
          value={data.description}
          onChange={e => onDataChange('description', e.target.value)}
          disabled={visualization}
        />
        {listErrors.descriptionError && (
          <FormHelperText error>Preencha o campo</FormHelperText>
        )}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form2;
