/* eslint-disable no-constant-condition */
import React, { useState, useEffect } from 'react';
import * as C from './styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../context';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { themeDialogAlert } from '../../../styles/theme/themeMui';
import * as UserService from '../../../services/user.service';
import { checkUserFields } from '../../../helpers/account.check.fields';
import { UpdateName } from '../../../context/utils/UpdateRedux';
import { useDispatch } from 'react-redux';

const SettingsUser = () => {
  const infoUser = useSelector((state: RootStore) => state.user);
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [showLoad, setShowLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [formReturnIsSuc, setFormReturnIsSuc] = useState(false);
  const [showPasswordConfirm, setShowPassConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleClickShowPasswordConfirm = () => {
    setShowPassConfirm(!showPasswordConfirm);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleRegister = async () => {
    setShowLoad(true);

    let data = {};

    data = { ...data, email };

    if (name) {
      data = { ...data, name };
    }

    if (password) {
      data = { ...data, password };
    }

    if (passwordConfirm) {
      data = { ...data, confirm: passwordConfirm };
    }
    if (!checkUserFields(data).success) {
      const msgError = checkUserFields(data).message;
      setTimeout(async () => {
        setShowLoad(false);
        setShowButton(false);
        setShowAlertErr(true);
        setMessage(msgError);

        setTimeout(() => {
          setShowAlertErr(false);
          setShowButton(true);
          setMessage('');
        }, 3000);
      }, 2000);
    } else {
      const response = await UserService.Update({
        id: id,
        data: data,
        setSuccess: setFormReturnIsSuc,
      });

      setTimeout(async () => {
        if (response) {
          UpdateName(dispatch, name);
          setShowLoad(false);
          setShowButton(false);
          setShowAlertSuc(true);
          setMessage('Editado com sucesso');
          setTimeout(() => {
            setShowAlertSuc(false);
            setShowButton(true);
            setPassword('');
            setPasswordConfirm('');
            setMessage('');
          }, 2000);
        } else {
          setShowLoad(false);
          setShowButton(false);
          setShowAlertErr(true);
          setMessage('Erro ao editar');

          setTimeout(() => {
            setShowAlertErr(false);
            setShowButton(true);
            setMessage('');
          }, 2000);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (infoUser) {
      setId(infoUser.id);
      setName(infoUser.name);
      setEmail(infoUser.email);
    }
  }, [infoUser]);

  return (
    <C.container>
      <C.containerForm>
        <C.wraper>
          <C.formTitle>Informações pessoais</C.formTitle>
          <C.textFieldWraper50>
            <C.boxTextField>
              <C.titleTextField>Nome Completo</C.titleTextField>
              <C.textField
                value={name}
                placeholder="Nome completo"
                onChange={e => setName(e.target.value)}
              />
            </C.boxTextField>
            <C.boxTextField>
              <C.titleTextField>E-mail</C.titleTextField>
              <C.textField
                value={email}
                disabled
                placeholder="E-mail"
                onChange={e => setEmail(e.target.value)}
              />
            </C.boxTextField>
          </C.textFieldWraper50>
        </C.wraper>

        <C.wraper>
          <C.formTitle>Alterar Senha</C.formTitle>
          <C.textFieldWraper50>
            <C.boxTextField50>
              <C.titleTextField>Nova senha</C.titleTextField>
              <C.textField
                value={password}
                placeholder="Nova senha"
                onChange={e => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </C.boxTextField50>
          </C.textFieldWraper50>
          <C.infoTextField>
            Sua senha deve conter no mínimo 6 caracteres incluindo um número
          </C.infoTextField>
          <C.textFieldWraper50>
            <C.boxTextField50>
              <C.titleTextField>Confirmar senha</C.titleTextField>
              <C.textField
                value={passwordConfirm}
                placeholder="Confirmar senha"
                onChange={e => setPasswordConfirm(e.target.value)}
                type={showPasswordConfirm ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPasswordConfirm}
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </C.boxTextField50>
          </C.textFieldWraper50>
        </C.wraper>
        {showButton ? (
          <div className="container_buttons">
            <Button
              variant="contained"
              className="btn_save"
              onClick={handleRegister}
              style={{ width: 300 }}
            >
              {showLoad ? (
                <CircularProgress size={34} color={'secondary'} />
              ) : (
                'Salvar alterações'
              )}
            </Button>
          </div>
        ) : null}

        {showAlertErr ? (
          <Alert variant="outlined" severity="error" className="alert">
            {message}
          </Alert>
        ) : null}

        {showAlertSuc ? (
          <Alert variant="outlined" severity="success" className="alert">
            {message}
          </Alert>
        ) : null}
        <div className="container_buttons">
          <Button
            variant="outlined"
            className="btn_exclud"
            disabled={showLoad}
            onClick={() => setShowDialog(true)}
          >
            Encerrar conta
          </Button>
        </div>
      </C.containerForm>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
      >
        <C.containerTitle>
          <C.titleDialog>Encerar Conta</C.titleDialog>
          <Button variant="text" onClick={() => setShowDialog(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialog>
            Tem certeza que deseja encerrar a sua conta? Essa ação não poderá
            ser desfeita.
          </C.textDialog>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowDialog(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => setShowDialog(false)}
            >
              Confirmar
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
    </C.container>
  );
};

export default SettingsUser;
