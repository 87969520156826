import styled from 'styled-components';

export const containerLogo = styled.div`
  display: flex;
  height: 235px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V1};
  box-shadow: 0px 4px 4px rgba(142, 141, 208, 0.16);
  transition: all 0.2s ease-in-out;
  margin: 0 auto;

  .logo {
    height: 235px;
    background-size: cover;
    background-position: center;
  }

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
`;
