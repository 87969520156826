import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as C from './styles';
import Switch from '@mui/material/Switch';
import { IconButton, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { IMines } from '../../../../interfaces/mine.interface';
import Details from '../Details';
interface ICard {
  listPendeng: IMines;
  setButtonScroll: Dispatch<SetStateAction<boolean>>;
}
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const CardActive = ({ listPendeng, setButtonScroll }: ICard) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [registerId, setRegisterId] = useState('');
  const [registerCategory, setRegisterCotegory] = useState('');
  useEffect(() => {
    setButtonScroll(!showEdit);
  }, [showEdit]);
  const handleEdit = (id: string, category: string) => {
    setRegisterId(id);
    setShowEdit(!showEdit);
    setRegisterCotegory(category);
  };
  return (
    <>
      {listPendeng.data?.map((data, index) => {
        return (
          <C.card key={index}>
            <C.title>Tipo do Cadastro</C.title>
            <C.subTitle>{data.type}</C.subTitle>
            <C.containerInfos>
              <div>
                <span className="info">Nome do Cadastro</span>
                <p>{data.name}</p>
              </div>
              <div>
                <span className="info2">Ativo</span>
                <div>
                  <Switch {...label} defaultChecked />
                </div>
              </div>
              <div>
                <label>Detalhes</label>
                <MenuItem>Visualizar</MenuItem>
              </div>
            </C.containerInfos>
            <C.containerButtonsActions>
              <IconButton
                onClick={() => {
                  handleEdit(data.id, data.category);
                }}
                className="btn_edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton className="btn_delete">
                <DeleteIcon />
              </IconButton>
              <IconButton className="btn_transfer">
                <AutorenewRoundedIcon />
              </IconButton>
            </C.containerButtonsActions>
          </C.card>
        );
      })}
      {showEdit ? (
        <Details
          id={registerId}
          showModal={showEdit}
          setShowModal={setShowEdit}
          category={registerCategory}
        />
      ) : null}
    </>
  );
};

export default CardActive;
