import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as C from './styles';
import { Button } from '@mui/material';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Mentores from '../../../assets/mentores.png';
import { type } from '../../../interfaces/card.interface';
import NavbarMobile from '../../../components/NavbarMobile';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterModal from '../../../components/Mentors/Filter';
import AddModal from '../../../components/Mentors/Add';
import { isLogged } from '../../../services/session.service';
import * as MentorService from '../../../services/mentor.service';
import { IGetMentor, IListMentor } from '../../../interfaces/mentor.interface';
import Card from '../../../components/Card';

const Mentors = () => {
  const navigation = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [mentors, setMentors] = useState<IGetMentor>();
  const [filterName, setFilterName] = useState('');
  const [filterGenre, setFilterGenre] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filteredMentors, setFilteredMentors] = useState<IListMentor[]>();

  const getMentors = async () => {
    const response: IGetMentor | undefined = await MentorService.Get();
    setMentors(response);
  };

  const handleAdd = () => {
    if (isLogged()) {
      setShowAdd(!showAdd);
    } else {
      navigation('/login');
    }
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    setFilteredMentors(
      mentors?.data.filter(mentor => {
        const nameMatch = mentor.name
          .toLowerCase()
          .includes(filterName.toLowerCase());
        const genreMatch = mentor.genre
          .toLowerCase()
          .includes(filterGenre.toLowerCase());
        // const titleMatch = mentor.title
        //   .toLowerCase()
        //   .includes(filterTitle.toLowerCase());
        const cityMatch = mentor.city
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return nameMatch && genreMatch && cityMatch;
      }),
    );
  }, [filterName, filterGenre, filterTitle, filterCity, mentors]);

  useEffect(() => {
    getMentors();
  }, []);

  return (
    <C.container>
      <Navbar secondary={false} logged={isLogged()} />
      <NavbarMobile />
      <C.wraper>
        <C.containerFilters>
          <C.filters>
            <C.title>Banco de Mentores</C.title>
            <C.containerTextField>
              <C.textFieldWraper>
                <SearchRoundedIcon />
                <C.textField
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                />
              </C.textFieldWraper>
              <Button variant="contained" onClick={handleFilters}>
                Filtros
              </Button>
            </C.containerTextField>
            <Button
              variant="contained"
              className="btn_add_startup"
              onClick={handleAdd}
            >
              Cadastrar Mentor
            </Button>
          </C.filters>

          <Button
            variant="contained"
            className="btn_mobile"
            onClick={handleFilters}
          >
            Filtros
          </Button>

          <C.containerButtonAdd>
            <Button
              variant="contained"
              className="btn_mobile"
              onClick={handleAdd}
            >
              Cadastrar Mentor
            </Button>
          </C.containerButtonAdd>
        </C.containerFilters>

        <C.containerImage>
          <C.image>
            <img src={Mentores} alt="Mentor" />
          </C.image>
        </C.containerImage>
      </C.wraper>
      <C.fillEfect />
      <C.wraperTwo>
        <C.containerCards>
          {filteredMentors?.map(item => (
            <Card
              key={item.id}
              name={item.name}
              title={item.title}
              city={item.city}
              type={type.First}
              id={item.id}
              logoId={item.logoId}
              path="detalhes-mentor"
            />
          ))}
        </C.containerCards>
      </C.wraperTwo>

      <FilterModal
        show={showFilter}
        state={showFilter}
        setState={setShowFilter}
        setFilterName={setFilterName}
        setFilterGenre={setFilterGenre}
        setFilterTitle={setFilterTitle}
        setFilterCity={setFilterCity}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <Footer />
    </C.container>
  );
};

export default Mentors;
