import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as C from './styles';
import { Button } from '@mui/material';
import Card from '../../../components/Card';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Rocket from '../../../assets/foguete.png';
import { type } from '../../../interfaces/card.interface';
import { AddModal } from '../../../components/Startups/Add';
import NavbarMobile from '../../../components/NavbarMobile';
import FilterModal from '../../../components/Startups/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import * as StatupService from '../../../services/startup.service';
import { IFormdata } from '../../../interfaces/startup.interface';
import { isLogged } from '../../../services/session.service';

const Startups = () => {
  const navigation = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [startups, setStartups] = useState<IFormdata[]>();
  const [filteredStartups, setFilteredStartups] = useState<IFormdata[]>();
  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterSegment, setFilterSegment] = useState('');
  const [filterStage, setFilterStage] = useState('');

  const handleAdd = () => {
    if (isLogged()) {
      setShowAdd(!showAdd);
    } else {
      navigation('/login');
    }
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };
  const getStatups = async () => {
    const response: IFormdata[] | undefined = await StatupService.GetAll();
    setStartups(response);
  };

  useEffect(() => {
    setFilteredStartups(
      startups?.filter(startup => {
        const nameMatch = startup.name
          .toLowerCase()
          .includes(filterName.toLowerCase());
        const typeMatch = startup.businessType
          .toLowerCase()
          .includes(filterType.toLowerCase());
        const modelMatch = startup.businessModel
          .toLowerCase()
          .includes(filterModel.toLowerCase());
        const StageMatch = startup.businessStage
          ?.toLowerCase()
          .includes(filterStage.toLowerCase());
        const segmentMatch = startup.businessSegment
          ?.toLowerCase()
          .includes(filterSegment.toLowerCase());
        const cityMatch = startup.address.city
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return (
          nameMatch &&
          typeMatch &&
          modelMatch &&
          StageMatch &&
          segmentMatch &&
          cityMatch
        );
      }),
    );
  }, [
    filterName,
    filterType,
    filterCity,
    filterSegment,
    filterStage,
    filterModel,
    startups,
  ]);

  useEffect(() => {
    getStatups();
    window.scrollTo(0, 0);
  }, []);

  return (
    <C.container>
      <Navbar secondary={false} logged={isLogged()} />
      <NavbarMobile />
      <C.wraper>
        <C.containerFilters>
          <C.filters>
            <C.title>Startups</C.title>
            <C.containerTextField>
              <C.textFieldWraper>
                <SearchRoundedIcon />
                <C.textField
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                />
              </C.textFieldWraper>
              <Button variant="contained" onClick={handleFilters}>
                Filtros
              </Button>
            </C.containerTextField>
            <Button
              variant="contained"
              className="btn_add_startup"
              onClick={handleAdd}
            >
              Cadastrar Startup
            </Button>
          </C.filters>

          <Button
            variant="contained"
            className="btn_mobile"
            onClick={handleFilters}
          >
            Filtros
          </Button>

          <C.containerButtonAdd>
            <Button
              variant="contained"
              className="btn_mobile"
              onClick={handleAdd}
            >
              Cadastrar Startup
            </Button>
          </C.containerButtonAdd>
        </C.containerFilters>

        <C.containerImage>
          <C.image>
            <img src={Rocket} alt="Rocket" />
          </C.image>
        </C.containerImage>
      </C.wraper>
      <C.fillEfect />
      <C.wraperTwo>
        <C.containerCards>
          {filteredStartups?.map(item => (
            <Card
              key={item.id}
              id={item.id ? item.id : ''}
              type={type.First}
              title={item.businessSegment}
              name={item.name}
              city={item.shortDescription}
              path="detalhes-startup"
              logoId={item.logoId}
            />
          ))}
        </C.containerCards>
      </C.wraperTwo>
      <FilterModal
        state={showFilter}
        setState={setShowFilter}
        setFilterCity={setFilterCity}
        setFilterModel={setFilterModel}
        setFilterName={setFilterName}
        setFilterSegment={setFilterSegment}
        setFilterStage={setFilterStage}
        setFilterType={setFilterType}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <Footer />
    </C.container>
  );
};

export default Startups;
