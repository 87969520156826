import React from 'react';
import * as C from './styles';
import { useState, useEffect } from 'react';
import Form1 from '../forms/form1';
import Form2 from '../forms/form2';
import Form3 from '../forms/form3';
import Form4 from '../forms/form4';
import Form6 from '../forms/form6';
import Form5 from '../forms/form5';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/DoneAllRounded';
import { themeDialog } from '../../../styles/theme/themeMui';
import { IAddStartup } from '../../../interfaces/startup.interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddReactionRoundedIcon from '@mui/icons-material/AddReactionRounded';
import { useFormik } from 'formik';
import { IMentor } from '../../../interfaces/mentor.interface';
import { checkMentorFields } from '../../../helpers/account.check.fields';
import * as MentorService from '../../../services/mentor.service';
import * as ImageService from '../../../services/image.service';

export const AddModal = ({
  setShow,
  show,
  id,
  refresh,
  visualization = false,
}: IAddStartup) => {
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [index, setIndex] = useState(1);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [form4, setForm4] = useState(false);
  const [form5, setForm5] = useState(false);
  const [form6, setForm6] = useState(false);
  const [notReturn, setNotReturn] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const initialValues: IMentor = {
    id: '',
    name: '',
    title: '',
    genre: '',
    description: '',
    businessSegment: '',
    city: '',
    contact: {
      site: '',
      email: '',
      phone: '',
    },
    socialMedia: {
      instagram: '',
      facebook: '',
      linkedin: '',
      youtube: '',
    },
    skills: [],
    mentorship: [],
    logoId: null,
    bannerId: null,
    imagePath: null,
    bannerPath: null,
    imageSelected: false,
    bannerSelected: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      // Handle Submit
    },
  });

  const handleNext = () => {
    if (!checkMentorFields(formik.values, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);
        setErrMessage(checkMentorFields(formik.values, index).message);

        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      if (index === 1) {
        setIndex(2);
        setForm1(false);
        setForm2(true);
      }
      if (index === 2) {
        setIndex(3);
        setForm2(false);
        setForm3(true);
      }
      if (index === 3) {
        setIndex(4);
        setForm3(false);
        setForm4(true);
      }
      if (index === 4) {
        setIndex(5);
        setForm4(false);
        setForm5(true);
      }
      if (index === 5) {
        setIndex(6);
        setForm5(false);
        setForm6(true);
      }
    }
  };

  const handlePrev = () => {
    if (index === 2) {
      setIndex(1);
      setForm1(true);
      setForm2(false);
    }
    if (index === 3) {
      setIndex(2);
      setForm2(true);
      setForm3(false);
    }
    if (index === 4) {
      setIndex(3);
      setForm4(false);
      setForm3(true);
    }
    if (index === 5) {
      setIndex(4);
      setForm5(false);
      setForm4(true);
    }
    if (index === 6) {
      setIndex(5);
      setForm6(false);
      setForm5(true);
    }
  };

  const handleConcluded = async () => {
    const data = {
      id: id,
      name: formik.values.name,
      title: formik.values.title,
      genre: formik.values.genre,
      description: formik.values.description,
      businessSegment: formik.values.businessSegment,
      city: formik.values.city,
      contact: formik.values.contact,
      socialMedia: formik.values.socialMedia,
      skills: formik.values.skills,
      mentorship: formik.values.mentorship,
      bannerId: formik.values.bannerId,
      logoId: formik.values.logoId,
    };

    if (!checkMentorFields(formik.values, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);
        setErrMessage(checkMentorFields(formik.values, index).message);
        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      setForm6(false);
      setShowConcluded(true);
      setNotReturn(true);

      if (typeof data.logoId != 'string') {
        if (data.logoId) {
          const response = await ImageService.Create(data.logoId);
          if (response.success) {
            data.logoId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com a logo. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm6(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }
      }

      if (typeof data.bannerId != 'string')
        if (data.bannerId) {
          const response = await ImageService.Create(data.bannerId);
          if (response.success) {
            data.bannerId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com o banner. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm6(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }

      let success = false;
      if (isEditing) {
        //serviço de edição
        success = await MentorService.Update(data);
      } else {
        success = await MentorService.Create(data);
      }
      if (success) {
        if (isEditing && refresh) {
          refresh();
        }
        setSuccess(true);
        setTitle('Tudo\nPronto!');
        setSubOne(
          'Obrigada pela sua colaboração!  Assim que seu conteúdo for aprovado, ele ficará disponível para consulta no site.',
        );
        setSubTwo('');
        setTimeout(() => {
          handleClose();
        }, 4000);
      } else {
        setSuccess(false);
        setTitle('Erro');
        setSubOne(
          'Sentimos muito, porém nosso servidor não conseguiu gravar as informações. A situação deverá ser resolvida em breve.',
        );
        setSubTwo(
          'Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte.',
        );
        setTimeout(() => {
          setForm6(true);
          setNotReturn(false);
          setShowConcluded(false);
          setTitle('');
          setSubOne('');
          setSubTwo('');
        }, 5000);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setForm1(true);
    setForm2(false);
    setForm3(false);
    setForm4(false);
    setForm5(false);
    setForm6(false);

    formik.setValues({
      id: '',
      name: '',
      title: '',
      genre: '',
      description: '',
      businessSegment: '',
      city: '',
      contact: {
        site: '',
        email: '',
        phone: '',
      },
      socialMedia: {
        instagram: '',
        facebook: '',
        linkedin: '',
        youtube: '',
      },
      skills: [],
      mentorship: [],
      bannerId: null,
      bannerPath: null,
      bannerSelected: false,
      logoId: null,
      imagePath: null,
      imageSelected: false,
    });

    setShowConcluded(false);
    setIndex(1);
  };

  const setInfos = async (id: string) => {
    const response = await MentorService.GetById(id);
    if (response) {
      formik.setValues({
        id: id,
        name: response.name,
        title: response.title,
        genre: response.genre,
        description: response.description,
        businessSegment: response.businessSegment,
        city: response.city,
        contact: {
          site: response.contact.site,
          email: response.contact.email,
          phone: response.contact.phone,
        },
        socialMedia: {
          instagram: response.socialMedia.instagram,
          facebook: response.socialMedia.facebook,
          linkedin: response.socialMedia.linkedin,
          youtube: response.socialMedia.youtube,
        },
        skills: response.skills,
        mentorship: response.mentorship,
        bannerId: response.bannerId,
        bannerPath:
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.bannerId,
        bannerSelected: response.bannerId ? true : false,
        logoId: response.logoId,
        imagePath:
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.logoId,
        imageSelected: response.logoId ? true : false,
      });
    }
  };

  useEffect(() => {
    if (id) {
      setInfos(id);
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>
            <AddReactionRoundedIcon />
            {isEditing ? 'Edição de Mentor' : 'Cadastro de Mentor'}
          </C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {form1 && (
            <Form1
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form2 && (
            <Form2
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form3 && (
            <Form3
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
              message={errMessage}
            />
          )}
          {form4 && (
            <Form4
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form5 && (
            <Form5
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form6 && (
            <Form6
              {...formik}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}

          {/* {showLoad && <CircularProgress size={30} />} */}
          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>
        <C.containerDotsMobile>
          <C.dot disabled={form1} />
          <C.dot disabled={form2} />
          <C.dot disabled={form3} />
          <C.dot disabled={form4} />
          <C.dot disabled={form5} />
          <C.dot disabled={form6} />
        </C.containerDotsMobile>
        <C.containerButtons disabled={form1}>
          <Button
            variant="outlined"
            className="btn_previus"
            disabled={form1 || notReturn}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
            Anterior
          </Button>
          <C.containerDots>
            <C.dot disabled={form1} />
            <C.dot disabled={form2} />
            <C.dot disabled={form3} />
            <C.dot disabled={form4} />
            <C.dot disabled={form5} />
            <C.dot disabled={form6} />
          </C.containerDots>
          {form6 || showConcluded ? (
            <Button
              variant="outlined"
              className="btn_send"
              onClick={handleConcluded}
              disabled={!form6 || visualization}
            >
              Enviar
              <SendIcon />
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="btn_next"
              onClick={handleNext}
            >
              Próximo
              <ArrowNextIcon />
            </Button>
          )}
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default AddModal;
