import React, { useState } from 'react';
import * as C from './styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import { IFilterInnovation } from '../../../interfaces/filter.interface';
import { cities, innovationTypes } from '../../../helpers/options';

const FilterModal = ({
  setState,
  state,
  setFilterCity,
  setFilterType,
  setFilterName,
}: IFilterInnovation) => {
  const handleClose = () => {
    setState(false);
  };

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [city, setCity] = useState('');

  const clearStates = () => {
    setName('');
    setType('');
    setCity('');
  };

  const clearFilters = () => {
    setFilterCity('');
    setFilterType('');
    setFilterName('');
    clearStates();
    setState(false);
  };

  const applyFilters = () => {
    setFilterCity(city);
    setFilterType(type);
    setFilterName(name);

    setState(false);
  };

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state}
      >
        <C.containerTitle>
          <C.title>Filtrar</C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Nome</C.titleTextField>
                <C.textField
                  placeholder="Nome da instituição"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>Tipo</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={type}
                  onChange={e => setType(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Tipos</em>
                  </MenuItem>
                  {innovationTypes.map(type => {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
            <C.textFieldWraper50>
              {/* <C.boxTextField>
                <C.titleTextField>Região</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas as Regiões</em>
                  </MenuItem>
                  <MenuItem value={10}>Teste</MenuItem>
                </Select>
              </C.boxTextField> */}
              <C.boxTextField>
                <C.titleTextField>Cidade</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {cities.map(city => {
                    return (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>
        </DialogContent>

        <C.containerButtons>
          <Button
            variant="outlined"
            className="btn_clear"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            className="btn_apply"
            onClick={applyFilters}
          >
            Aplicar Filtros
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default FilterModal;
