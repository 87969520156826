import React, { useState, useEffect } from 'react';
import * as C from './styles';
import SlideMenu from '../Slide';
import TableActive from './tableActive';
import TablePendeng from './tablePendeng';
import TableAnalyze from './tableAnalyze';
import { Button, IconButton } from '@mui/material';
import NavbarAdmin from '../../../components/NavbarAdmin';
import FilterModal from '../../../components/Startups/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Details from '../../User/list/Details';

const AdminStartups = () => {
  const [value, setValue] = React.useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterSegment, setFilterSegment] = useState('');
  const [filterStage, setFilterStage] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    }
  }, [refresh]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVisualize = (id: string) => {
    setSelectedId(id);
    setShow(true);
  };

  return (
    <C.container>
      <C.wraper>
        <NavbarAdmin secondary={true} />
        <C.header>
          <C.headerWraper>
            <C.containerFiltersHeader>
              <C.filters>
                <C.title>Startups</C.title>
                <C.containerTextField>
                  <C.textFieldWraper>
                    <SearchRoundedIcon />
                    <C.textField
                      placeholder="Nome da Startup"
                      value={filterName}
                      onChange={e => setFilterName(e.target.value)}
                    />
                  </C.textFieldWraper>
                  <Button variant="contained" onClick={handleFilters}>
                    Filtros
                  </Button>
                </C.containerTextField>
              </C.filters>
            </C.containerFiltersHeader>
          </C.headerWraper>
        </C.header>
        <SlideMenu currentStep={0} />
        <C.containerTables>
          <TablePendeng
            setSelectedId={handleVisualize}
            setRefresh={setRefresh}
            filterName={filterName}
            filterType={filterType}
            filterModel={filterModel}
            filterStage={filterStage}
            filterSegment={filterSegment}
            filterCity={filterCity}
          />
          <TableActive
            setSelectedId={handleVisualize}
            refresh={refresh}
            filterName={filterName}
            filterType={filterType}
            filterModel={filterModel}
            filterStage={filterStage}
            filterSegment={filterSegment}
            filterCity={filterCity}
          />
          <TableAnalyze
            setSelectedId={handleVisualize}
            refresh={refresh}
            filterName={filterName}
            filterType={filterType}
            filterModel={filterModel}
            filterStage={filterStage}
            filterSegment={filterSegment}
            filterCity={filterCity}
          />
        </C.containerTables>
      </C.wraper>
      <FilterModal
        state={showFilter}
        setState={setShowFilter}
        setFilterCity={setFilterCity}
        setFilterModel={setFilterModel}
        setFilterName={setFilterName}
        setFilterSegment={setFilterSegment}
        setFilterStage={setFilterStage}
        setFilterType={setFilterType}
      />
      {show && (
        <Details
          visualization={true}
          category="startup"
          showModal={show}
          setShowModal={setShow}
          id={selectedId}
        />
      )}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default AdminStartups;
