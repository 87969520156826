import React, { useState, useRef } from 'react';
import * as C from './styles';
import { ItensSlide } from './itens.slide';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeftRounded';
import ArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';

const SlideMenu = ({ currentStep = 0 }) => {
  const navigation = useNavigate();
  const newRef = useRef<HTMLDivElement | null>(null);
  const Iref = useRef<HTMLDivElement | null>(null);
  const myRefs = useRef<HTMLLIElement[] | null[]>([]);
  const [activeStep, setActiveStep] = useState(currentStep);
  const maxSteps = ItensSlide.length;

  const handleNext = () => {
    if (newRef.current) {
      newRef.current.scrollLeft += 100;
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    navigation(ItensSlide[activeStep + 1].path);
  };

  const handleBack = () => {
    if (newRef.current) {
      newRef.current.scrollLeft -= 120;
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    navigation(ItensSlide[activeStep - 1].path);
  };

  return (
    <C.containerSlide>
      <C.slide>
        <IconButton
          aria-label="delete"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          <ArrowLeft />
        </IconButton>
        <C.containerActions ref={newRef}>
          {ItensSlide.map((item, index) => (
            <C.action
              key={index}
              active={index === activeStep ? true : false}
              ref={Iref}
            >
              <MenuItem
                ref={el => (myRefs.current[index] = el)}
                onClick={() => navigation(item.path)}
              >
                {item.name}
              </MenuItem>
            </C.action>
          ))}
        </C.containerActions>
        <IconButton
          aria-label="delete"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          <ArrowRight />
        </IconButton>
      </C.slide>
    </C.containerSlide>
  );
};

export default SlideMenu;
