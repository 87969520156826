export const MaskCEP = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};
export const MaskPhone = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})/, '$1-$2');
};

export const maskValue = (value: string | number) => {
  if (typeof value == 'string') {
    const cleanedValue = value.replace(/\D/g, '');

    const formattedValue = (Number(cleanedValue) / 100).toLocaleString(
      'pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      },
    );
    return formattedValue;
  } else {
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return formattedValue;
  }
};

export const unmaskValue = (value: string) => {
  const cleanedValue = value.replace(/\D/g, '');

  const formattedValue = Number(cleanedValue) / 100;

  return formattedValue;
};

export const MaskCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const MaskOnlyLetters = (value: string) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');
};

export const MaskOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '');
};

export const MaskCNPJ = (val: string) => {
  const x = val
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  if (x) {
    val = !x[2]
      ? x[1]
      : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
  }

  return val;
};
