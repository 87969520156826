import styled from 'styled-components';

export const container = styled.div<{ isSecondary: boolean | undefined }>`
  width: 100%;
  top: 0;
  z-index: 1100;

  background-color: transparent;
  position: relative;
  .popper_menu {
    font-family: 'Montserrat';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  }

  .MuiButtonBase-root {
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif !important;
    &:hover {
      background-color: none !important ;
    }
    &:focus {
      background-color: none !important;
    }
  }

  @media only screen and (max-width: 1090px) {
    display: none;
  }
`;

export const wraper = styled.div`
  padding: 20px;
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
  align-items: center;
  justify-content: space-between;
`;

export const containerLogo = styled.div`
  width: 100%;
  display: flex;
  padding-left: 50px;
  align-items: flex-start;
`;

export const logo = styled.img`
  max-height: 80px;
`;

export const containerLinks = styled.div<{ isSecondary: boolean | undefined }>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  .MuiButton-text {
    background-color: transparent !important;
    text-transform: none;

    color: #fff;
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    padding: 1%;

    span {
      font-weight: 400 !important;
    }
  }
`;

export const spanDrop = styled.span<{ isSecondary: boolean | undefined }>`
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 8px;
  color: '#fff';
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  &:hover {
    .dropDown {
      opacity: 100%;
    }
  }
  .dropDown {
    width: 250px;
    height: 400px;
    opacity: 0;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.6s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  svg {
    color: #fff;
  }
`;

export const containerButton = styled.div<{ isSecondary: boolean | undefined }>`
  width: 30%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .MuiButton-text {
    font-weight: 700;
    padding: 5px 35px;
    border-radius: 50px;
    text-transform: none !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    font-family: 'Montserrat' !important;
    border: 2px solid #fff;
    color: #fff;
  }

  svg {
    font-size: 32px;
    color: #fff;
  }

  .MuiButton-text {
    font-weight: 700;
    padding: 5px 35px;
    border-radius: 50px;
    text-transform: none !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    font-family: 'Montserrat' !important;
    border: 2px solid ${props => (props.isSecondary ? '#fff' : '#D4FBF1')};
    color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};
  }
`;

export const dotNotification = styled.div`
  width: 13px;
  right: 12px;
  top: 12px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  background-color: ${({ theme }) => theme.COLORS.RED};
`;

export const itemNotification = styled.div`
  width: 100%;
  padding: 20px;
  cursor: pointer;
  max-width: 260px;
  transition: all 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.GREY_V3};
  }
`;

export const textNotification = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
