import React, { useState } from 'react';
import * as C from './styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import { IFilterEvent } from '../../../interfaces/filter.interface';
import { cities } from '../../../helpers/options';

const FilterModal = ({
  show,
  setName,
  setCity,
  setFee,
  setShow,
}: IFilterEvent) => {
  const [filterName, setFilterName] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterFee, setFilterFee] = useState('');

  const clearFilters = () => {
    setFilterName('');
    setFilterCity('');
    setFilterFee('');
    setCity('');
    setFee(true);
    setShow(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFilterCity(event.target.value);
  };
  const handleChangeFee = (event: SelectChangeEvent) => {
    setFilterFee(event.target.value);
  };

  const handleSetFilters = () => {
    setName(filterName);
    setCity(filterCity);
    setFee(filterFee == '1' ? false : true);
    setShow(false);
  };

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        {/* <TitleModal id="customized-dialog-title" onClose={handleClose}>
          Filtrar
        </TitleModal> */}
        <C.containerTitle>
          <C.title>Filtrar</C.title>

          <Button variant="text" onClick={clearFilters}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper100>
              <C.boxTextField>
                <C.titleTextField>Cidade</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={filterCity}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {cities.map(city => {
                    return (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
                </Select>
              </C.boxTextField>
            </C.textFieldWraper100>
            {/* <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Região</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={10}>Teste</MenuItem>
                </Select>
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>Custo</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={10}>Teste</MenuItem>
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50> */}
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>Custo</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  onChange={handleChangeFee}
                  value={filterFee}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={'1'}>Gratuito</MenuItem>
                  <MenuItem value={'2'}>Todos</MenuItem>
                </Select>
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>
        </DialogContent>

        <C.containerButtons>
          <Button
            variant="outlined"
            className="btn_clear"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            className="btn_apply"
            onClick={handleSetFilters}
          >
            Aplicar Filtros
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default FilterModal;
