import Api from '../api';
import { IResponse } from '../interfaces/image.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(file: any): Promise<IResponse> {
  const response: IResponse = {
    uid: '',
    success: false,
  };
  const token = getAccessToken();

  const newImage: File = file;
  const formData = new FormData();
  formData.append('file', newImage);

  await Api.post('/files', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(res => {
      response.success = true;
      response.uid = res.data;
    })
    .catch(error => {
      console.log(error);
      response.success = false;
    });

  return response;
}
