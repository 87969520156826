import React, { useEffect, useState } from 'react';
import * as C from './styles';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as geojson from 'geojson';
// import regions from './regions.json';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import * as DashboardMetrics from '../../services/metrics.service';
import { ILocation } from '../../interfaces/home.interface';

interface IMap {
  data: ILocation[];
}

interface IFeatures {
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
}
interface IObjects {
  type: string;
  features: IFeatures[];
}

const Map = () => {
  const [regions, setRegions] = useState<IObjects>();
  const [locations, setLocations] = useState<ILocation[]>([]);

  const getMetricsLocation = async () => {
    const response = await DashboardMetrics.GetLocations();
    if (response) {
      setLocations(response);
      const points = {
        type: 'FeatureCollection',
        features: response
          .filter(f => f.category == 'startup')
          .map(e => {
            const point = {
              type: 'Feature',
              properties: {
                name: e.name,
              },
              geometry: {
                type: 'Point',
                coordinates: [e.longitude, e.latitude],
              },
            };
            return point;
          }),
      };
      map(points);
    }
  };

  useEffect(() => {
    getMetricsLocation();
  }, []);

  const defaultIcon = L.icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
    iconSize: [25, 41],
    className: 'leaflet-marker-icon',
  });

  const map = (points: IObjects) => {
    //logica para previnir erro: map container is already initialized
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container: any = L.DomUtil.get('map');
    if (container != null) {
      if (container._leaflet_id) {
        return;
      }
    }

    const map = L.map('map', {
      scrollWheelZoom: true,
      dragging: true,
    }).setView([-30.6046, -53.2958], 6);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      minZoom: 6,
      maxZoom: 15,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    L.geoJSON(points as geojson.FeatureCollection, {
      style: {
        fillColor: '#26d2a9',
        fill: true,
        stroke: true,
        opacity: 1,
        color: '#26d2a9',
      },
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, { icon: defaultIcon })
          .bindPopup(feature.properties['name'])
          .openPopup();
      },
    }).addTo(map);
  };

  return (
    <C.container>
      <C.image>
        <div className="map" id="map"></div>
      </C.image>
    </C.container>
  );
};

export default Map;
