import { ICompany } from '../interfaces/company.interface';
import { IEvent } from '../interfaces/event.interface';
import { IInnovationEnvironment } from '../interfaces/innovationEnvironment.interface';
import { IMentor } from '../interfaces/mentor.interface';
import { ITechnologyPark } from '../interfaces/technologyParks.interface';
import { IFormdataEdit, IFormdataTwo } from '../interfaces/user.interface';
import { validateCNPJ } from './validate.cnpj';
import { validateEmail } from './validate.email';
import validatePassword from './validate.password';
import { validateURL } from './validate.url';

export const checkUserFields = (data: any) => {
  let message = '';
  let success = true;

  if (!data.name) {
    message = 'Preencha o campo Nome';
    success = false;
    return { message, success };
  }

  if (!data.password || !data.confirm) {
    const verify = validatePassword(data.password, data.confirm);
    if (!verify.status) {
      message = verify.message;
      success = verify.status;
      return { message, success };
    }
  }

  return { message, success };
};

export const checkUserFieldsTwo = (data: IFormdataTwo) => {
  let message = '';
  let success = true;

  if (
    !data.name &&
    !data.email &&
    !data.password &&
    !data.confirm &&
    !data.role
  ) {
    message = 'Preencha todos os campos';
    success = false;
    return { message, success };
  }

  if (!data.name) {
    message = 'Preencha o campo Nome';
    success = false;
    return { message, success };
  }

  if (!data.email) {
    message = 'Preencha o campo E-mail';
    success = false;
    return { message, success };
  }

  if (!data.role) {
    message = 'Defina o tipo de usuário';
    success = false;
    return { message, success };
  }
  if (data.email) {
    if (!validateEmail(data.email)) {
      message = 'O E-mail informado é inválido';
      success = false;
      return { message, success };
    }
  }

  if (data.password && data.confirm) {
    const verify = validatePassword(data.password, data.confirm);
    if (!verify.status) {
      message = verify.message;
      success = verify.status;
      return { message, success };
    }
  }

  if (!data.password) {
    message = 'Preencha o campo Senha';
    success = false;
    return { message, success };
  }

  if (!data.confirm) {
    message = 'Preencha o campo Repetir Senha';
    success = false;
    return { message, success };
  }

  return { message, success };
};

export const checkEditUserFields = (data: IFormdataEdit) => {
  let message = '';
  let success = true;

  if (!data.name) {
    message = 'Preencha o campo Nome';
    success = false;
    return { message, success };
  }

  if (!data.email) {
    message = 'Preencha o campo E-mail';
    success = false;
    return { message, success };
  }
  if (data.email) {
    if (!validateEmail(data.email)) {
      message = 'O E-mail informado é inválido';
      success = false;
      return { message, success };
    }
  }

  return { message, success };
};

export const checkEventFields = (
  data: IEvent,
  index: number,
): { message: string; success: boolean } => {
  let message = '';
  let success = true;

  switch (index) {
    case 1:
      if (!data.name || !data.date) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      if (new Date(data.date) < new Date()) {
        message = 'Data do evento não pode ser menor do que data atual';
        success = false;
      }
      break;
    case 2:
      if (!data.location || !data.description || !data.city) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      if (data.admissionCharged === 1 && !data.fee) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 3:
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }
      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }
      break;
    default:
      message = 'Index inválido';
      success = false;
  }

  return { message, success };
};

function validPhone(phone: string): boolean {
  const cleanPhone = phone.replace(/\D/g, '');

  const isMobile =
    /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );

  if (isMobile) {
    return /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/.test(
      cleanPhone,
    );
  } else {
    return /^([1-9]{2})\s?[2-5|7-9][0-9]{7}$/.test(cleanPhone);
  }
}
export const checkMentorFields = (
  data: IMentor,
  index: number,
): { message: string; success: boolean } => {
  let message = '';
  let success = true;

  switch (index) {
    case 1:
      if (!data.name || !data.title || !data.genre || !data.businessSegment) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 2:
      if (!data.description) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 3:
      if (!data.city) {
        success = false;
        message = `Cidade é Obrigatória`;

        break;
      }
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }

      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }

      if (data.socialMedia?.instagram) {
        if (!validateURL(data.socialMedia?.instagram)) {
          success = false;
          message = `O formato do instagram é inválido`;
        }
      }

      if (data.socialMedia?.linkedin) {
        if (!validateURL(data.socialMedia?.linkedin)) {
          success = false;
          message = `O formato do linkedin é inválido`;
        }
      }

      if (data.socialMedia?.facebook) {
        if (!validateURL(data.socialMedia?.facebook)) {
          success = false;
          message = `O formato do facebook é inválido`;
        }
      }

      if (data.socialMedia?.youtube) {
        if (!validateURL(data.socialMedia?.youtube)) {
          success = false;
          message = `O formato do youtube é inválido`;
        }
      }
      break;
    case 4:
      if (!data.skills || data.skills.length === 0) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    default:
      message = '';
      success = true;
  }

  return { message, success };
};

export const checkInnovationFields = (
  data: IInnovationEnvironment,
  index: number,
): { message: string; success: boolean } => {
  let message = '';
  let success = true;

  switch (index) {
    case 1:
      if (!data.name || !data.title || !data.type) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 2:
      if (!data.description) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 3:
      if (
        !data.address.city ||
        !data.address.number ||
        !data.address.state ||
        !data.address.zipCode
      ) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 4:
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }

      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }

      if (data.socialMedia?.instagram) {
        if (!validateURL(data.socialMedia?.instagram)) {
          success = false;
          message = `O formato do instagram é inválido`;
        }
      }

      if (data.socialMedia?.linkedin) {
        if (!validateURL(data.socialMedia?.linkedin)) {
          success = false;
          message = `O formato do linkedin é inválido`;
        }
      }

      if (data.socialMedia?.facebook) {
        if (!validateURL(data.socialMedia?.facebook)) {
          success = false;
          message = `O formato do facebook é inválido`;
        }
      }

      if (data.socialMedia?.youtube) {
        if (!validateURL(data.socialMedia?.youtube)) {
          success = false;
          message = `O formato do youtube é inválido`;
        }
      }
      break;
    default:
      message = '';
      success = true;
  }

  return { message, success };
};

export const checkParksFields = (
  data: ITechnologyPark,
  index: number,
): { message: string; success: boolean } => {
  let message = '';
  let success = true;

  switch (index) {
    case 1:
      if (!data.name || !data.title || !data.type) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 2:
      if (!data.description) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 3:
      if (
        !data.address.city ||
        !data.address.number ||
        !data.address.state ||
        !data.address.zipCode
      ) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 4:
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }

      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }

      if (data.socialMedia?.instagram) {
        if (!validateURL(data.socialMedia?.instagram)) {
          success = false;
          message = `O formato do instagram é inválido`;
        }
      }

      if (data.socialMedia?.linkedin) {
        if (!validateURL(data.socialMedia?.linkedin)) {
          success = false;
          message = `O formato do linkedin é inválido`;
        }
      }

      if (data.socialMedia?.facebook) {
        if (!validateURL(data.socialMedia?.facebook)) {
          success = false;
          message = `O formato do facebook é inválido`;
        }
      }

      if (data.socialMedia?.youtube) {
        if (!validateURL(data.socialMedia?.youtube)) {
          success = false;
          message = `O formato do youtube é inválido`;
        }
      }
      break;
    default:
      message = '';
      success = true;
  }

  return { message, success };
};

export const checkCompaniesFields = (
  data: ICompany,
  index: number,
): { message: string; success: boolean } => {
  let message = '';
  let success = true;

  switch (index) {
    case 1:
      if (
        !data.name ||
        !data.companyType ||
        !data.companyCode ||
        !data.shortDescription ||
        !data.solution
      ) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }

      if (!validateCNPJ(data.companyCode)) {
        message = 'CNPJ inválido';
        success = false;
        break;
      }
      break;
    case 2:
      if (!data.description) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 3:
      if (
        !data.businessSegment ||
        !data.businessStage ||
        !data.businessModel ||
        !data.businessType ||
        !data.employees
      ) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 4:
      if (
        !data.address.city ||
        !data.address.number ||
        !data.address.state ||
        !data.address.zipCode
      ) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 5:
      if (!data.contact.email) {
        success = false;
        message = `E-mail é Obrigatório`;

        break;
      }
      if (!data.contact.phone) {
        success = false;
        message = `Telefone é Obrigatório`;

        break;
      }

      if (!validPhone(data.contact.phone)) {
        success = false;
        message = `Telefone digitado é inválido`;

        break;
      }

      if (!validateEmail(data.contact.email) && data.contact.email) {
        success = false;
        message = `O formato do e-mail é inválido`;
      }

      if (data.contact.site) {
        if (!validateURL(data.contact.site)) {
          success = false;
          message = `O formato do site é inválido`;
        }
      }

      if (data.socialMedia?.instagram) {
        if (!validateURL(data.socialMedia?.instagram)) {
          success = false;
          message = `O formato do instagram é inválido`;
        }
      }

      if (data.socialMedia?.linkedin) {
        if (!validateURL(data.socialMedia?.linkedin)) {
          success = false;
          message = `O formato do linkedin é inválido`;
        }
      }

      if (data.socialMedia?.facebook) {
        if (!validateURL(data.socialMedia?.facebook)) {
          success = false;
          message = `O formato do facebook é inválido`;
        }
      }

      if (data.socialMedia?.youtube) {
        if (!validateURL(data.socialMedia?.youtube)) {
          success = false;
          message = `O formato do youtube é inválido`;
        }
      }
      break;
    case 6:
      if (!data.foundingDate) {
        message = 'Preencha todos os campos';
        success = false;
        break;
      }
      break;
    case 7:
      if (data.partners.length == 0) {
        message = 'Você deve salvar o cadastro de um sócio antes de enviar';
        success = false;
        break;
      }

      if (!data.isPartnersSaved) {
        message = 'Você deve salvar o cadastro do sócio antes de enviar';
        success = false;
        break;
      }
      break;
    default:
      message = '';
      success = true;
  }

  return { message, success };
};
