import { shade } from 'polished';
import styled from 'styled-components';
export const container = styled.div`
  width: 100%;
  padding-bottom: 200px;
  height: auto;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f5f5f5;

  .container_buttons {
    display: block;
  }

  .btn_save {
    font-weight: 700;
    margin-top: 30px;
    padding: 10px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_exclud {
    border: none;
    font-weight: 400;
    padding: 8px 25px;
    margin-top: 30px;
    text-transform: none;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    color: ${({ theme }) => theme.COLORS.RED}!important;

    &:hover {
      border: none;
      outline: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }

  .MuiPaper-root {
    max-width: 100% !important;

    th {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.GREY_V5}!important;
    }
    td {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.BLACK}!important;
    }
  }

  @media screen and (max-width: 920px) {
    .table_desk {
      display: none;
    }
  }
`;

export const header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
  padding-bottom: 20px;
`;

export const headerWraper = styled.div`
  max-width: 1600px;
  min-height: 290px;
  display: flex;
  align-items: center;
  padding: 0 2%;
  margin: 0 auto;
  justify-content: space-between;
  @media only screen and (max-width: 1090px) {
    flex-wrap: wrap;
  }
`;

export const title = styled.h2`
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
  @media only screen and (max-width: 1090px) {
    text-align: center;
  }
`;

export const containerFiltersHeader = styled.div`
  width: 75%;
  height: 300px;
  @media only screen and (max-width: 1090px) {
    width: 100%;
    height: auto;
  }
`;
export const containerButtonAdd = styled.div`
  width: 25%;
  height: 300px;
  display: flex;
  padding-bottom: 20px;
  align-items: flex-end;

  button {
    font-weight: 700;
    margin-left: -10px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  @media only screen and (max-width: 1090px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 120px;
  }
`;

export const filters = styled.div`
  width: 100%;
  margin-top: 150px;
  padding-left: 2%;

  @media only screen and (max-width: 1090px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    padding-left: 0%;
    justify-content: center;
  }
`;

export const containerTextField = styled.div`
  width: 100%;
  display: -webkit-box;
  svg {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    color: #ffff !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
  button {
    font-weight: 700;
    margin-left: 20px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    @media only screen and (max-width: 1090px) {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 1090px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    button {
      margin-left: 5px;
      margin-top: 0px;
    }
  }
`;

export const textFieldWraper = styled.div``;

export const textField = styled.input`
  width: 400px;
  border: 0;
  height: 3.6375em;
  border-radius: 3px;
  padding-left: 60px;
  margin-right: 45px;
  color: #fff;
  background-color: transparent;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};

  ::placeholder {
    color: #ffff;
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  @media only screen and (max-width: 1090px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const containerTable = styled.div`
  width: 100%;
  padding: 0 2%;
  max-width: 1550px;
  margin: 50px auto 0 auto;
`;

export const titleTable = styled.h2`
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  @media only screen and (max-width: 1090px) {
    text-align: center;
  }
`;

export const containerButtonsActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .btn_edit {
    svg {
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
`;

// Dialog's
export const titleDialog = styled.h2`
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const containerTitle = styled.div`
  display: flex;
  padding: 20px 40px;
  align-items: center;
  justify-content: space-between;

  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px !important;
    color: #26d2a9 !important;
  }

  @media screen and (max-width: 600px) {
    padding: 5%;
  }
`;

export const textDialog = styled.p`
  text-align: center;
  font-weight: 700;
  padding: 0 10%;
  margin-top: 30px;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
`;

export const containerButtonsDialog = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .btn_confirm {
    font-weight: 500;
    color: #fff !important;
    font-family: 'Montserrat';
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2}!important;
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_cancel {
    border: none;
    font-weight: 500;
    font-family: 'Montserrat';
    outline: 1px solid ${({ theme }) => theme.COLORS.RED};
    color: ${({ theme }) => theme.COLORS.RED}!important;

    &:hover {
      border: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }
`;
