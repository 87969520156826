import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../../styles/theme/themeMui';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import NavbarMobile from '../../../components/NavbarMobile';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Button, CircularProgress } from '@mui/material';
import * as LoginService from '../../../services/login.service';
import { UpdateRedux } from '../../../context/utils/UpdateRedux';
import { checkLoginFields } from '../../../helpers/login.check.fields';
import { verifySessionRole } from '../../../helpers/verify.session.role';
import {
  getAccessToken,
  setAccessToken,
} from '../../../services/session.service';
import { validateEmail } from '../../../helpers/validate.email';

interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}

const Login = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [values, setValues] = React.useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [message, setMessage] = useState('');

  const [showLoad, setShowLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const validateForm = () => {
    let valid = true;
    const errors: any = {};

    if (formValues.email.trim() === '') {
      errors.email = 'Campo obrigatório';
      valid = false;
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'E-mail inválido';
      valid = false;
    }

    if (formValues.password.trim() === '') {
      errors.password = 'Campo obrigatório';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;
    setShowLoad(true);

    const data = {
      email: formValues.email,
      password: formValues.password,
    };
    if (!checkLoginFields(data).success) {
      const msgError = checkLoginFields(data).message;
      setTimeout(async () => {
        setShowLoad(false);
        setShowButton(false);
        setShowAlertErr(true);
        setMessage(msgError);

        setTimeout(() => {
          setShowAlertErr(false);
          setShowButton(true);
          setMessage('');
        }, 2000);
      }, 1000);
    } else {
      const response = await LoginService.Create({
        data: data,
      });

      setTimeout(() => {
        if (response.success) {
          setAccessToken(response.token);

          setShowLoad(false);
          setShowButton(false);
          setShowAlertSuc(true);
          setMessage('Autenticado com sucesso');
          setTimeout(() => {
            UpdateRedux(dispatch);
            setShowAlertSuc(false);
            setShowButton(true);
            setMessage('');
            if (!response.termsAccepted) {
              return navigation('/aceite-termos', {
                state: {
                  email: formValues.email,
                  password: formValues.password,
                },
              });
            }
            if (response.role == 'admin') {
              return navigation('/admin');
            }
            return navigation('/usuario');
          }, 1000);
        } else {
          setShowLoad(false);
          setShowButton(false);
          setShowAlertErr(true);
          setMessage('Usuário/senha inválido!');

          setTimeout(() => {
            setShowAlertErr(false);
            setShowButton(true);
            setMessage('');
          }, 2000);
        }
      }, 2000);
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const sessionToken = getAccessToken();

    if (sessionToken) {
      const role = verifySessionRole(sessionToken);

      if (role == 'admin') {
        navigation('/admin');
      } else {
        navigation('/usuario');
      }
    }
  }, []);

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm data-aos="zoom-in">
        <C.form>
          <C.title>Login</C.title>
          <C.space />
          <C.textField
            placeholder="E-mail"
            value={formValues.email}
            onChange={e =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            style={{ marginBottom: formErrors.email ? 0 : 30 }}
          />
          {formErrors.email && (
            <C.errorMessage>{formErrors.email}</C.errorMessage>
          )}

          <OutlinedInput
            sx={theme}
            placeholder="Senha"
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={formValues.password}
            onChange={e =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formErrors.password && (
            <C.errorMessage>{formErrors.password}</C.errorMessage>
          )}
          {showButton ? (
            <Button
              variant="contained"
              className="btn_login"
              onClick={handleLogin}
              disabled={showLoad}
            >
              {showLoad ? (
                <CircularProgress size={34} color={'primary'} />
              ) : (
                'Entrar'
              )}
            </Button>
          ) : null}

          {showAlertErr ? (
            <Alert variant="outlined" severity="error" className="alert">
              {message}
            </Alert>
          ) : null}

          {showAlertSuc ? (
            <Alert variant="outlined" severity="success" className="alert">
              {message}
            </Alert>
          ) : null}

          <Button
            variant="text"
            className="btn_recover"
            onClick={() => navigation('/recuperar-senha')}
          >
            Esqueci a senha
          </Button>
        </C.form>
      </C.containerForm>
      <C.containerButtonSupport>
        <Button
          variant="contained"
          className="btn_support"
          onClick={() => navigation('/criar-conta')}
        >
          Cadastre-se
        </Button>
      </C.containerButtonSupport>
    </C.container>
  );
};

export default Login;
