import React, { useEffect, useState } from 'react';
import * as C from './styles';
import LogoDefault from '../../../assets/logo-default.png';
import { Button } from '@mui/material';
import Navbar from '../../../components/Navbar';
import BannerLogo from '../../../assets/banner.png';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NavbarMobile from '../../../components/NavbarMobile';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import { ITechnologyPark } from '../../../interfaces/technologyParks.interface';
import * as TechnologParksService from '../../../services/technologyParks.service';
import Banner from '../../../components/Banner';
import Logo from '../../../components/Logo';
import { isLogged } from '../../../services/session.service';

const DetailsPark = () => {
  const [park, setPark] = useState<ITechnologyPark>();
  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getPark = async () => {
      await TechnologParksService.GetById(id).then(res => {
        setPark(res);
      });
    };

    getPark();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />
        {park?.bannerId ? (
          <Banner size={400} url={park?.bannerId} />
        ) : (
          <C.bannerDetails src={BannerLogo} alt="Banner Detalhes" />
        )}
        <C.headerDetails>
          <C.containerInfos>
            <C.containerImage className="image_card">
              {park?.logoId ? (
                <Logo size={180} url={park?.logoId} />
              ) : (
                <img src={LogoDefault} alt="Logo Startup" />
              )}
            </C.containerImage>
            <C.containerTitles>
              <C.headerTitle>{park?.name}</C.headerTitle>
              <C.headerSubTitle>{park?.contact.email}</C.headerSubTitle>
              <C.headerSubTitle>{park?.contact.phone}</C.headerSubTitle>
            </C.containerTitles>
            <C.containerButtonsSocial>
              {park?.socialMedia?.instagram && (
                <IconButton
                  onClick={() =>
                    window.open(
                      park?.socialMedia?.instagram?.includes('https://')
                        ? park?.socialMedia?.instagram
                        : `https://${park?.socialMedia?.instagram}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {park?.socialMedia?.facebook && (
                <IconButton
                  onClick={() =>
                    window.open(
                      park?.socialMedia?.facebook?.includes('https://')
                        ? park?.socialMedia?.facebook
                        : `https://${park?.socialMedia?.facebook}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <FacebookIcon />
                </IconButton>
              )}
              {park?.socialMedia?.linkedin && (
                <IconButton
                  onClick={() =>
                    window.open(
                      park?.socialMedia?.linkedin?.includes('https://')
                        ? park?.socialMedia?.linkedin
                        : `https://${park?.socialMedia?.linkedin}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {park?.socialMedia?.youtube && (
                <IconButton
                  onClick={() =>
                    window.open(
                      park?.socialMedia?.youtube?.includes('https://')
                        ? park?.socialMedia?.youtube
                        : `https://${park?.socialMedia?.youtube}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <YouTubeIcon />
                </IconButton>
              )}
              {park?.contact?.site && (
                <IconButton
                  onClick={() =>
                    window.open(
                      park?.contact?.site?.includes('https://')
                        ? park?.contact?.site
                        : `https://${park?.contact?.site}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <PublicIcon />
                </IconButton>
              )}
            </C.containerButtonsSocial>
            {/* {logged && (
              <C.containerButtonEdit>
                <IconButton aria-label="social">
                  <EditRoundedIcon />
                </IconButton>
              </C.containerButtonEdit>
            )} */}
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>{park?.title}</C.detailTitle>
            <pre>{park?.description}</pre>
          </C.details>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/parques-tecnologicos')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsPark;
