import { shade } from 'polished';
import styled from 'styled-components';
export const container = styled.div`
  width: 100%;
  padding-bottom: 200px;
  height: auto;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f5f5f5;

  .container_buttons {
    display: block;
  }

  .btn_save {
    font-weight: 700;
    margin-top: 30px;
    padding: 10px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_exclud {
    border: none;
    font-weight: 400;
    padding: 8px 25px;
    margin-top: 30px;
    text-transform: none;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    color: ${({ theme }) => theme.COLORS.RED}!important;

    &:hover {
      border: none;
      outline: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }

  .MuiPaper-root {
    max-width: 100% !important;

    th {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.GREY_V5}!important;
    }
    td {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.BLACK}!important;
    }
  }
`;

export const header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
  padding-bottom: 20px;
`;

export const headerWraper = styled.div`
  max-width: 1600px;
  min-height: 290px;
  display: flex;
  align-items: center;
  padding: 0 2%;
  margin: 0 auto;
  justify-content: space-between;
  @media only screen and (max-width: 1090px) {
    flex-wrap: wrap;
  }
`;

export const title = styled.h2`
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
  @media only screen and (max-width: 1090px) {
    text-align: center;
  }
`;

export const containerFiltersHeader = styled.div`
  width: 75%;
  height: 300px;

  @media only screen and (max-width: 1090px) {
    width: 100%;
    height: auto;
  }
`;
export const containerButtonAdd = styled.div`
  width: 25%;
  height: 300px;
  display: flex;
  padding-bottom: 20px;
  align-items: flex-end;

  button {
    font-weight: 700;
    margin-left: -10px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  @media only screen and (max-width: 1090px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 120px;
  }
`;

export const filters = styled.div`
  width: 100%;
  margin-top: 150px;
  padding-left: 2%;

  @media only screen and (max-width: 1090px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    padding-left: 0%;
    justify-content: center;
  }
`;

export const containerTextField = styled.div`
  width: 100%;
  display: -webkit-box;
  svg {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    color: #ffff !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
  button {
    font-weight: 700;
    margin-left: 20px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
    @media only screen and (max-width: 1090px) {
      margin-top: 15px;
    }
    @media only screen and (max-width: 1090px) {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 1090px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    button {
      margin-left: 5px;
      margin-top: 0px;
    }
  }
`;

export const textFieldWraper = styled.div``;

export const textField = styled.input`
  width: 400px;
  border: 0;
  height: 3.6375em;
  border-radius: 3px;
  padding-left: 60px;
  margin-right: 45px;
  background-color: transparent;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};

  ::placeholder {
    color: #ffff;
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  @media only screen and (max-width: 1090px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const wraper = styled.div`
  width: 100%;
`;

export const containerTables = styled.div`
  width: 100%;
  margin-top: 120px;

  // @media screen and (max-width: 920px) {
  //   display: none;
  // }
`;

// export const containerTabs = styled.div`
//   width: 100%;
//   display: none;
//   padding: 0 2%;
//   margin-top: 30px;

//   .MuiTab-root {
//     font-weight: 600;
//     font-family: 'Montserrat', sans-serif !important;
//   }

//   .Mui-selected {
//     color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
//   }
//   .MuiTabs-indicator {
//     background-color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
//   }

//   @media screen and (max-width: 920px) {
//     display: block;
//   }
// `;

// export const containerCards = styled.div`
//   width: 100%;
//   display: none;
//   padding: 0 2%;
//   margin-top: 60px;

//   @media screen and (max-width: 920px) {
//     display: block;
//   }
// `;

// export const cardItem = styled.div`
//   width: 100%;
//   margin: 20px 0;
// `;

export const containerButtonScrollTop = styled.div<{ show: boolean }>`
  width: 100%;
  height: 60px;
  z-index: 13000;
  margin-top: 40px;

  position: fixed;
  bottom: 10px;
  right: 20px;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: end;
  .MuiIconButton-root {
    width: 50px !important;
    height: 50px !important;
    min-width: 0;
    padding: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
    &:hover {
      transform: translateY(-7px);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 20px -7px;
      background-color: ${({ theme }) => theme.COLORS.YELLOW_V2} !important;
    }

    svg {
      color: ${({ theme }) => theme.COLORS.BLACK};
      font-size: 40px;
    }
  }

  @media screen and (max-width: 980px) {
    justify-content: center;
    bottom: 0;
    right: 0;
  }
`;
