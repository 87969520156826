import styled from 'styled-components';

export const container = styled.div<{ isSecondary: boolean | undefined }>`
  width: 100%;
  top: 0;
  z-index: 1100;

  background-color: ${props => (props.isSecondary ? '#21987C' : '')};
  position: relative;
  .popper_menu {
    font-family: 'Montserrat';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  }

  .MuiButtonBase-root {
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif !important;
    &:hover {
      background-color: none !important ;
    }
    &:focus {
      background-color: none !important;
    }
  }
`;

export const wraper = styled.div`
  padding: 20px;
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1090px) {
    display: block;
  }
`;

export const containerLogo = styled.div`
  width: 100%;
  display: flex;
  padding-left: 50px;
  align-items: flex-start;
  @media only screen and (max-width: 1090px) {
    justify-content: center;
    padding-left: 0px;
    margin-bottom: 10px;
  }
`;

export const logo = styled.img`
  max-height: 80px;
`;

export const containerLinks = styled.div<{ isSecondary: boolean | undefined }>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .btn_profile_mobile {
    width: 40px;
    height: 40px;
    font-weight: 700;
    min-width: 40px;
    display: none;

    border-radius: 100% !important;
    text-transform: none !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    font-family: 'Montserrat' !important;
    border: 2px solid ${props => (props.isSecondary ? '#fff' : '#D4FBF1')};
    color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};

    transition: all 0.4s ease-in-out;

    svg {
      color: #ffff !important;
      font-size: 25px;
      color: ${({ theme }) => theme.COLORS.GREEN_V4};
    }

    &:hover {
      background-color: ${({ theme }) => theme.COLORS.GREEN_V4} !important;
    }
    @media only screen and (max-width: 1090px) {
      display: flex;
    }
  }

  .MuiButton-text {
    background-color: transparent !important;
    text-transform: none;

    color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    padding: 1%;

    span {
      font-weight: 400 !important;
    }

    @media only screen and (max-width: 1090px) {
      padding: 0 2%;
      justify-content: center !important;
      background-color: rebeccapurple;
    }
  }
`;

export const spanDrop = styled.span<{ isSecondary: boolean | undefined }>`
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 8px;
  color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  &:hover {
    .dropDown {
      opacity: 100%;
    }
  }
  .dropDown {
    width: 250px;
    height: 400px;
    opacity: 0;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.6s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  svg {
    color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};
  }
`;

export const containerButton = styled.div<{ isSecondary: boolean | undefined }>`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1090px) {
    display: none;
  }

  .MuiButton-text {
    width: 50px;
    height: 50px;
    font-weight: 700;
    min-width: 50px;
    background-color: #fff;
    border-radius: 100% !important;
    text-transform: none !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    font-family: 'Montserrat' !important;
    border: 2px solid ${props => (props.isSecondary ? '#fff' : '#D4FBF1')};
    color: ${props => (props.isSecondary ? '#fff' : '#26D2A9')};

    transition: all 0.4s ease-in-out;

    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.COLORS.GREEN_V4};
    }

    &:hover {
      background-color: ${({ theme }) => theme.COLORS.GREEN_V4} !important;
      svg {
        color: #fff !important;
      }
    }
  }
`;
