import React, { useEffect, useState } from 'react';
import * as C from './styles';
import LogoDefault from '../../../assets/logo-default.png';
import { Button } from '@mui/material';
import BannerLogo from '../../../assets/banner.png';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import NavbarMobile from '../../../components/NavbarMobile';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import PersondIcon from '@mui/icons-material/MoodRounded';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import CategoryIcon from '@mui/icons-material/AssistantPhotoRounded';
import StageIcon from '@mui/icons-material/BatteryCharging80Rounded';
import BusinessIcon from '@mui/icons-material/BusinessCenterRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import * as StartupService from '../../../services/startup.service';
import { IFormdata } from '../../../interfaces/startup.interface';
import Logo from '../../../components/Logo';
import Banner from '../../../components/Banner';
import { isLogged } from '../../../services/session.service';

const DetailsStartup = () => {
  const navigation = useNavigate();
  const [startup, setStartup] = useState<IFormdata>();
  const location = useLocation();

  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getStartup = async () => {
      await StartupService.Get(id).then(res => {
        setStartup(res);
      });
    };

    getStartup();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />
        {startup?.bannerId ? (
          <Banner size={235} url={startup?.bannerId} />
        ) : (
          <C.bannerDetails src={BannerLogo} alt="Banner Detalhes" />
        )}
        <C.headerDetails data-aos="fade-right">
          <C.containerInfos>
            <C.containerImage className="image_card">
              {startup?.logoId ? (
                <Logo size={180} url={startup?.logoId} />
              ) : (
                <img src={LogoDefault} alt="Logo Startup" />
              )}
            </C.containerImage>
            <C.containerTitles>
              <C.headerTitle>{startup?.name}</C.headerTitle>
              <C.headerSubTitle>{startup?.contact.email}</C.headerSubTitle>
              <C.headerSubTitle>{startup?.contact.phone}</C.headerSubTitle>
            </C.containerTitles>
            <C.containerButtonsSocial>
              {startup?.socialMedia?.instagram && (
                <IconButton
                  onClick={() =>
                    window.open(
                      startup?.socialMedia?.instagram?.includes('https://')
                        ? startup?.socialMedia?.instagram
                        : `https://${startup?.socialMedia?.instagram}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {startup?.socialMedia?.facebook && (
                <IconButton
                  onClick={() =>
                    window.open(
                      startup?.socialMedia?.facebook?.includes('https://')
                        ? startup?.socialMedia?.facebook
                        : `https://${startup?.socialMedia?.facebook}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <FacebookIcon />
                </IconButton>
              )}
              {startup?.socialMedia?.linkedin && (
                <IconButton
                  onClick={() =>
                    window.open(
                      startup?.socialMedia?.linkedin?.includes('https://')
                        ? startup?.socialMedia?.linkedin
                        : `https://${startup?.socialMedia?.linkedin}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {startup?.socialMedia?.youtube && (
                <IconButton
                  onClick={() =>
                    window.open(
                      startup?.socialMedia?.youtube?.includes('https://')
                        ? startup?.socialMedia?.youtube
                        : `https://${startup?.socialMedia?.youtube}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <YouTubeIcon />
                </IconButton>
              )}
              {startup?.contact?.site && (
                <IconButton
                  onClick={() =>
                    window.open(
                      startup?.contact?.site?.includes('https://')
                        ? startup?.contact?.site
                        : `https://${startup?.contact?.site}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <PublicIcon />
                </IconButton>
              )}
            </C.containerButtonsSocial>
            {/* {logged && (
              <C.containerButtonEdit>
                <IconButton aria-label="social">
                  <EditRoundedIcon />
                </IconButton>
              </C.containerButtonEdit>
            )} */}
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>{startup?.shortDescription}</C.detailTitle>

            <pre>{startup?.solution}</pre>
            <C.space />
            <pre>{startup?.description}</pre>

            <C.countTitleMaster>Perfil da Startup</C.countTitleMaster>
            <C.containerCounts>
              {/* <C.countItem>
                <LocationIcon />
                <C.countTitle>Região</C.countTitle>
                <C.countText>xxxxxxx</C.countText>
              </C.countItem> */}
              <C.countItem>
                <CategoryIcon />
                <C.countTitle>Categoria</C.countTitle>
                <C.countText>{startup?.businessSegment}</C.countText>
              </C.countItem>
              <C.countItem>
                <BadgeRoundedIcon />
                <C.countTitle>Colaboradores</C.countTitle>
                <C.countText>{startup?.employees}</C.countText>
              </C.countItem>
              <C.countItem>
                <StageIcon />
                <C.countTitle>Estágio</C.countTitle>
                <C.countText>{startup?.businessStage}</C.countText>
              </C.countItem>
              <C.countItem>
                <BusinessIcon />
                <C.countTitle>Tipo de Negócio</C.countTitle>
                <C.countText>{startup?.businessType}</C.countText>
              </C.countItem>
            </C.containerCounts>
          </C.details>
          <C.containerPartiners>
            <C.title>O Time</C.title>
            {startup?.partners.map((item, index) => (
              <C.partnerItem key={index}>
                {item.image ? (
                  <Logo size={110} url={item.image} />
                ) : (
                  <C.partinerPhoto>
                    <PersondIcon />
                  </C.partinerPhoto>
                )}
                <C.partnerName>{item.name}</C.partnerName>
                <C.partnerOffice>{item.role}</C.partnerOffice>
                <C.partnerProfile>{'Perfil ' + item.profile}</C.partnerProfile>
              </C.partnerItem>
            ))}
          </C.containerPartiners>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/startups')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsStartup;
