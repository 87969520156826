import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import * as C from './styles';
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/EditRounded';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import {
  themeDialogAlert,
  themeTable,
} from '../../../../styles/theme/themeMui';
import { IMines } from '../../../../interfaces/mine.interface';
import Details from '../Details';
import Api from '../../../../api';
import { getAccessToken } from '../../../../services/session.service';

interface Column {
  id: 'type' | 'name' | 'created_at' | 'active' | 'actions';
  label: string;
  maxWidth?: number;
  minWidth?: number;
  align?: 'center' | 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'type', label: 'Tipo de Cadastro' },
  { id: 'name', label: 'Nome do Cadastro' },
  { id: 'created_at', label: 'Data da Aprovação' },
  { id: 'actions', label: 'Ações', align: 'center' },
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };

interface ITable {
  listApproved: IMines;
  refresh: () => Promise<void>;
  setButtonScroll: Dispatch<SetStateAction<boolean>>;
}

const TableActive = ({ listApproved, refresh, setButtonScroll }: ITable) => {
  const count = 5;
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(count);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedItem, setSelectedItem] = useState<any>();

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [registerId, setRegisterId] = useState('');
  const [registerCategory, setRegisterCotegory] = useState('');

  const [entityId, setEntityId] = useState('');
  const [showTransfer, setShowTransfer] = useState(false);
  const [emailOwner, setEmailOwner] = useState('');

  const token = getAccessToken();

  // const [showRefused, setShowRefused] = useState(false);
  // const [swhowApproved, setShowAproved] = useState(false);
  // const [showTransfer, setShowTransfer] = useState(false);

  const handleEdit = (id: string, category: string) => {
    setRegisterId(id);
    setShowEdit(!showEdit);
    setRegisterCotegory(category);
  };

  const handleChangeOwner = async () => {
    await Api.post(
      `/users/ownership`,
      {
        entityId: entityId,
        email: emailOwner.trim(),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setEntityId('');
    setEmailOwner('');
    setShowTransfer(false);
    refresh();
  };

  useEffect(() => {
    setButtonScroll(!showEdit);
  }, [showEdit]);
  useEffect(() => {
    setPage(0);
    setRowsCount(count);
    setRowsPerPage(count);
  }, [listApproved]);
  return (
    <C.containerTable>
      <C.titleTable>Aprovados</C.titleTable>
      <Paper sx={themeTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table_head_desk">
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {listApproved.data ? (
                listApproved.data.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {columns.map(column => {
                        const value = row[column.id];
                        const formatedValue =
                          column.format && typeof value === 'number'
                            ? column.format(value)
                            : column.id === 'created_at'
                            ? new Date(value).toLocaleDateString()
                            : value;
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                              column.id === 'name' ? 'table_cell_click' : ''
                            }
                          >
                            {column.id === 'actions' && (
                              <C.containerButtonsActions>
                                <IconButton
                                  className="btn_edit"
                                  onClick={() =>
                                    handleEdit(row.id, row.category)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  className="btn_delete"
                                  onClick={() => {
                                    setSelectedItem(row);
                                    setShowDelete(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  className="btn_transfer"
                                  onClick={() => {
                                    setEntityId(row.id);
                                    setShowTransfer(true);
                                  }}
                                >
                                  <AutorenewRoundedIcon />
                                </IconButton>
                              </C.containerButtonsActions>
                            )}

                            {formatedValue}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {showEdit ? (
            <Details
              id={registerId}
              showModal={showEdit}
              setShowModal={setShowEdit}
              category={registerCategory}
              refresh={refresh}
            />
          ) : null}
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Itens"
          labelDisplayedRows={({ from, to, count }) => {
            return from + ' à ' + to + ' de ' + count;
          }}
          rowsPerPageOptions={[5, 10, 25, 30]}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => setShowEdit(false)}
          onRowsPerPageChange={() => setShowEdit(false)}
        />
      </Paper>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDelete(false)}
        aria-labelledby="customized-dialog-title"
        open={showDelete}
      >
        <C.containerTitle>
          <C.titleDialog>Excluir {selectedItem?.type}</C.titleDialog>
          <Button variant="text" onClick={() => setShowDelete(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialog>
            Tem certeza que deseja excluir este registro de {selectedItem?.type}
            ? Essa ação não pode ser desfeita.
          </C.textDialog>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => setShowDelete(false)}
            >
              Excluir
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowTransfer(false)}
        aria-labelledby="customized-dialog-title"
        open={showTransfer}
      >
        <C.containerTitle>
          <C.titleDialog>Transferir Cadastro</C.titleDialog>
          <Button variant="text" onClick={() => setShowTransfer(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialogTransfer>
            Tem certeza que deseja transferir este cadastro para outro usuário?
            Essa ação não poderá ser desfeita
          </C.textDialogTransfer>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>
                  Digite o usuário para transferir o cadastro
                </C.titleTextField>
                <C.textField
                  placeholder="E-mail do usuário"
                  value={emailOwner}
                  onChange={e => setEmailOwner(e.target.value)}
                />
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowTransfer(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => handleChangeOwner()}
            >
              Transferir
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
    </C.containerTable>
  );
};

export default TableActive;
