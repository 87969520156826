import React, { useEffect } from 'react';
import * as C from './styles';
import Api from '../../../api';
import { useState } from 'react';
import Form1 from '../forms/form1';
import Form2 from '../forms/form2';
import Form3 from '../forms/form3';
import Form4 from '../forms/form4';
import Form5 from '../forms/form5';
import Form6 from '../forms/form6';
import Form7 from '../forms/form7';
import Form8 from '../forms/form8';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/DoneAllRounded';
import { themeDialog } from '../../../styles/theme/themeMui';
import * as ImageService from '../../../services/image.service';
import RocketIcon from '@mui/icons-material/RocketLaunchRounded';
import { IPartner } from '../../../interfaces/partner.interface';
import { IAddress } from '../../../interfaces/startup.interface';
import * as StartupService from '../../../services/startup.service';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { checkProgressSturtupFields } from '../../../helpers/startups.check.fields';

export const AddModal = ({
  setShow,
  show,
  id,
  visualization = false,
  refresh,
}: any) => {
  dayjs.extend(utc);

  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [index, setIndex] = useState(1);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [form4, setForm4] = useState(false);
  const [form5, setForm5] = useState(false);
  const [form6, setForm6] = useState(false);
  const [form7, setForm7] = useState(false);
  const [form8, setForm8] = useState(false);
  const [notReturn, setNotReturn] = useState(false);

  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [site, setSite] = useState('');
  const [pitch, setPitch] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipcode] = useState('');
  const [youtube, setYoutube] = useState('');
  const [district, setDistrict] = useState('');
  const [register, setRegister] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [employees, setEmployees] = useState('');
  const [instagram, setInstagram] = useState('');
  const [complement, setComplement] = useState('');
  const [resolution, setResolution] = useState('');
  const [zipcodeInfo, setZipcodeInfo] = useState('');
  const [description, setDescription] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessStage, setBusinessStage] = useState('');
  const [businessModel, setBusinessModel] = useState('');
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [acelerationText, setAcelerationText] = useState('');
  const [businessSegment, setBusinessSegment] = useState('');
  const [formalizationType, setFormalizaTionType] = useState('');
  const [foundation, setFoundation] = useState<Dayjs | null>(dayjs(new Date()));

  const [image, setImage] = useState<any>();
  const [banner, setBanner] = useState<any>();
  const [imagePath, setimagePath] = useState<any>();
  const [bannerPatch, setBannerPatch] = useState<any>();
  const [imageSelected, setImageSelected] = useState(false);
  const [bannerSelected, setBannerSelected] = useState(false);

  const [thereIsAcelerarion, setThereIsAceleration] = useState(false);
  const [thereIsFormalization, setThereIsFormalization] = useState(false);
  const [isPartnersSaved, setPartnersSaved] = useState(true);

  const [isEditing, setIsEditing] = useState(false);

  const handleNext = () => {
    const data = {
      id: id,
      name: name,
      solution: resolution,
      address: {
        street: address,
        number: number,
        zipCode: zipCode,
        district: district,
        city: city,
        state: state,
        street2: complement,
      },
      contact: {
        site: site,
        email: email,
        phone: phone,
      },
      employees: Number(employees),
      foundingDate: foundation,
      companyType: formalizationType,
      description: pitch,
      partners: partners,
      companyCode: register,
      businessType: businessType,
      businessStage: businessStage,
      businessModel: businessModel,
      shortDescription: description,
      businessSegment: businessSegment,
      accelerationProgram: acelerationText,
      socialMedia: {
        instagram: instagram,
        facebook: facebook,
        linkedin: linkedin,
        youtube: youtube,
      },
      thereIsAcelerarion,
      thereIsFormalization,
      logoId: image,
      bannerId: banner,
    };

    if (!checkProgressSturtupFields(data, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);
        setErrMessage(checkProgressSturtupFields(data, index).message);

        setTimeout(() => {
          const dialog = document.getElementById('dialog');
          if (dialog) {
            dialog.scrollTop = dialog.scrollHeight;
          }
        });

        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      if (index === 1) {
        setIndex(2);
        setForm1(false);
        setForm2(true);
        setNotReturn(false);
      }
      if (index === 2) {
        setIndex(3);
        setForm2(false);
        setForm8(true);
      }
      if (index === 3) {
        setIndex(4);
        setForm8(false);
        setForm3(true);
      }
      if (index === 4) {
        setIndex(5);
        setForm3(false);
        setForm4(true);
      }
      if (index === 5) {
        setIndex(6);
        setForm4(false);
        setForm5(true);
      }
      if (index === 6) {
        setIndex(7);
        setForm5(false);
        setForm6(true);
      }
      if (index === 7) {
        setIndex(8);
        setForm6(false);
        setForm7(true);
      }
    }
  };

  const handlePrev = () => {
    if (index === 2) {
      setIndex(1);
      setForm1(true);
      setForm2(false);
      setNotReturn(true);
    }
    if (index === 3) {
      setIndex(2);
      setForm2(true);
      setForm8(false);
    }
    if (index === 4) {
      setIndex(3);
      setForm3(false);
      setForm8(true);
    }
    if (index === 5) {
      setIndex(4);
      setForm4(false);
      setForm3(true);
    }
    if (index === 6) {
      setIndex(5);
      setForm5(false);
      setForm4(true);
    }
    if (index === 7) {
      setIndex(6);
      setForm6(false);
      setForm5(true);
    }
    if (index === 8) {
      setIndex(7);
      setForm7(false);
      setForm6(true);
    }
  };

  const handleConcluded = async () => {
    const data = {
      id: id,
      name: name,
      solution: resolution,
      address: {
        street: address,
        number: number,
        zipCode: zipCode,
        district: district,
        city: city,
        state: state,
        street2: complement,
      },
      contact: {
        site: site,
        email: email,
        phone: phone,
      },
      employees: Number(employees),
      foundingDate: foundation,
      companyType: formalizationType,
      description: pitch,
      partners: partners,
      companyCode: register,
      businessType: businessType,
      businessStage: businessStage,
      businessModel: businessModel,
      shortDescription: description,
      businessSegment: businessSegment,
      accelerationProgram: acelerationText,
      socialMedia: {
        instagram: instagram,
        facebook: facebook,
        linkedin: linkedin,
        youtube: youtube,
      },
      thereIsAcelerarion,
      thereIsFormalization,
      logoId: image,
      bannerId: banner,
      isPartnersSaved: isPartnersSaved,
    };

    if (!checkProgressSturtupFields(data, index).success) {
      setShowAlertErr(true);
      setErrMessage(checkProgressSturtupFields(data, index).message);

      setTimeout(() => {
        const dialog: any = document.getElementById('dialog');
        if (dialog) {
          dialog.scrollTop = dialog.scrollHeight;
        }
      });

      setTimeout(() => {
        setShowAlertErr(false);
      }, 5000);
    } else {
      setForm7(false);
      setShowConcluded(true);
      setNotReturn(true);

      if (typeof data.logoId != 'string') {
        if (data.logoId) {
          const response = await ImageService.Create(image);
          if (response.success) {
            data.logoId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com a logo. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm7(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }
      }

      if (typeof data.bannerId != 'string') {
        if (data.bannerId) {
          const response = await ImageService.Create(banner);
          if (response.success) {
            data.bannerId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com o banner. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm7(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }
      }

      if (data.partners.length > 0) {
        for (let i = 0; i < data.partners.length; i++) {
          const partner = data.partners[i];
          if (typeof partner.image != 'string') {
            const response = await ImageService.Create(partner.image);
            if (response.success) {
              partner.image = response.uid;

              partner.imagePath = null;
            } else {
              setTitle('Ooops!');
              setSuccess(false);
              setSubOne(
                'Algo deu errado com a imagem do sócio. verifique o tamanho do arquivo ou selecione novamente.',
              );
              setTimeout(() => {
                setForm7(true);
                setNotReturn(false);
                setShowConcluded(false);
                setTitle('');
                setSubOne('');
                setSubTwo('');
              }, 5000);
              return;
            }
          }
        }
      }
      let response: any = { success: false, message: '' };
      if (isEditing) {
        //serviço de edição
        response = await StartupService.Update(data);
      } else {
        response = await StartupService.Create(data);
      }

      if (response.success) {
        if (isEditing && refresh) {
          refresh();
        }
        setTitle('Tudo Pronto!');
        setSubOne(
          'Obrigada pela sua colaboração!  Assim que seu conteúdo for aprovado, ele ficará disponível para consulta no site.',
        );
        setSubTwo('');
        setSuccess(true);

        setTimeout(() => {
          handleClose();
        }, 4000);
      } else {
        setSuccess(false);
        setTitle('Erro');
        setSubOne(
          'Sentimos muito, porém nosso servidor não conseguiu gravar as informações. A situação deverá ser resolvida em breve.',
        );
        setSubTwo(response.message.join('<br/>'));

        setTimeout(() => {
          setForm7(true);
          setNotReturn(false);
          setShowConcluded(false);
          setTitle('');
          setSubOne('');
          setSubTwo('');
        }, 10000);
      }
    }
  };

  const handleClose = () => {
    setName('');
    setCity('');
    setSite('');
    setPitch('');
    setState('');
    setEmail('');
    setPhone('');
    setImage(undefined);
    setBanner(undefined);
    setNumber('');
    setAddress('');
    setZipcode('');
    setYoutube('');
    setPartners([]);
    setRegister('');
    setFacebook('');
    setLinkedin('');
    setEmployees('');
    setInstagram('');
    setimagePath('');
    setComplement('');
    setResolution('');
    setZipcodeInfo('');
    setDescription('');
    setBannerPatch('');
    setBusinessType('');
    setBusinessStage('');
    setBusinessModel('');
    setBusinessSegment('');
    setAcelerationText('');
    setImageSelected(false);
    setBannerSelected(false);
    setFormalizaTionType('');
    setThereIsAceleration(false);
    setThereIsFormalization(false);
    setFoundation(dayjs(new Date()));

    setShow(false);
    setForm1(true);
    setForm2(false);
    setForm3(false);
    setForm4(false);
    setForm5(false);
    setForm6(false);
    setForm7(false);
    setForm8(false);

    setShowConcluded(false);
    setIndex(1);
  };

  useEffect(() => {
    if (zipcodeInfo.length == 9) {
      Api.get(`/addresses/${zipcodeInfo}`)
        .then(res => {
          const response: IAddress = res.data;

          setCity(response.city ? response.city : '-');
          setState(response.state ? response.state : '-');
          setAddress(response.street ? response.street : '-');
          setDistrict(response.district ? response.district : '-');
        })
        .catch(e => console.log(e));
    }
  }, [zipcodeInfo]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setZipcodeInfo(zipCode);
    }, 700);

    return () => clearTimeout(delay);
  }, [zipCode]);

  const setInfos = async (id: string) => {
    const response = await StartupService.Get(id);

    if (response?.name) {
      setImage('');
      setBanner('');
      setName(response.name);
      setRegister(register);
      setPitch(response.description);
      setResolution(response.solution);
      setFoundation(dayjs.utc(response.foundingDate));

      setBusinessType(response.businessType);
      setBusinessStage(response.businessStage);
      setBusinessModel(response.businessModel);
      setDescription(response.shortDescription);
      setBusinessSegment(response.businessSegment);
      if (response?.employees) {
        setEmployees('' + response.employees);
      }
      if (response?.logoId) {
        setimagePath(
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.logoId,
        );
        setImage(response?.logoId);
        setImageSelected(true);
      }

      if (response.bannerId) {
        setBannerPatch(
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.bannerId,
        );
        setBanner(response?.bannerId);
        setBannerSelected(true);
      }

      if (response.partners) {
        for (let i = 0; i < response.partners.length; i++) {
          const partner = response.partners[i];

          if (partner.image) {
            partner.imagePath =
              process.env.REACT_APP_ENV_BASE_API + '/files/' + partner.image;
          }
        }

        setPartners(response.partners);
      }
    }

    if (response?.companyCode) {
      setRegister(response.companyCode);
      setThereIsFormalization(true);
    }

    if (response?.companyType) {
      setThereIsFormalization(true);
      setFormalizaTionType(response.companyType);
    }

    if (response?.socialMedia) {
      if (response.socialMedia.youtube)
        setYoutube(response.socialMedia.youtube);
      if (response.socialMedia.facebook)
        setFacebook(response.socialMedia.facebook);
      if (response.socialMedia.instagram)
        setInstagram(response.socialMedia.instagram);

      if (response.socialMedia.linkedin)
        setLinkedin(response.socialMedia.linkedin);
    }

    if (response?.address) {
      setCity(response.address.city);
      setState(response.address.state);
      setNumber(response.address.number);
      setAddress(response.address.street);
      setZipcode(response.address.zipCode);
      setDistrict(response.address.district);
      setComplement(response.address.street2);
    }

    if (response?.contact) {
      setEmail(response.contact.email);
      setPhone(response.contact.phone);
      if (response.contact.site) setSite(response?.contact?.site);
    }

    if (response?.accelerationProgram) {
      setThereIsAceleration(true);
      setAcelerationText(response?.accelerationProgram);
    }
  };

  useEffect(() => {
    if (id) {
      setInfos(id);
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog sx={themeDialog} fullWidth={true} open={show}>
        <C.containerTitle>
          <C.title>
            <RocketIcon />
            {isEditing ? 'Edite sua Startup' : 'Cadastre sua Startup'}
          </C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers id="dialog">
          {form1 && (
            <Form1
              name={name}
              setName={setName}
              resolution={resolution}
              description={description}
              setResolution={setResolution}
              setDescription={setDescription}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}

          {form2 && (
            <Form2
              pitch={pitch}
              setPitch={setPitch}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form8 && (
            <Form8
              city={city}
              state={state}
              number={number}
              address={address}
              zipCode={zipCode}
              setCity={setCity}
              district={district}
              setState={setState}
              setNumber={setNumber}
              complement={complement}
              setAddress={setAddress}
              setZipcode={setZipcode}
              setDistric={setDistrict}
              setComplement={setComplement}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form3 && (
            <Form3
              site={site}
              email={email}
              phone={phone}
              setSite={setSite}
              youtube={youtube}
              facebook={facebook}
              linkedin={linkedin}
              setEmail={setEmail}
              setPhone={setPhone}
              instagram={instagram}
              setYoutube={setYoutube}
              setFacebook={setFacebook}
              setLinkedin={setLinkedin}
              setInstagram={setInstagram}
              showAlertErr={showAlertErr}
              message={errMessage}
              visualization={visualization}
            />
          )}
          {form4 && (
            <Form4
              register={register}
              setRegister={setRegister}
              acelerationText={acelerationText}
              formalizationType={formalizationType}
              thereIsAcelerarion={thereIsAcelerarion}
              setAcelerationText={setAcelerationText}
              thereIsFormalization={thereIsFormalization}
              setFormalizationType={setFormalizaTionType}
              setThereIsAceleration={setThereIsAceleration}
              setThereIsFormalization={setThereIsFormalization}
              showAlertErr={showAlertErr}
              message={errMessage}
              visualization={visualization}
            />
          )}
          {form5 && (
            <Form5
              employees={employees}
              setEmployees={setEmployees}
              businessType={businessType}
              businesStage={businessStage}
              businessModel={businessModel}
              businessSegment={businessSegment}
              setBusinessType={setBusinessType}
              setBusinessModel={setBusinessModel}
              setBusinessStage={setBusinessStage}
              setBusinessSegment={setBusinessSegment}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}
          {form6 && (
            <Form6
              imagePath={imagePath}
              bannerPatch={bannerPatch}
              setImage={setImage}
              setBanner={setBanner}
              foundation={foundation}
              setBannerPatch={setBannerPatch}
              imageSelected={imageSelected}
              setFoundation={setFoundation}
              setimagePath={setimagePath}
              bannerSelected={bannerSelected}
              setImageSelected={setImageSelected}
              setBannerSelected={setBannerSelected}
              showAlertErr={showAlertErr}
              visualization={visualization}
            />
          )}

          {form7 && (
            <Form7
              setPartners={setPartners}
              partners={partners}
              showAlertErr={showAlertErr}
              visualization={visualization}
              setPartnersSaved={setPartnersSaved}
              errMessage={errMessage}
            />
          )}

          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>
        <C.containerDotsMobile>
          <C.dot disabled={form1} />
          <C.dot disabled={form2} />
          <C.dot disabled={form8} />
          <C.dot disabled={form3} />
          <C.dot disabled={form4} />
          <C.dot disabled={form5} />
          <C.dot disabled={form6} />
          <C.dot disabled={form7} />
        </C.containerDotsMobile>
        <C.containerButtons disabled={form1}>
          <Button
            variant="outlined"
            className="btn_previus"
            disabled={form1 || notReturn}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
            Anterior
          </Button>

          <C.containerDots>
            <C.dot disabled={form1} />
            <C.dot disabled={form2} />
            <C.dot disabled={form8} />
            <C.dot disabled={form3} />
            <C.dot disabled={form4} />
            <C.dot disabled={form5} />
            <C.dot disabled={form6} />
            <C.dot disabled={form7} />
          </C.containerDots>

          {form7 || showConcluded ? (
            <Button
              variant="outlined"
              className="btn_send"
              onClick={handleConcluded}
              disabled={!form7 || visualization}
            >
              Enviar
              <SendIcon />
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="btn_next"
              onClick={handleNext}
            >
              Próximo
              <ArrowNextIcon />
            </Button>
          )}
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};
