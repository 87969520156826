/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import * as C from './styles';
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import ThumbDownOffAltRoundedIcon from '@mui/icons-material/ThumbDownOffAltRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import {
  themeDialogAlert,
  themeTable,
} from '../../../../styles/theme/themeMui';
import Api from '../../../../api';
import { getAccessToken } from '../../../../services/session.service';
import Tooltip from '@mui/material/Tooltip';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

interface Column {
  id: 'name' | 'user' | 'actions';
  label: string;
  maxWidth?: number;
  minWidth?: number;
  align?: 'center' | 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome da Empresa' },
  { id: 'user', label: 'Usuário do Cadastro' },
  { id: 'actions', label: 'Ações', align: 'center' },
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const TableActive = ({
  refresh,
  setSelectedId,
  filterName,
  filterType,
  filterModel,
  filterStage,
  filterSegment,
  filterCity,
}: any) => {
  const count = 8;
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(count);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDialogRefuse, setShowDialogRefuse] = useState(false);
  const [messageRefuse, setMessageRefuse] = useState('');

  const [entityId, setEntityId] = useState('');
  const [showTransfer, setShowTransfer] = useState(false);
  const [emailOwner, setEmailOwner] = useState('');
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);
  const token = getAccessToken();

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newpage: number,
  ) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const [registers, setRegisters] = useState<any[]>([]);
  const [filteredRegisters, setFilteredRegisters] = useState<any[]>([]);

  const handleAprove = async (id: any) => {
    await Api.patch(
      `/companies/${id}`,
      {
        action: 'approve',
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setRegisters([]);

    handleLoad();
  };

  const handleDelete = async () => {
    await Api.delete(`/companies/${entityId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setRegisters([]);

    handleLoad();
    setShowDelete(false);
  };

  const handleReject = async () => {
    await Api.patch(
      `/companies/${entityId}`,
      {
        action: 'reject',
        rejectionReason: messageRefuse,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setRegisters([]);
    handleLoad();
    setMessageRefuse('');
    setShowDialogRefuse(false);
  };

  const handleLoad = async () => {
    const response = await Api.get(`/companies?status=A&page=1&size=1000`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setRegisters(response.data.data);
    setPage(response.data.paging.page - 1);
    // setRowsCount(response.data.paging.count);
    // setRowsPerPage(response.data.paging.count);
  };

  useEffect(() => {
    setFilteredRegisters(
      registers?.filter(startup => {
        const nameMatch =
          !startup.name ||
          !filterName ||
          startup.name.toLowerCase().includes(filterName.toLowerCase());
        const typeMatch =
          !startup.businessType ||
          !filterType ||
          startup.businessType.toLowerCase().includes(filterType.toLowerCase());
        const modelMatch =
          !startup.businessModel ||
          !filterModel ||
          startup.businessModel
            .toLowerCase()
            .includes(filterModel.toLowerCase());
        const StageMatch =
          !startup.businessStage ||
          !filterStage ||
          startup.businessStage
            ?.toLowerCase()
            .includes(filterStage.toLowerCase());
        const segmentMatch =
          !startup.businessSegment ||
          !filterSegment ||
          startup.businessSegment
            ?.toLowerCase()
            .includes(filterSegment.toLowerCase());
        const cityMatch =
          !startup.address ||
          !startup.address.city ||
          !filterCity ||
          startup.address.city.toLowerCase().includes(filterCity.toLowerCase());

        return (
          nameMatch &&
          typeMatch &&
          modelMatch &&
          StageMatch &&
          segmentMatch &&
          cityMatch
        );
      }),
    );
  }, [
    filterName,
    filterType,
    filterCity,
    filterSegment,
    filterStage,
    filterModel,
    registers,
  ]);

  const handleChangeOwner = async () => {
    await Api.post(
      `/users/ownership`,
      {
        entityId: entityId,
        email: emailOwner.trim(),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setRegisters([]);
    setEntityId('');
    setEmailOwner('');
    setShowTransfer(false);
    handleLoad();
  };

  useEffect(() => {
    handleLoad();
  }, []);
  return (
    <C.containerTable>
      <C.titleTable>Aprovados</C.titleTable>
      <Paper sx={themeTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table_head_desk">
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredRegisters ? (
                filteredRegisters
                  .slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        {columns.map(column => {
                          const value =
                            column.id == 'user'
                              ? row['owner']['name']
                              : row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.id === 'name' ? 'table_cell_click' : ''
                              }
                            >
                              {column.id === 'actions' && (
                                <C.containerButtonsActions>
                                  <Tooltip title="Visualizar">
                                    <IconButton
                                      className="btn_edit"
                                      onClick={() => setSelectedId(row.id)}
                                    >
                                      <VisibilityRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Rejeitar">
                                    <IconButton
                                      className="btn_delete"
                                      onClick={() => {
                                        setEntityId(row.id);
                                        setShowDialogRefuse(true);
                                      }}
                                    >
                                      <ThumbDownOffAltRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Excluir">
                                    <IconButton
                                      className="btn_delete"
                                      onClick={() => {
                                        setEntityId(row.id);
                                        setShowDelete(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Transferir titularidade">
                                    <IconButton
                                      className="btn_transfer"
                                      onClick={() => {
                                        setEntityId(row.id);
                                        setShowTransfer(true);
                                      }}
                                    >
                                      <AutorenewRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </C.containerButtonsActions>
                              )}

                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Itens"
          rowsPerPageOptions={[5, 10, 25, 30]}
          component="div"
          labelDisplayedRows={({ from, to, count }) => {
            return from + ' à ' + to + ' de ' + count;
          }}
          count={filteredRegisters.length}
          rowsPerPage={rpg}
          page={pg}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDelete(false)}
        aria-labelledby="customized-dialog-title"
        open={showDelete}
      >
        <C.containerTitle>
          <C.titleDialog>Excluir Empresa</C.titleDialog>
          <Button variant="text" onClick={() => setShowDelete(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialog>
            Tem certeza que deseja excluir esta empresa? Essa ação não poderá
            ser desfeita.
          </C.textDialog>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => handleDelete()}
            >
              Excluir
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowTransfer(false)}
        aria-labelledby="customized-dialog-title"
        open={showTransfer}
      >
        <C.containerTitle>
          <C.titleDialog>Transferir Cadastro</C.titleDialog>
          <Button variant="text" onClick={() => setShowTransfer(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.textDialogTransfer>
            Tem certeza que deseja transferir este cadastro para outro usuário?
            Essa ação não poderá ser desfeita
          </C.textDialogTransfer>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>
                  Digite o usuário para transferir o cadastro
                </C.titleTextField>
                <C.textField
                  placeholder="E-mail do usuário"
                  value={emailOwner}
                  onChange={e => setEmailOwner(e.target.value)}
                />
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowTransfer(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_confirm"
              onClick={() => handleChangeOwner()}
            >
              Transferir
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_alert"
        sx={themeDialogAlert}
        fullWidth={true}
        onClose={() => setShowDialogRefuse(false)}
        aria-labelledby="customized-dialog-title"
        open={showDialogRefuse}
      >
        <C.containerTitle>
          <C.titleDialog>Recusar cadastro</C.titleDialog>
          <Button variant="text" onClick={() => setShowDialogRefuse(false)}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.titleTextField>
                  Tem certeza que deseja recusar este cadastro?
                </C.titleTextField>
                <C.textField
                  placeholder="Digite o motivo"
                  value={messageRefuse}
                  onChange={e => setMessageRefuse(e.target.value)}
                />
              </C.boxTextField>
            </C.textFieldWraper50>
          </C.containerTextFields>

          <C.containerButtonsDialog>
            <Button
              variant="outlined"
              className="btn_cancel"
              onClick={() => setShowDialogRefuse(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="btn_refuse"
              onClick={() => handleReject()}
            >
              Recusar
            </Button>
          </C.containerButtonsDialog>
        </DialogContent>
      </Dialog>
    </C.containerTable>
  );
};

export default TableActive;
