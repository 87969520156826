import React, { useEffect } from 'react';
import * as C from './styles';
import { useState } from 'react';
import { Alert, Button, IconButton } from '@mui/material';
// import { Dispatch, SetStateAction } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/AddRounded';
import { theme } from '../../../styles/theme/themeMui';
import { genresOPT } from '../../../helpers/options';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ICompany, IPartner } from '../../../interfaces/company.interface';
import { FormikProps } from 'formik';
import EditIcon from '@mui/icons-material/EditRounded';
import { MaskCPF } from '../../../helpers/mask';
import { IFormProps } from '../../../interfaces/form.interface';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import PhotoIcon from '@mui/icons-material/PhotoSizeSelectActualRounded';
import { validateEmail } from '../../../helpers/validate.email';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

const Form7 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization, setPartnersSaved, message } = props;
  const [showForm, setShowForm] = useState(false);

  const [errMessage, setErrMessage] = useState('');
  const [editing, setEditing] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showAlertValidation, setShowAlertValidation] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [genre, setGenre] = useState('');
  const [role, setRole] = useState('');
  const [cpf, setCpf] = useState<string>('');
  const [profile, setProfile] = useState('');
  const [image, setImage] = useState<any>({});
  const [imagePath, setimagePath] = useState<any>();
  const [imageSelected, setImageSelected] = useState(false);
  const [partner, setPartner] = useState<IPartner>();
  const [partnerEditedName, setPartnerEditedName] = useState('');

  function validateCpf(strCPF: string) {
    strCPF = strCPF.replace(/\D/g, '');
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == '00000000000') return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  const validateImage = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setimagePath(reader.result);
        });
        reader.readAsDataURL(image[0]);

        setImage(image[0]);
        setImageSelected(true);
      }
    }
  };

  const handleAddPartner = () => {
    setName('');
    setEmail('');
    setCpf('');
    setRole('');
    setGenre('');
    setProfile('');
    setImage('');
    setimagePath('');
    setPartner(undefined);
    setEditing(false);
    setImageSelected(false);
    setShowForm(!showForm);
    if (setPartnersSaved) {
      setPartnersSaved(false);
    }
  };

  const handleSavePartner = () => {
    if (
      !name ||
      !email ||
      !cpf ||
      !role ||
      !genre ||
      !profile ||
      !validateEmail(email)
    ) {
      setShowButton(false);
      setShowAlertValidation(true);
      setErrMessage(`Preencha todos os campos`);

      if (!validateEmail(email) && email) {
        setErrMessage(`O formato do e-mail é inválido`);
      }

      setTimeout(() => {
        setShowButton(true);
        setShowAlertValidation(false);
        setErrMessage('');
      }, 2000);
      return;
    }

    if (!editing) {
      const nameFilter = name;
      if (props.values.partners.some(({ name }: any) => name == nameFilter)) {
        setShowButton(false);
        setShowAlertValidation(true);
        if (!validateEmail(email)) {
          setErrMessage(`O formato do e-mail é inválido`);
        } else if (!validateCpf(cpf)) {
          setErrMessage(`O cpf digitado é inválido`);
        } else {
          setErrMessage(`Preencha todos os campos`);
        }

        setTimeout(() => {
          setShowButton(true);
          setShowAlertValidation(false);
          setErrMessage('');
        }, 2000);
        return;
      }

      props.setFieldValue('partners', [
        ...props.values.partners,
        { name, email, role, cpf, genre, profile, image, imagePath },
      ]);
      if (props.setPartnersSaved) {
        props.setPartnersSaved(true);
      }
    } else {
      const index = props.values.partners.findIndex(
        (item: { name: string }) => item.name == partnerEditedName,
      );
      const newList = props.values.partners.filter(
        (e: { name: string }) => e.name != partnerEditedName,
      );

      newList.splice(index, 0, {
        name,
        email,
        role,
        cpf,
        genre,
        profile,
        image,
        imagePath,
      });

      props.setFieldValue('partners', newList);
      if (props.setPartnersSaved) {
        props.setPartnersSaved(true);
      }
    }
    setShowForm(false);
  };

  const handleEditPartner = (part: IPartner) => {
    setPartner(part);
    setEditing(true);
    setShowForm(true);
    setImageSelected(true);
    setPartnerEditedName(part.name);
    if (props.setPartnersSaved) {
      props.setPartnersSaved(false);
    }
  };

  const handleDeletePartner = (partnerName: string) => {
    const newList = props.values.partners.filter(
      (e: { name: string }) => e.name != partnerName,
    );
    const newPartners = [...newList];

    setName('');
    setEmail('');
    setCpf('');
    setRole('');
    setGenre('');
    setProfile('');
    setImage('');
    setimagePath('');
    setEditing(false);

    props.setFieldValue('partners', newPartners);
  };

  useEffect(() => {
    if (partner) {
      setName(partner.name);
      setCpf(partner.cpf);
      setEmail(partner.email);
      setRole(partner.role);
      setGenre(partner.genre);
      setImage(partner.image);
      setProfile(partner.profile);
      setimagePath(partner.imagePath);
    }
  }, [partner]);

  return (
    <C.container>
      <C.titleMaster>Sócios</C.titleMaster>
      {!showForm && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {props.values.partners ? (
                props.values.partners.map(
                  (item: IPartner, index: React.Key | null | undefined) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.role}</TableCell>
                        <TableCell>{item.profile}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>
                          <IconButton
                            className="btn_edit"
                            onClick={() => handleEditPartner(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            disabled={visualization}
                            className="btn_delete"
                            onClick={() => handleDeletePartner(item.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!showForm && (
        <C.textFieldWraper100>
          <C.boxTextField>
            <Button
              variant="contained"
              className="btn_add_partner"
              onClick={handleAddPartner}
              disabled={visualization}
            >
              <AddIcon />
              Adicionar Sócio
            </Button>
          </C.boxTextField>
        </C.textFieldWraper100>
      )}
      {showForm && (
        <>
          <C.textFieldWraper50>
            <C.boxTextField>
              <C.titleTextField>Nome*</C.titleTextField>
              <C.textField
                disabled={visualization}
                placeholder="Nome do sócio"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </C.boxTextField>
            <C.boxTextField>
              <C.titleTextField>E-mail Corporativo*</C.titleTextField>
              <C.textField
                disabled={visualization}
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </C.boxTextField>
          </C.textFieldWraper50>
          <C.textFieldWraper50>
            <C.boxTextField>
              <C.titleTextField>Cargo*</C.titleTextField>
              <C.textField
                disabled={visualization}
                placeholder="Cargo"
                value={role}
                onChange={e => setRole(e.target.value)}
              />
            </C.boxTextField>
            <C.boxTextField>
              <C.titleTextField>CPF*</C.titleTextField>
              <C.textField
                disabled={visualization}
                placeholder="CPF"
                value={cpf}
                onChange={e => setCpf(MaskCPF(e.target.value as string))}
              />
            </C.boxTextField>
          </C.textFieldWraper50>
          <C.textFieldWraper50>
            <C.boxTextField>
              <C.titleTextField>Gênero*</C.titleTextField>
              <Select
                disabled={visualization}
                defaultValue={''}
                sx={theme}
                className="select"
                onChange={e => setGenre(e.target.value)}
                displayEmpty
                value={genre}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {genresOPT.map(item => (
                  <MenuItem value={item} key={item}>
                    <em>{item}</em>
                  </MenuItem>
                ))}
              </Select>
            </C.boxTextField>
            <C.boxTextField>
              <C.titleTextField>Perfil*</C.titleTextField>
              <RadioGroup
                row
                sx={theme}
                defaultValue={partner?.profile}
                name="controlled-radio-buttons-group"
                onChange={e => setProfile(e.target.value)}
                aria-labelledby="demo-controlled-radio-buttons-group"
              >
                <FormControlLabel
                  disabled={visualization}
                  control={<Radio value="Técnico" />}
                  label="Técnico"
                />
                <FormControlLabel
                  disabled={visualization}
                  control={<Radio value="Negócio" />}
                  label="Negócio"
                />
                <FormControlLabel
                  disabled={visualization}
                  control={<Radio value="Investidor" />}
                  label="Investidor"
                />
              </RadioGroup>
            </C.boxTextField>
          </C.textFieldWraper50>

          <C.titleTextLogo>Foto</C.titleTextLogo>
          <C.boxLogo>
            <C.containerImage>
              {imageSelected ? (
                <div
                  style={{ backgroundImage: `url(${imagePath})` }}
                  className="photo"
                />
              ) : (
                <PhotoIcon />
              )}
            </C.containerImage>
            <div className="image_wraper">
              <Button
                variant="contained"
                className="btn_add_logo"
                component="label"
              >
                Selecionar Foto
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={t => validateImage(t.target.files)}
                />
              </Button>
              <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
            </div>
          </C.boxLogo>
          <C.buttonsSupport>
            {showButton ? (
              <>
                <Button
                  disabled={visualization}
                  variant="contained"
                  className="btn_save"
                  onClick={handleSavePartner}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  className="btn_cancel"
                  onClick={() => {
                    setShowForm(false);
                    if (setPartnersSaved) {
                      setPartnersSaved(true);
                    }
                  }}
                >
                  Cancelar
                </Button>
              </>
            ) : null}
            {showAlertValidation ? (
              <Alert variant="outlined" severity="error" className="alert">
                {errMessage}
              </Alert>
            ) : null}
          </C.buttonsSupport>
        </>
      )}
      {showAlertErr ? (
        <Alert
          variant="outlined"
          severity="error"
          className="alert"
          sx={{ mt: 1 }}
        >
          {message}
        </Alert>
      ) : null}
    </C.container>
  );
};
export default Form7;
