import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body{
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}
a{
  color: none;
  text-decoration: none;
}

.slick-slider{
  max-width: 900px;


  @media screen and (max-width: 1088px) {
    max-width: 650px;
    width: 100%;

  }

  @media screen and (max-width: 768px) {
    max-width: none;
    width: 75%;

  }
}

/* .slick-slide{
  margin-left: 10px !important;
} */

.slick-prev{
  left: -55px !important;
}
.slick-prev:before,
.slick-next:before {
  font-size: 50px;
  width: 50px;
}


.slick-dots li.slick-active button:before {
  color: #fff;
  font-size: 20px;
}

.link_logo{
  border-radius: 5px;
  max-width: 350px;
  padding: 10px !important;
}


.MuiPaper-root{
  max-width: 800px !important;
  /* @media screen and (max-width: 780px) {
      margin: 0px !important;
      width: 100% !important;
      height: 100% !important;
    } */
}



.popper_menu  .MuiMenuItem-root{
  font-size: 1.2em;
  color: #333840 !important;
  transition: all 0.3s ease-in-out;
}
.popper_menu  .MuiMenuItem-root:hover{
  color: #26D2A9 !important;
}

.popper_menu  .MuiMenuItem-root {
  color: #333840 !important;
    line-height: 2.5 !important;
    &:hover a {
      transition: all 0.3s;
      color: #26D2A9 !important;
    }
}
.MuiMenuItem-root{

  width:100%;

  li{
    margin-right: 0px !important;
  }
  em{
    font-style: normal !important;
  }

  &:before {
      border-color: blue !important;
    }
    &:after {
      border-color: red !important;
    }
}


.modal_add{
  min-height: 700px !important;
}

.MuiOutlinedInput-root{
  &:before {
      border-color: #26D2A9 !important;
    }
}

.Mui-focused::before{
  border-color: #26D2A9 !important;
}

::-webkit-scrollbar{
    width: 4px;
    height: 6px;
}
::-webkit-scrollbar-track{
box-shadow: inset 0 0 5px #26D2A9;

}
::-webkit-scrollbar-thumb{
background: #26D2A9;

transition: all 0.8s;
}
::-webkit-scrollbar-thumb:hover{
    background: #26D2A9;
}
`;
