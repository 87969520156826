import styled from 'styled-components';

export const container = styled.div`
  flex: 0 20%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  margin: 20px;
  display: flex;
  box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);

  svg {
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
  }
  &:hover .card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 30px 20px -20px;
  }
`;
export const wraper = styled.div``;

export const headerCard = styled.div`
  width: 35%;
  display: flex;
  padding: 2%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
`;
export const containerInfos = styled.div`
  width: 65%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const title = styled.h3`
  margin-top: 20px;
  margin-left: 10%;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.COLORS.GREEN_V4};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;

  @media only screen and (max-width: 580px) {
    margin-left: 2%;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  }
`;

export const titleDiv = styled.div`
  word-wrap: break-word;
  width: 100%;
  max-width: 400px;

  @media only screen and (max-width: 580px) {
    margin-left: 2%;
    width: 100%;
    max-width: 200px;

    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  }
`;

export const titleHeader = styled.h3`
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-in-out;
  color: #fff;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const numberHeader = styled.h2`
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-in-out;
  color: #fff;
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
`;

export const infosEvent = styled.div`
  margin-bottom: 10%;
`;

export const containerTitle = styled.div`
  display: flex;
  margin-top: 5%;
  margin-left: 10%;
  @media only screen and (max-width: 580px) {
    margin-left: 2%;
  }
`;

export const titleInfos = styled.span`
  top: -2px;
  font-weight: 400;
  margin-left: 15px;
  position: relative;
  color: ${({ theme }) => theme.COLORS.GREY_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;

  @media only screen and (max-width: 580px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  }
`;
