import React, { useEffect, useState } from 'react';
import * as C from './styles';
import { Button } from '@mui/material';
import RGS from '../../../assets/ags.png';
import Navbar from '../../../components/Navbar';
import BannerLogo from '../../../assets/banner.png';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import NavbarMobile from '../../../components/NavbarMobile';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PersondIcon from '@mui/icons-material/MoodRounded';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import CategoryIcon from '@mui/icons-material/AssistantPhotoRounded';
import StageIcon from '@mui/icons-material/BatteryCharging80Rounded';
import BusinessIcon from '@mui/icons-material/BusinessCenterRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { ICompany } from '../../../interfaces/company.interface';
import * as CompanyService from '../../../services/company.service';
import Banner from '../../../components/Banner';
import Logo from '../../../components/Logo';
import { isLogged } from '../../../services/session.service';

const DetailsCompany = () => {
  const [company, setCompany] = useState<ICompany>();
  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getCompany = async () => {
      await CompanyService.GetById(id).then(res => {
        setCompany(res);
      });
    };

    getCompany();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      <C.container>
        <Navbar secondary={true} logged={isLogged()} />
        <NavbarMobile />
        {company?.bannerId ? (
          <Banner size={400} url={company?.bannerId} />
        ) : (
          <C.bannerDetails src={BannerLogo} alt="Banner Detalhes" />
        )}
        <C.headerDetails>
          <C.containerInfos>
            <C.containerImage className="image_card">
              {company?.logoId ? (
                <Logo size={180} url={company?.logoId} />
              ) : (
                <img src={RGS} alt="Logo company" />
              )}
            </C.containerImage>
            <C.containerTitles>
              <C.headerTitle>{company?.name}</C.headerTitle>
              <C.headerSubTitle>{company?.contact.email}</C.headerSubTitle>
              <C.headerSubTitle>{company?.contact.phone}</C.headerSubTitle>
            </C.containerTitles>
            <C.containerButtonsSocial>
              {company?.socialMedia?.instagram && (
                <IconButton
                  onClick={() =>
                    window.open(
                      company?.socialMedia?.instagram?.includes('https://')
                        ? company?.socialMedia?.instagram
                        : `https://${company?.socialMedia?.instagram}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {company?.socialMedia?.facebook && (
                <IconButton
                  onClick={() =>
                    window.open(
                      company?.socialMedia?.facebook?.includes('https://')
                        ? company?.socialMedia?.facebook
                        : `https://${company?.socialMedia?.facebook}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <FacebookIcon />
                </IconButton>
              )}
              {company?.socialMedia?.linkedin && (
                <IconButton
                  onClick={() =>
                    window.open(
                      company?.socialMedia?.linkedin?.includes('https://')
                        ? company?.socialMedia?.linkedin
                        : `https://${company?.socialMedia?.linkedin}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {company?.socialMedia?.youtube && (
                <IconButton
                  onClick={() =>
                    window.open(
                      company?.socialMedia?.youtube?.includes('https://')
                        ? company?.socialMedia?.youtube
                        : `https://${company?.socialMedia?.youtube}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <YouTubeIcon />
                </IconButton>
              )}
              {company?.contact?.site && (
                <IconButton
                  onClick={() =>
                    window.open(
                      company?.contact?.site?.includes('https://')
                        ? company?.contact?.site
                        : `https://${company?.contact?.site}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <PublicIcon />
                </IconButton>
              )}
            </C.containerButtonsSocial>
            {/* {logged && (
              <C.containerButtonEdit>
                <IconButton aria-label="social">
                  <EditRoundedIcon />
                </IconButton>
              </C.containerButtonEdit>
            )} */}
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>{company?.shortDescription}</C.detailTitle>
            <pre>{company?.solution}</pre>
            <C.space />
            <pre>{company?.description}</pre>
            <C.countTitleMaster>Perfil da Empresa</C.countTitleMaster>
            <C.containerCounts>
              {/* <C.countItem>
                <LocationIcon />
                <C.countTitle>Região</C.countTitle>
                <C.countText>xxxxxxx</C.countText>
              </C.countItem> */}
              <C.countItem>
                <CategoryIcon />
                <C.countTitle>Categoria</C.countTitle>
                <C.countText>{company?.businessSegment}</C.countText>
              </C.countItem>
              <C.countItem>
                <StageIcon />
                <C.countTitle>Estágio</C.countTitle>
                <C.countText>{company?.businessStage}</C.countText>
              </C.countItem>
              <C.countItem>
                <BusinessIcon />
                <C.countTitle>Tipo de Negócio</C.countTitle>
                <C.countText>{company?.businessType}</C.countText>
              </C.countItem>
            </C.containerCounts>
          </C.details>
          <C.containerPartiners>
            <C.title>O Time</C.title>
            {company?.partners.map((item, index) => {
              return (
                <C.partnerItem key={index}>
                  {item.image ? (
                    <Logo size={110} url={item.image} />
                  ) : (
                    <C.partinerPhoto>
                      <PersondIcon />
                    </C.partinerPhoto>
                  )}
                  <C.partnerName>{item.name}</C.partnerName>
                  <C.partnerOffice>{item.role}</C.partnerOffice>
                  <C.partnerProfile>
                    {'Perfil ' + item.profile}
                  </C.partnerProfile>
                </C.partnerItem>
              );
            })}
          </C.containerPartiners>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/empresas')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsCompany;
