import React, { useEffect } from 'react';
import * as C from './styles';
import Logo from '../../assets/logo.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoWhite from '../../assets/logobranco.png';
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { INavbar } from '../../interfaces/navbar.interface';
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';
import { logOut } from '../../context/slice/userSlice';

const NavbarAdmin = ({ secondary }: INavbar) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const openSett = Boolean(anchorElSettings);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElSettings) {
      setAnchorElSettings(event.currentTarget);
      return;
    }
    setAnchorElSettings(null);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(logOut());
    navigation('/');
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1090) setAnchorEl(null);
    });
  }, []);

  return (
    <C.container isSecondary={secondary}>
      <C.wraper>
        <C.containerLogo>
          <MenuItem onClick={() => navigation('/')} className="link_logo">
            {secondary ? <C.logo src={LogoWhite} /> : <C.logo src={Logo} />}
          </MenuItem>
        </C.containerLogo>
        <C.containerLinks isSecondary={secondary}>
          <Button
            className="button"
            variant="text"
            onClick={() => navigation('/admin')}
          >
            Home
          </Button>

          <Button
            className="button"
            variant="text"
            onClick={() => navigation('/admin/usuarios')}
          >
            Usuários
          </Button>
          <Button className="button" variant="text" onClick={handleClick}>
            Cadastros
            <DownIcon />
          </Button>
          <Button
            className="btn_profile_mobile"
            variant="text"
            onClick={handleClickSettings}
          >
            <PersonIcon />
          </Button>
        </C.containerLinks>
        <C.containerButton isSecondary={secondary}>
          <Button variant="text" onClick={handleClickSettings}>
            <PersonIcon />
          </Button>
        </C.containerButton>
      </C.wraper>

      <Menu
        style={{ top: '26px', position: 'absolute', zIndex: '99999999' }}
        className="popper_menu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        aria-haspopup="true"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigation('/admin/startups')}>
          Startups
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/instituicoes')}>
          Instituições
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/parques')}>
          Parques Tecnológicos
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/empresas')}>
          Empresas
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/eventos')}>
          Eventos
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/ambientes')}>
          Ambientes de Inovação
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/mentores')}>
          Banco de Mentores
        </MenuItem>
        <MenuItem onClick={() => navigation('/admin/cidades')}>
          Cidades
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElSettings}
        open={openSett}
        onClose={handleCloseSettings}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigation('/admin/perfil')}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Perfil</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <SubdirectoryArrowLeftRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </C.container>
  );
};

export default NavbarAdmin;
