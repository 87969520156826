import { shade } from 'polished';
import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  padding: 0px 20px;

  .image_wraper {
    margin-top: 30px;
  }

  .btn_add_image {
    font-weight: 400;
    margin-top: 5px;
    margin-left: 20px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-left: 0px;
    }
  }

  .btn_add_logo {
    font-weight: 400;
    margin-left: 20px;
    height: 40px;
    margin-top: 5px;
    padding: 5px 20px !important;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-left: 0px;
    }
  }

  .btn_add_partner {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }

  .btn_save {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }

  .btn_cancel {
    font-weight: 400;
    margin-top: 30px;
    padding: 13px 25px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FD5D5D')} !important;
    }

    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 10px;
      padding: 13px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
    }
  }

  @media screen and (max-width: 780px) {
    padding: 1px;
  }
`;

export const titleMaster = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
`;

export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;

export const titleTextLogo = styled.p`
  margin: 30px 0 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;

export const infoImage = styled.p`
  font-weight: 400;
  margin-top: 5px;
  margin-left: 20px;
  font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const textFieldWraper100 = styled.div``;
export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const textFieldWraper33 = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;
export const boxTextField = styled.div`
  width: 100%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 30px 0;
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const textAreaField = styled.textarea`
  border: 0;
  width: 100%;
  min-height: 160px;
  padding: 20px;
  resize: none;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &.textaera_master {
    min-height: 500px;
  }

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const space = styled.div`
  margin: 50px 0;
`;
//TODO: ADD IMAGE
export const containerLogo = styled.div`
  width: 130px;
  height: 130px;
  display: flex;
  margin-top: 20px;
  border-radius: 65px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V1};

  .logo {
    width: 130px;
    height: 130px;
    border-radius: 65px;
    background-size: cover;
    background-position: center;
  }

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
`;

//TODO: ADD PARTNER
export const cardPartner = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 35px;

  .btn_edit {
    svg {
      color: ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }
  .btn_delete {
    svg {
      color: ${({ theme }) => theme.COLORS.RED};
    }
  }
`;

export const namePartner = styled.p`
  font-size: 17px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BLACK};
`;
export const officeParner = styled.p`
  font-size: 15px;
  position: relative;
  top: 8px;
  margin-right: 20px;
  color: ${({ theme }) => theme.COLORS.BLACK};
`;

export const infosPartner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};
`;
//END ADD PARTNER

export const containerBanner = styled.div`
  width: 350px;
  height: 100px;
  display: flex;
  margin-top: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V1};

  .banner {
    width: 350px;
    height: 100px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
  }

  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const boxLogo = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const containerImage = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  margin-top: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V1};
  .image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  .photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  svg {
    font-size: 40px;
    color: ${({ theme }) => theme.COLORS.GREEN_V4};
  }
`;

export const buttonsSupport = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  button {
    height: 45px;
  }
  .alert {
    margin-top: 30px;
    height: 50px;
  }
`;
