import { Alert } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { MaskPhone } from '../../../helpers/mask';
import * as C from './styles';

interface IForm {
  site: string;
  email: string;
  phone: string;
  setSite: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  showMessageErr: boolean;
  visualization: boolean;
  message: string;
}

const Form3 = ({
  site,
  email,
  phone,
  setSite,
  setEmail,
  setPhone,
  showMessageErr,
  visualization,
  message,
}: IForm) => {
  return (
    <C.container>
      <C.titleMaster>Contato</C.titleMaster>
      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>Site</C.titleTextField>
          <C.textField
            placeholder="Site"
            value={site}
            onChange={e => setSite(e.target.value)}
            disabled={visualization}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>E-mail</C.titleTextField>
          <C.textField
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={visualization}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Telefone</C.titleTextField>
          <C.textField
            placeholder="Telefone"
            value={phone}
            maxLength={15}
            onChange={e => setPhone(MaskPhone(e.target.value))}
            disabled={visualization}
          />
        </C.boxTextField>
      </C.textFieldWraper33>
      {showMessageErr ? (
        <Alert
          variant="outlined"
          severity="error"
          className="alert"
          sx={{ mt: 1 }}
        >
          {message}
        </Alert>
      ) : null}
    </C.container>
  );
};
export default Form3;
