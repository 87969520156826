import { shade } from 'polished';
import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.GREY_V3};

  .support_material {
    text-decoration: underline;
    color: #ffffff;
  }

  .btn_contact {
    font-weight: 700;
    margin-top: 30px;
    padding: 13px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.RED};
    &:hover {
      background-color: ${shade(0.2, '#FD5D5D')} !important;
    }

    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
  }
`;

export const title = styled.h1`
  font-weight: 700;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;

export const headerDetails = styled.div`
  width: 100%;
  padding-top: 170px;
  height: 400px;
  display: flex;
  align-self: baseline;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};

  @media screen and (max-width: 1090px) {
    height: auto;
    padding-top: 100px;
  }
`;

export const containerInfos = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8%;
  max-width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 1390px) {
    padding: 0 8%;
    max-width: 100%;
  }
  @media screen and (max-width: 1090px) {
    display: block;
    padding-bottom: 50px;
  }
`;

export const containerTitles = styled.div`
  margin-left: 30px;
  margin-top: 10px;

  @media screen and (max-width: 1090px) {
    width: 100%;
    position: relative;
    margin-left: 0px;
    margin-top: 0px;
    top: -20px;
    text-align: center;
  }
`;
export const headerTitle = styled.h1`
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;
  @media screen and (max-width: 1090px) {
    width: 100% !important;
    margin: 0 auto;
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;
export const headerSubTitle = styled.h4`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
  color: #ffff;
  @media screen and (max-width: 1390px) {
    padding: 0 1%;
  }
  @media screen and (max-width: 1090px) {
    width: 100%;
    margin: 10px auto;
  }
`;

export const containerBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10%;
  max-width: 80%;
  margin: 0 auto;
  height: auto;

  @media screen and (max-width: 1390px) {
    padding: 0 1%;
  }
  @media screen and (max-width: 1090px) {
    width: 100%;
    max-width: 90%;
    display: block;
    padding: 5% 1%;
  }
`;

export const contactForm = styled.form`
  width: 100%;
  height: auto;
  padding: 40px 70px;
  background-color: #ffff;
  position: relative;
  top: -70px;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

  @media only screen and (max-width: 780px) {
    padding: 40px 8%;
  }
`;

export const titleForm = styled.h2`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
`;

export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;

export const textFieldWraper100 = styled.div`
  margin-top: 40px;
`;
export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 780px) {
    display: block;
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const textAreaField = styled.textarea`
  border: 0;
  width: 100%;
  min-height: 160px;
  padding: 20px;
  resize: none;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const boxTextField = styled.div`
  width: 100%;

  @media screen and (max-width: 600px) {
    margin: 30px 0;
  }
`;

export const containerButton = styled.div`
  margin-top: 30px;
  width: 100%;
  padding: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const errorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;
