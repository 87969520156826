import React, { useEffect } from 'react';
import * as C from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoWhite from '../../assets/logobranco.png';
import { INavbar } from '../../interfaces/navbar.interface';
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { RootStore } from '../../context';
import { logOut } from '../../context/slice/userSlice';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';

const NavbarHome = ({ secondary, logged }: INavbar) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const infoUser = useSelector((state: RootStore) => state.user);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);

  const openNoti = Boolean(anchorElNotification);
  const openSett = Boolean(anchorElSettings);

  const handleLogout = () => {
    setAnchorElSettings(null);
    sessionStorage.clear();
    dispatch(logOut());
    navigation('/');
  };

  const handleSettings = () => {
    if (infoUser.role == 'admin') {
      return navigation('/admin');
    }
    return navigation('/usuario');
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElNotification) {
      setAnchorElNotification(event.currentTarget);
      return;
    }
    setAnchorElNotification(null);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElSettings) {
      setAnchorElSettings(event.currentTarget);
      return;
    }
    setAnchorElSettings(null);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1090) setAnchorEl(null);
    });
  }, []);

  return (
    <C.container isSecondary={secondary}>
      <C.wraper>
        <C.containerLogo>
          <MenuItem onClick={() => navigation('/')} className="link_logo">
            <C.logo src={LogoWhite} />
          </MenuItem>
        </C.containerLogo>
        <C.containerLinks isSecondary={secondary}>
          <Button
            className="button"
            variant="text"
            onClick={() => navigation('/startups')}
          >
            Startups
          </Button>

          <Button className="button" variant="text" onClick={handleClick}>
            Recursos
            <DownIcon />
          </Button>
          <Button
            className="button"
            variant="text"
            onClick={() => navigation('/agenda-eventos')}
          >
            Eventos
          </Button>
          <Button
            className="button"
            variant="text"
            onClick={() => navigation('/contato')}
          >
            Contato
          </Button>
        </C.containerLinks>

        {!logged ? (
          <C.containerButton isSecondary={secondary}>
            <Button variant="text" onClick={() => navigation('/login')}>
              Login
            </Button>
          </C.containerButton>
        ) : (
          <C.containerButton isSecondary={secondary}>
            {/* <IconButton
              aria-label="delete"
              size="large"
              onClick={handleClickNotification}
            >
              <NotificationsRoundedIcon />
              <C.dotNotification />
            </IconButton> */}
            <IconButton
              aria-label="delete"
              size="large"
              onClick={handleClickSettings}
            >
              <AccountCircleRoundedIcon />
            </IconButton>
          </C.containerButton>
        )}
      </C.wraper>
      <Menu
        style={{ top: '26px', position: 'absolute', zIndex: '99999999' }}
        className="popper_menu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        aria-haspopup="true"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigation('/empresas')}>Empresas</MenuItem>
        <MenuItem onClick={() => navigation('/instituicoes')}>
          Instituições
        </MenuItem>
        <MenuItem onClick={() => navigation('/parques-tecnologicos')}>
          Parques Tecnológicos
        </MenuItem>
        <MenuItem onClick={() => navigation('/ambiente-inovacao')}>
          Ambientes de Inovação
        </MenuItem>
        <MenuItem onClick={() => navigation('/mentores')}>
          Banco de Mentores
        </MenuItem>
        <MenuItem onClick={() => navigation('/cidades')}>Cidades</MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElNotification}
        open={openNoti}
        onClose={handleCloseNotification}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <C.itemNotification>
          <C.textNotification>Cadastro de Startup aprovado!</C.textNotification>
        </C.itemNotification>
        <Divider />
        <C.itemNotification>
          <C.textNotification>
            Cadastro de Empresa aguardando ativação
          </C.textNotification>
        </C.itemNotification>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorElSettings}
        open={openSett}
        onClose={handleCloseSettings}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleSettings()}>
          <ListItemIcon>
            <SettingsRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Configurações</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <SubdirectoryArrowLeftRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </C.container>
  );
};

export default NavbarHome;
