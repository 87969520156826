import { createSlice } from '@reduxjs/toolkit';

const store = createSlice({
  name: 'store',
  initialState: {
    id: '',
    role: '',
    name: '',
    email: '',
  },
  reducers: {
    setId(state, { payload }) {
      state.id = payload;
    },
    setRole(state, { payload }) {
      state.role = payload;
    },
    setName(state, { payload }) {
      state.name = payload;
    },
    setEmail(state, { payload }) {
      state.email = payload;
    },
    logOut(state) {
      state.id = '';
      state.role = '';
      state.name = '';
      state.email = '';
    },
  },
});

export const { setId, logOut, setRole, setName, setEmail } = store.actions;
export default store.reducer;
