import styled from 'styled-components';
import { shade } from 'polished';
export const container = styled.div`
  width: 100%;
  padding-bottom: 200px;
  height: auto;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f5f5f5;

  .container_buttons {
    display: block;
  }

  .btn_save {
    font-weight: 700;
    margin-top: 30px;
    padding: 10px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_exclud {
    border: none;
    font-weight: 400;
    padding: 8px 25px;
    margin-top: 30px;
    text-transform: none;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    color: ${({ theme }) => theme.COLORS.RED}!important;

    &:hover {
      border: none;
      outline: none;
      background-color: rgba(255, 135, 135, 0.2);
    }
  }

  .MuiPaper-root {
    max-width: 100% !important;

    th {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.GREY_V5}!important;
    }
    td {
      font-family: 'Montserrat';
      color: ${({ theme }) => theme.COLORS.BLACK}!important;
    }
  }
`;

export const wraper = styled.div`
  width: 100%;
`;

export const containerTables = styled.div`
  width: 100%;
  margin-top: 120px;

  @media screen and (max-width: 920px) {
    display: none;
  }
`;

export const containerTabs = styled.div`
  width: 100%;
  display: none;
  padding: 0 2%;
  margin-top: 30px;

  .MuiTab-root {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
  }

  .Mui-selected {
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }

  @media screen and (max-width: 920px) {
    display: block;
  }
`;

export const containerCards = styled.div`
  width: 100%;
  display: none;
  padding: 0 2%;
  margin-top: 60px;

  @media screen and (max-width: 920px) {
    display: block;
  }
`;

export const cardItem = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const subTitleTable = styled.p`
  width: 100%;
  margin: 10px 0 40px 0;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
