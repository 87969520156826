import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import * as C from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { theme } from '../../../styles/theme/themeMui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { cities } from '../../../helpers/options';
import { Alert } from '@mui/material';
import { maskValue } from '../../../helpers/mask';

interface IForm {
  location: string;
  description: string;
  admissionCharged: number;
  fee: string;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  setLocation: Dispatch<SetStateAction<string>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setAdmissionCharged: Dispatch<SetStateAction<number>>;
  setFee: Dispatch<SetStateAction<string>>;
  showMessageErr: boolean;
  visualization: boolean;
}

const Form2 = ({
  location,
  description,
  admissionCharged,
  fee,
  city,
  setCity,
  setLocation,
  setDescription,
  setAdmissionCharged,
  setFee,
  showMessageErr,
  visualization,
}: IForm) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCost = (value: number) => {
    setAdmissionCharged(value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Cidade</C.titleTextField>
        <Select
          sx={theme}
          fullWidth
          className="select"
          value={city}
          onChange={handleChange}
          displayEmpty
          disabled={visualization}
        >
          <MenuItem value="">
            <em>Selecione</em>
          </MenuItem>
          {cities.map(city => {
            return (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            );
          })}
        </Select>
        {showMessageErr && city == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Local do evento</C.titleTextField>
        <C.textField
          placeholder="Local"
          value={location}
          onChange={e => setLocation(e.target.value)}
          disabled={visualization}
        />
        {showMessageErr && location == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Custo do evento?</C.titleTextField>
          <RadioGroup
            row
            sx={theme}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              checked={admissionCharged === 0}
              label="Não"
              disabled={visualization}
              control={
                <Radio
                  onChange={() => handleCost(0)}
                  disabled={visualization}
                />
              }
            />
            <FormControlLabel
              checked={admissionCharged === 1}
              disabled={visualization}
              label="Sim"
              control={
                <Radio
                  onChange={() => handleCost(1)}
                  disabled={visualization}
                />
              }
            />
            <FormControlLabel
              checked={admissionCharged === 2}
              label="Consulte no site"
              disabled={visualization}
              control={
                <Radio
                  onChange={() => handleCost(2)}
                  disabled={visualization}
                />
              }
            />
          </RadioGroup>
        </C.boxTextField>

        {admissionCharged === 1 && (
          <C.boxTextField>
            <C.titleTextField>Entrada (custo do evento)</C.titleTextField>
            <C.textField
              placeholder="R$ --"
              value={fee}
              type="text"
              onChange={e => setFee(maskValue(e.target.value))}
              disabled={visualization}
            />
            {showMessageErr && admissionCharged === 1 && !fee ? (
              <Alert
                variant="outlined"
                severity="error"
                className="alert"
                sx={{ mt: 1 }}
              >
                Informação Obrigatória
              </Alert>
            ) : null}
          </C.boxTextField>
        )}
      </C.textFieldWraper50>

      <C.space />

      <C.textFieldWraper100>
        <C.titleTextField>Detalhes do Evento</C.titleTextField>
        <C.textAreaField
          placeholder="Descreva o evento"
          value={description}
          onChange={e => setDescription(e.target.value)}
          disabled={visualization}
        />
        {showMessageErr && description == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      {/* <C.textFieldWraper100>
        <C.titleTextField>
          Link da inscrição / detalhes do evento
        </C.titleTextField>
        <C.textField placeholder="Link" />
      </C.textFieldWraper100> */}
    </C.container>
  );
};
export default Form2;
