import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../styles/theme/themeMui';
import Select from '@mui/material/Select';
import { FormikProps } from 'formik';
import { IMentor } from '../../../interfaces/mentor.interface';
import { businessSegment } from '../../../helpers/options';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form1 = (props: FormikProps<IMentor> & IFormProps) => {
  const { showAlertErr, visualization } = props;
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Nome</C.titleTextField>
        <C.textField
          maxLength={60}
          placeholder="Digite o nome do mentor"
          name="name"
          value={props.values.name}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.name.length}/60`}</p>
        {showAlertErr && props.values.name === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Título</C.titleTextField>
        <C.textField
          maxLength={100}
          placeholder="Digite o título"
          name="title"
          value={props.values.title}
          onChange={props.handleChange}
          disabled={visualization}
        />
        <p>{`${props.values.title.length}/100`}</p>
        {showAlertErr && props.values.title === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
      <C.space />
      <C.space />
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Gênero</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="genre"
            value={props.values.genre}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Todos os Gêneros</em>
            </MenuItem>
            <MenuItem value={'M'}>Masculino</MenuItem>
            <MenuItem value={'F'}>Feminino</MenuItem>
            <MenuItem value={'outro'}>Outro</MenuItem>
          </Select>
          {showAlertErr && props.values.genre === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Área de Atuação</C.titleTextField>
          <Select
            sx={theme}
            className="select"
            name="businessSegment"
            value={props.values.businessSegment}
            onChange={props.handleChange}
            displayEmpty
            disabled={visualization}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessSegment.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {showAlertErr && props.values.businessSegment === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
    </C.container>
  );
};
export default Form1;
