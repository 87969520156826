import Api from '../api';
import { ICompany } from '../interfaces/company.interface';
import { getAccessToken } from '../services/session.service';

export async function Create(data: ICompany): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.post(
    '/companies',
    {
      name: data.name,
      shortDescription: data.shortDescription,
      description: data.description,
      solution: data.solution,
      companyCode: data.companyCode,
      companyType: data.companyType,
      accelerationProgram: data.accelerationProgram,
      foundingDate: data.foundingDate,
      partners: data.partners,
      employees: Number(data.employees),
      businessSegment: data.businessSegment,
      businessStage: data.businessStage,
      businessModel: data.businessModel,
      businessType: data.businessType,
      contact: data.contact,
      address: {
        street: data.address.street,
        number: data.address.number,
        zipCode: data.address.zipCode,
        district: data.address.district,
        city: data.address.city,
        state: data.address.state,
        street2: data.address.street2,
      },
      socialMedia: data.socialMedia,
      logoId: data.logoId,
      bannerId: data.bannerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function Get(): Promise<ICompany[] | undefined> {
  let response: ICompany[] | undefined;

  await Api.get(`/companies`, {
    params: { size: 1000 },
  })
    .then(res => {
      return (response = res.data.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}

export async function GetById(id: string): Promise<ICompany | undefined> {
  let response: ICompany | undefined;

  await Api.get(`/companies/${id}`)
    .then(res => {
      return (response = res.data);
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err));

  return response;
}

export async function Update(data: ICompany): Promise<boolean> {
  const token = getAccessToken();

  let response = false;

  await Api.put(
    `/companies/${data.id}`,
    {
      name: data.name,
      shortDescription: data.shortDescription,
      description: data.description,
      solution: data.solution,
      companyCode: data.companyCode,
      companyType: data.companyType,
      accelerationProgram: data.accelerationProgram,
      foundingDate: data.foundingDate,
      partners: data.partners,
      employees: Number(data.employees),
      businessSegment: data.businessSegment,
      businessStage: data.businessStage,
      businessModel: data.businessModel,
      businessType: data.businessType,
      contact: data.contact,
      address: data.address,
      socialMedia: data.socialMedia,
      bannerId: data.bannerId,
      logoId: data.logoId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(res => {
      console.log(res.data);
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}
