import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { FormikProps } from 'formik';
import { MaskCEP, MaskOnlyNumbers } from '../../../helpers/mask';
import { ICompany } from '../../../interfaces/company.interface';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form4 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization } = props;
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (MaskOnlyNumbers(props.values.address.zipCode).length === 8) {
      fetch(
        `https://api.postmon.com.br/v1/cep/${MaskOnlyNumbers(
          props.values.address.zipCode,
        )}`,
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          setDisabled(true);
          props.setFieldValue(
            'address.street',
            data.logradouro ? data.logradouro : '-',
          );
          props.setFieldValue(
            'address.district',
            data.bairro ? data.bairro : '-',
          );
          props.setFieldValue('address.city', data.cidade ? data.cidade : '-');
          props.setFieldValue('address.state', data.estado ? data.estado : '-');
        });
    } else {
      setDisabled(false);
    }
  }, [props.values.address.zipCode]);

  return (
    <C.container>
      <C.titleMaster>Localização</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>CEP*</C.titleTextField>
          <C.textField
            placeholder="CEP"
            name="address.zipCode"
            value={props.values.address.zipCode}
            maxLength={9}
            onChange={e =>
              props.setFieldValue('address.zipCode', MaskCEP(e.target.value))
            }
            disabled={visualization}
          />
          {showAlertErr && props.values.address.zipCode === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Endereço*</C.titleTextField>
          <C.textField
            placeholder="Endereço"
            name="address.street"
            value={props.values.address.street}
            onChange={props.handleChange}
            disabled={visualization}
          />
          {showAlertErr && props.values.address.street === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />

      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Número*</C.titleTextField>
          <C.textField
            placeholder="Número"
            name="address.number"
            value={props.values.address.number}
            onChange={e => {
              props.setFieldValue(
                'address.number',
                MaskOnlyNumbers(e.target.value),
              );
            }}
            disabled={visualization}
          />
          {showAlertErr && props.values.address.number === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Complemento</C.titleTextField>
          <C.textField
            placeholder="Complemento"
            name="address.street2"
            value={props.values.address.street2}
            onChange={props.handleChange}
            disabled={visualization}
          />
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />

      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>Estado*</C.titleTextField>
          <C.textField
            placeholder="Estado"
            name="address.state"
            value={props.values.address.state}
            disabled={visualization}
            onChange={props.handleChange}
          />
          {showAlertErr && props.values.address.state === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>

        <C.boxTextField>
          <C.titleTextField>Cidade*</C.titleTextField>
          <C.textField
            placeholder="Cidade"
            name="address.city"
            value={props.values.address.city}
            onChange={props.handleChange}
            disabled={visualization}
          />
          {showAlertErr && props.values.address.city === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Bairro*</C.titleTextField>
          <C.textField
            placeholder="Bairro"
            name="address.district"
            value={props.values.address.district}
            onChange={props.handleChange}
            disabled={visualization}
          />
          {showAlertErr && props.values.address.district === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper33>
      <C.textFieldWraper50></C.textFieldWraper50>
    </C.container>
  );
};
export default Form4;
