import { shade } from 'polished';
import styled from 'styled-components';

export const containerFilters = styled.div`
  width: 80% !important;

  .MuiButton-text {
    min-width: 0 !important;
    padding: 5px !important;
    border-radius: 50px !important;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  svg {
    font-size: 30px !important;
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
`;

export const containerTitle = styled.div`
  display: flex;
  padding: 20px 40px;
  align-items: center;
  justify-content: space-between;

  .MuiButton-text {
    min-width: 0;
    padding: 5px;
    border-radius: 50px !important;
    color: #26d2a9 !important;
  }
  svg {
    font-size: 30px !important;
    color: #26d2a9 !important;
  }
`;
export const title = styled.h2`
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;

export const containerButtons = styled.div`
  display: flex;

  padding: 20px 40px;
  justify-content: space-between;

  .btn_apply {
    font-weight: 400;
    padding: 8px 25px;
    margin-left: -10px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};

    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }

    @media screen and (max-width: 730px) {
      padding: 8px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    }
  }

  .btn_clear {
    font-weight: 400;
    padding: 8px 25px;
    margin-left: -10px;
    text-transform: none;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    border: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;

    &:hover {
      transition: none;
      border: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};
    }

    @media screen and (max-width: 730px) {
      padding: 8px 15px;
      font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
    }
  }
`;

export const containerTextFields = styled.div`
  width: 100%;
  padding: 10px 20px;

  @media screen and (max-width: 730px) {
    padding: 0px;
  }
`;
export const titleTextField = styled.p`
  margin: 10px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
`;
export const textFieldWraper100 = styled.div`
  margin: 10px 0;
`;
export const textFieldWraper50 = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media screen and (max-width: 730px) {
    display: block;
  }
`;

export const boxTextField = styled.div`
  width: 100%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }

  @media screen and (max-width: 730px) {
    margin: 30px 0;
  }
`;

export const boxTextField50 = styled.div`
  width: 50%;

  .select {
    border: 0 !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
    font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;

    em {
      font-style: normal;
    }
  }

  @media screen and (max-width: 730px) {
    margin: 30px 0;
    width: 100%;
  }
`;

export const textField = styled.input`
  border: 0;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 1px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  @media only screen and (max-width: 1090px) {
    margin: 0 auto 20px auto;
  }

  @media only screen and (max-width: 590px) {
    width: 100%;
  }
`;
