import React, { useState } from 'react';
import * as C from './styles';
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import Navbar from '../../../components/Navbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavbarMobile from '../../../components/NavbarMobile';
import Api from '../../../api';
import theme from '../../../styles/theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPassword = () => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showAlertSuc, setShowAlertSuc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const sendRecoveryPassword = async () => {
    setLoading(true);
    if (password != confirmPassword) {
      setErrMessage('As duas senhas devem ser iguais.');
      setLoading(false);
      setShowAlertErr(true);
      setTimeout(() => {
        setShowAlertErr(false);
      }, 2000);
    } else {
      await Api.post('/reset-password', {
        email: searchParams.get('email')?.trim(),
        password: confirmPassword,
        token: searchParams.get('token'),
      })
        .then(() => {
          setLoading(false);

          setShowAlertSuc(true);
          setTimeout(() => {
            setShowAlertSuc(false);
            navigation('/login');
          }, 2000);
        })
        .catch(e => {
          setErrMessage(e.response.data.message);
          setLoading(false);
          setShowAlertErr(true);
          setTimeout(() => {
            setShowAlertErr(false);
          }, 2000);
        });
    }
  };

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm>
        <C.form>
          <C.title>Alteração de senha</C.title>
          <C.space />
          <OutlinedInput
            sx={theme}
            placeholder="Senha"
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <C.infoTextField>Digite a nova senha</C.infoTextField>

          <C.textFieldSpace />

          <OutlinedInput
            sx={theme}
            placeholder="Senha"
            id="outlined-adornment-password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <C.infoTextField>Confirme sua nova senha</C.infoTextField>

          {showAlertSuc ? (
            <Alert
              style={{ marginTop: 10 }}
              variant="outlined"
              severity="success"
              className="alert"
            >
              {'Senha alterada com sucesso'}
            </Alert>
          ) : null}
          {showAlertErr ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              {errMessage}
            </Alert>
          ) : null}

          <Button
            variant="contained"
            className="btn_login"
            onClick={() => {
              sendRecoveryPassword();
            }}
            disabled={loading}
          >
            Enviar
          </Button>
          <Button variant="text" className="btn_recover">
            Voltar para login
          </Button>
        </C.form>
      </C.containerForm>
      <C.containerButtonSupport>
        <Button
          variant="contained"
          className="btn_support"
          onClick={() => navigation('/criar-conta')}
        >
          Cadastre-se
        </Button>
      </C.containerButtonSupport>
    </C.container>
  );
};

export default ResetPassword;
