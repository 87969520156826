import { AnyAction, Dispatch } from 'redux';
import { IToken } from '../../interfaces/token.interface';
import { getAccessToken } from '../../services/session.service';
import { setEmail, setId, setName, setRole } from '../slice/userSlice';
import { decodeToken } from './decodeToken';

export async function UpdateRedux(dispatch: Dispatch<AnyAction>) {
  const token = getAccessToken();
  if (token) {
    const infos = decodeToken(token);
    const infoUser: IToken = infos;

    dispatch(setId(infoUser.id));
    dispatch(setRole(infoUser.role));
    dispatch(setName(infoUser.name));
    dispatch(setEmail(infoUser.email));
  }
}

export async function UpdateName(dispatch: Dispatch<AnyAction>, name: string) {
  dispatch(setName(name));
}
