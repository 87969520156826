import React, { useState, useEffect } from 'react';
import * as C from './styles';
import SlideMenu from '../Slide';
import TableCities from './Table';
import { Button, IconButton } from '@mui/material';
import NavbarAdmin from '../../../components/NavbarAdmin';
import FilterModal from '../../../components/Cities/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICity } from '../../../services/cities.service';
import { getAccessToken } from '../../../services/session.service';
import Details from '../../User/list/Details';
import Api from '../../../api';

const AdminCities = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [filterRegion, setFilterRegion] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [visualize, setVisualize] = useState(true);
  const token = getAccessToken();
  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);

  const handleVisualize = (id: string) => {
    setSelectedId(id);
    setVisualize(true);
    setShow(true);
  };
  const handleAdd = () => {
    setVisualize(false);
    setShow(true);
  };

  const handleLoad = async () => {
    const response = await Api.get('/cities?page=1&size=1000', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setCityList(response.data.data);
  };

  useEffect(() => {
    handleLoad();
    window.scrollTo(0, 0);
  }, []);
  return (
    <C.container>
      <C.wraper>
        <NavbarAdmin secondary={true} />
        <C.header>
          <C.headerWraper>
            <C.containerFiltersHeader>
              <C.filters>
                <C.title>Cidades</C.title>
                <C.containerTextField>
                  <C.textFieldWraper>
                    <SearchRoundedIcon />
                    <C.textField
                      placeholder="Cidade"
                      value={filterCity}
                      onChange={e => setFilterCity(e.target.value)}
                    />
                  </C.textFieldWraper>
                  <Button variant="contained" onClick={handleFilters}>
                    Filtros
                  </Button>
                  <Button variant="contained" onClick={handleAdd}>
                    Cadastrar Cidade
                  </Button>
                </C.containerTextField>
              </C.filters>
            </C.containerFiltersHeader>
          </C.headerWraper>
        </C.header>
        <SlideMenu currentStep={6} />
        <C.containerTables>
          <TableCities
            setSelectedId={handleVisualize}
            setRefresh={setRefresh}
            filterCity={filterCity}
            filterRegion={filterRegion}
          />
        </C.containerTables>
      </C.wraper>
      <FilterModal
        state={showFilter}
        setState={setShowFilter}
        cities={cityList}
        setFilterCity={setFilterCity}
        setFilterRegion={setFilterRegion}
      />
      {show && (
        <Details
          visualization={visualize}
          category="city"
          showModal={show}
          setShowModal={setShow}
          id={visualize ? selectedId : ''}
        />
      )}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default AdminCities;
