import React, { useEffect } from 'react';
import * as C from './styles';
import { useDispatch } from 'react-redux';
import Logo from '../../assets/logobranco.png';
import { useNavigate } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/MenuRounded';
import { logOut } from '../../context/slice/userSlice';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Button, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';

const NavbarMobileUser = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorElTwo);
  const openNoti = Boolean(anchorElNotification);
  const openSett = Boolean(anchorElSettings);

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(logOut());
    navigation('/');
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElNotification) {
      setAnchorElNotification(event.currentTarget);
      return;
    }
    setAnchorElNotification(null);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElSettings) {
      setAnchorElSettings(event.currentTarget);
      return;
    }
    setAnchorElSettings(null);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElTwo) {
      setAnchorElTwo(event.currentTarget);
      return;
    }
    setAnchorElTwo(null);
  };
  const handleClose = () => {
    setAnchorElTwo(null);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1090) setAnchorElTwo(null);
    });
  }, []);

  return (
    <C.container>
      <C.wraper>
        <C.containerBurgerMenu>
          <Button variant="text" onClick={handleClick}>
            <MenuIcon />
          </Button>
        </C.containerBurgerMenu>
        <C.containerLogo>
          <C.logo src={Logo} />
        </C.containerLogo>
        <C.containerBurgerMenu>
          {/* <IconButton
            aria-label="delete"
            size="large"
            onClick={handleClickNotification}
          >
            <NotificationsRoundedIcon />
            <C.dotNotification />
          </IconButton> */}
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleClickSettings}
          >
            <AccountCircleRoundedIcon />
          </IconButton>
        </C.containerBurgerMenu>
      </C.wraper>
      <Menu
        style={{ top: '26px', position: 'absolute', zIndex: '99999999' }}
        className="popper_menu"
        anchorEl={anchorElTwo}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        aria-haspopup="true"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigation('/')}>Página Inicial</MenuItem>
        <MenuItem onClick={() => navigation('/startups')}>Startups</MenuItem>
        <MenuItem onClick={() => navigation('/empresas')}>Empresas</MenuItem>
        <MenuItem onClick={() => navigation('/instituicoes')}>
          Instituições
        </MenuItem>
        <MenuItem onClick={() => navigation('/parques-tecnologicos')}>
          Parques Tecnológicos
        </MenuItem>
        <MenuItem onClick={() => navigation('/ambiente-inovacao')}>
          Ambientes de Inovação
        </MenuItem>
        <MenuItem onClick={() => navigation('/mentores')}>
          Banco de Mentores
        </MenuItem>
        <MenuItem onClick={() => navigation('/cidades')}>Cidades</MenuItem>
        <MenuItem onClick={() => navigation('/agenda-eventos')}>
          Eventos
        </MenuItem>
        <MenuItem onClick={() => navigation('/contato')}>Contato</MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElNotification}
        open={openNoti}
        onClose={handleCloseNotification}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <C.itemNotification>
          <C.textNotification>Cadastro de Startup aprovado!</C.textNotification>
        </C.itemNotification>
        <Divider />
        <C.itemNotification>
          <C.textNotification>
            Cadastro de Empresa aguardando ativação
          </C.textNotification>
        </C.itemNotification>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorElSettings}
        open={openSett}
        onClose={handleCloseSettings}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigation('/usuario')}>
          <ListItemIcon>
            <SettingsRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Configurações</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <SubdirectoryArrowLeftRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </C.container>
  );
};

export default NavbarMobileUser;
