import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as C from './styles';
import { Button } from '@mui/material';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import { type } from '../../../interfaces/card.interface';
import NavbarMobile from '../../../components/NavbarMobile';
import AddModal from '../../../components/Institutions/Add';
import Instituicoes from '../../../assets/instituicoes.png';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterModal from '../../../components/Institutions/Filter';
import { isLogged } from '../../../services/session.service';
import { IInstitutions } from '../../../interfaces/institutions.interface';
import * as InstitutionsService from '../../../services/Institutions.service';
import Card from '../../../components/Card';

const Instituitons = () => {
  const navigation = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [institutions, setInstitutions] = useState<IInstitutions[]>();
  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterCity, setFilterCity] = useState('');

  const [filteredInstitutions, setFilteredInstitutions] =
    useState<IInstitutions[]>();

  const handleAdd = () => {
    if (isLogged()) {
      setShowAdd(!showAdd);
    } else {
      navigation('/login');
    }
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  const getInstitutions = async () => {
    const response: IInstitutions[] | undefined =
      await InstitutionsService.Get();
    setInstitutions(response);
  };

  useEffect(() => {
    setFilteredInstitutions(
      institutions?.filter(innovation => {
        const nameMatch = innovation.name
          .toLowerCase()
          .includes(filterName.toLowerCase());
        const typeMatch = innovation.type
          .toLowerCase()
          .includes(filterType.toLowerCase());
        const cityMatch = innovation.address.city
          .toLowerCase()
          .includes(filterCity.toLowerCase());

        return nameMatch && typeMatch && cityMatch;
      }),
    );
  }, [filterName, filterType, filterCity, institutions]);

  useEffect(() => {
    getInstitutions();
    window.scrollTo(0, 0);
  }, []);

  return (
    <C.container>
      <Navbar secondary={false} logged={isLogged()} />
      <NavbarMobile />
      <C.wraper>
        <C.containerFilters>
          <C.filters>
            <C.title>Instituições</C.title>
            <C.containerTextField>
              <C.textFieldWraper>
                <SearchRoundedIcon />
                <C.textField
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                />
              </C.textFieldWraper>
              <Button variant="contained" onClick={handleFilters}>
                Filtros
              </Button>
            </C.containerTextField>
            <Button
              variant="contained"
              className="btn_add_startup"
              onClick={handleAdd}
            >
              Cadastrar Instituição
            </Button>
          </C.filters>

          <Button
            variant="contained"
            className="btn_mobile"
            onClick={handleFilters}
          >
            Filtros
          </Button>

          <C.containerButtonAdd>
            <Button
              variant="contained"
              className="btn_mobile"
              onClick={handleAdd}
            >
              Cadastrar Instituição
            </Button>
          </C.containerButtonAdd>
        </C.containerFilters>

        <C.containerImage>
          <C.image>
            <img src={Instituicoes} alt="Instituição" />
          </C.image>
        </C.containerImage>
      </C.wraper>
      <C.fillEfect />
      <C.wraperTwo>
        <C.containerCards>
          {filteredInstitutions?.map(item => (
            <Card
              key={item.id}
              title={item.title}
              city={item.address.city}
              name={item.name}
              type={type.First}
              id={item.id ? item.id : ''}
              logoId={item.logoId}
              path="detalhes-instituicoes"
            />
          ))}
        </C.containerCards>
      </C.wraperTwo>
      <FilterModal
        show={showFilter}
        state={showFilter}
        setState={setShowFilter}
        setFilterName={setFilterName}
        setFilterType={setFilterType}
        setFilterCity={setFilterCity}
      />
      <AddModal show={showAdd} setShow={setShowAdd} />
      <Footer />
    </C.container>
  );
};

export default Instituitons;
