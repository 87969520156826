import React from 'react';
import * as C from './styles';
import { FormikProps } from 'formik';
import { MaskPhone } from '../../../helpers/mask';
import { ITechnologyPark } from '../../../interfaces/technologyParks.interface';
import { Alert } from '@mui/material';
import { IFormProps } from '../../../interfaces/form.interface';

const Form4 = (props: FormikProps<ITechnologyPark> & IFormProps) => {
  const { showAlertErr, visualization, message } = props;

  return (
    <C.container>
      <C.titleMaster>Contato</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Site</C.titleTextField>
          <C.textField
            placeholder="Site"
            name="contact.site"
            value={props.values.contact.site}
            onChange={props.handleChange}
            disabled={visualization}
          />
        </C.boxTextField>

        <C.boxTextField>
          <C.titleTextField>Telefone*</C.titleTextField>
          <C.textField
            placeholder="Telefone"
            name="contact.phone"
            value={props.values.contact.phone}
            maxLength={15}
            onChange={e =>
              props.setFieldValue('contact.phone', MaskPhone(e.target.value))
            }
            disabled={visualization}
          />
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />

      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Instagram</C.titleTextField>
          <C.textField
            placeholder="Instagram"
            name="socialMedia.instagram"
            value={props.values.socialMedia.instagram}
            onChange={props.handleChange}
            disabled={visualization}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>E-mail Corporativo*</C.titleTextField>
          <C.textField
            placeholder="E-mail"
            name="contact.email"
            value={props.values.contact.email}
            onChange={props.handleChange}
            disabled={visualization}
          />
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />

      <C.textFieldWraper33>
        <C.boxTextField>
          <C.titleTextField>YouTube</C.titleTextField>
          <C.textField
            placeholder="YouTube"
            name="socialMedia.youtube"
            value={props.values.socialMedia.youtube}
            disabled={visualization}
            onChange={props.handleChange}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Facebook</C.titleTextField>
          <C.textField
            placeholder="Facebook"
            name="socialMedia.facebook"
            value={props.values.socialMedia.facebook}
            disabled={visualization}
            onChange={props.handleChange}
          />
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Linkedin</C.titleTextField>
          <C.textField
            placeholder="Linkedin"
            name="socialMedia.linkedin"
            value={props.values.socialMedia.linkedin}
            onChange={props.handleChange}
            disabled={visualization}
          />
        </C.boxTextField>
      </C.textFieldWraper33>
      {showAlertErr ? (
        <Alert
          variant="outlined"
          severity="error"
          className="alert"
          sx={{ mt: 1 }}
        >
          {message}
        </Alert>
      ) : null}
    </C.container>
  );
};
export default Form4;
