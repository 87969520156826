const validatePassword = (passOne: string, passTwo?: string) => {
  const verify = {
    status: true,
    message: '',
  };

  if (passTwo) {
    if (passOne.trim() !== passTwo.trim()) {
      verify.status = false;
      verify.message = 'As senhas são diferentes.';
      return verify;
    }
  }
  if (passTwo) {
    if (!validPASS(passOne) || !validPASS(passTwo)) {
      verify.status = false;
      verify.message = 'Sua senha possui o formato inválido.';
      return verify;
    }
  } else {
    if (!validPASS(passOne)) {
      verify.status = false;
      verify.message = 'Sua senha possui o formato inválido.';
      return verify;
    }
  }

  return verify;
};

const validPASS = (pass: string) => {
  const re = /(?=^.{6,}$)(?=.*[a-zA-Z])(?=.*[0-9])/;
  return re.test(pass);
};

export default validatePassword;
