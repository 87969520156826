import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 200px;
  background-color: ${({ theme }) => theme.COLORS.GREY_V3};

  .MuiLinearProgress-root {
    background-color: rgba(255, 202, 67, 0.2) !important;
  }
  .linear_progress .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
  }

  .MuiTab-root {
    min-width: 600px !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
    @media screen and (max-width: 1330px) {
      min-width: 400px !important;
    }
    @media screen and (max-width: 830px) {
      min-width: 300px !important;
    }
    @media screen and (max-width: 630px) {
      min-width: 200px !important;
    }

    @media screen and (max-width: 530px) {
      min-width: 100px !important;
    }
  }
  .Mui-selected {
    color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2} !important;
  }
`;

export const header = styled.div`
  width: 100%;
  padding: 0 2%;
  display: flex;
  min-height: 290px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_V4};
`;

export const containerTitles = styled.div`
  width: 400px;
  text-align: center;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;
export const title = styled.h2`
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  font-size: ${({ theme }) => theme.FONT_SIZES.XX}em;

  @media screen and (max-width: 630px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  }
`;
export const subTitle = styled.h4`
  color: #ffff;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  @media screen and (max-width: 630px) {
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  }
`;

export const containerButtonScrollTop = styled.div<{ show: boolean }>`
  width: 100%;
  height: 60px;
  z-index: 13000;
  margin-top: 40px;

  position: fixed;
  bottom: 10px;
  right: 20px;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: end;
  .MuiIconButton-root {
    width: 50px !important;
    height: 50px !important;
    min-width: 0;
    padding: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    box-shadow: 0px 4px 8px rgba(142, 141, 208, 0.16);
    &:hover {
      transform: translateY(-7px);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 20px -7px;
      background-color: ${({ theme }) => theme.COLORS.YELLOW_V2} !important;
    }

    svg {
      color: ${({ theme }) => theme.COLORS.BLACK};
      font-size: 40px;
    }
  }

  @media screen and (max-width: 980px) {
    justify-content: center;
    bottom: 0;
    right: 0;
  }
`;
