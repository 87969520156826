import { shade } from 'polished';
import styled from 'styled-components';
import Image from '../../../assets/banner_home.png';

export const container = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding-bottom: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${Image});

  .btn_support {
    font-weight: 700;
    max-width: 200px;
    margin-top: 50px;
    padding: 10px 35px;
    text-transform: none;
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.YELLOW};
    &:hover {
      background-color: ${shade(0.2, '#FFCA43')} !important;
    }
  }

  @media screen and (max-height: 700px) {
    height: 100%;
  }
  @media screen and (max-width: 780px) {
    height: none;
  }
`;

export const containerForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 70px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 780px) {
    padding: 5%;
  }
`;
export const form = styled.div`
  width: 450px;
  height: auto;
  padding: 30px;
  border-radius: 4px;
  background-color: #fff;
  .MuiOutlinedInput-root {
    border: 0 !important;
    width: 100% !important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    font-family: 'Montserrat' !important;
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.COLORS.BLACK} !important;
    ::placeholder {
      opacity: 1 !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    &:hover {
      outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
    }
  }

  .alert {
    margin-top: 50px !important;
    padding: 10px 15px !important;
  }

  .btn_login {
    font-weight: 700;
    width: 100%;
    margin-top: 50px;
    padding: 10px 35px;
    text-transform: none;
    color: #fff !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
    background-color: ${({ theme }) => theme.COLORS.GREEN_V2};
    &:hover {
      background-color: ${shade(0.2, '#26D2A9')} !important;
    }
  }

  .btn_recover {
    display: block;
    margin: 30px auto 0px auto;
    color: ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;
export const title = styled.h2`
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  font-size: ${({ theme }) => theme.FONT_SIZES.XL}em;
  text-align: center;
`;

export const space = styled.div`
  margin: 0 0 60px 0;
`;

export const textField = styled.input`
  border: 0;
  color: ${({ theme }) => theme.COLORS.BLACK} !important;
  width: 100%;
  padding: 20px;
  height: 3.6375em;
  transition: 0.1s;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.FONT_SIZES.MM}em;
  outline: 1px solid ${({ theme }) => theme.COLORS.GREY_V2};

  &:focus {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
  &:hover {
    transition: 0.1s;
    outline: 2px solid ${({ theme }) => theme.COLORS.GREEN_V2};
  }
`;

export const containerButtonSupport = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const errorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 30px;
  display: block;
`;

export const messageTerms = styled.p`
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 60px;
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: ${({ theme }) => theme.FONT_SIZES.SM}em;
  text-align: center;
`;

export const checkBoxes = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const linkAcepteds = styled.a`
  text-decoration: none;
  font-weight: 600;
  left: -10px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${shade(0.2, '#26D2A9')} !important;
  }

  color: ${({ theme }) => theme.COLORS.GREEN_V2};
`;
