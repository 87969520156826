import React from 'react';
import * as C from './styles';
import { Dispatch, SetStateAction } from 'react';
import { Alert } from '@mui/material';
interface IForm {
  pitch: string;
  setPitch: Dispatch<SetStateAction<string>>;
  showAlertErr: boolean;
  visualization: boolean;
}

const Form2 = ({
  pitch,
  setPitch,
  showAlertErr,
  visualization = false,
}: IForm) => {
  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleTextField>Pitch*</C.titleTextField>
        <C.textAreaField
          placeholder="Faça o pitch da sua startup..."
          className="textaera_master"
          value={pitch}
          maxLength={2000}
          onChange={e => setPitch(e.target.value)}
          disabled={visualization}
        />
        <p>{`${pitch.length}/2000`}</p>
        {showAlertErr && pitch == '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form2;
