import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import * as C from './styles';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { themeTable } from '../../../../styles/theme/themeMui';

import Details from '../Details';
import EditIcon from '@mui/icons-material/EditRounded';
import { IMines } from '../../../../interfaces/mine.interface';

interface Column {
  id: 'type' | 'name' | 'created_at' | 'actions';
  label: string;
  maxWidth?: number;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'type', label: 'Tipo de Cadastro', minWidth: 250 },
  { id: 'name', label: 'Nome do Cadastro', minWidth: 250 },
  { id: 'created_at', label: 'Data do Cadastro', minWidth: 250 },
  { id: 'actions', label: 'Ações', minWidth: 10 },
];
interface ITable {
  listPendeng: IMines;
  refresh: () => Promise<void>;
  setButtonScroll: Dispatch<SetStateAction<boolean>>;
}

const TablePendeng = ({ listPendeng, refresh, setButtonScroll }: ITable) => {
  const count = 5;
  const [page, setPage] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(count);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [showEdit, setShowEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [registerId, setRegisterId] = useState('');
  const [registerCategory, setRegisterCotegory] = useState('');

  const handleEdit = (id: string, category: string) => {
    setRegisterId(id);
    setShowEdit(!showEdit);
    setRegisterCotegory(category);
  };
  useEffect(() => {
    setButtonScroll(!showEdit);
  }, [showEdit]);

  useEffect(() => {
    setPage(0);
    setRowsCount(count);
    setRowsPerPage(count);
  }, [listPendeng]);
  return (
    <C.containerTable>
      <C.titleTable>Pendentes</C.titleTable>
      <Paper sx={themeTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table_head_desk">
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listPendeng.data ? (
                listPendeng.data.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {columns.map(column => {
                        const value = row[column.id];

                        const formatedValue =
                          column.format && typeof value === 'number'
                            ? column.format(value)
                            : column.id === 'created_at'
                            ? new Date(value).toLocaleDateString()
                            : value;
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                              column.id === 'name' ? 'table_cell_click' : ''
                            }
                          >
                            {column.id === 'actions' && (
                              <C.containerButtonsActions>
                                <IconButton
                                  className="btn_edit"
                                  onClick={() =>
                                    handleEdit(row.id, row.category)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </C.containerButtonsActions>
                            )}
                            {formatedValue}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {showEdit ? (
            <Details
              id={registerId}
              showModal={showEdit}
              setShowModal={setShowEdit}
              category={registerCategory}
              refresh={refresh}
            />
          ) : null}
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Itens"
          labelDisplayedRows={({ from, to, count }) => {
            return from + ' à ' + to + ' de ' + count;
          }}
          rowsPerPageOptions={[5, 10, 25, 30]}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => setShowEdit(false)}
          onRowsPerPageChange={() => setShowEdit(false)}
        />
      </Paper>
    </C.containerTable>
  );
};

export default TablePendeng;
