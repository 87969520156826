import * as React from 'react';
import * as C from './styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { theme, themeDialog } from '../../../styles/theme/themeMui';
import { IFilterUserAdmin } from '../../../interfaces/filter.interface';

const FilterModal = ({
  setState,
  state,
  name,
  setName,
  email,
  setEmail,
  role,
  setRole,
  handle,
}: IFilterUserAdmin) => {
  const handleClose = () => {
    setState(false);
  };
  const handleClear = () => {
    setName('');
    setEmail('');
    setRole('');
  };

  const handleApply = () => {
    handle();
    setState(false);
  };

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state}
      >
        <C.containerTitle>
          <C.title>Filtrar</C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          <C.containerTextFields>
            <C.textFieldWraper50>
              <C.boxTextField>
                <C.boxTextField>
                  <C.titleTextField>Nome</C.titleTextField>
                  <C.textField
                    placeholder="Digite o nome"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </C.boxTextField>
              </C.boxTextField>
              <C.boxTextField>
                <C.titleTextField>E-mail</C.titleTextField>
                <C.textField
                  placeholder="Digite o e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </C.boxTextField>
            </C.textFieldWraper50>
            <C.textFieldWraper50>
              <C.boxTextField50>
                <C.titleTextField>Tipo</C.titleTextField>
                <Select
                  sx={theme}
                  className="select"
                  value={role}
                  onChange={e => setRole(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Todas os Tipos</em>
                  </MenuItem>
                  <MenuItem value="user">Usuário</MenuItem>
                  <MenuItem value="admin">Administrador</MenuItem>
                </Select>
              </C.boxTextField50>
            </C.textFieldWraper50>
          </C.containerTextFields>
        </DialogContent>

        <C.containerButtons>
          <Button
            variant="outlined"
            className="btn_clear"
            onClick={handleClear}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            className="btn_apply"
            onClick={handleApply}
          >
            Aplicar Filtros
          </Button>
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default FilterModal;
