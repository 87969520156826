import React from 'react';
import * as C from './styles';
import Navbar from '../../../components/Navbar';
import { useNavigate } from 'react-router-dom';
// import { theme } from '../../styles/theme/themeMui';
import NavbarMobile from '../../../components/NavbarMobile';

import { Button } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import Visibility from '@mui/icons-material/Visibility';
// import InputAdornment from '@mui/material/InputAdornment';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';

//interface State {
//   amount: string;
//   password: string;
//   weight: string;
//   weightRange: string;
//   showPassword: boolean;
// }

const ResetPass = () => {
  const navigation = useNavigate();
  // const [values, setValues] = React.useState<State>({
  //   amount: '',
  //   password: '',
  //   weight: '',
  //   weightRange: '',
  //   showPassword: false,
  // });

  // const handleChange =
  //   (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setValues({ ...values, [prop]: event.target.value });
  //   };

  // const handleClickShowPassword = () => {
  //   setValues({
  //     ...values,
  //     showPassword: !values.showPassword,
  //   });
  // };

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  // ) => {
  //   event.preventDefault();
  // };

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm>
        <C.form>
          <C.title>Redefinindo Senha</C.title>

          <C.infoTitle>
            Um código foi enviado para o seu e-mail. Digite ele abaixo.
          </C.infoTitle>

          <C.textField placeholder="Código" />

          {/* <OutlinedInput
            sx={theme}
            placeholder="Senha"
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          /> */}
          {/* <Button variant="contained" className="btn_login">
            Entrar
          </Button> */}
          <Button
            variant="text"
            className="btn_recover"
            onClick={() => navigation('/login')}
          >
            Voltar para login
          </Button>
        </C.form>
      </C.containerForm>
      <C.containerButtonSupport>
        <Button
          variant="contained"
          className="btn_support"
          onClick={() => navigation('/criar-conta')}
        >
          Cadastre-se
        </Button>
      </C.containerButtonSupport>
    </C.container>
  );
};

export default ResetPass;
