import React from 'react';
import * as C from './styles';
import Form1 from '../forms/form1';
import Form2 from '../forms/form2';
import Form3 from '../forms/form3';
import Form4 from '../forms/form4';
import Form5 from '../forms/form5';
import Concluded from '../../Concluded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/DoneAllRounded';

import { themeDialog } from '../../../styles/theme/themeMui';
import BusinessIcon from '@mui/icons-material/BusinessRounded';
import { IAddStartup } from '../../../interfaces/startup.interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';

import * as InstitutionsService from '../../../services/Institutions.service';
import { IInstitutions } from '../../../interfaces/institutions.interface';
import { useFormik } from 'formik';
import { checkInnovationFields } from '../../../helpers/account.check.fields';
import * as ImageService from '../../../services/image.service';

export const AddModal = ({
  setShow,
  show,
  id,
  refresh,
  visualization = false,
}: IAddStartup) => {
  const [title, setTitle] = useState('');
  const [subOne, setSubOne] = useState('');
  const [subTwo, setSubTwo] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [index, setIndex] = useState(1);
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [form4, setForm4] = useState(false);
  const [form5, setForm5] = useState(false);

  const [notReturn, setNotReturn] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const initialValues: IInstitutions = {
    id: '',
    name: '',
    title: '',
    description: '',
    contact: {
      site: '',
      email: '',
      phone: '',
    },
    address: {
      city: '',
      street2: '',
      district: '',
      number: '',
      state: '',
      street: '',
      zipCode: '',
    },
    type: '',
    socialMedia: {
      instagram: '',
      facebook: '',
      linkedin: '',
      youtube: '',
    },
    logoId: null,
    bannerId: null,
    imagePath: null,
    bannerPath: null,
    imageSelected: false,
    bannerSelected: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      // Handle Submit
    },
  });

  const handleNext = () => {
    if (!checkInnovationFields(formik.values, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);
        setErrMessage(checkInnovationFields(formik.values, index).message);
        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      if (index === 1) {
        setIndex(2);
        setForm1(false);
        setForm2(true);
      }
      if (index === 2) {
        setIndex(3);
        setForm2(false);
        setForm3(true);
      }
      if (index === 3) {
        setIndex(4);
        setForm3(false);
        setForm4(true);
      }
      if (index === 4) {
        setIndex(5);
        setForm4(false);
        setForm5(true);
      }
    }
  };

  const handlePrev = () => {
    if (index === 2) {
      setIndex(1);
      setForm1(true);
      setForm2(false);
    }
    if (index === 3) {
      setIndex(2);
      setForm2(true);
      setForm3(false);
    }
    if (index === 4) {
      setIndex(3);
      setForm4(false);
      setForm3(true);
    }
    if (index === 5) {
      setIndex(4);
      setForm5(false);
      setForm4(true);
    }
  };

  const handleConcluded = async () => {
    const data = {
      id: id,
      name: formik.values.name,
      title: formik.values.title,
      description: formik.values.description,
      contact: formik.values.contact,
      address: formik.values.address,
      type: formik.values.type,
      socialMedia: formik.values.socialMedia,
      logoId: formik.values.logoId,
      bannerId: formik.values.bannerId,
    };

    if (!checkInnovationFields(formik.values, index).success) {
      setTimeout(async () => {
        setShowAlertErr(true);
        setErrMessage(checkInnovationFields(formik.values, index).message);
        setTimeout(() => {
          setShowAlertErr(false);
        }, 2000);
      }, 200);
    } else {
      let success = false;
      if (typeof data.logoId != 'string') {
        if (data.logoId) {
          const response = await ImageService.Create(data.logoId);
          if (response.success) {
            data.logoId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com a logo. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm5(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }
      }

      if (typeof data.bannerId != 'string')
        if (data.bannerId) {
          const response = await ImageService.Create(data.bannerId);
          if (response.success) {
            data.bannerId = response.uid;
          } else {
            setTitle('Ooops!');
            setSuccess(false);
            setSubOne(
              'Algo deu errado com o banner. verifique o tamanho do arquivo ou selecione novamente.',
            );

            setTimeout(() => {
              setForm5(true);
              setNotReturn(false);
              setShowConcluded(false);
              setTitle('');
              setSubOne('');
              setSubTwo('');
            }, 5000);
            return;
          }
        }
      setForm5(false);
      setShowConcluded(true);
      setNotReturn(true);
      if (isEditing) {
        success = await InstitutionsService.Update(data);
      } else {
        success = await InstitutionsService.Create(data);
      }
      if (success) {
        if (isEditing && refresh) {
          refresh();
        }
        setSuccess(true);
        setTitle('Tudo\nPronto!');
        setSubOne(
          'Obrigada pela sua colaboração!  Assim que seu conteúdo for aprovado, ele ficará disponível para consulta no site.',
        );
        setSubTwo('');

        setTimeout(() => {
          handleClose();
        }, 4000);
      } else {
        setSuccess(false);
        setTitle('Erro');
        setSubOne(
          'Sentimos muito, porém nosso servidor não conseguiu gravar as informações. A situação deverá ser resolvida em breve.',
        );
        setSubTwo(
          'Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte.',
        );
        setTimeout(() => {
          setForm5(true);
          setNotReturn(false);
          setShowConcluded(false);
          setTitle('');
          setSubOne('');
          setSubTwo('');
        }, 5000);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setForm1(true);
    setForm2(false);
    setForm3(false);
    setForm4(false);
    setForm5(false);

    formik.setValues({
      id: '',
      name: '',
      title: '',
      description: '',
      contact: {
        site: '',
        email: '',
        phone: '',
      },
      address: {
        city: '',
        street2: '',
        district: '',
        number: '',
        state: '',
        street: '',
        zipCode: '',
      },
      type: '',
      socialMedia: {
        instagram: '',
        facebook: '',
        linkedin: '',
        youtube: '',
      },
      logoId: null,
      bannerId: null,
      imagePath: null,
      bannerPath: null,
      imageSelected: false,
      bannerSelected: false,
    });
    setShowConcluded(false);
    setIndex(1);
  };

  const setInfos = async (id: string) => {
    const response = await InstitutionsService.GetById(id);
    if (response) {
      formik.setValues({
        id: id,
        name: response.name,
        title: response.title,
        description: response.description,
        contact: {
          site: response.contact.site,
          email: response.contact.email,
          phone: response.contact.phone,
        },
        address: {
          city: response.address.city,
          street2: response.address.street2,
          district: response.address.district,
          number: response.address.number,
          state: response.address.state,
          street: response.address.street,
          zipCode: response.address.zipCode,
        },
        type: response.type,
        socialMedia: {
          instagram: response.socialMedia.instagram,
          facebook: response.socialMedia.facebook,
          linkedin: response.socialMedia.linkedin,
          youtube: response.socialMedia.youtube,
        },
        bannerId: response.bannerId,
        bannerPath:
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.bannerId,
        bannerSelected: response.bannerId ? true : false,
        logoId: response.logoId,
        imagePath:
          process.env.REACT_APP_ENV_BASE_API + '/files/' + response.logoId,
        imageSelected: response.logoId ? true : false,
      });
    }
  };

  useEffect(() => {
    if (id) {
      setInfos(id);
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <C.containerFilters className="container_filters">
      <Dialog
        sx={themeDialog}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <C.containerTitle>
          <C.title>
            <BusinessIcon />
            {isEditing ? 'Edição de Instituição' : 'Cadastro de Instituição'}
          </C.title>

          <Button variant="text" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </C.containerTitle>

        <DialogContent dividers>
          {form1 && (
            <Form1
              visualization={visualization}
              {...formik}
              showAlertErr={showAlertErr}
            />
          )}
          {form2 && (
            <Form2
              visualization={visualization}
              {...formik}
              showAlertErr={showAlertErr}
            />
          )}
          {form3 && (
            <Form3
              visualization={visualization}
              {...formik}
              showAlertErr={showAlertErr}
            />
          )}
          {form4 && (
            <Form4
              visualization={visualization}
              {...formik}
              showAlertErr={showAlertErr}
              message={errMessage}
            />
          )}
          {form5 && (
            <Form5
              showAlertErr={showAlertErr}
              visualization={visualization}
              {...formik}
            />
          )}
          {showConcluded && (
            <Concluded
              title={title}
              subOne={subOne}
              subTwo={subTwo}
              success={success}
            />
          )}
        </DialogContent>
        <C.containerDotsMobile>
          <C.dot disabled={form1} />
          <C.dot disabled={form2} />
          <C.dot disabled={form3} />
          <C.dot disabled={form4} />
          <C.dot disabled={form5} />
        </C.containerDotsMobile>
        <C.containerButtons disabled={form1}>
          <Button
            variant="outlined"
            className="btn_previus"
            disabled={form1 || notReturn}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
            Anterior
          </Button>
          <C.containerDots>
            <C.dot disabled={form1} />
            <C.dot disabled={form2} />
            <C.dot disabled={form3} />
            <C.dot disabled={form4} />
            <C.dot disabled={form5} />
          </C.containerDots>
          {form5 || showConcluded ? (
            <Button
              variant="outlined"
              className="btn_send"
              onClick={handleConcluded}
              disabled={!form5 || visualization}
            >
              Enviar
              <SendIcon />
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="btn_next"
              onClick={handleNext}
            >
              Próximo
              <ArrowNextIcon />
            </Button>
          )}
        </C.containerButtons>
      </Dialog>
    </C.containerFilters>
  );
};

export default AddModal;
