import Api from '../api';
import { IMines } from '../interfaces/mine.interface';
import {
  IForm,
  IFormdataEdit,
  IFormdataTwo,
  IUsers,
} from '../interfaces/user.interface';
import { getAccessToken } from '../services/session.service';

export async function Create({ data }: IForm): Promise<any> {
  const name = data.name;
  const email = data.email ? data.email.trim() : data.email;
  const confirm = data.confirm;
  let response = false;
  let message = '';

  await Api.post('/users', { name, email, password: confirm, role: 'user' })
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      message = JSON.parse(error.request.response).message;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return { success: response, message };
}

export async function CreateByAdmin({
  confirm,
  email,
  name,
  role,
}: IFormdataTwo): Promise<any> {
  let response = false;
  let message = '';
  const token = getAccessToken();

  await Api.post(
    '/users',
    {
      name,
      email: email.trim(),
      password: confirm,
      role,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      message = JSON.parse(error.request.response).message;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return { success: response, message };
}

export async function Update({ data, setSuccess, id }: any) {
  const name = data.name;
  const email = data.email ? data.email.trim() : data.email;
  const confirm = data.confirm;

  const token = getAccessToken();
  let success = false;
  await Api.put(
    `/users/${id}`,
    {
      name,
      email,
      password: confirm,
      role: 'user',
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      success = true;
    })
    .catch(error => {
      setSuccess(false);
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return success;
}

export async function UpdateByAdmin({
  email,
  id,
  name,
}: IFormdataEdit): Promise<boolean> {
  let response = false;

  const token = getAccessToken();

  await Api.put(
    `/users/${id}`,
    {
      name,
      email: email.trim(),
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then(() => {
      response = true;
    })
    .catch(error => {
      response = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return response;
}

export async function ListMine(status: string): Promise<IMines> {
  let response: any;
  const token = getAccessToken();

  await Api.get('/users/mine', {
    params: { status: status, size: 1000 },
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(res => {
      response = res.data;
      // console.log(res.data.data);
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
    });

  return response;
}

export async function List(
  email?: string,
  role?: string,
  name?: string,
  start?: number,
  limit?: number,
  search?: string,
): Promise<IUsers | undefined> {
  let response: IUsers | undefined;
  const token = getAccessToken();

  await Api.get('/users', {
    params: {
      email: email ? email.trim() : email,
      role: role,
      name: name ? name : search,
      page: start,
      size: limit,
    },
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
    });

  return response;
}

export async function Remove(id: string | undefined): Promise<boolean> {
  let success = false;
  const token = getAccessToken();

  await Api.delete(`/users/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return success;
}
