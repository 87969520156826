import React from 'react';
import * as C from './styles';
import { useNavigate } from 'react-router-dom';
import { ICardEvent } from '../../interfaces/card.interface';
import TimeIcon from '@mui/icons-material/AccessTimeRounded';
import LocationIcon from '@mui/icons-material/FmdGoodOutlined';
import MoneyIcon from '@mui/icons-material/PaymentsOutlined';
import dayjs from 'dayjs';
import localeDe from 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import { month } from '../../helpers/options';
import { maskValue } from '../../helpers/mask';

const EventCard = ({
  admissionCharged,
  title,
  path,
  date,
  fee,
  location,
  id,
}: ICardEvent) => {
  const navigation = useNavigate();
  dayjs.extend(utc);

  const eventDate = dayjs.utc(date);
  const eventDay = eventDate.get('date');
  const eventMonth = month[eventDate.get('month')];
  const eventHour = eventDate.locale(localeDe).format('LT');

  const getCostText = () => {
    switch (admissionCharged) {
      case 0:
        return 'Sem custo';
      case 1:
        return maskValue(fee);
      default:
        return 'Consulte no site do evento';
    }
  };

  return (
    <C.container
      onClick={() => {
        path ? navigation(`/${path}/${id}`, { state: id }) : null;
      }}
    >
      <C.headerCard className="header_card">
        <div>
          <C.numberHeader>{eventDay}</C.numberHeader>
          <C.titleHeader>{eventMonth}</C.titleHeader>
        </div>
      </C.headerCard>
      <C.containerInfos>
        <C.titleDiv>
          <C.title className="text_card">{title}</C.title>
        </C.titleDiv>

        <C.infosEvent>
          <C.containerTitle>
            <TimeIcon />
            <C.titleInfos>{eventHour}</C.titleInfos>
          </C.containerTitle>
          <C.containerTitle>
            <LocationIcon />
            <C.titleInfos>{location}</C.titleInfos>
          </C.containerTitle>
          <C.containerTitle>
            <MoneyIcon />
            <C.titleInfos>{getCostText()}</C.titleInfos>
          </C.containerTitle>
        </C.infosEvent>
      </C.containerInfos>
    </C.container>
  );
};

export default EventCard;
