import React from 'react';
import * as C from './styles';
import { Alert, Button } from '@mui/material';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import { TextField } from '@mui/material';
import { theme } from '../../../styles/theme/themeMui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PhotoIcon from '@mui/icons-material/PhotoSizeSelectActualRounded';
import { ICompany } from '../../../interfaces/company.interface';
import { FormikProps } from 'formik';
import { IFormProps } from '../../../interfaces/form.interface';

const Form6 = (props: FormikProps<ICompany> & IFormProps) => {
  const { showAlertErr, visualization } = props;

  dayjs.extend(utc);

  const foundingDate = dayjs.utc(props.values.foundingDate);

  const formattingFile = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        if (image[0].size < 512000) {
          reader.addEventListener('load', () => {
            props.setFieldValue('imagePath', reader.result);
          });
          reader.readAsDataURL(image[0]);

          props.setFieldValue('logoId', image[0]);
          props.setFieldValue('imageSelected', true);
        }
      }
    }
  };

  const formattingBanner = (image: FileList | null) => {
    if (image) {
      if (image.length > 0) {
        const reader = new FileReader();
        if (image[0].size < 512000) {
          reader.addEventListener('load', () => {
            props.setFieldValue('bannerPath', reader.result);
          });
          reader.readAsDataURL(image[0]);

          props.setFieldValue('bannerId', image[0]);
          props.setFieldValue('bannerSelected', true);
        }
      }
    }
  };

  return (
    <C.container>
      <C.textFieldWraper100>
        <C.titleMaster>Mais Informações</C.titleMaster>
        <C.titleTextField>Logo</C.titleTextField>
        <C.boxLogo>
          <C.containerLogo>
            {props.values.imageSelected ? (
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundImage: `url(${props.values.imagePath})`,
                }}
                className="logo"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerLogo>
          <div className="image_wraper">
            <C.infoImage>largura 500px x altura 500px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
              disabled={visualization}
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingFile(t.target.files)}
                disabled={visualization}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
        {/* END IMAGE */}
      </C.textFieldWraper100>

      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Banner</C.titleTextField>
        <C.boxLogo>
          <C.containerBanner>
            {props.values.bannerSelected ? (
              <div
                style={{
                  width: 235,
                  height: '100%',
                  backgroundImage: `url(${props.values.bannerPath})`,
                }}
                className="banner"
              />
            ) : (
              <PhotoIcon />
            )}
          </C.containerBanner>
          <div>
            <C.infoImage>largura 1500px X Altura 400px</C.infoImage>
            <Button
              variant="contained"
              className="btn_add_image"
              component="label"
              disabled={visualization}
            >
              Selecionar Imagem
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={t => formattingBanner(t.target.files)}
                disabled={visualization}
              />
            </Button>
            <C.infoImage>Max. 512kb no formato PNG</C.infoImage>
          </div>
        </C.boxLogo>
      </C.textFieldWraper100>

      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Data de fundação</C.titleTextField>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={locale}
          >
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={foundingDate}
              onChange={e => props.setFieldValue('foundingDate', e)}
              className="datepicker_event"
              renderInput={params => <TextField sx={theme} {...params} />}
              disabled={visualization}
            />
          </LocalizationProvider>
          {showAlertErr && !foundingDate ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
    </C.container>
  );
};
export default Form6;
