import React, { useState, useEffect } from 'react';
import * as C from './styles';
import SlideMenu from '../Slide';
import TableActive from './tableActive';
import TablePendeng from './tablePendeng';
import { Button, IconButton } from '@mui/material';
import NavbarAdmin from '../../../components/NavbarAdmin';
import FilterModal from '../../../components/Mentors/Filter';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Details from '../../User/list/Details';

const AdminMentors = () => {
  const [value, setValue] = React.useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterGenre, setFilterGenre] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    }
  }, [refresh]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFilters = () => {
    setShowFilter(!showFilter);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleVisualize = (id: string) => {
    setSelectedId(id);
    setShow(true);
  };

  return (
    <C.container>
      <C.wraper>
        <NavbarAdmin secondary={true} />
        <C.header>
          <C.headerWraper>
            <C.containerFiltersHeader>
              <C.filters>
                <C.title>Banco de Mentores</C.title>
                <C.containerTextField>
                  <C.textFieldWraper>
                    <SearchRoundedIcon />
                    <C.textField
                      placeholder="Noma do Mentor"
                      value={filterName}
                      onChange={e => setFilterName(e.target.value)}
                    />
                  </C.textFieldWraper>
                  <Button variant="contained" onClick={handleFilters}>
                    Filtros
                  </Button>
                </C.containerTextField>
              </C.filters>
            </C.containerFiltersHeader>
          </C.headerWraper>
        </C.header>
        <SlideMenu currentStep={5} />
        <C.containerTables>
          <TablePendeng
            setSelectedId={handleVisualize}
            setRefresh={setRefresh}
            filterName={filterName}
            filterGenre={filterGenre}
            filterCity={filterCity}
          />
          {!refresh && (
            <TableActive
              setSelectedId={handleVisualize}
              refresh={refresh}
              filterName={filterName}
              filterGenre={filterGenre}
              filterCity={filterCity}
            />
          )}
        </C.containerTables>
      </C.wraper>
      <FilterModal
        show={showFilter}
        state={showFilter}
        setState={setShowFilter}
        setFilterName={setFilterName}
        setFilterGenre={setFilterGenre}
        setFilterTitle={setFilterTitle}
        setFilterCity={setFilterCity}
      />
      {show && (
        <Details
          visualization={true}
          category="mentor"
          showModal={show}
          setShowModal={setShow}
          id={selectedId}
        />
      )}

      <C.containerButtonScrollTop show={showScrollTop}>
        <IconButton aria-label="social" onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </C.containerButtonScrollTop>
    </C.container>
  );
};

export default AdminMentors;
