import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Navbar from '../../../components/Navbar';
import NavbarMobile from '../../../components/NavbarMobile';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import * as LoginService from '../../../services/login.service';
import { setAccessToken } from '../../../services/session.service';

const TermsAccept = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [shareAccepted, setShareAccepted] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const location = useLocation();
  const { email, password } = location.state;

  const handleTermsAccepted = (id: string) => {
    if (id == '1') {
      if (termsAccepted) {
        setTermsAccepted(false);
      } else {
        setTermsAccepted(true);
      }
    }

    if (id == '2') {
      if (shareAccepted) {
        setShareAccepted(false);
      } else {
        setShareAccepted(true);
      }
    }
  };

  const navigation = useNavigate();

  const handleLogin = async () => {
    setShowLoad(true);

    const data = {
      email: email,
      password: password,
      termsAccepted: true,
    };

    const response = await LoginService.Create({
      data: data,
    });

    setTimeout(() => {
      if (response.success) {
        setAccessToken(response.token);
        setTimeout(() => {
          if (response.role == 'admin') {
            return navigation('/admin');
          }
          return navigation('/usuario');
        }, 1000);
      }
    });
  };

  return (
    <C.container>
      <Navbar />
      <NavbarMobile />
      <C.containerForm data-aos="zoom-in">
        <C.form>
          <C.title>Termos e Condições</C.title>
          <C.messageTerms>
            Antes de entrar, você deve aceitar os termos e condições da
            plataforma
          </C.messageTerms>
          <C.checkBoxes>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    onChange={e => handleTermsAccepted(e.target.value)}
                  />
                }
                label="Aceito os"
              />
              <C.linkAcepteds href="/termos-e-condicoes" target={'_blank'}>
                Termos e condições
              </C.linkAcepteds>
            </div>
          </C.checkBoxes>
          <Button
            variant="contained"
            className="btn_login"
            onClick={handleLogin}
            disabled={showLoad || !termsAccepted}
          >
            {showLoad ? (
              <CircularProgress size={34} color={'primary'} />
            ) : (
              'Entrar'
            )}
          </Button>
        </C.form>
      </C.containerForm>
    </C.container>
  );
};

export default TermsAccept;
