import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.COLORS.BLACK_V2};
  @media screen and (max-width: 980px) {
    padding-bottom: 80px;
  }
`;

export const wraper = styled.div`
  max-width: 1980px;
  margin: 0 auto;
  padding: 0 10%;
  justify-content: space-between;

  @media only screen and (max-width: 1090px) {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const boxOne = styled.div`
  width: 100%;
  display: flex;
  padding-top: 40px;
  align-items: center;
  justify-content: space-between;

  img {
    max-height: 90px;
  }
  button {
    font-weight: 700;
    padding: 13px 35px;
    text-transform: none;
    border: 3px solid ${({ theme }) => theme.COLORS.YELLOW} !important;
    color: ${({ theme }) => theme.COLORS.YELLOW} !important;
    font-family: 'Montserrat';
    font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
  }
  @media only screen and (max-width: 1090px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    button {
      margin: 50px;
      width: 55%;
    }
  }

  @media only screen and (max-width: 580px) {
    img {
      max-height: 70px;
    }
    button {
      padding: 13px 20px;
    }
  }
`;

export const contact = styled.div`
  display: flex;
  align-items: center;
`;

export const instagram = styled.div`
  display: flex;
  margin-right: 30px;
`;

export const boxTwo = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1090px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const containerInfos = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1090px) {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const itemInfo = styled.div`
  margin-right: 120px;
  @media only screen and (max-width: 1090px) {
    width: 100%;
    margin: 0;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
`;

export const title = styled.h3`
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  margin-bottom: 30px;
  text-decoration: none;
  font-weight: 900;
  font-size: ${({ theme }) => theme.FONT_SIZES.XM}em;
  @media only screen and (max-width: 1090px) {
    margin-bottom: 10px;
  }
`;
export const infos = styled.p`
  color: #fff;
  text-decoration: none;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;
`;
export const link = styled.a`
  color: ${({ theme }) => theme.COLORS.GREEN_V2};
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.FONT_SIZES.MD}em;

  @media only screen and (max-width: 1090px) {
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }
`;

export const containerLinks = styled.div`
  width: 55%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1090px) {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1190px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 690px) {
    margin-top: 60px;
  }
`;

export const containerLogo = styled.div`
  display: flex;
  gap: 50;
  flex-wrap: wrap !important;
  padding-left: 30px;
  align-items: center;
  justify-content: right;

  .logo_sebrae {
    margin: 10px 0;
    max-height: 56px;
  }

  .logo_ags {
    margin: 10px 0;
    width: 200px;
  }
  @media only screen and (max-width: 1190px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1090px) {
    margin-top: 70px;
    padding: 0;
  }
`;

export const logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1190px) {
    flex-direction: column;
  }
`;

export const containerPoweredBy = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  top: 30px;
  align-items: center;
  justify-content: right;
  margin-bottom: 50px;
  @media only screen and (max-width: 1190px) {
    flex-direction: column;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    color: #fff;
  }
`;
