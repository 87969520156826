import React, { useEffect, useState } from 'react';
import * as C from './styles';
import LogoDefault from '../../../assets/logo-default.png';
import { Button } from '@mui/material';
import Navbar from '../../../components/Navbar';
import BannerLogo from '../../../assets/banner.png';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NavbarMobile from '../../../components/NavbarMobile';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import * as InnovationEnvironmentService from '../../../services/innovationEnvironment.service';
import { IInnovationEnvironment } from '../../../interfaces/innovationEnvironment.interface';
import Banner from '../../../components/Banner';
import Logo from '../../../components/Logo';

const DetailsInnovations = () => {
  const [innovation, seInnovation] = useState<IInnovationEnvironment>();

  const navigation = useNavigate();

  const location = useLocation();
  const pathSplitted: any = location.pathname.split('/');
  const id = pathSplitted[pathSplitted.length - 1];

  useEffect(() => {
    const getInnovation = async () => {
      await InnovationEnvironmentService.GetById(id).then(res => {
        seInnovation(res);
      });
    };

    getInnovation();
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <C.container>
        <Navbar secondary={true} />
        <NavbarMobile />
        {innovation?.bannerId ? (
          <Banner size={400} url={innovation?.bannerId} />
        ) : (
          <C.bannerDetails src={BannerLogo} alt="Banner Detalhes" />
        )}
        <C.headerDetails>
          <C.containerInfos>
            <C.containerImage className="image_card">
              {innovation?.logoId ? (
                <Logo size={180} url={innovation?.logoId} />
              ) : (
                <img src={LogoDefault} alt="Logo Startup" />
              )}
            </C.containerImage>
            <C.containerTitles>
              <C.headerTitle>{innovation?.name}</C.headerTitle>
              <C.headerSubTitle>{innovation?.contact.email}</C.headerSubTitle>
              <C.headerSubTitle>{innovation?.contact.phone}</C.headerSubTitle>
            </C.containerTitles>
            <C.containerButtonsSocial>
              {innovation?.socialMedia?.instagram && (
                <IconButton
                  onClick={() =>
                    window.open(
                      innovation?.socialMedia?.instagram?.includes('https://')
                        ? innovation?.socialMedia?.instagram
                        : `https://${innovation?.socialMedia?.instagram}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {innovation?.socialMedia?.facebook && (
                <IconButton
                  onClick={() =>
                    window.open(
                      innovation?.socialMedia?.facebook?.includes('https://')
                        ? innovation?.socialMedia?.facebook
                        : `https://${innovation?.socialMedia?.facebook}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <FacebookIcon />
                </IconButton>
              )}
              {innovation?.socialMedia?.linkedin && (
                <IconButton
                  onClick={() =>
                    window.open(
                      innovation?.socialMedia?.linkedin?.includes('https://')
                        ? innovation?.socialMedia?.linkedin
                        : `https://${innovation?.socialMedia?.linkedin}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {innovation?.socialMedia?.youtube && (
                <IconButton
                  onClick={() =>
                    window.open(
                      innovation?.socialMedia?.youtube?.includes('https://')
                        ? innovation?.socialMedia?.youtube
                        : `https://${innovation?.socialMedia?.youtube}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <YouTubeIcon />
                </IconButton>
              )}
              {innovation?.contact?.site && (
                <IconButton
                  onClick={() =>
                    window.open(
                      innovation?.contact?.site?.includes('https://')
                        ? innovation?.contact?.site
                        : `https://${innovation?.contact?.site}`,
                      '_blank',
                    )
                  }
                  aria-label="social"
                >
                  <PublicIcon />
                </IconButton>
              )}
            </C.containerButtonsSocial>
            {/* {logged && (
              <C.containerButtonEdit>
                <IconButton aria-label="social">
                  <EditRoundedIcon />
                </IconButton>
              </C.containerButtonEdit>
            )} */}
          </C.containerInfos>
        </C.headerDetails>
        <C.containerBody>
          <C.details>
            <C.detailTitle>{innovation?.title}</C.detailTitle>
            <pre>{innovation?.description}</pre>
          </C.details>
        </C.containerBody>
        <C.containerButtonReturn>
          <Button
            variant="text"
            className="button_return"
            onClick={() => navigation('/ambiente-inovacao')}
          >
            <ArrowBackIcon />
            Voltar
          </Button>
        </C.containerButtonReturn>
      </C.container>
      <Footer />
    </>
  );
};

export default DetailsInnovations;
