import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as C from './styles';
import { IconButton, MenuItem } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IMines } from '../../../../interfaces/mine.interface';
import EditIcon from '@mui/icons-material/EditRounded';
import Details from '../Details';

interface ICard {
  listPendeng: IMines;
  setButtonScroll: Dispatch<SetStateAction<boolean>>;
}

const CardPendeng = ({ listPendeng, setButtonScroll }: ICard) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [registerId, setRegisterId] = useState('');
  const [registerCategory, setRegisterCotegory] = useState('');
  useEffect(() => {
    setButtonScroll(!showEdit);
  }, [showEdit]);

  const handleEdit = (id: string, category: string) => {
    setRegisterId(id);
    setShowEdit(!showEdit);
    setRegisterCotegory(category);
  };
  if (!listPendeng.data?.length) return <></>;
  return (
    <>
      {listPendeng.data?.map((data, index) => {
        return (
          <C.card key={index}>
            <C.title>Tipo de Cadastro</C.title>
            <C.subTitle>{data.type}</C.subTitle>
            <C.containerInfos>
              <div>
                <span className="info">Nome do Cadastro</span>
                <p>{data.name}</p>
              </div>
              <div>
                <span>Data do Cadastro</span>
                <p>{new Date(data.created_at).toLocaleDateString()}</p>
              </div>
              <div>
                <label>Detalhes</label>
                <MenuItem>Visualizar</MenuItem>
              </div>
            </C.containerInfos>
            <C.containerButtonsActions>
              <IconButton
                onClick={() => {
                  handleEdit(data.id, data.category);
                }}
                className="btn_edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton className="btn_delete">
                <CloseRoundedIcon />
              </IconButton>
            </C.containerButtonsActions>
          </C.card>
        );
      })}
      {showEdit ? (
        <Details
          id={registerId}
          showModal={showEdit}
          setShowModal={setShowEdit}
          category={registerCategory}
        />
      ) : null}
    </>
  );
};

export default CardPendeng;
