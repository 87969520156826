import React from 'react';
import * as C from './styles';
import MenuItem from '@mui/material/MenuItem';
import { Dispatch, SetStateAction } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { theme } from '../../../styles/theme/themeMui';
import {
  businessModelOPT,
  businessSegmentsOPT,
  businessStageOPT,
  businessTypeOPT,
} from '../../../helpers/options';
import { MaskOnlyNumbers } from '../../../helpers/mask';
import { Alert } from '@mui/material';

interface IForm {
  employees: string;
  businesStage: string;
  businessModel: string;
  businessType: string;
  businessSegment: string;
  setEmployees: Dispatch<SetStateAction<string>>;
  setBusinessType: Dispatch<SetStateAction<string>>;
  setBusinessStage: Dispatch<SetStateAction<string>>;
  setBusinessModel: Dispatch<SetStateAction<string>>;
  setBusinessSegment: Dispatch<SetStateAction<string>>;
  showAlertErr: boolean;
  visualization: boolean;
}

const Form5 = ({
  employees,
  businessType,
  setEmployees,
  businesStage,
  businessModel,
  businessSegment,
  setBusinessType,
  setBusinessModel,
  setBusinessStage,
  setBusinessSegment,
  showAlertErr,
  visualization = false,
}: IForm) => {
  return (
    <C.container>
      <C.titleMaster>Classificação*</C.titleMaster>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Segmento*</C.titleTextField>
          <Select
            disabled={visualization}
            sx={theme}
            className="select"
            value={businessSegment}
            onChange={e => setBusinessSegment(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessSegmentsOPT.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {showAlertErr && businessSegment === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Estágio*</C.titleTextField>
          <Select
            disabled={visualization}
            sx={theme}
            className="select"
            value={businesStage}
            onChange={e => setBusinessStage(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessStageOPT.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {showAlertErr && businesStage === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.textFieldWraper50>
        <C.boxTextField>
          <C.titleTextField>Modelo de Negócio*</C.titleTextField>
          <Select
            disabled={visualization}
            sx={theme}
            className="select"
            value={businessModel}
            onChange={e => setBusinessModel(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessModelOPT.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {showAlertErr && businessModel === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
        <C.boxTextField>
          <C.titleTextField>Tipo de Negócio*</C.titleTextField>
          <Select
            disabled={visualization}
            sx={theme}
            className="select"
            value={businessType}
            onChange={e => setBusinessType(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {businessTypeOPT.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {showAlertErr && businessType === '' ? (
            <Alert
              variant="outlined"
              severity="error"
              className="alert"
              sx={{ mt: 1 }}
            >
              Informação Obrigatória
            </Alert>
          ) : null}
        </C.boxTextField>
      </C.textFieldWraper50>
      <C.space />
      <C.textFieldWraper100>
        <C.titleTextField>Número de Colaboradores*</C.titleTextField>
        <C.textField
          disabled={visualization}
          placeholder="Digite o total de colaboradores"
          value={employees}
          onChange={e => setEmployees(MaskOnlyNumbers(e.target.value))}
        />
        {showAlertErr && Number(employees) === 0 ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Número de colaboradores deve ser no mínimo 1
          </Alert>
        ) : null}
        {showAlertErr && employees === '' ? (
          <Alert
            variant="outlined"
            severity="error"
            className="alert"
            sx={{ mt: 1 }}
          >
            Informação Obrigatória
          </Alert>
        ) : null}
      </C.textFieldWraper100>
    </C.container>
  );
};
export default Form5;
