import jwt_decode from 'jwt-decode';
export function decodeToken(token: string): any {
  try {
    const infos = jwt_decode(token);

    return infos;
  } catch (error) {
    return null;
  }
}
